import * as React from "react";
import { IFieldProps } from "./index";
import * as moment from 'moment';

import { DateTimePicker } from 'react-widgets';

var momentLocalizer = require('react-widgets-moment');
momentLocalizer(moment);

//The react-widget DateTimePicker time prop has been hard coded to true/false based on DateTime or Date widget. This is so we can
// automate momentJs datetime parsing without breaking the Date only picker in the instance when the Databse returns only date with momentjs.local()
//causing the UI to go out of sync between creating and retrieving the date
//only time to display dd/mm/yyyy is on the UI, everything else uses ISO 8601(YYYY-MM-DD), including the client date value calculation
export const DATE_DISPLAY_FORMAT = "DD/MM/YYYY";
export const DATETIME_DISPLAY_FORMAT = "DD/MM/YYYY hh:mm a";
export const DATE_SERVER_FORMAT = "YYYY/MM/DD";

export interface IRwDateTimePickerProps extends IFieldProps {
    rootClassName?: string;
    label?: any;
    parse?: ((str: string) => Date) | string[] | string;
    format?: any;
    step?: number;
    disabled?: boolean;
}


export const RwDateTimePicker = (props: IRwDateTimePickerProps) => {
    const { format, disabled } = props;

    let touched = false, error = null;
    const { meta } = props;

    if (meta) {
        touched = meta.touched;
        error = meta.error;
    }


    return (
        <div className={(props.rootClassName ? props.rootClassName : "field-container")}>
            <div className="input-group">

                {(props.label ?
                    <span className="input-group-addon">
                        {props.label}
                        {String.fromCharCode(160)}
                        {touched && error && <span className="label label-danger">{error}</span>}

                    </span> :
                    <noscript />)}
                <DateTimePicker
                    onChange={(val) => {
                        if (val) {
                            var temp = moment.utc(val);
                            props.input.onChange(temp.toISOString());
                        }
                    }}
                    {...props}
                    disabled={disabled}
                    value={(props.input.value ? moment.utc(props.input.value).local().toDate() : undefined)}
                    format={(format ? format : DATETIME_DISPLAY_FORMAT)}
                    time={true}
                />

            </div>
        </div>
    )
}


export interface IRwDatePickerProps extends IFieldProps {
    rootClassName?: string;
    label?: any;

    /** optional method to explicitly parse the date. Defaults to using moment with the dateFormat */
    parse?: (str: string) => Date;
    
    format?: any;
    disabled?: boolean;

    /** the date format 
     * @default DD/MM/YYYY DATE_DISPLAY_FORMAT */
    dateFormat?: string;

    /** the format the server expects the date
     * @default YYYY/MM/DD
     */
    serverFormat: string;
}


/**
 * Wraps a React-Widgets Date picker.
 * The displayed value will be in the specified format, but the input will be serialised as YYYY-MM-DD ONLY since we want to discard the time portion
 * @param props
 */
export const RwDatePicker = (props: IRwDatePickerProps) => {
    const { format, disabled } = props;

    let touched = false, error = null;
    const { meta } = props;
    if (meta) {
        touched = meta.touched;
        error = meta.error;
    }
    const parse = props.parse || ((val) => moment(val, props.dateFormat || DATE_DISPLAY_FORMAT).toDate());
    const serverFormat = props.serverFormat || "YYYY-MM-DD";

    return (
        <div className={(props.rootClassName ? props.rootClassName : "field-container")}>
            <div className="input-group">
                {(props.label ?
                    <span className="input-group-addon">
                        {props.label}
                        {String.fromCharCode(160)}
                        {touched && error && <span className="label label-danger">{error}</span>}

                    </span> :
                    <noscript />)}
                <DateTimePicker
                    onChange={(val) => {
                        if (val) {
                            props.input.onChange(moment(val).format(serverFormat));
                        } else {
                            props.input.onChange(null);
                        }
                    }}
                    disabled={disabled}
                    parse={parse}
                    {...props}
                    value={(props.input.value ? moment(props.input.value, serverFormat).toDate() : undefined)}
                    format={(format ? format : DATE_DISPLAY_FORMAT)}
                    time={false}
                />
            </div>
        </div>
    )
}

