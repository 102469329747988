import { ISurveyChoiceItem } from "./fields/choice";

const CHOICE_DELIMITER = "|";

export class RemoteChoiceAnswerSet {
    private values: string[]
    private otherAnswer?: string;
    constructor(answer?: string) {
        const vals = (answer || "").split(CHOICE_DELIMITER).filter(v => v != "");
        const other = vals.filter(v => v.indexOf("o:") >= 0)[0];
        this.values = vals.filter(v => v.indexOf("o:") < 0);
        if (other) {
            this.otherAnswer = other.substring(2);
        }

    }
    public getAnswers(): string[] {
        return this.values;
    }
    public getOtherAnswer(): string | undefined {
        return this.otherAnswer;
    }
    public updateItems(items: ISurveyChoiceItem[]): string[] {
        const invalid = [] as string[];
        //Clear checked state first
        for (const it of items) {
            it.checked = false;
        }
        //For each current value, add to invalid list if no matches, otherwise set
        //the checked state on the matching item
        for (const v of this.values) {
            const match = items.filter(i => i.value == v)[0];
            if (match) {
                match.checked = true;
            } else {
                invalid.push(v);
            }
        }
        //Remove invalid items from current set of values
        if (invalid.length > 0) {
            this.values = this.values.filter(v => invalid.indexOf(v) < 0);
        }
        return invalid;
    }
    public encodeThis(): string {
        let encoded = this.values.join(CHOICE_DELIMITER);
        if (this.otherAnswer) {
            if (encoded != "") {
                encoded += `${CHOICE_DELIMITER}o:${this.otherAnswer}`;
            } else {
                encoded = `o:${this.otherAnswer}`;
            }
        }
        return encoded;
    }
    public static encode(selectedValues: any[], otherAnswer: string): string {
        const values = [...(selectedValues || [])].filter(v => v != null);
        if (otherAnswer != null && otherAnswer != "") {
            values.push(`o:${otherAnswer}`);
        }
        return values.join(CHOICE_DELIMITER);
    }
}