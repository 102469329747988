//TODO: If we ever have a survey npm module, this should be moved there

import { ISurveyContainerContext } from "./context";
import { ISurveyContainerTheme } from "./container-theme";

export interface IPluginQuestionOptions {
    qid: number;
    questionRef: React.Ref<any>;
    isReadOnly: boolean;
    label?: string;
    context: ISurveyContainerContext;
    theme: ISurveyContainerTheme;
    onChange: (value: any) => void;
    answer: any;
    pluginConfig: any;
}

export interface ISurveyPluginQuestionType {
    pluginId: string;
    factory: (options: IPluginQuestionOptions) => JSX.Element;
}

const SURVEY_PLUGINS: { [id: string]: ISurveyPluginQuestionType } = {};

export function registerPluginQuestionType(plugin: ISurveyPluginQuestionType) {
    SURVEY_PLUGINS[plugin.pluginId] = plugin;
}

export function getPlugin(id: string): ISurveyPluginQuestionType {
    return SURVEY_PLUGINS[id];
}