//TODO: If we ever have a survey npm module, this should be moved there

/** 
 * Supported container styles
 */
export enum SurveyContainerStyle {
    Info,
    Error,
    Success,
    RespondentDetails,
    SurveyContainer
}

/**
 * Supported button styles
 */
export enum SurveyButtonStyle {
    Primary,
    Success,
    Info,
    Warning,
    Danger
}

/** 
 * Defines the theming contract of a survey component
 */
export interface ISurveyContainerTheme {
    containerClass: string;
    itemClass: string;
    itemLabelClass: string;
    getItemClassForQuestion(qkind: string, defaultClass: string): string;
    getItemLabelClassForQuestion(qkind: string, defaultClass: string): string;
    getContainerClasses(style: SurveyContainerStyle): string;
    getButtonClass(desiredStyle?: SurveyButtonStyle): string;
    getFieldContainerClass(): string;
    getTextboxClass(): string;
    getQuestionLabelClass(): string;
    getQuestionBodyClass(): string;
    getSelectClass(): string;
}