import { ICriteriaViewContext } from './context';

// Same names as enum EthnicityType
export const ETHNICITY_TYPES: ITextValuePair[] = [
    { Text: "All", Value: "All" },
    { Text: "VerifiedOnly", Value: "VerifiedOnly" },
    { Text: "UnverifiedOnly", Value: "UnverifiedOnly" }
];

//TODO: Typewriter
export type NodeQuestionCategory = "Groups" | "Area" | "Demographic" | "Interaction" | "Voter ID" | "Event" | "Volunteering" | "Roll" | "Utility" | "Other" | "Contact" | "Survey";

//TODO: Typewriter
export type NodeQuestionType =
    "group" |
    "issue" |
    "eventname" |
    "eventtype" |
    "notscheduledfor" |
    "confirmstatus" |
    "attendstatus" |
    "notcontacted" |
    "addedin" |
    "volunteerfor" |
    "electorate" |
    "noelectorate" |
    "noactivity" |
    "confidence" |
    "party" |
    "election" |
    "category" |
    "votestatus" |
    "vote" |
    "votedate" |
    "details" |
    "todo" |
    "done" |
    "topic" |
    "idate" |
    "custom" |
    "unit" |
    "number" |
    "ccd" |
    "sa1" |
    "state" |
    "postcode" |
    "habitat" |
    "eroll" |
    "cycle" |
    "statusntr" |
    "statusnmi" |
    "status" |
    "suburb" |
    "street" |
    "age" |
    "lastname" |
    "firstname" |
    "gender" |
    "ethnicity" |
    "language" |
    "religion" |
    "countryofbirth" |
    "leaning" |
    "birthday" |
    "occupation" |
    "elecfed" |
    "elecfednew" |
    "elecstate" |
    "elecstatenew" |
    "eleclocal" |
    "isearchType" |
    "iscope" |
    "vimportType" |
    "tag" |
    "splitter" |
    "preferredcontactmethod" |
    RelatedSets.Contact |
    RelatedSets.Survey |
    "rts" |
    "nocontacts" |
    "postalrts" |
    "noemail";

export function GetCombinators(): { name: string, value: NodeCombinatorType }[] {
    return [
        { name: "All", value: "All" },
        { name: "Any", value: "Any" },
        { name: "None", value: "None" }
    ];
};

export interface IAutoCompleteCallbackArgs {
    options: { value: string, label: string }[];
    complete?: boolean;
}

export type AutoCompleteCallback = (error: Error, args: IAutoCompleteCallbackArgs) => void;

export interface IAdvancedListBuilderCriteriaNodeProps {
    style?: any;
    debug?: boolean;
    node: IAdvancedListBuilderNode;
    layoutRenderer?: LayoutRendererTemplate; //TODO: Should move to context so it is implicitly provided to every child element
    optionsLayoutRenderer?: NodeOptionsEditorLayoutTemplate; //TODO: Should move to context so it is implicitly provided to every child element
    nodeCollectionOptionsLayoutRenderer?: NodeCollectionEditorOptionsLayoutTemplate; //TODO: Should move to context so it is implicitly provided to every child element
}

export interface IAdvancedListBuilderCriteriaNodeEditorProps extends IAdvancedListBuilderCriteriaNodeProps {

}

export enum GenericModeModifier {
    Text,
    Range,
    Any,
    None
}

export type GenericEventHandler = (e: any) => void;

export type AnyReactElement = JSX.Element | JSX.Element[] | undefined;

export type NodeCollectionEditorOptionsLayoutTemplate = (context: ICriteriaViewContext, addCriteriaBtn: AnyReactElement, addGroupBtn: AnyReactElement, addSelectedBoundariesBtn: AnyReactElement, removeMeBtn: AnyReactElement) => JSX.Element;

export type LayoutRendererTemplate = (context: ICriteriaViewContext | undefined, label: AnyReactElement, modeSelector: AnyReactElement, valueEditor: AnyReactElement, nodeOpts: AnyReactElement) => AnyReactElement;

export type NodeOptionsEditorLayoutTemplate = (context: ICriteriaViewContext, demoteBtn: AnyReactElement, promoteBtn: AnyReactElement, children: any, removeBtn: AnyReactElement) => JSX.Element;

/**
 * TS interface proxy for System.Web.Mvc.SelectListItem
 */
export interface ISelectListItem extends ITextValuePair {
    /**
     * Gets or sets a value that indicates whether this System.Web.Mvc.SelectListItem
     * is disabled.
     */
    Disabled: boolean;
    /**
     * Gets or sets a value that indicates whether this System.Web.Mvc.SelectListItem
     * is selected.
     */
    Selected: boolean;
}

export type ItemsCallback = (values: ITextValuePair[]) => void;

export interface ITextValuePair {
    /**
     * Gets or sets the text of the selected item.
     */
    Text: string;
    /**
     * Gets or sets the value of the selected item.
     */
    Value: string;
}

/**
 * Models a criteria node in the Advanced List Builder
 */
export interface IAdvancedListBuilderNode {
    items?: IAdvancedListBuilderNode[];
    rst?: string;
    /**
     * Determines how child criteria is to be logically combined. This has dual meaning:
     * 
     *  1. If items is set, this controls how the child criteria is logically combined when evaluated. This also signifies that this node is a group
     *  2. If a is a delimited string, this controls how said values are to be evaluated when converted into a criteira.
     */
    op?: NodeCombinatorType;
    q: string | undefined;
    m?: NodeRangeCondition | NodeTextCondition | "";
    a: string | undefined;
    b?: string;
    xdata?: any;
    clientId: string;
    parentId: string | undefined;
    validationError?: string;
    validationWarning?: string;
}

export interface CriteriaNodeProvider {
    type: string;
    label: string;
    category?: string;
    /**
     * The React element factory for rendering the editor component for this criteria node editor
     */
    factory?: (node: IAdvancedListBuilderNode, style?: any, layoutRenderer?: LayoutRendererTemplate, optionsLayoutRenderer?: NodeOptionsEditorLayoutTemplate) => JSX.Element;
    /**
     * If the criteria node is a lookup, indicates if an appropriate data store has been set up for it.
     * 
     * This is a sanity check function called by the List Builder UI to ensure all lookup registration is in order.
     */
    isLookupConnectedToDataStore?: () => boolean;
    isGroupEditor?: boolean;
    genericModifier?: GenericModeModifier;
    /**
     * Indicates the criteria related set for this node provider
     */
    relatedSet?: string;
    /**
     * Indicates if this is criteria node provider is for a dynamic related set
     */
    dynamicRelatedSet?: string;
    /**
     * Indicates the node id where this criteria node provider should be made availble for
     */
    dynamicParentId?: string;
    /**
     * Indicates if this criteria node has a geographic representation (for display on a map)
     */
    isGeographic: boolean;
    createAndAddNode: (parentNode: IAdvancedListBuilderNode, nodeMap: any) => [IAdvancedListBuilderNode /*node to add as child*/, IAdvancedListBuilderNode /*node in question*/];
}

//TODO: Typewriter
export enum RelatedSets {
    Interaction = "interaction",
    VoterID = "vote",
    Event = "event",
    Contact = "contact",
    Survey = "survey"
}

export type NodeCombinatorType = "All" | "Any" | "None";
export type NodeRangeCondition = "is" | "le" | "lt" | "ge" | "gt";
export type NodeTextCondition = "starts" | "pattern" | "isnull" | "notnull";

export type RemoveBoundaryCallback = (name: string, callback: (removed: boolean) => void) => void;
export type DrawCustomBoundaryCallback = (callback: (wkt: string, xdata?: any) => void) => void;
export type SelectedBoundaryRequestCallback = (selectedFeatures: any) => void;


export function isCriteriaNodeTrue(node: IAdvancedListBuilderNode): boolean {
    if (node) {
        const a = (node.a || "").toLowerCase();
        return a == "1" || a == "true";
    }
    return false;
}

export function isGroupCriteriaNode(node: IAdvancedListBuilderNode) {
    return node.op != null
        && node.items != null;
}