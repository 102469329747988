import * as React from "react";
import * as ReactDOM from "react-dom";
import { SurveyContainer, SurveyContainerMode } from "./container";
import { ISurveyContainerTheme } from "@electrac/plugin";
import * as ElectracAPI from "@electrac/model";
import { ISurvey, ISurveyDraft } from "./contracts";


export interface ISurveyResponseOptions {
    survey: ISurvey;
    answers: ISurveyDraft;
    persuasionModel: ElectracAPI.StandardVoteOutput;
    voteModel: ElectracAPI.VoteModel;
    theme?: ISurveyContainerTheme;
}

export class SurveyResponseViewModel {
    private node?: Element;
    // private component: SurveyContainer | null = null;
    private options: ISurveyResponseOptions;
    constructor(options: ISurveyResponseOptions) {
        this.options = options;
    }
    mount(node: Element) {
        this.node = node;
        
        const surveyModel = (this.options.survey);
        const draftModel = (this.options.answers);
        ReactDOM.render(<SurveyContainer
            token={true}
            // ref={(r) => this.component = r}
            mode={SurveyContainerMode.Manual}
            isOnline={true}
            survey={surveyModel}
            draft={draftModel}
            theme={this.options.theme}
            persuasionModel={this.options.persuasionModel}
            voteModel={this.options.voteModel}
            isReadOnly={true} />, this.node);
    }
}