import * as React from "react";

//TODO: @electrac/components

export interface IKendoSpinnerProps {
    /**
     * The loading message
     */
    message: string;
    /**
     * True if full width/height of its containing element
     */
    full?: boolean;
}

/**
 * A load spinner component using kendo styling
 */
export class KendoSpinner extends React.Component<IKendoSpinnerProps, any> {
    constructor(props: IKendoSpinnerProps) {
        super(props);
    }
    render(): JSX.Element {
        if (this.props.full === true) {
            let style = {
                width: "100%",
                height: "100%"
            };
            return (<div className="k-loading-mask" style={style}>
                <span className="k-loading-text">{this.props.message}</span>
                <div className="k-loading-image">
                    <div className="k-loading-color"></div>
                </div>
            </div>);
        } else {
            return (<div>
                <span className="k-loading-text">{this.props.message}</span>
                <div className="k-loading-image">
                    <div className="k-loading-color"></div>
                </div>
            </div>);
        }
    }
}