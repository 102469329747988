/**
 * Nameof.ts 
 * 
 * Provides a convenience nameof pseudo-operator to avoid "magic string" property name references
 * 
 * Source for this trick: https://schneidenbach.gitbooks.io/typescript-cookbook/nameof-operator.html
 */


/**
 * Provides a callable nameof pseudo-operator for the given type T
 */
export const nameofFactory = <T>() => (name: keyof T) => name;