import * as React from 'react';
import FormGroup = require("react-bootstrap/lib/FormGroup");
import ControlLabel = require("react-bootstrap/lib/ControlLabel");
import FormControl = require("react-bootstrap/lib/FormControl");
import HelpBlock = require("react-bootstrap/lib/HelpBlock");
import InputGroup = require("react-bootstrap/lib/InputGroup");
import InputGroupAddon = require("react-bootstrap/lib/InputGroupAddon");
import Col = require("react-bootstrap/lib/Col");

//TODO: @electrac/components

/**
 * Valid HTML input field types
 */
export type InputFieldType = "text" | "number" | "email" | "password" | "textarea";

/**
 * Valid feedback styles
 */
export type ValidationFeedbackType = "warning" | "error" | "success";

export type BsInputSize =  "xsmall" | "small" | "large" | "lg" | "sm" | "xs";

export interface IAddonSpec {
    generateAddonWrapper: boolean;
    element: JSX.Element;
}

function isAddonSpecArray(obj: IAddonSpec|IAddonSpec[]): obj is IAddonSpec[] {
    return obj instanceof Array;
}

export interface IInputFieldProps {
    /**
     * The field control id
     */
    id: string;
    /**
     * The input field type
     */
    type?: InputFieldType;
    /**
     * The field value
     */
    value?: string;
    /**
     * The label for this field
     */
    label?: string;
    /**
     * Content to prepend the field with
     */
    addonBefore?: IAddonSpec|IAddonSpec[];
    /**
     * Content to append the field with
     */
    addonAfter?: IAddonSpec|IAddonSpec[];
    /**
     * Feedback content to append to the field with
     */
    feedback?: any;
    /**
     * Help text for this field
     */
    helpText?: string;
    /**
     * The field placeholder text
     */
    placeholder?: string;
    /**
     * Control size
     */
    size?: BsInputSize;
    /**
     * Raised when the field's value changes
     */
    onChange?: (value: string) => void;
    /**
     * Raised when the field loses focus
     */
    onBlur?: (value: string) => void;
    /**
     * Any extra input attributes to transfer to the underlying HTML input field
     */
    extraInputProps?: any;
    /**
     * The feedback style to apply for the input field. Leave empty for no styling
     */
    validationState?: ValidationFeedbackType;
    /**
     * Indicates if this field is read-only. If true, any change handlers will have no effect and this field
     * will be rendered out as a static content control
     */
    isReadOnly?: boolean;
    /**
     * If true, will put the label on the same "row" as the field. Must wrap this component with a <Form> component with horizontal=true. Default is false
     */
    columnLayout?: boolean;
    /**
     * The grid column size of the label "column". Must be between 1 - 11. Has no effect if columnLayout is false or not specified
     */
    labelColumnSize?: number;
}

const BS_MAX_GRID_LENGTH = 12;

export function renderInputFieldCommon(props: IInputFieldProps, extraInputProps?: any) {
    const onChange = props.onChange ? (e: any) => props.onChange!(e.target.value) : null;
    const onBlur = props.onBlur ? (e: any) => props.onBlur!(e.target.value) : null;
    const extraProps: any = extraInputProps ? extraInputProps : {};
    //const label = props.label ? <ControlLabel>{props.label}</ControlLabel> : null;
    const help = props.helpText ? <HelpBlock>{props.helpText}</HelpBlock> : null;
    const field = props.isReadOnly === true ? (<FormControl.Static>{props.value}</FormControl.Static>) : (
        <FormControl type={props.type}
                     value={props.value}
                     placeholder={props.placeholder}
                     onChange={onChange}
                     onBlur={onBlur}
                     {...extraProps} />); 
    let fieldContainer = field;
    if (props.addonBefore || props.addonAfter) {
        let addonsBefore: any = null;
        let addonsAfter: any = null;
        if (props.addonBefore) {
            const before = props.addonBefore;
            if (isAddonSpecArray(before)) {
                addonsBefore = before.map((v, i) => {
                    if (v.generateAddonWrapper) {
                        return <InputGroupAddon key={i}>{v.element}</InputGroupAddon>;
                    } else {
                        return v.element;
                    }
                });
            } else {
                if (before.generateAddonWrapper) {
                    addonsBefore = <InputGroupAddon>{before.element}</InputGroupAddon>;
                } else {
                    addonsBefore = before.element;
                }
            }
        }
        if (props.addonAfter) {
            const after = props.addonAfter;
            if (isAddonSpecArray(after)) {
                addonsAfter = after.map((v, i) => {
                    if (v.generateAddonWrapper) {
                        return <InputGroupAddon key={i}>{v.element}</InputGroupAddon>;
                    } else {
                        return v.element;
                    }
                });
            } else {
                if (after.generateAddonWrapper) {
                    addonsAfter = <InputGroupAddon>{after.element}</InputGroupAddon>;
                } else {
                    addonsAfter = after.element;
                }
            }
        }
        fieldContainer = <InputGroup>
            {addonsBefore}
            {field}
            {addonsAfter}
        </InputGroup>;
    } 
    return <FormGroup controlId={props.id} validationState={props.validationState} bsSize={props.size}>
        {(() => {
            if (props.label) {
                if (props.columnLayout == true && props.labelColumnSize! > 0 && props.labelColumnSize! < BS_MAX_GRID_LENGTH) {
                    return <Col componentClass={ControlLabel} sm={props.labelColumnSize}>{props.label}</Col>;
                } else {
                    return <ControlLabel>{props.label}</ControlLabel>;
                }
            } else {
                return null;
            }
        })()}
        {(() => {
            if (props.columnLayout == true && props.labelColumnSize! > 0 && props.labelColumnSize! < BS_MAX_GRID_LENGTH) {
                return <Col sm={BS_MAX_GRID_LENGTH - props.labelColumnSize!}>{fieldContainer}</Col>;
            } else {
                return fieldContainer;
            }
        })()}
        <FormControl.Feedback />
        {help}
    </FormGroup>;
}

//TODO: Make this into a stateful component, with configurable value change propagation (change/blur)

/**
 * This represents a convenience stateless component wrapper for bootstrap form input controls
 */
export const InputField = (props: IInputFieldProps) => {
    const extraProps: any = { ...props.extraInputProps };
    if (props.type == "textarea") {
        extraProps.componentClass = props.type;
    }
    return renderInputFieldCommon(props, extraProps);
};