import * as React from "react";

import * as moment from "moment";
import { SurveyButtonStyle, SurveyContainerStyle, ISurveyContainerContext } from "@electrac/plugin";
import { ShiftDisplayModel, EventScheduleModel, UpcomingEventModel } from "@electrac/model";
import { ISurveyQuestionCommonProps, SurveyFieldDescription } from "./Common";
import uniq = require("lodash.uniq");

export interface ISurveyAddToEventProps extends ISurveyQuestionCommonProps {
    context: ISurveyContainerContext;
    onChange: (args: any) => void;
    answer: any;
}

//TODO: This currently assumes one selected elector. This assumption can easily break on the mobile app, where multiple
//electors can be selected

export class SurveyAddToEvent extends React.Component<ISurveyAddToEventProps, {
    selectedEvent?: UpcomingEventModel,
    isOpen: boolean,
    selectedEventShifts?: any,
    currentEvents: null | UpcomingEventModel[]
}> {

    constructor(props: ISurveyAddToEventProps) {
        super(props);
        this.state = {
            isOpen: false,
            selectedEventShifts: null,
            currentEvents: null
        };

    }
    private onShiftSelectionMade = (e) => {
        const { selectedEvent } = this.state;
        if (!selectedEvent) return;

        const eventId = selectedEvent.Id;

        const date = selectedEvent.Date;
        const shiftId = e.target.getAttribute("data-shift-id");
        const answer = JSON.parse(this.props.answer || "{}") as EventScheduleModel;
        if (answer.eventId != eventId) {
            answer.eventId = eventId;
            answer.scheduled = [];
        }
        answer.date = date;
        answer.eventId = eventId;
        if (e.target.checked) {
            answer.scheduled = uniq([shiftId, ...answer.scheduled]);
        } else {
            answer.scheduled = answer.scheduled.filter(s => s != shiftId);
        }
        this.props.onChange(JSON.stringify(answer));
    }
    private onSelectedEventChanged = (e) => {
        // value is going to be an encoded EventScheduleModel
        const value = JSON.parse(e.target.value) as UpcomingEventModel;
        this.setState({ selectedEvent: value, selectedEventShifts: null });
        this.props.context.getShiftsForEvent(value.Id).then(res => {
            this.setState({ selectedEventShifts: res });
        });
    }
    private onAddToEvent = (e) => {
        this.setState({ isOpen: true });
        this.props.context.getEvents().then(res => {
            this.setState({ currentEvents: res });
        });
    }
    private onDone = (e) => {
        this.setState({ isOpen: false });
    }
    render(): JSX.Element {
        const { label, description, theme, context, isReadOnly } = this.props;
        const { isOpen, currentEvents, selectedEvent, selectedEventShifts } = this.state;
        const selectedElectors = context.getSelectedElectors();
        if (!context.isOnline()) {
            return <div className={`${theme && theme.getFieldContainerClass()}`}>
                <label className={theme && theme.itemLabelClass}>{label}</label>
                <div className={`${theme && theme.getContainerClasses(SurveyContainerStyle.Error)}`}>
                    <strong>WARNING: This question requires online access to display and you are determined to be either offline or have limited connectivity</strong>
                </div>
            </div>;
        }
        const parsedAnswer = JSON.parse(this.props.answer || "{}") as EventScheduleModel;

        return <div className={`${theme && theme.getFieldContainerClass()} survey-add-to-event`}>
            <div className={theme && theme.getQuestionLabelClass()}>
                <label className={theme && theme.itemLabelClass}>{label}</label>
                <SurveyFieldDescription description={description} />
            </div>
            <div className={theme && theme.getQuestionBodyClass()}>
                {(() => {
                    if (isReadOnly === true) {
                        const sched = (parsedAnswer.scheduled || []);
                        if (sched.length > 0) {
                            return <div className={theme && theme.getContainerClasses(SurveyContainerStyle.Info)}>
                                <p>The currently selected person has been scheduled to {sched.length} shifts</p>
                            </div>;
                        }
                    } else if (isOpen === true) {
                        return <div>
                            {!currentEvents ? <strong>Checking for available events ...</strong>
                                : <div>
                                    <select onChange={this.onSelectedEventChanged}>
                                        <option value={undefined}>(Select an event...) </option>
                                        {(currentEvents as UpcomingEventModel[]).map((ev, idx, arr) => {
                                            // encode the data
                                            // this has an ID and a date which is important for scheduling to recurring events
                                            const evData = JSON.stringify(ev);
                                            return <option key={evData} value={evData}>{ev.Name}</option>;
                                        })}
                                    </select>
                                    {selectedEvent != null &&
                                        (!selectedEventShifts ?
                                            <div><strong>Fetching shifts for event ...</strong></div>
                                            :

                                            <div>
                                                {/*NOTE: This is a model-imposed limitation*/}
                                                {parsedAnswer.eventId != selectedEvent.Id && parsedAnswer.date != selectedEvent.Date && (parsedAnswer.scheduled || []).length > 0
                                                    && <strong>WARNING: You have selected shifts from a different event, they will be cleared if you select any shifts from this event</strong>}

                                                {(selectedEventShifts as ShiftDisplayModel[]).map((shift, index, arr) => {
                                                    const start = moment(shift.Start).local().format("LT");
                                                    const end = moment(shift.End).local().format("LT");
                                                    const isChecked = (parsedAnswer.eventId == selectedEvent.Id && parsedAnswer.date == selectedEvent.Date)
                                                        && (parsedAnswer.scheduled.filter(s => s == shift.Id).length > 0);
                                                    return <div key={shift.Id}>
                                                        <label>
                                                            <input type="checkbox" checked={isChecked} onChange={this.onShiftSelectionMade} data-shift-id={shift.Id} /> {shift.Name} ({start} - {end})
                                                            </label>
                                                    </div>;
                                                })}
                                            </div>)}


                                    <button type="button" className={theme && theme.getButtonClass(SurveyButtonStyle.Success)} onClick={this.onDone}>Done</button>
                                </div>
                            }
                    </div>;
                    }
                    return <div>
                        {(() => {
                            if (selectedElectors != null && selectedElectors.length > 0) {
                                const sched = (parsedAnswer.scheduled || []);
                                if (parsedAnswer.eventId != null && sched.length > 0) {
                                    return <div className={theme && theme.getContainerClasses(SurveyContainerStyle.Info)}>
                                        <p>The currently selected person has been scheduled to {sched.length} shifts. Selecting a different person will reset this question</p>
                                        <button type="button" className={theme && theme.getButtonClass(SurveyButtonStyle.Primary)} onClick={this.onAddToEvent}>Change scheduled shifts</button>
                                    </div>;
                                } else {
                                    return <button type="button" className={theme && theme.getButtonClass(SurveyButtonStyle.Primary)} onClick={this.onAddToEvent}>Add to event</button>;
                                }
                            } else {
                                return <strong>Please select a person first</strong>;
                            }
                        })()}
                    </div>;
                }
                )()}
            </div>
        </div>;
    }
}