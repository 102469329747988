import * as React from "react";
import { SurveyButtonStyle, SurveyContainerStyle } from "@electrac/plugin";
import { ISurveyContainerContext } from "@electrac/plugin";
import { ISurveyQuestionCommonProps, SurveyFieldDescription } from "./Common";

export interface ISurveyPersuasionProps extends ISurveyQuestionCommonProps {
    context: ISurveyContainerContext;
    onChange: (args: any) => void;
    answer: any;
}

export class SurveyPersuasion extends React.Component<ISurveyPersuasionProps, any> {
    private fnActivated: (e) => void;
    private fnUndo: (e) => void;
    constructor(props) {
        super(props);
        this.fnActivated = this.onActivated.bind(this);
        this.fnUndo = this.onUndo.bind(this);
    }
    private onActivated(e) {
        const model = this.props.context.getPersuasionModel();
        this.props.onChange(JSON.stringify(model));
    }
    private onUndo(e) {
        this.props.onChange(null);
    }
    render(): JSX.Element {
        const { theme, label, description, answer, context } = this.props;
        const model = context.getPersuasionModel();
        return <div className={theme && theme.getFieldContainerClass() }>
            <div className={theme && theme.getQuestionLabelClass() }>
                <label className={theme && theme.itemLabelClass}>{label}</label>
                <SurveyFieldDescription description={description} />
            </div>
            <div className={theme && theme.getQuestionBodyClass() }>
            {(() => {
                if (answer) {
                    return <div className={theme && theme.getContainerClasses(SurveyContainerStyle.Info)}>
                        <strong>Moved closer to Labor <a href="#" onClick={this.fnUndo}>(undo)</a></strong>
                    </div>;
                } else {
                    if (model) {
                        return <div>
                            <button type="button" onClick={this.fnActivated} className={theme && theme.getButtonClass(SurveyButtonStyle.Primary)}>Moved closer to Labor</button>
                        </div>;
                    } else {
                        return <div className={theme && theme.getContainerClasses(SurveyContainerStyle.Error) }>
                            <strong>Cannot render question. Missing persuasion model</strong>
                        </div>;
                    }
                }
            })() }
            </div>
        </div>;
    }
}