import * as React from "react";
import { SurveyContainerStyle, ISurveyContainerContext } from "@electrac/plugin";
import { ISurveyQuestionCommonProps, SurveyFieldDescription } from "./Common";
// import { Multiselect } from "react-widgets";

import * as Multiselect from 'react-widgets/lib/Multiselect';
import debounce = require("lodash.debounce");

export interface ISurveyAddElectorateProps extends ISurveyQuestionCommonProps {
    context: ISurveyContainerContext;
    onChange: (args: any) => void;
    answer: any;
    flyoutUp?: boolean;
}

export class SurveyAddElectorate extends React.Component<ISurveyAddElectorateProps, any> {
    private fnElectorateSearch: (searchTerm: string) => void;
    private fnElectorateSearchDebounced: (searchTerm: string) => void;
    private fnChange: (e) => void;
    constructor(props) {
        super(props);
        this.state = {
            isSearching: false,
            availableElectorates: []
        };
        this.fnChange = this.onChange.bind(this);
        this.fnElectorateSearch = this.onElectorateSearch.bind(this);
        this.fnElectorateSearchDebounced = debounce((arg) => this.onElectorateSearchDebounced(arg), 500);

    }
    private onChange(e) {
        this.props.onChange(e.map(item => item.Id).join(","));
    }
    private onElectorateSearch(searchTerm: string) {
        this.fnElectorateSearchDebounced(searchTerm);
    }
    private onElectorateSearchDebounced(searchTerm: string) {
        this.setState({ isSearching: true });
        this.props.context.getElectorates(searchTerm).then(res => {
            this.setState({
                isSearching: false,
                availableElectorates: res
            });
        }).catch(err => {
            this.setState({
                isSearching: false,
                availableElectorates: []
            });
        });
    }
    render(): JSX.Element {
        const { label, description, theme, context, answer } = this.props;
        if (!context.isOnline()) {
            return <div className={`${theme && theme.getFieldContainerClass()}`}>
                <label className={theme && theme.itemLabelClass}>{label}</label>
                <SurveyFieldDescription description={description} />
                <div className={`${theme && theme.getContainerClasses(SurveyContainerStyle.Error)}`}>
                    <strong>WARNING: This question requires online access to display and you are determined to be either offline or have limited connectivity</strong>
                </div>
            </div>;
        }
        const value = (answer != null && answer.length > 0) ? answer.split(",").map(v => parseInt(v, 10)) : null;
        const msProps = {
            value: value,
            data: this.state.availableElectorates,
            busy: this.state.isSearching,
            onSearch: this.fnElectorateSearch,
            onChange: this.fnChange,
            valueField: "Id",
            textField: "Name",
            dropUp: (this.props.flyoutUp === true)
        };

        return <div className={`${theme && theme.getFieldContainerClass()} survey-add-electorate`}>
            <div className={theme && theme.getQuestionLabelClass() }>
                <label className={theme && theme.itemLabelClass}>{label}</label>
                <SurveyFieldDescription description={description} />
            </div>
            <div className={theme && theme.getQuestionBodyClass()}>
                <Multiselect {...msProps} />
            </div>
        </div>;
    }
}