import * as React from "react";


export interface IButtonChooserProps {
    value?: string;
    choices: { [key: string]: string }
    onChoice: (key: string) => void;
}

export function ButtonChooser(props: IButtonChooserProps) {
    const { choices, onChoice, value } = props;

    return <div className="text-center" style={{
        justifyContent: "space-around",
        display: "flex",
        verticalAlign: 'center',
        padding: 7
    }}>
        {Object.keys(choices).map((k) => <button key={k} className={`btn btn-${k == value ? "success" : "primary"} btn-lg`} onClick={() => onChoice(k)}>{choices[k]}</button>)}
    </div>;

}