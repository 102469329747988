import * as React from "react";
import { ICriteriaViewContext, CRITERIA_VIEW_CONTEXT_VALIDATION_MAP } from './context';
import { NodeOptionsEditorLayoutTemplate, IAdvancedListBuilderNode } from './common';
import { FontelloFontIcon } from "@ml/components";

export const NODE_TYPE_TITLE_STYLE = { marginBottom: "3px" };

export const DEFAULT_NODE_CONTAINER_STYLE = {
    padding: "10px"
};

export const BUTTON_GROUP_STYLE = { marginTop: "8px", paddingTop: "3px" }; //, borderTop: "1px solid rgb(204, 204, 204)" };

export interface INodeEditorOptionsPanelProps {
    node: IAdvancedListBuilderNode;
    onDemote: (e: any) => void;
    onPromoteToGroup: (e: any) => void;
    onRemove: (e: any) => void;
    canDemote: boolean;
    canPromote: boolean;
    children?: any;
    optionsLayoutRenderer?: NodeOptionsEditorLayoutTemplate;
}

export const NodeEditorOptionsPanel = (props: INodeEditorOptionsPanelProps, context: ICriteriaViewContext) => {
    const parentNode = context.findNode(props.node.parentId);
    const isNotSoleChildAtRoot = (parentNode != null && parentNode.parentId != null && parentNode.items && parentNode.items.length == 1 && parentNode.items[0].clientId == props.node.clientId);

    const demoteBtn = (isNotSoleChildAtRoot && props.canDemote && !context.isGeographicOnly()) ? <button type="button" className="btn btn-default" onClick={props.onDemote} title="Move this sub-criteria item up to its parent"><FontelloFontIcon name="left-open" /></button> : undefined;
    const promoteBtn = (props.canPromote && !context.isGeographicOnly()) ? <button type="button" className="btn btn-default" onClick={props.onPromoteToGroup} title="Promote this sub-criteria item to its own group"><FontelloFontIcon name="right-open" /></button> : undefined;
    const removeBtn = <button type="button" className="btn btn-danger" style={{ float: "right" }} onClick={props.onRemove} title="Remove this sub-criteria item"><FontelloFontIcon name="cancel" /></button>;

    if (props.optionsLayoutRenderer) {
        return props.optionsLayoutRenderer(context, demoteBtn, promoteBtn, props.children, removeBtn);
    } else {
        return <div style={BUTTON_GROUP_STYLE}>
            {demoteBtn}
            {promoteBtn}
            {props.children}
            {removeBtn}
        </div>;
    }
};
(NodeEditorOptionsPanel as any).contextTypes = CRITERIA_VIEW_CONTEXT_VALIDATION_MAP;