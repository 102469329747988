import * as React from "react";
import {  SurveyContainerStyle, ISurveyContainerContext } from "@electrac/plugin";
import { ISurveyQuestionCommonProps, SurveyFieldDescription } from "./Common";
// import { Multiselect } from "react-widgets";
import * as Multiselect from 'react-widgets/lib/Multiselect';


import debounce = require("lodash.debounce");

export interface ISurveyAddActivityProps extends ISurveyQuestionCommonProps {
    context: ISurveyContainerContext;
    onChange: (args: any) => void;
    answer: any;
    flyoutUp?: boolean;
}

export class SurveyAddActivity extends React.Component<ISurveyAddActivityProps, any> {
    private fnActivitySearch: (searchTerm: string) => void;
    private fnActivitySearchDebounced: (searchTerm: string) => void;
    private fnChange: (e) => void;
    constructor(props) {
        super(props);
        this.state = {
            isSearching: false,
            availableActivities: []
        };
        this.fnChange = this.onChange.bind(this);
        this.fnActivitySearch = this.onActivitySearch.bind(this);
        this.fnActivitySearchDebounced = debounce((arg) => this.onActivitySearchDebounced(arg), 500);
    }
    private onChange(e) {
        this.props.onChange(e.map(item => item.Value).join(","));
    }
    private onActivitySearch(searchTerm: string) {
        this.fnActivitySearchDebounced(searchTerm);
    }
    private onActivitySearchDebounced(searchTerm: string) {
        this.setState({ isSearching: true });
        this.props.context.getActivities(searchTerm).then(res => {
            this.setState({
                isSearching: false,
                availableActivities: res
            });
        }).catch(err => {
            this.setState({
                isSearching: false,
                availableActivities: []
            });
        });
    }
    componentDidMount() {
        this.onActivitySearchDebounced("");
    }
    render(): JSX.Element {
        const { label, description, theme, context, answer } = this.props;
        if (!context.isOnline()) {
            return <div className={`${theme && theme.getFieldContainerClass()}`}>
                <label className={theme && theme.itemLabelClass}>{label}</label>
                <div className={`${theme && theme.getContainerClasses(SurveyContainerStyle.Error)}`}>
                    <strong>WARNING: This question requires online access to display and you are determined to be either offline or have limited connectivity</strong>
                </div>
            </div>;
        }
        const value = (answer != null && answer.length > 0) ? answer.split(",") : null;
        const msProps = {
            value: value,
            data: this.state.availableActivities,
            busy: this.state.isSearching,
            onSearch: this.fnActivitySearch,
            onChange: this.fnChange,
            valueField: "Value",
            textField: "Text",
            dropUp: (this.props.flyoutUp === true)
        };
        return <div className={`${theme && theme.getFieldContainerClass()} survey-add-activity`}>
            <div className={theme && theme.getQuestionLabelClass() }>
                <label className={theme && theme.itemLabelClass}>{label}</label>
                <SurveyFieldDescription description={description} />
            </div>
            <div className={theme && theme.getQuestionBodyClass() }>
                <Multiselect {...msProps} />
            </div>
        </div>;
    }
}