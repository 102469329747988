import * as React from "react";
import * as ReactDOM from "react-dom";
import { logInfo } from '@ml/common';


/**
 * Valid HTML input field types
 */
export type InputFieldType = "text" | "number" | "email" | "password" | "textarea";

//TODO: @electrac/components

export interface IKendoWidgetPropsBase<T> extends React.Props<any> {
    /**
     * The main Kendo options object that is sent into the constructor
     */
    options: T;
    /**
     * Set debug=true to log detailed information on the lifecycle events of your react-kendo component.
     */
    debug?: boolean;
    /**
     * The tag property specifies the html tag that the Kendo widget will be bound to. This is div by default, but can be set to any
     */
    tag?: string;
    /**
     * Version 0.13 and later support automatically re-initializing the Kendo Widget when the options property is updated. This is useful for re-loading Grids with new data, among other things. This is false by default.
     */
    reactive?: boolean;
    /**
     * Style to apply to the wrapping DOM element
     */
    style?: React.CSSProperties;
}

export interface IKendoDataSourceableOptions {
    /**
     * The data source of the widget which is used to display the items. Can be a JavaScript object which represents a valid data source configuration, a JavaScript array or an existing kendo.data.DataSource instance.
     */
    dataSource?: Object | any[] | kendo.data.DataSource;
}

/**
 * Kendo widget name decorator. Required for any subclass of KendoWidgetComponent
 * @param name
 */
export function KendoWidgetName(name: string) {
    return function (target: any) {
        target.prototype.kendoWidgetName = name;
    }
}

// This is a TS version of: https://github.com/tjwebb/react-kendo

/**
 * The base class of most kendo widget components
 */
export abstract class KendoWidgetComponent<T extends kendo.ui.Widget, TOptions, TProps extends IKendoWidgetPropsBase<TOptions>, TState> extends React.Component<TProps, TState> {
    protected elem?: Element | null | Text;
    protected $elem?: JQuery<Element>;
    protected $widget?: T;
    kendoWidgetName?: string; //This is set by the @KendoWidgetName decorator
    constructor(props: TProps) {
        super(props);
    }
    protected onKendoWidgetMounted() { } //Overridable
    componentDidMount() {
        if (this.props.debug) logInfo('[componentDidMount] kendo widget mounting... ', this.kendoWidgetName);

        const elem = ReactDOM.findDOMNode(this);
        if (!elem || elem instanceof Text) return;

        this.elem = elem;
        
        this.$elem = jQuery(this.elem);
        this.$widget = this.makeWidget();

        if (this.props.debug) logInfo('[componentDidMount] kendo widget mounted:', this.kendoWidgetName, ', widget=', this.$widget);
        if (this.props.debug) logInfo('[componentDidMount] elem=', this.elem);
        if (this.props.debug) logInfo('[componentDidMount] $elem=', this.$elem);

        this.onKendoWidgetMounted();
    }
    componentWillUpdate() {
        if (this.props.debug) logInfo('[componentWillUpdate] kendo widget', this.kendoWidgetName, '...');
    }
    componentWillUnmount() {
        if (this.props.debug) logInfo('[componentWillUnmount] kendo widget', this.kendoWidgetName, '...');

        this.teardownWidget();

        if (this.props.debug) logInfo('[componentWillUnmount] kendo widget unmounted:', this.kendoWidgetName);
    }
    protected teardownWidget(): void { //Overridable
        if (this.$widget && this.$widget.element) {
            this.$widget.destroy();
            if (this.props.debug) logInfo('[teardownWidget] widget destroyed', this.kendoWidgetName);
        }
    }
    protected augmentWidgetOptions(baseOptions: TOptions): any { //Overridable
        return baseOptions
    }
    protected makeWidget(): T {
        if (!this.kendoWidgetName) throw Error("kendoWidgetName not specified");

        return ((this.$elem as any)[this.kendoWidgetName] as any)(this.augmentWidgetOptions(this.props.options)).data(this.kendoWidgetName) as T;
    }
    componentDidUpdate() {
        if (this.props.debug) logInfo('[componentDidUpdate] kendo widget', this.kendoWidgetName);
        if (this.props.debug) logInfo('[componentDidUpdate] new options:', this.props.options);

        if (!this.props.reactive)
            return;

        if (this.props.debug) logInfo('[componentDidUpdate] [', this.kendoWidgetName, '] refreshing "reactive" widget...');

        this.teardownWidget();

        if (this.props.debug) logInfo('[componentDidUpdate] torn down widget', this.kendoWidgetName);

        this.$elem && this.$elem.empty();

        if (this.props.debug) logInfo('[componentDidUpdate] emptied root element', this.kendoWidgetName);

        this.$widget = this.makeWidget();

        if (this.props.debug) logInfo('[componentDidUpdate] re-made widget', this.kendoWidgetName);
    }
    protected getWidget(): T {
        if (!this.$widget) throw new Error("this.$widget is not defined");
        return this.$widget
    }
    protected getElement(): JQuery<Element> {
        if (!this.$elem) throw new Error("this.$elem is not defined");
        return this.$elem;
    }
    protected shouldPropBeExcludedFromWrappingElement(name: string): boolean { //Overridable
        return name == "options" || name == "children" || name == "tag" || name == "reactive" || name == "debug";
    }
    render(): JSX.Element {
        let componentProps: any = {};
        for (let key in this.props) {
            if (this.shouldPropBeExcludedFromWrappingElement(key)) {
                continue;
            }
            componentProps[key] = (this.props as any)[key];
        }
        const Tag = `${this.props.tag || "div"}`
        return <Tag {...componentProps}>{this.props.children}</Tag>;
        //return (React.DOM as any)[this.props.tag || "div"](componentProps, this.props.children);
    }
}

//Based on: https://github.com/ryanflorence/react-training/blob/gh-pages/lessons/05-wrapping-dom-libs.md

/**
 * The base class of kendo widgets that are containers of other arbitrary content (eg. kendo windows)
 */
export abstract class KendoDetachedContentWidgetComponent<T extends kendo.ui.Widget, TOptions, TProps extends IKendoWidgetPropsBase<TOptions>, TState> extends React.Component<TProps, TState> {
    node?: Element | Text | null;
    widget?: T;
    kendoWidgetName?: string; //This is set by the @KendoWidgetName decorator
    constructor(props: TProps) {
        super(props);
    }
    protected makeWidget(): T {
        if (!this.kendoWidgetName)
            throw "Unknown kendo widget name. Make sure the component has a @KendoWidgetName decorator";

        if (!this.node) throw "DOM Node was not found";

        return ((jQuery(this.node) as any)[this.kendoWidgetName] as any)(this.props.options).data(this.kendoWidgetName) as T;
    }
    protected tearDownWidget() { //Overridable
        this.widget && this.widget.destroy();
    }
    protected handleWidgetProps(props?: any) { //Overridable

    }
    componentDidMount() { //Override
        // 2) do DOM lib stuff
        const node = ReactDOM.findDOMNode(this);
        if (!node || node instanceof Text) return;

        this.node = node;
        this.widget = this.makeWidget();

        // 3) call method to reconnect React's rendering
        this.renderWidgetContent();
    }
    componentWillReceiveProps(props: TProps) {
        // 4) render reconnected tree when props change
        this.renderWidgetContent(props);
    }
    componentWillUnmount() {
        this.tearDownWidget();
    }
    renderWidget(props: TProps): JSX.Element { //Overridable
        return (<div className={`react-widget-root-${this.kendoWidgetName}`}>{props.children}</div>);
    }
    private renderWidgetContent(props?: TProps) {
        // decide to use newProps from `componentWillReceiveProps` or to use
        // existing props from `componentDidMount`
        props = props || this.props;

        if (!this.widget) return;

        // 5) make a new rendering tree, we've now hidden the DOM
        //    manipulation from the kendo widget and then continued
        //    rendering with React
        ReactDOM.render(this.renderWidget(props), this.widget.element[0]);

        // 6) Call methods on the DOM lib via prop changes
        this.handleWidgetProps(props);
    }
    render(): JSX.Element {
        // 1) render nothing, this way the DOM diff will never try to do
        //    anything to it again, and we get a node to mess with
        return (<div className={`react-root-${this.kendoWidgetName}`} />);
    }
}

export interface IKendoInputFieldProps {
    id: string;
    /**
     * The field value
     */
    value?: string;
    /**
     * The label for this field
     */
    label?: string;
    /**
     * The input field type
     */
    type?: InputFieldType;
    /**
     * The field placeholder text
     */
    placeholder?: string;
    /**
     * Raised when the value has changed
     */
    onValueChanged?: (value: string) => void;
    /**
     * Determines what internal event propagates the change of value
     */
    valueChangeTrigger: "change" | "blur";

    textareaRows?: number;
}

export const DEFAULT_KENDO_LABEL_STYLE: any = {
    display: "block",
    paddingBottom: "0.2em",
    paddingTop: "0.8em",
    fontWeight: "bold",
    fontSize: "12px"
};

export class KendoInputField extends React.Component<IKendoInputFieldProps, any> {
    constructor(props: IKendoInputFieldProps) {
        super(props);
        this.state = {
            value: props.value
        };
    }
    componentWillReceiveProps(nextProps: IKendoInputFieldProps) {
        this.setState({ value: nextProps.value });
    }
    private onChange = (e: any) => {
        const value = e.target.value;
        this.setState({ value: value });
        const { valueChangeTrigger, onValueChanged } = this.props;
        if (valueChangeTrigger == "change" && onValueChanged) {
            onValueChanged(value);
        }
    }
    private onBlur = (e: any) => {
        const value = e.target.value;
        this.setState({ value: value });
        const { valueChangeTrigger, onValueChanged } = this.props;
        if (valueChangeTrigger == "blur" && onValueChanged) {
            onValueChanged(value);
        }
    }
    render(): JSX.Element {
        const { id, type, label, textareaRows, placeholder } = this.props;
        const { value } = this.state;
        return <div>
            <label style={DEFAULT_KENDO_LABEL_STYLE} htmlFor={id}>{label}</label>
            {(() => {
                if (type == "textarea") {
                    return <textarea id={id} placeholder={placeholder} className="k-textbox" style={{ width: "100%" }} value={value} onChange={this.onChange} onBlur={this.onBlur} rows={textareaRows} />;
                } else {
                    return <input id={id} type={type} placeholder={placeholder} className="k-textbox" style={{ width: "100%" }} value={value} onChange={this.onChange} onBlur={this.onBlur} />;
                }
            })() }
        </div>;
    }
}

export interface IKendoDialogButton {
    id: string;
    text: string;
    disabled: boolean;
    click: (e: any) => void;
}

export interface IKendoDialogProps {
    title: string;
    width: number;
    height: number;
    modal?: boolean;
    hideCancel?: boolean;
    buttons?: IKendoDialogButton[];
    onBeforeClose?: (userTriggered: boolean, callback: (closeMe: boolean) => void) => void;
    onClosed?: () => void;
    onResize?: () => void;
    bodyStyle?: React.CSSProperties;

    mainStyle?: React.CSSProperties;
}

/**
 * This is a generic dialog component based on kendo's window. Any component contained within can be shown
 * in a modal dialog.
 *
 * NOTE: This component will render into a separate react root. Things such as contexts will not be available when
 * the component is docked within this dialog. To workaround this, design your component to accept callbacks or other
 * non-contextual means
 *
 */
export class KendoDialog extends React.Component<IKendoDialogProps, any> {
    private $dialog: JQuery | undefined;
    private win: kendo.ui.Window | undefined;
    private buttons: any[];
    constructor(props: IKendoDialogProps) {
        super(props);
        this.buttons = [];
        this.state = {};
    }
    close() {
        if (this.win) {
            this.win.close();
        }
    }
    componentDidMount() {
        const { onBeforeClose, hideCancel } = this.props;
        const thisNode = ReactDOM.findDOMNode(this);
        if (this.props.buttons) {
            for (const btn of this.props.buttons) {
                this.buttons.push(btn);
            }
        }
        if (!(hideCancel === true)) {
            this.buttons.push({
                id: "dialog-cancel",
                text: "Cancel",
                click: (e: any) => {
                    e.preventDefault();
                    if (this.win)
                        this.win.close();
                }
            });
        }
        const $dialog = jQuery('<div>').kendoWindow({
            title: this.props.title,
            width: this.props.width,
            height: this.props.height,
            modal: !!this.props.modal,
            resize: (e: any) => {
                if (this.$dialog) {
                    this.$dialog.applyResponsiveClass();
                }
            },
            close: (e: any) => {
                const closeMe = () => {
                    ReactDOM.unmountComponentAtNode($dialog[0]);
                    $dialog.remove();
                    this.$dialog = undefined;
                    if (this.props.onClosed) {
                        this.props.onClosed();
                    } else {
                        console.warn("No onClosed handler set for this component! Will attempt to auto-unmount component from parent container. Sibling components will be be destroyed!");
                    }
                    if (thisNode && thisNode.parentElement) {
                        ReactDOM.unmountComponentAtNode(thisNode.parentElement);
                    }
                };

                if (onBeforeClose != null) {
                    onBeforeClose(e.userTriggered, bCloseMe => {
                        if (bCloseMe === true) {
                            closeMe();
                        } else {
                            e.preventDefault();
                        }
                    });
                } else {
                    closeMe();
                }
            }
        });
        this.$dialog = $dialog;
        this.win = this.$dialog.data("kendoWindow");

        this.renderDialogContent();

        this.win.center().open();
        this.$dialog.applyResponsiveClass();
    }
    private renderDialogContent() {
        if (this.win && this.win.element[0]) {
            ReactDOM.render(<div className="kendo-modal-main" style={this.props.mainStyle}>
                <div className="kendo-modal-body" style={this.props.bodyStyle}>{this.props.children}</div>
                {(() => {
                    if (this.buttons.length > 0) {
                        return <div className="kendo-modal-footer">
                            {this.buttons.map((v, i) => {
                                return <button key={`modal-action-${v.id}`} className="k-button" type="button" onClick={v.click} disabled={v.disabled === true}>{v.text}</button>;
                            })}
                        </div>;
                    }
                    else return undefined;
                })()}
            </div>, this.win.element[0]);
        }
    }
    componentWillUpdate() {
        //The KendoDialog child content is mounted on a separate react root, so we use the componentWillUpdate() hook of
        //KendoDialog to communicate across to the other react root that the children need to be rerendered.
        this.renderDialogContent();
    }
    componentWillUnmount() {
        if (this.win) {
            this.win.destroy();
            this.win = undefined;
        }
        if (this.$dialog) {
            this.$dialog = undefined;
        }
    }
    render(): JSX.Element {
        return (<div className="kendo-window-component-root" />);
    }
}

/**
 * Shows the specified dialog react element at the global mount point. This is assumed to be a JQDialog component
 * @param diag
 */
export function showDialog(diag: JSX.Element) {
    ReactDOM.render(diag, document.getElementById("react-dialog-mount-point"));
}


/*
export function ktest() {
    ReactDOM.render(<KendoDialog title="test" width={640} height={400} modal={true}>
        <p>Hello World</p>
    </KendoDialog>, document.getElementById("react-dialog-mount-point"));
}
*/