import * as ElectracAPI from "@electrac/model";
import {
    ISurveyAnswerImmutable, IConditionGroupChildImmutable, IQuestionConditionImmutable, IConditionGroupImmutable, IDisplayConditionModelImmutable,    
} from "../contracts";


export class ConditionWriter {
    private indentLevel: number;
    private str: string;
    constructor(indentLevel = 0) {
        this.indentLevel = indentLevel;
        this.str = "";
    }
    public getIndent(): number {
        return this.indentLevel;
    }
    public indent(): void {
        this.indentLevel++;
    }
    public unindent(): void {
        this.indentLevel--;
    }
    public writeln(str: string): void {
        this.str += str + "\n";
    }
    public getString(): string {
        return this.str;
    }
}

export interface Evaluator {
    evaluate(): boolean;
    dump(writer: ConditionWriter): void;
    stringify(writer: ConditionWriter): string;
}

class GroupEvaluator implements Evaluator {
    private children: Evaluator[];
    private op: ElectracAPI.GroupOperator;
    private negate: boolean;
    constructor(op: ElectracAPI.GroupOperator, negate: boolean) {
        this.children = [];
        this.op = op;
        this.negate = negate;
    }
    public addChild(evaluator: Evaluator): void {
        this.children.push(evaluator);
    }
    private evalInternal(): boolean {
        const op = parseInt(this.op as any, 10); //Guard against stringified op
        if (op == ElectracAPI.GroupOperator.And) {
            //All must evaluate to true
            return this.children.every((ev, idx, arr) => ev.evaluate() === true);
        } else { //Or
            //At least one must evaluate to true
            return this.children.filter((ev, idx, arr) => ev.evaluate() === true).length > 0;
        }
    }
    public evaluate(): boolean {
        const result = this.evalInternal();
        if (this.negate) {
            return !result;
        }
        return result;
    }
    private stringifyInternal(writer: ConditionWriter): string {
        const op = parseInt(this.op as any, 10); //Guard against stringified op
        const opName = this.op ? ElectracAPI.GroupOperator[op] : ElectracAPI.GroupOperator[ElectracAPI.GroupOperator.Or];
        const str = this.children.map(ev => ev.stringify(writer)).join(` ${opName}\n`);
        return str;
    }
    public stringify(writer: ConditionWriter): string {
        const result = this.stringifyInternal(writer);
        if (this.negate) {
            return `NOT (${result})`;
        }
        return result;
    }
    public dump(writer: ConditionWriter): void {
        writer.indent();
        writer.writeln(this.stringify(writer));
        writer.unindent();
    }
}

class QuestionConditionEvaluator implements Evaluator {
    private op: ElectracAPI.ConditionOperator;
    private currentAnswer: any;
    private currentChoices: any[];
    private expectedValue: any;
    private negate: boolean;
    constructor(op: ElectracAPI.ConditionOperator, current: ISurveyAnswerImmutable, expected: any, negate: boolean) {
        this.op = op;
        this.negate = negate;
        this.currentAnswer = null;
        this.currentChoices = [];
        if (current != null) {
            this.currentAnswer = current.Answer;
            const choices = current.Choices;
            if (choices) {
                const choiceIDs = choices.map((ch) => ch && ch.ID);
                for (const id of choiceIDs) {
                    this.currentChoices.push(id);
                }
            }
        }
        this.expectedValue = expected;
    }
    private evalInternal(): boolean {
        const op = parseInt(this.op as any, 10); //Guard against stringified op
        switch (op) {
            case ElectracAPI.ConditionOperator.ChoiceContains:
                return this.currentChoices.filter(v => v == this.expectedValue).length > 0; //NOTE: indexOf is type-strict, filter is not
            case ElectracAPI.ConditionOperator.AnswerEqual: //TODO: case-insensitive?
                return this.currentAnswer == this.expectedValue;
            default:
                console.error(`Unsupported operator: ${ElectracAPI.ConditionOperator[op]}`);
                return true;
        }
    }
    public evaluate(): boolean {
        const result = this.evalInternal();
        if (this.negate) {
            return !result;
        }
        return result;
    }
    private stringifyInternal(): string {
        const op = parseInt(this.op as any, 10); //Guard against stringified op
        if ((op == ElectracAPI.ConditionOperator.AnswerEqual) && this.currentAnswer) {
            return `'${this.currentAnswer}'`;
        } else if ((op == ElectracAPI.ConditionOperator.ChoiceContains) && this.currentChoices) {
            return `['${this.currentChoices.join("','")}']`
        } else {
            return "<null>";
        }
    }
    private stringifyAnswer(): string {
        const result = this.stringifyInternal();
        if (this.negate) {
            return `NOT (${result})`;
        }
        return `${result}`;
    }
    public stringify(writer: ConditionWriter): string {
        const op = parseInt(this.op as any, 10); //Guard against stringified op
        return `([EXPECT:'${this.expectedValue}' ${ElectracAPI.ConditionOperator[op]} ANSWERED:${this.stringifyAnswer()}] = ${this.evaluate()})`
    }
    public dump(writer: ConditionWriter): void {
        writer.writeln(this.stringify(writer));
    }
}

class AlwaysTrueEvaluator implements Evaluator {
    constructor() { }
    public evaluate(): boolean {
        return true;
    }
    public stringify(writer: ConditionWriter): string {
        return "ALWAYS TRUE";
    }
    public dump(writer: ConditionWriter): void {
        writer.writeln(this.stringify(writer));
    }
}

class AlwaysFalseEvaluator implements Evaluator {
    constructor(private extraMessage?: string) { }
    public evaluate(): boolean {
        return false;
    }
    public stringify(writer: ConditionWriter): string {
        if (this.extraMessage) {
            return `ALWAYS FALSE (${this.extraMessage})`;
        } else {
            return "ALWAYS FALSE";
        }
    }
    public dump(writer: ConditionWriter): void {
        writer.writeln(this.stringify(writer));
    }
}

function IsQuestionCondition(cond: IConditionGroupChildImmutable): cond is IQuestionConditionImmutable {
    return cond.Kind === ElectracAPI.ConditionGroupChildKind.Condition;
}

function IsConditionGroup(cond: IConditionGroupChildImmutable): cond is IConditionGroupImmutable {
    return cond.Kind === ElectracAPI.ConditionGroupChildKind.Group;
}

function BuildChildEvaluators(items: IConditionGroupChildImmutable[], answers: { [key: number]: ISurveyAnswerImmutable }): Evaluator[] {
    const evaluators: Evaluator[] = [];
    if (items) {
        items.forEach((v) => {
            //TODO: For the sake of readability, this should be an object with a evaluate(): boolean contract, instead
            //of a boolean func IIFE, which will no doubt confuse JS novices
            ((value) => {
                if (!value) return;

                if (IsConditionGroup(value)) {
                    evaluators.push(MakeGroupEvaluator(value, answers));
                } else if (IsQuestionCondition(value)) {
                    evaluators.push(MakeQuestionEvaluator(value, answers));
                }
            })(v);
        });
    }
    return evaluators;
}

function MakeQuestionEvaluator(condition: IQuestionConditionImmutable, answers: { [key: number]: ISurveyAnswerImmutable }): Evaluator {
    const qid = condition.QuestionID;
    const op = condition.Operator;
    const expectedAnswer = condition.Value;
    const negate = condition.Negate;
    const questionID: any = `${qid}`; //HACK: Number must be string because immutable.js ...
    const currentAnswer = answers[questionID];

    if (!currentAnswer) {
        return new AlwaysFalseEvaluator(`No answer provided for question [${questionID}] checking for expected answer of: ${expectedAnswer}`);
    } else {
        return new QuestionConditionEvaluator(op, currentAnswer, expectedAnswer, negate);
    }
}

function MakeGroupEvaluator(group: IConditionGroupImmutable, answers: { [key: number]: ISurveyAnswerImmutable }): Evaluator {
    const children = group.Conditions;
    const op = group.Operator;
    const negate = group.Negate;
    const evaluator = new GroupEvaluator(op, negate);
    if (!children) return evaluator;

    const childEvals = BuildChildEvaluators(children, answers);
    for (const child of childEvals) {
        evaluator.addChild(child);
    }
    return evaluator;
}

function isJunkGroup(group: ElectracAPI.ConditionGroup): boolean {
    return group.Conditions == null || group.Conditions.length == 0;
}

function isEmptyGroup(map: ElectracAPI.ConditionGroup): boolean {
    return !map
        || isJunkGroup(map);
}

export function EvaluateDisplayCondition(condition?: IDisplayConditionModelImmutable, answers?: { [key: number]: ISurveyAnswerImmutable }): Evaluator {
    if (!condition) return new AlwaysTrueEvaluator();

    const group = condition.RootGroup;
    
    if (group && !isEmptyGroup(group)) {
        if (answers) {
            return MakeGroupEvaluator(group, answers);
        } else {
            return new AlwaysFalseEvaluator();
        }
    }
    return new AlwaysTrueEvaluator();
}