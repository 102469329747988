import * as React from "react";
import * as ReactDOM from "react-dom";
// import { DateTimePicker } from "react-widgets";
import * as DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { IFocusableField } from "../container";
import { ThemableInput } from "./ThemableInput";
import { ISurveyQuestionCommonProps, SurveyFieldDescription } from "./Common";
import moment = require("moment");
import uuid = require("node-uuid");

//require("react-widgets/lib/css/react-widgets.css");

export interface ISurveyDateProps extends ISurveyQuestionCommonProps {
    onChange: (value: string) => void;
    answer?: string | null;
    format: string;
    minDate?: string;
    dropUp?: boolean;
}

export class SurveyDate extends React.Component<ISurveyDateProps, any> implements IFocusableField {
    private fnChange: (date, dateStr) => void;
    private fnContainerMounted: (component: HTMLDivElement) => void;
    private containerEl: HTMLDivElement | null = null;
    constructor(props) {
        super(props);
        this.fnChange = this.onChange.bind(this);
        this.fnContainerMounted = this.onContainerMounted.bind(this);
    }
    private onContainerMounted = (component: HTMLDivElement) => {
        this.containerEl = component;
    }
    private onChange(date, dateStr) {
        const { format } = this.props;
        const str = moment(dateStr, format).utc().toISOString();
        this.props.onChange(str);
    }
    focus(): void {
        if (this.containerEl) {
            const node: any = ReactDOM.findDOMNode(this.containerEl);
            node.scrollIntoView();
        }
        //TODO: Flash animate the container to bring to user's attention
    }
    render(): JSX.Element {
        const { label, answer, description, isReadOnly, format, theme, minDate, dropUp } = this.props;

        if (!theme) throw new Error("theme was not passed in");

        let md: Date | undefined = undefined;
        if (minDate) {
            const minLocal = moment.utc(minDate).local();
            if (minLocal.isValid()) {
                md = minLocal.toDate();
            }
        }
        let field: JSX.Element;
        if (isReadOnly && answer) {
            const value = moment.utc(answer).local().format(format);
            field = <ThemableInput id={uuid.v4()} theme={theme} label={label} isReadOnly={true} value={value} />;
        } else {
            const value = answer != null ? moment.utc(answer).local().toDate() : null;
            const dtProps: any = {
                value: value,
                format: format,
                time: false,
                onChange: this.fnChange,
                min: md,
                dropUp: (dropUp === true)
            }
            field = <div className={theme.getFieldContainerClass() }>
                <div className={theme.getQuestionLabelClass() }>
                    <label className={theme.itemLabelClass}>{label}</label>
                    <SurveyFieldDescription description={description} />
                </div>
                <div className={theme.getQuestionBodyClass() }>
                    <DateTimePicker {...dtProps} />
                </div>
            </div>;
        }
        return <div ref={this.fnContainerMounted} className="clearfix survey-date">
            {field}
        </div>;
    }
}