/**
 * Adds 2 number-like objects
 * @param a 
 * @param b 
 */
export function safeAdd(a: any, b: any) {
    var first = (isNaN(a) ? 0 : a);
    var sec = (isNaN(b) ? 0 : b);
    var res = (parseFloat(first) + parseFloat(sec));
    return (!res || res == 0 ? 0 : res);
}

/**
 * Subtracts 2 number-like objects
 * @param first 
 * @param second 
 */
export function safeSubtract(first: any, second: any) {
    var a = (isNaN(first) ? 0 : first);
    var b = (isNaN(second) ? 0 : second);
    var res = (parseFloat(a) - parseFloat(b));
    return (!res || res == 0 ? 0 : res);
}