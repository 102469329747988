import * as React from "react";

export interface MapWidget {
    addOverlay(options?: any): any;
    getSelectedFeatureCollection(): ObservableFeatureCollection;
}

export type Feature = any;

export interface ItemRenderCallback {
    highlight(key: string, on: boolean): void;
    update(key: string, values: any): void;
    remove(key: string): void;
}

export interface ObservableFeatureCollection {
    clear(): void;
    getLength(): number;
    on(event: "add" | "remove", handler: Function): void;
    getArray(): Feature[];
    forEach(handler: (f: Feature) => void): void;
    remove(item: Feature): void;
    item(index: number): Feature;
}

export interface IMapFeatureSelectionProps {
    onCollectionChange?: () => void;
    featureCollection: ObservableFeatureCollection;
    containerClass: string;
    onRemoveItem: (key: string) => any;
    onHighlightItem: (key: string, on: boolean) => void;
    itemKeySelector: (item: any) => any;
    itemProjection: (feature: Feature) => any;
    itemRenderer: (key: any, item: any, callback: ItemRenderCallback) => JSX.Element;
    noItemsRenderer: () => JSX.Element;
}

export interface IMapFeatureSelectionState {
    features: Feature[];
}

export class MapFeatureSelection extends React.Component<IMapFeatureSelectionProps, IMapFeatureSelectionState> implements ItemRenderCallback {
    constructor(props: IMapFeatureSelectionProps) {
        super(props);
        this.state = {
            features: [...props.featureCollection.getArray().map(f => props.itemProjection(f))]
        };
        props.featureCollection.on("add", this.onAdded);
        props.featureCollection.on("remove", this.onRemoved);
    }
    highlight(key: string, on: boolean): void {
        const { onHighlightItem } = this.props;
        if (onHighlightItem) {
            onHighlightItem(key, on);
        }
    }
    update(key: string, values: any): void {
        const { itemKeySelector } = this.props;
        const { features } = this.state;
        const item = features.filter(f => itemKeySelector(f) == key)[0];
        if (item) {
            for (const k in values) {
                item[k] = values[k];
            }
            this.setState({ features: features });
        }
    }
    remove(key: string): void {
        const { onRemoveItem } = this.props;
        if (onRemoveItem) {
            onRemoveItem(key);
        }
    }
    public clear(): void {
        this.setState({ features: [] }, () => {
            this.props.featureCollection.clear();
        });
    }
    private onAdded = (e: any) => {
        const features = this.state.features;
        const { itemProjection, onCollectionChange } = this.props;
        this.setState({ features: [itemProjection(e.element), ...features] }, () => {
            if (onCollectionChange) {
                onCollectionChange();
            }
        });
    };
    private onRemoved = (e: any) => {
        const features = this.state.features;
        const { itemProjection, itemKeySelector, onCollectionChange } = this.props;
        const key = itemKeySelector(itemProjection(e.element));
        this.setState({ features: features.filter(f => itemKeySelector(f) != key) }, () => {
            if (onCollectionChange) {
                onCollectionChange();
            }
        });
    };
    render(): JSX.Element {
        const { containerClass, itemRenderer, itemKeySelector, noItemsRenderer } = this.props;
        const { features } = this.state;
        if (features.length > 0) {
            return <div className={containerClass}>
                {features.map(f => itemRenderer(itemKeySelector(f), f, this))}
            </div>;
        } else {
            return noItemsRenderer();
        }
    }
}