import * as React from 'react';
import * as ElectracAPI from "@electrac/model";
import { getTopics } from '@electrac/api';
import * as Combobox from 'react-widgets/lib/Combobox';
import * as DropdownList from 'react-widgets/lib/DropdownList';
import { isString } from '@ml/common';

//TODO: @electrac/components

export const EXISTING_TOPIC_ID = 0;
export const NEW_TOPIC_ID = -1;

export interface ITopicPickerProps {
    value: ElectracAPI.IdNamePair;
    onChange: (value: ElectracAPI.IdNamePair) => void;
    disableNewValues: boolean;
}

export class TopicPicker extends React.Component<ITopicPickerProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            isBusy: false,
            availableTopics: []
        };
    }
    componentDidMount() {
        this.setState({ isBusy: true });
        getTopics(true).then(topics => {
            this.setState({
                isBusy: false,
                availableTopics: topics
            });
        }).catch(err => {
            this.setState({
                isBusy: false,
                availableTopics: []
            });
        });
    }
    private onChange = (e) => {
        this.props.onChange(e);
    }
    render(): JSX.Element {
        if (this.props.disableNewValues) {
            return <DropdownList
                value={this.props.value}
                data={this.state.availableTopics}
                onChange={this.onChange}
                textField="Name"
                filter='contains'
                busy={this.state.isBusy} />;
        } else {
            return <Combobox
                value={this.props.value}
                suggest={true}
                filter="contains"
                data={this.state.availableTopics}
                onChange={this.onChange}
                textField="Name"
                busy={this.state.isBusy} />;
        }
    }
}