import * as React from "react";
import * as ReactDOM from "react-dom";
import { ISurveyChoiceItem } from "./choice";
import { ISurveyQuestionCommonProps, SurveyFieldDescription } from "./Common";
import { DropTarget, DragSource } from 'react-dnd';
import { IDragSourceProps, IDropTargetProps } from "@electrac/components";
import { RankedChoiceAnswer } from "@electrac/model";

function packAnswer(choices: ISurveyChoiceItem[]): string {
    const projected: RankedChoiceAnswer[] = choices.map((v, i) => { return { Order: (i + 1), ChoiceID: v.value }; });
    return JSON.stringify(projected);
}

const ItemTypes = {
    RANKED_CHOICE: "ranked-choice"
};

const DRAGGABLE_ITEM_STYLE = {
    border: '1px dashed gray',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move'
};

const cardSource = {
    beginDrag(props: IRankedChoiceItemProps) {
        return {
            id: props.id,
            index: props.index
        };
    }
};

const cardTarget = {
    hover(props: IRankedChoiceItemProps, monitor, component: RankedChoiceItem) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = (ReactDOM.findDOMNode(component) as Element).getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
        }

        // Time to actually perform the action
        props.moveCard(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    }
};

interface IRankedChoiceItemProps {
    id: number;
    index: number;
    text: string;
    debugMode?: boolean;
    moveCard: (dragIndex, hoverIndex) => void;
}

@DropTarget(ItemTypes.RANKED_CHOICE, cardTarget, connect => ({
    connectDropTarget: connect.dropTarget()
}))
@DragSource(ItemTypes.RANKED_CHOICE, cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
}))
class RankedChoiceItem extends React.Component<IRankedChoiceItemProps & IDragSourceProps & IDropTargetProps, any> {
    constructor(props) {
        super(props);
    }
    render(): JSX.Element | null {
        const { id, index, text, isDragging, connectDragSource, connectDropTarget, debugMode } = this.props;
        const opacity = isDragging ? 0 : 1;
        const itemStyle: React.CSSProperties = { ...DRAGGABLE_ITEM_STYLE };
        itemStyle.opacity = opacity;
        const label = (debugMode === true) ? `(${index + 1}) - [${id}] - ${text}` : `(${index + 1}) - ${text}`;
        if (!connectDragSource || !connectDropTarget) {
            console.log("Drag and drop is not possible, rendering nothing");
            return null;
        }
        return connectDragSource(connectDropTarget(
            <div style={itemStyle}>
                {label}
            </div>
        ));
    }
}

interface IRankedChoiceContainerProps {
    debugMode?: boolean;
    choices: ISurveyChoiceItem[];
    onChange: (answer: string) => void;
}

interface IRankedChoiceContainerState {
    choices: ISurveyChoiceItem[];
}

class RankedChoiceContainer extends React.Component<IRankedChoiceContainerProps, IRankedChoiceContainerState> {
    private fnMoveCard: (dragIndex, hoverIndex) => void;
    constructor(props) {
        super(props);
        this.fnMoveCard = this.moveCard.bind(this);
        this.state = {
            choices: []
        };
    }
    private moveCard(dragIndex: number, hoverIndex: number) {
        const { choices } = this.state;
        const newChoices = [...choices];
        const removed = newChoices.splice(dragIndex, 1)[0];
        newChoices.splice(hoverIndex, 0, removed);
        const newState = { choices: newChoices };
        this.setState(newState, () => {
            this.props.onChange(packAnswer(newState.choices));
        });
    }
    private updateChoicesFromProps(props: IRankedChoiceContainerProps) {
        this.setState({
            choices: [...props.choices]
        });
    }
    componentDidMount() {
        this.updateChoicesFromProps(this.props);
    }
    componentWillReceiveProps(nextProps: IRankedChoiceContainerProps) {
        this.updateChoicesFromProps(nextProps);
    }
    render() {
        const { debugMode } = this.props;
        const { choices } = this.state;
        return (
            <div>
                {choices.filter(c => c != null).map((choice, i) => {
                    return (
                        <RankedChoiceItem key={choice.label}
                            index={i}
                            id={choice.value}
                            text={choice.label}
                            debugMode={debugMode}
                            moveCard={this.fnMoveCard} />
                    );
                })}
            </div>
        );
    }
}

export interface ISurveyRankedChoiceProps extends ISurveyQuestionCommonProps {
    choices: ISurveyChoiceItem[];
    answer: any;
    onChange: (answer: string) => void;
}

export class SurveyRankedChoice extends React.Component<ISurveyRankedChoiceProps, any> {
    constructor(props: ISurveyRankedChoiceProps) {
        super(props);
    }
    componentDidMount() {
        const { choices, answer, onChange } = this.props;
        //If no answer set (most likely for a fresh survey instance), then establish the initial ordering
        if (!answer) {
            onChange(packAnswer(choices));
        }
    }
    render(): JSX.Element {
        const { choices, theme, label, description, answer, onChange, isReadOnly, debugMode } = this.props;

        let choicesToRender = choices;
        if (answer) {
            const ordering = JSON.parse(answer) as RankedChoiceAnswer[];
            if (ordering.length) {
                ordering.sort((a, b) => {
                    if (a.Order > b.Order) {
                        return 1;
                    } else if (a.Order < b.Order) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
                choicesToRender = [];
                for (const item of ordering) {
                    const match = choices.filter(c => c.value == item.ChoiceID);
                    if (match.length === 1) {
                        choicesToRender.push(match[0]);
                    }
                }
            }
        }

        return <div className={`${theme && theme.getFieldContainerClass()} survey-ranked-choice`}>
            <div className={theme && theme.getQuestionLabelClass()}>
                <label className={theme && theme.itemLabelClass}>{label}</label>
                <SurveyFieldDescription description={description} />
            </div>
            <div className={theme && theme.getQuestionBodyClass()}>
                {(() => {
                    if (isReadOnly) {
                        return <ol className="ranked-choice-answer-list">
                            {choicesToRender.map((v, i) => <li key={i}>{v.label}</li>)}
                        </ol>;
                    } else {
                        return <RankedChoiceContainer choices={choicesToRender} onChange={onChange} debugMode={debugMode} />
                    }
                })()}
            </div>
        </div>;
    }
}