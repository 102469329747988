import * as React from "react";
import Alert = require("react-bootstrap/lib/Alert");

//TODO: @electrac/components

export interface IBsDismissableAlertProps {
    bsStyle: "info" | "danger" | "warning" | "success";
    onDismissed?: () => void;
}

export interface IBsDismissableAlertState {
    visible: boolean;
}

export class BsDismissableAlert extends React.Component<IBsDismissableAlertProps, Partial<IBsDismissableAlertState>> {
    constructor(props: IBsDismissableAlertProps) {
        super(props);
        this.state = {
            visible: true
        };
    }
    private onDismiss = (e: any) => {
        this.setState({
            visible: false
        });
        if (this.props.onDismissed) {
            this.props.onDismissed();
        }
    }
    render(): JSX.Element {
        if (this.state.visible) {
            return <Alert bsStyle={this.props.bsStyle} onDismiss={this.onDismiss}>
                {this.props.children}
            </Alert>;
        }
        return <noscript />;
    }
}