import * as PropTypes from "prop-types";
import { NodeQuestionType, IAdvancedListBuilderNode, RemoveBoundaryCallback, CriteriaNodeProvider } from './common';

export type CategorisedNodeProviderList = { [category: string]: CriteriaNodeProvider[] };

export interface IListBuilderMapContext {
    drawCustomBoundary: (callback: (wkt: string, xdata?: any) => void) => void;
    removeCustomBoundary: RemoveBoundaryCallback;
    zoomToBoundary: (boundaryNode: IAdvancedListBuilderNode) => void;
}

/**
 * The component context that child node editor components can access
 */
export interface IListBuilderContext extends IListBuilderMapContext {
    getRootNodeId: () => string;
    setNodePosition: (id: string, pos: number) => void;
    addNewChildNode: (id: string, qtype: NodeQuestionType) => void;
    addNewChildGroup: (id: string) => void;
    updateNode: (id: string, props: Partial<IAdvancedListBuilderNode>) => void;
    clearChildNodes: (id: string) => void;
    removeChildNode: (id: string, childId: string) => void;
    promoteNodeToGroup: (id: string) => void;
    demoteChildNode: (id: string) => void;
    findNode: (id: string | undefined) => IAdvancedListBuilderNode | undefined;
    moveItemToGroup: (id: string, targetGroupId: string) => void;
    addAllSelectedBoundaries: (id: string) => void;
}

export interface ICriteriaViewContext extends IListBuilderContext {
    isGeographicOnly: () => boolean;
}

export interface IRelatedSetContext extends ICriteriaViewContext {
    /**
     * Gets the set of available node options. If none (undefined) provided, the caller
     * should provide the standard list of options appropriately categorised
     * 
     * @memberof IRelatedSetContext
     */
    getAvailableNodes: () => CategorisedNodeProviderList | undefined;
}

/**
 * A predefined context validation map for child node editors
 */
export const LIST_BUILDER_CONTEXT_VALIDATION_MAP: React.ValidationMap<any> = {
    getRootNodeId: PropTypes.func.isRequired,
    findNode: PropTypes.func.isRequired,
    setNodePosition: PropTypes.func.isRequired,
    updateNode: PropTypes.func.isRequired,
    addNewChildNode: PropTypes.func.isRequired,
    addNewChildGroup: PropTypes.func.isRequired,
    clearChildNodes: PropTypes.func.isRequired,
    removeChildNode: PropTypes.func.isRequired,
    promoteNodeToGroup: PropTypes.func.isRequired,
    demoteChildNode: PropTypes.func.isRequired,
    drawCustomBoundary: PropTypes.func.isRequired,
    removeCustomBoundary: PropTypes.func.isRequired,
    zoomToBoundary: PropTypes.func.isRequired,
    moveItemToGroup: PropTypes.func.isRequired,
    addAllSelectedBoundaries: PropTypes.func.isRequired
};

export const CRITERIA_VIEW_CONTEXT_VALIDATION_MAP = {
    ...LIST_BUILDER_CONTEXT_VALIDATION_MAP,
    ...{
        isGeographicOnly: PropTypes.func.isRequired
    }
};

export const RELATED_SET_CONTEXT_VALIDATION_MAP = {
    ...CRITERIA_VIEW_CONTEXT_VALIDATION_MAP,
    ...{
        getAvailableNodes: PropTypes.func.isRequired
    }
};