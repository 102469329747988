import * as React from "react";
import { SurveyChoice, ISurveyChoiceItem } from "./choice";
import { ISurveyQuestionCommonProps } from "./Common";

export interface ISurveyYesNoProps extends ISurveyQuestionCommonProps {
    onChange: (value: string|null) => void;
    answer?: string;
}

export class SurveyYesNo extends React.Component<ISurveyYesNoProps, any> {
    fnChoiceChanged: (selectedValues, otherAnswer) => void;
    constructor(props) {
        super(props);
        this.fnChoiceChanged = this.onChoiceChanged.bind(this);
    }
    onChoiceChanged(selectedValues: any[], otherAnswer: string) {
        if (selectedValues.length == 1) {
            this.props.onChange(selectedValues[0]);
        } else {
            this.props.onChange(null);
        }
    }
    render(): JSX.Element {
        const { label, description, isReadOnly, answer, theme } = this.props;
        const data: ISurveyChoiceItem[] = [
            { label: "Yes", value: "Y", checked: (answer == "Y") },
            { label: "No", value: "N", checked: (answer == "N") }
        ];
        const selectedAnswer: string[] = [];
        if (answer != null) {
            selectedAnswer.push(answer);
        }
        return <SurveyChoice
            allowOtherChoice={false}
            allowMultipleChoice={false}
            label={label}
            description={description}
            data={data}
            theme={theme}
            isReadOnly={isReadOnly}
            answer={selectedAnswer}
            onChange={this.fnChoiceChanged} />;
    }
}