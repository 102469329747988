import * as React from "react";
import { InputFieldType } from "@electrac/components";
import { ISurveyContainerTheme } from "@electrac/plugin";
import { SurveyFieldDescription } from "./Common";

export interface IThemableInputProps {
    /**
     * The field control id
     */
    id: string;
    /**
     * The survey theme to use
     */
    theme?: ISurveyContainerTheme;
    /**
     * The input field type
     */
    type?: InputFieldType;
    /**
     * The field value
     */
    value?: string;
    /**
     * The label for this field
     */
    label?: string;
    /**
     * Feedback content to append to the field with
     */
    feedback?: any;
    /**
     * Help text for this field
     */
    helpText?: string;
    /**
     * The field placeholder text
     */
    placeholder?: string;

    /**
     * Raised when the field's value changes
     */
    onChange?: (value: string) => void;
    /**
     * Raised when the field loses focus
     */
    onBlur?: (value: string) => void;
    /**
     * Indicates if this field is read-only. If true, any change handlers will have no effect and this field
     * will be rendered out as a static content control
     */
    isReadOnly?: boolean;
    /**
     * Any extra input attributes to transfer to the underlying HTML input field
     */
    extraInputProps?: any;
    /**
     * If true, the description will be rendered as a placeholder for the input field
     */
    descriptionAsPlaceholder?: boolean;
    noLayout?: boolean;
}

export class ThemableInput extends React.Component<IThemableInputProps, any> {
    private fnChange: (e) => void;
    private fnBlur: (e) => void;
    constructor(props: IThemableInputProps) {
        super(props);
        this.fnChange = this.onChange.bind(this);
        this.fnBlur = this.onBlur.bind(this);
    }
    private onChange(e) {
        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    }
    private onBlur(e) {
        if (this.props.onBlur) {
            this.props.onBlur(e.target.value);
        }
    }
    render(): JSX.Element {
        const { id, theme, label, type, placeholder, value, isReadOnly, extraInputProps, descriptionAsPlaceholder } = this.props;
        const isPlaceholder = !!descriptionAsPlaceholder;
        let placeholderText: string | undefined;
        if (isPlaceholder) {
            placeholderText = placeholder;
        }

        const body = type == "textarea" 
            ? <textarea
                    id={id}
                    type={type}
                    readOnly={isReadOnly}
                    className={theme && theme.getTextboxClass() }
                    value={value || ""}
                    placeholder={placeholderText}
                    onChange={this.fnChange}
                    onBlur={this.fnBlur}
                    rows={3}
                    {...extraInputProps} />
           : <input
                    id={id}
                    type={type}
                    readOnly={isReadOnly}
                    className={theme && theme.getTextboxClass() }
                    value={value || ""}
                    placeholder={placeholderText}
                    onChange={this.fnChange}
                    onBlur={this.fnBlur}
                    {...extraInputProps} />;
            

        if (this.props.noLayout === true) {
            return <div>
                <label htmlFor={id} className={theme && theme.itemLabelClass}>{label}</label>
                {placeholder && !isPlaceholder && <SurveyFieldDescription description={placeholder} />}
                {body}
            </div>;
        } else {
            return <div>
                <div className={theme && theme.getQuestionLabelClass() }>
                    <label htmlFor={id} className={theme && theme.itemLabelClass}>{label}</label>
                    {placeholder && !isPlaceholder && <SurveyFieldDescription description={placeholder} />}
                </div>
                <div className={theme && theme.getQuestionBodyClass() }>
                    {body}
                </div>
            </div>;
        }
    }
}