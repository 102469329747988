import * as ElectracAPI from "@electrac/model";
import {
    ISurveyImmutable,
    ISurveyDraftImmutable,
    IManualSurveyResponseImmutable
} from "./contracts";
import { ApiContext,fetchSurveyChoices } from '@electrac/api';


export class RemoteChoiceFetcher {
    constructor() {

    }
    public getChoices(token: ApiContext, survey: ISurveyImmutable, draft: ISurveyDraftImmutable | IManualSurveyResponseImmutable | undefined,
         dataSourceId: number, choiceParameterBindings: any, latestAnswers: any): Promise<ElectracAPI.IMvcSelectItem[] | Error> {
        const currentParameterSet: any = {};
        if (latestAnswers) {
            //If latestAnswers specified, it takes precedence
            for (const key in latestAnswers) {
                currentParameterSet[key] = latestAnswers[key];
            }
        }
        const unsatisifedAnswers:string[] = [];
        let bRequiredParameterNotMapped = false;
        if (choiceParameterBindings) {
            const questions = survey.Questions;
            let currentAnswers;
            if (draft) {
                currentAnswers = draft.Answers;
            }
            for (let paramId in choiceParameterBindings) {
                const iqid = parseInt(choiceParameterBindings[paramId].questionId, 10);
                const pName = choiceParameterBindings[paramId].name;
                let a;
                if (currentAnswers) {
                    a = currentAnswers[iqid];
                }
                //Only set if not already covered by latestAnswers as its value takes precedence
                if (a && !currentParameterSet[pName]) {
                    currentParameterSet[pName] = a.Answer;
                }
                const qn = questions.filter(q => q.SurveyQuestionID == iqid)[0];
                if (choiceParameterBindings[paramId].isRequired == true && (!currentParameterSet[pName] || currentParameterSet[pName] == "")) {
                    if (qn) {
                        unsatisifedAnswers.push(qn.Question || 'Unknown question');
                    } else {
                        bRequiredParameterNotMapped = true;
                    }
                }
            }
        }

        

        if (bRequiredParameterNotMapped) {
            return Promise.resolve(new Error("A parameter is required to fetch these choices but it is not mapped to any question. This survey has not been properly configured"));
        }
        if (unsatisifedAnswers.length > 0) {
            return Promise.resolve(new Error(`Please provide an answer for the following questions first: [${unsatisifedAnswers.join("], [")}]`));
        }
        return fetchSurveyChoices(token, dataSourceId, currentParameterSet);
    }
}