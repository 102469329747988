import * as React from 'react';
import * as ElectracAPI from "@electrac/model";
import { InteractionModelProperties, makeNewInteraction } from '@electrac/api';
import { KendoSpinner, KendoInputField, DEFAULT_KENDO_LABEL_STYLE, KendoDialog } from '@ml/kendo-wrappers';
import * as moment from 'moment';


import * as DateTimePicker from 'react-widgets/lib/DateTimePicker';
import * as SelectList from 'react-widgets/lib/SelectList';

import { isString } from '@ml/common';
import { SingleUsernameSelector } from '@electrac/plugin';
import { TagList, EXISTING_TAG_ID } from './tag-list';
import { ShareOfficeMultiSelect } from './ShareOfficeMultiSelect';
import { TopicPicker, EXISTING_TOPIC_ID, NEW_TOPIC_ID } from './topic-picker';
import { InteractionAttachments } from './attachments';
import { SensitiveLevelDescriptor } from './Context';
import { getOfficesForElector, getInteractionDetails, IElectracWebContext } from '@electrac/api';
import { InteractionModel } from '@electrac/model';

// import { attachLetter } from '../../api/Letter';
// import { getCurrentOfficeID } from '../../utils/Context';
// import { flagDirtyState, clearDirtyState } from '../../utils/Context';

const DATE_EDIT_FORMATS = [
    "D/M",
    "D/M/YYYY",
    "DD/MM/YYYY"
];
const DATETIME_EDIT_FORMATS = [
    "D/M/YYYY H:mm",
    "DD/MM/YYYY H:mm",
    "D/M/YYYY H:m",
    "DD/MM/YYYY H:m",
    "D/M/YYYY H:mma",
    "DD/MM/YYYY H:mma",
    "D/M/YYYY H:ma",
    "DD/MM/YYYY H:ma"
];
const DATE_LABEL_DISPLAY_FORMAT = "MMMM Do YYYY";
const DIRTY_STATE_KEY = "Interaction.Details";

export interface IInteractionDetailsProps {
    isMulti: boolean;
    allowAddressInteraction: boolean;
    allowedSharingLevels: SensitiveLevelDescriptor[];
    interaction: ElectracAPI.InteractionSaveModel;
    disableTopicCreation: boolean;
    linkType: ElectracAPI.LinkType;
    linkId: number;
    onSave: (interaction: ElectracAPI.InteractionSaveModel) => Promise<number | undefined>;
    webContext: IElectracWebContext;
}

export interface IInteractionDetailsDialogProps {
    requestAllowedSharingLevels: (interaction: ElectracAPI.InteractionSaveModel, isCreating: boolean, isOwner: boolean) => SensitiveLevelDescriptor[];
    defaultSensitiveLevel?: ElectracAPI.SensitiveLevel;
    disableTopicCreation: boolean;
    linkType: ElectracAPI.LinkType,
    linkId: number;
    interaction?: Readonly<InteractionModel>;
    title: string;
    onSave: (interaction: ElectracAPI.InteractionSaveModel) => Promise<number | undefined>;
    webContext: IElectracWebContext;
}


const FIELDSET_STYLE = {
    padding: 8
};

const SharingLevel = (props) => {

    /**
     * NOTE:
     *
     * A regression since v3.3.3 of react-widgets makes the disabled option not being respected. In addition, there is a bug in IE where disabling certain elements
     * disables the whole component. As a result, we are using a custom build of react-widgets v3.3.3 with these fixes integrated
     *
     * Do not upgrade this library until this issue is resolved: https://github.com/jquense/react-widgets/issues/420 and disabling certain items no longer disables
     * the whole component in IE.
     */

    const data: SensitiveLevelDescriptor[] = props.allowedSharingLevels; //.map(item => { return { label: item.label, id: item.value } });
    const disableValues = data.filter(item => item.isEnabled === false); //.map(item => item.id);
    //.map(item => { return { label: item.label, id: item.value } }); //.map(item => item.value);
    const disabled = Array.isArray(disableValues);
    const selectedValue = data.filter(item => item.id === props.value)[0];
    return <SelectList
        readOnly={false}
        data={data}
        textField="label"
        valueField="id"
        value={selectedValue}
        onChange={props.onChange}
        disabled={disabled ? disableValues : false} />;
};

/** Dialog / editor */
export class InteractionDetails extends React.Component<IInteractionDetailsProps, any> {
    private fnApplyToAllChanged: (e) => void;
    private fnApplyToAddressChanged: (e) => void;
    private fnAttachLetter: (e) => void;
    private fnTagSelectionChanged: (items) => void;
    private fnOfficeSelectionChanged: (items) => void;
    private fnPendingAttachmentsChanged: (pendingAttachments: ElectracAPI.InteractionFileAttachment[]) => void;
    private fnMarkActioned: (e) => void;
    constructor(props: IInteractionDetailsProps) {
        super(props);
        this.fnApplyToAllChanged = this.onApplyToAllChanged.bind(this);
        this.fnApplyToAddressChanged = this.onApplyToAddressChanged.bind(this);
        this.fnAttachLetter = this.onAttachLetter.bind(this);
        this.fnTagSelectionChanged = this.onTagSelectionChanged.bind(this);
        this.fnOfficeSelectionChanged = this.onOfficeSelectionChanged.bind(this);
        this.fnPendingAttachmentsChanged = this.onPendingAttachmentsChanged.bind(this);
        this.fnMarkActioned = this.onMarkActioned.bind(this);
        this.state = {
            init: false,
            isSaving: false,
            availableTopics: null,
            availableOffices: [],
            selectedTopic: null,
            selectedTags: [],
            selectedOffices: [],
            pendingAttachments: [],
            applyToAll: false,
            applyToAddress: false
        };
    }
    private onApplyToAllChanged(e) {
        this.setState({ applyToAll: e.target.checked });
    }
    private onApplyToAddressChanged(e) {
        this.setState({ applyToAddress: e.target.checked });
    }
    private onAttachLetter = (e) => {
        e.preventDefault();
        if (!this.props.webContext || !this.props.webContext.attachLetter) {
            alert('Unable to attach a letter at this time');
            return;
        }
        this.props.webContext.attachLetter(this.props.linkId, this.props.linkType as number, this.state.selectedTopic.Name, async (file) => {
            const attachments = this.state.pendingAttachments;
            attachments.push(file);
            this.setState({ pendingAttachments: attachments });

            this.beginSave();

            // Build and save interaction synchronously
            const interactionForSave = this.buildInteractionForSave(attachments);

            // call the back-end code to save interaction
            const interactionId = await this.props.onSave(interactionForSave);

            // Update the ID of the interaction if ID was missing at the start
            // (which means that a new interaction was created)
            if (interactionForSave.Id == null) {
                const updatedInteraction = {
                    ...this.state.interaction,
                    Id: interactionId
                };
                this.setState({interaction: updatedInteraction});
            }

            this.endSave();
        });
    }
    private onTagSelectionChanged(items: ElectracAPI.IdNamePair[]) {
        this.trackFieldChange("Tags", items);
    }
    private onOfficeSelectionChanged(items: ElectracAPI.IdNamePair[]) {
        this.trackFieldChange("ShareOffices", items);
    }
    private onPendingAttachmentsChanged(pendingAttachments: ElectracAPI.InteractionFileAttachment[]) {
        this.flagUnsavedInteraction();
        this.setState({ pendingAttachments: pendingAttachments });
    }
    private onMarkActioned(e) {
        e.preventDefault();
        this.setState({
            isActionedOutOfOffice: true
        });
        return false;
    }
    public beginSave() {
        this.setState({ isSaving: true });
    }
    public endSave() {
        this.setState({ isSaving: false });
    }
    public buildInteractionForSave(pendingAttachments?: ElectracAPI.InteractionFileAttachment[]): ElectracAPI.InteractionSaveModel {
        const inter: ElectracAPI.InteractionSaveModel = this.state.interaction;
        inter.FilesToAttach = (pendingAttachments || this.state.pendingAttachments || []).map(att => att.StorageFileID);
        inter.ApplyToAll = (this.state.applyToAll === true);
        inter.ApplyToAddress = (this.state.applyToAddress === true);
        if (this.state.isActionedOutOfOffice === true) {
            inter.IsActionedOutOfOffice = true;
        }
        return inter;
    }
    public isNewTopic(): boolean {
        if (this.state.selectedTopic != null)
            return this.state.selectedTopic.Id == NEW_TOPIC_ID;
        else
            return false; //NOTE: The intent of this method is whether the selected topic (if specified) is new
    }
    private initInteraction(interactionDetails: Partial<ElectracAPI.InteractionDetailsModel>, shareableOffices: ElectracAPI.IdNamePair[]) {
        const newState: any = {
            interaction: {}
        };
        for (const key in this.props.interaction) {
            switch (key) {
                case "Topic":
                    newState.selectedTopic = { Id: EXISTING_TOPIC_ID, Name: this.props.interaction[key] };
                    break;
                case "Date":
                case "Due":
                    if (this.props.interaction[key])
                        newState.interaction[key] = moment.utc(this.props.interaction[key]).local();
                    else
                        newState.interaction[key] = null;
                    break;
                case "Files":
                    newState.interaction[key] = this.props.interaction[key] || [];
                    break;
                case "Tags":
                    newState.selectedTags = (this.props.interaction.Tags || []).map(t => {
                        return { Id: EXISTING_TAG_ID, Name: t };
                    });
                    newState.interaction.AssignedTags = newState.selectedTags;
                    break;
                default:
                    newState.interaction[key] = this.props.interaction[key];
                    break;
            }
        }
        newState.availableOffices = shareableOffices;
        const selOffices: ElectracAPI.IdNamePair[] = [];
        if (interactionDetails.SharedOffices != null) {
            for (const officeID of interactionDetails.SharedOffices) {
                const match = shareableOffices.filter(o => o.Id == officeID);
                if (match.length == 1) {
                    selOffices.push({
                        Id: officeID,
                        Name: match[0].Name
                    });
                }
            }
        }
        newState.createdOn = interactionDetails.CreatedOn;
        newState.createdByUser = interactionDetails.CreatedByUser;
        newState.modifiedOn = interactionDetails.ModifiedOn;
        newState.modifiedByUser = interactionDetails.ModifiedByUser;
        newState.shareDirection = interactionDetails.ShareDirection;
        newState.createdByOffice = interactionDetails.CreatedByOffice;
        newState.actionedByUser = interactionDetails.ActionedByUser;
        newState.actionedByOffice = interactionDetails.ActionedByOffice;
        newState.actionedByOfficeOn = interactionDetails.ActionedByOfficeOn;
        newState.selectedOffices = selOffices;
        newState.canEditSharingLevel = interactionDetails.CanEditSharingLevel || true;
        newState.interaction.ShareOffices = newState.selectedOffices;
        newState.init = true;
        this.setState({ ...this.state, ...newState });
    }
    fetchInteractionDetails(): Promise<Partial<ElectracAPI.InteractionDetailsModel>> {
        const id = this.props.interaction.Id;
        if (id) {
            return getInteractionDetails(true, id);
        } else {
            return Promise.resolve({});
        }
    }
    fetchSharableOffices(): Promise<ElectracAPI.IdNamePair[]> {
        if (this.props.interaction.LinkType == ElectracAPI.LinkType.Elector) {
            return getOfficesForElector(true, this.props.interaction.LinkId);
        } else {
            return Promise.resolve<ElectracAPI.IdNamePair[]>([]);
        }
    }
    componentDidMount() {
        Promise
            .all([this.fetchInteractionDetails(), this.fetchSharableOffices()])
            .then(results => {
                this.initInteraction(results[0], results[1]);
            });
    }
    trackFieldChange(field: InteractionModelProperties, value: any) {
        const newState: any = {
            interaction: {
                ...this.state.interaction
            }
        };
        switch (field) {
            case "Date":
            case "Due":
                newState.interaction[field] = moment(value).utc();
                break;
            case "Level":
                newState.interaction[field] = value.id;
                break;
            case "Topic":
                if (isString(value)) { //New Topic
                    newState.selectedTopic = {
                        Id: NEW_TOPIC_ID,
                        Name: value
                    };
                } else {
                    newState.selectedTopic = value;
                }
                newState.interaction.AssignedTopic = newState.selectedTopic;
                break;
            case "Owner":
                newState.interaction[field] = value;
                if (value) {
                    newState.interaction.OwnerUserID = value.Id;
                } else {
                    newState.interaction.OwnerUserID = null;
                }
                break;
            case "Tags":
                newState.selectedTags = value;
                newState.interaction.AssignedTags = newState.selectedTags;
                break;
            case "ShareOffices":
                newState.selectedOffices = value;
                newState.interaction.ShareOffices = newState.selectedOffices;
                break;
            case "IsDone":
                newState.interaction[field] = value.target.checked;
                break;
            default:
                newState.interaction[field] = value;
                break;
        }
        this.flagUnsavedInteraction();
        this.setState({ ...this.state, ...newState });
    }
    flagUnsavedInteraction() {
        const { webContext } = this.props;
        if (webContext && webContext.flagDirtyState) {
            webContext.flagDirtyState(DIRTY_STATE_KEY, "You have un-saved interaction details");
        }
    }
    render(): JSX.Element {
        const { createdOn, modifiedOn, createdByUser, modifiedByUser } = this.state;
        const { webContext } = this.props;
        const interaction: ElectracAPI.InteractionModel = this.state.interaction;
        if (!this.state.init) {
            return <KendoSpinner message="Loading interaction details" />;
        }
        if (!interaction) {
            return <KendoSpinner message="Loading Interaction" />;
        }
        if (this.state.isSaving === true) {
            return <KendoSpinner message="Saving interaction" />;
        }
        const parseFormats: any = [...DATETIME_EDIT_FORMATS, ...DATE_EDIT_FORMATS];
        const date = interaction.Date != null ? moment.utc(interaction.Date).local().toDate() : undefined;
        const due = interaction.Due != null ? moment.utc(interaction.Due).local().toDate() : undefined;
        const isFromDifferentOffice = (interaction.Id != null && (webContext && webContext.getCurrentOfficeID && interaction.OfficeID != webContext.getCurrentOfficeID()));
        const bCanEditSharingLevel = (interaction.Id == null || !!this.state.canEditSharingLevel == true);
        return <div>
            <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-xxs-12">
                    <fieldset style={FIELDSET_STYLE}>
                        <legend>General Interaction Details</legend>
                        <div className="field-wrapper">
                            <label style={DEFAULT_KENDO_LABEL_STYLE}>Topic{(this.isNewTopic() ? " (New Topic will be created)" : "")}</label>
                            <TopicPicker value={this.state.selectedTopic} onChange={this.trackFieldChange.bind(this, "Topic")} disableNewValues={this.props.disableTopicCreation} />
                        </div>
                        <KendoInputField id="details" type="textarea" label="Details" value={interaction.Details || ""} textareaRows={5} onValueChanged={this.trackFieldChange.bind(this, "Details")} valueChangeTrigger="blur" />
                        <div className="field-wrapper">
                            <label style={DEFAULT_KENDO_LABEL_STYLE}>Tags</label>
                            <TagList value={this.state.selectedTags} onChange={this.fnTagSelectionChanged} />
                        </div>
                        {(() => {
                            if ((createdOn && createdByUser) || (modifiedOn && modifiedByUser)) {
                                return <div className="k-block" style={{ marginTop: 10, padding: 10 }}>
                                    <table>
                                        <tbody>
                                            {(() => {
                                                if (createdOn && createdByUser) {
                                                    return <tr>
                                                        <td><strong>Created</strong></td>
                                                        <td>{moment.utc(createdOn).local().format(DATE_LABEL_DISPLAY_FORMAT)} by {createdByUser.Name}</td>
                                                    </tr>
                                                }
                                            })()}
                                            {(() => {
                                                if (modifiedOn && modifiedByUser) {
                                                    return <tr>
                                                        <td><strong>Updated</strong></td>
                                                        <td>{moment.utc(modifiedOn).local().format(DATE_LABEL_DISPLAY_FORMAT)} by {modifiedByUser.Name}</td>
                                                    </tr>;
                                                }
                                            })()}
                                        </tbody>
                                    </table>
                                </div>
                            }
                        })()}
                        {(() => {
                            if (this.props.isMulti === true) {
                                return <div className="field-wrapper">
                                    <label>
                                        <input type="checkbox" value={this.state.applyToAll} onChange={this.fnApplyToAllChanged} /> Apply bulk changes to all
                                    </label>
                                </div>;
                            }
                        })()}
                    </fieldset>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-xxs-12">
                    <fieldset style={FIELDSET_STYLE}>
                        <legend>Dates, To-do, more</legend>
                        <div className="field-wrapper">
                            <label style={DEFAULT_KENDO_LABEL_STYLE}>Date (will default to today for new interactions if not specified)</label>
                            <DateTimePicker parse={parseFormats} value={date} onChange={this.trackFieldChange.bind(this, "Date")} />
                        </div>
                        <div className="field-wrapper">
                            <label style={DEFAULT_KENDO_LABEL_STYLE}>Due</label>
                            <DateTimePicker parse={parseFormats} value={due} onChange={this.trackFieldChange.bind(this, "Due")} />
                        </div>
                        <KendoInputField id="todo" type="textarea" label="Todo" value={interaction.Todo || ""} textareaRows={5} onValueChanged={this.trackFieldChange.bind(this, "Todo")} valueChangeTrigger="blur" />
                        <div className="field-wrapper">
                            <label style={DEFAULT_KENDO_LABEL_STYLE}>Owner</label>
                            <SingleUsernameSelector value={interaction.Owner} onChange={this.trackFieldChange.bind(this, "Owner")} />
                        </div>
                        <KendoInputField id="link" type="text" label="Link" value={interaction.Link || ""} onValueChanged={this.trackFieldChange.bind(this, "Link")} valueChangeTrigger="blur" />
                        <div className="field-wrapper">
                            <label>
                                <input type="checkbox" checked={interaction.IsDone} onChange={this.trackFieldChange.bind(this, "IsDone")} /> Is Done
                            </label>
                        </div>
                        <KendoInputField id="resolutionNotes" type="textarea" label="Resolution Notes" value={interaction.ResolutionNotes || ""} textareaRows={5} onValueChanged={this.trackFieldChange.bind(this, "ResolutionNotes")} valueChangeTrigger="blur" />
                        <KendoInputField id="priority" type="number" label="Priority" value={`${interaction.Priority}`} onValueChanged={this.trackFieldChange.bind(this, "Priority")} valueChangeTrigger="blur" />
                    </fieldset>
                </div>
                <div className="clearfix"></div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-xxs-12">
                    <fieldset style={FIELDSET_STYLE}>
                        <legend>Sharing</legend>
                        {(() => {
                            if (bCanEditSharingLevel) {
                                return <SharingLevel value={interaction.Level} onChange={this.trackFieldChange.bind(this, "Level")} allowedSharingLevels={this.props.allowedSharingLevels} />;
                            }
                        })()}
                        {(() => {
                            if (this.props.linkType == ElectracAPI.LinkType.Elector) {
                                if (!bCanEditSharingLevel) {
                                    return <div>
                                        <div className="clearfix" />
                                        <div className="k-block k-info-colored">
                                            <p><strong>Sharing level settings disabled as you are editing an interaction created from a different office that is shared to your current office</strong></p>
                                        </div>
                                    </div>;
                                } else if (!isFromDifferentOffice) {
                                    if (interaction.Level == ElectracAPI.SensitiveLevel.Office) {
                                        if (this.state.availableOffices.length > 0) {
                                            return <div className="field-wrapper">
                                                <label style={DEFAULT_KENDO_LABEL_STYLE}>Share with offices</label>
                                                <ShareOfficeMultiSelect data={this.state.availableOffices} value={this.state.selectedOffices} onChange={this.fnOfficeSelectionChanged} />
                                            </div>;
                                        } else {
                                            return <p><strong>NOTE: There are no related offices available to share this interaction with</strong></p>;
                                        }
                                    } else {
                                        if (this.state.selectedOffices.length > 0) {
                                            return <div>
                                                <p><strong>WARNING: You have chosen to share this interaction with {this.state.selectedOffices.length} other offices.Saving this interaction with the current sharing level will</strong></p>
                                                <ul style={{ marginLeft: 15 }}>
                                                    <li>If this is a new interaction you are creating: It will not share this interaction with your selected offices</li>
                                                    <li>If this is an existing interaction you are editing: It will un-share the interaction from any offices currently shared</li>
                                                </ul>
                                            </div>;
                                        }
                                    }
                                }
                            }
                        })()}
                    </fieldset>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-xxs-12">
                    <fieldset style={FIELDSET_STYLE}>
                        <legend>File Attachments</legend>
                        <div style={{ marginTop: 38 }}>
                            <button type="button" className="k-button" disabled={this.state.selectedTopic == null} onClick={this.fnAttachLetter}>Add Letter</button>
                            <InteractionAttachments attachments={interaction.Files} pendingAttachments={this.state.pendingAttachments} onChange={this.fnPendingAttachmentsChanged} />
                        </div>
                    </fieldset>
                </div>
                {(() => {
                    if (this.props.allowAddressInteraction === true) {
                        return <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-xxs-12">
                            <div className="field-wrapper">
                                <label>
                                    <input type="checkbox" value={this.state.applyToAddress} onChange={this.fnApplyToAddressChanged} /> Apply interaction to the address of this person
                                </label>
                            </div>
                        </div>;
                    }
                })()}
            </div>
            {(() => {
                const posStyle: React.CSSProperties = {
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0
                };
                if (this.state.shareDirection == ElectracAPI.OfficeShareDirection.OutOfOffice) {
                    const { createdByOffice, actionedByOffice, actionedByOfficeOn, actionedByUser } = this.state;
                    return <div style={posStyle} className="k-block k-info-colored">
                        <p>
                            <strong>This interaction has been shared with your office by a user from{createdByOffice ? `: ${createdByOffice.Name}` : " a different office"}</strong>
                            {' '}
                        </p>
                        {(() => {
                            if (this.state.isActionedOutOfOffice === true) {
                                return <p><strong>You have marked this interaction as being actioned. When saved, this will signify to the original author of this interaction that your office has actioned this interaction when they next look at this interaction from their office</strong></p>;
                            } else {
                                if (!actionedByOffice && !actionedByOfficeOn && !actionedByUser) {
                                    return <span>
                                        <button type="button" className="k-button" onClick={this.fnMarkActioned} title="Mark this interaction as actioned on your end. When saved, this will signify to the original author of this interaction that your office has actioned this interaction when they next look at this interaction from their office">Mark as actioned</button>
                                    </span>;
                                }
                            }
                        })()}
                    </div>;
                } else if (this.state.shareDirection == ElectracAPI.OfficeShareDirection.CurrentOffice) {
                    const { actionedByOffice, actionedByOfficeOn, actionedByUser } = this.state;
                    if (actionedByOffice && actionedByOfficeOn) {
                        return <div style={posStyle} className="k-block k-info-colored">
                            <p>This interaction was shared out to one or more different offices and was actioned by a user <strong>{actionedByUser.FullName} ({actionedByUser.UserName}) </strong> from <strong>{actionedByOffice.Name}</strong> on <strong>{moment.utc(actionedByOfficeOn).format("llll")}</strong></p>
                        </div>;
                    }
                } else if (isFromDifferentOffice) {
                    return <div style={posStyle} className="k-block k-info-colored">
                        <p><strong>NOTE: You are editing an interaction created by a user from a different office</strong></p>
                    </div>;
                }
            })()}
        </div>;
    }
}

export class InteractionDetailsDialog extends React.Component<IInteractionDetailsDialogProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            details: null
        };
    }
    private onSave = async (e) => {
        e.preventDefault();
        const diag = this.refs['dialog'] as KendoDialog;
        const details = this.refs['details'] as InteractionDetails;

        if (details.state.selectedTopic == null) {
            alert("Please specify a topic for this interaction");
            return;
        }

        if (details.isNewTopic() && !confirm(`You have specified a new topic "${details.state.selectedTopic.Name}". Are you sure you want to create an interaction with this new topic?`)) {
            return;
        }

        const inter = details.buildInteractionForSave();
        details.beginSave();

        try {
            await this.props.onSave(inter);
            diag.close();
        } catch {
            details.endSave();
        }
    }
    render(): JSX.Element {
        let interaction;
        let bCreating = false;
        let bOwner = false;
        let isMulti = false;
        let allowAddressInteraction = false;
        if (this.props.interaction != null) {
            isMulti = this.props.interaction.InteractionType === "MultiInteraction";
            interaction = { 
                ...this.props.interaction, 
                LinkType: this.props.linkType,
                LinkId: this.props.linkId
            };

            if (!interaction.FilesToAttach) {
                interaction.FilesToAttach = [];
            }
        } else {
            interaction = makeNewInteraction(this.props.linkType, this.props.linkId, this.props.defaultSensitiveLevel);
            if (this.props.linkType == ElectracAPI.LinkType.Elector) allowAddressInteraction = true;
            bCreating = true;
            bOwner = true;
        }
        const shareLevels = this.props.requestAllowedSharingLevels(interaction, bCreating, bOwner);
        const buttons = [{
            id: "interaction-save",
            text: "Save",
            disabled: false,
            click: this.onSave
        }];
        const onClosed = () => {
            const {webContext} = this.props;
            if (!webContext || !webContext.clearDirtyState) return;

            webContext.clearDirtyState(DIRTY_STATE_KEY);
        };
        const onBeforeClose = (userTriggered, callback) => {
            const { webContext} = this.props;
            const confirmDiscard = !webContext || !webContext.confirmDiscardDirtyState
                ? (() => true)
                : webContext.confirmDiscardDirtyState

            const discardChanges = !userTriggered || confirmDiscard(". Are you sure you want to close this dialog? Choose OK to close this dialog and discard any changes. Choose Cancel to stay.");
            callback(discardChanges);
        };
        const dialogHeight = Math.min(730, window.innerHeight - 60);
        return <KendoDialog ref='dialog' title={this.props.title} width={900} height={dialogHeight} modal={true} onClosed={onClosed} onBeforeClose={onBeforeClose} buttons={buttons}>
            <InteractionDetails
                ref='details'
                isMulti={isMulti}
                allowAddressInteraction={allowAddressInteraction}
                linkType={this.props.linkType}
                linkId={this.props.linkId}
                interaction={interaction}
                allowedSharingLevels={shareLevels}
                disableTopicCreation={this.props.disableTopicCreation}
                webContext={this.props.webContext}
                onSave={this.props.onSave} />
        </KendoDialog>;
    }
}