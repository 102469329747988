import * as React from "react";
import { AutoSizer, List } from "react-virtualized";

//TODO: @electrac/components

// Based on: https://github.com/bvaughn/react-virtualized/blob/master/playground/tree.js

const DEFAULT_ROW_HEIGHT = 20;
const TREE_PATH_SEPARATOR = "-";

export interface ITreeViewProps {
    data: ITreeNode[];
    className?: string;
    rowClassName?: string;
    canExpand?: (item: ITreeNode, nodePath: string[]) => boolean;
    beginExpand?: (item: ITreeNode, nodePath: string[]) => Promise<ITreeNode[]>;
    shouldRenderNodeHandler?: (item: ITreeNode) => boolean;
    renderEmptyToggler?: (item: ITreeNode, nodePath: string[]) => (string | JSX.Element);
    renderToggler?: (item: ITreeNode, nodePath: string[], expanded: boolean) => (string | JSX.Element);
    renderNodeContent?: (item: ITreeNode, nodePath: string[]) => (string | JSX.Element);
}

interface RowArgs {
    index: number;
}

interface RowRenderArgs extends RowArgs {
    key?: React.Key;
    style?: React.CSSProperties;
}

export class TreeView extends React.Component<ITreeViewProps, any> {
    private list?: List;
    // private fnListMounted: (list: List) => void;
    // private fnRowHeight: (args: RowArgs) => number;
    // private fnRowRenderer: (args: RowRenderArgs) => JSX.Element | undefined;
    constructor(props: ITreeViewProps) {
        super(props);
        // this.fnListMounted = this.onListMounted.bind(this);
        // this.fnRowHeight = this.calcRowHeight.bind(this);
        // this.fnRowRenderer = this.rowRenderer.bind(this);
    }
    public forceRefresh() {
        //HACK: Typings bug
        if (!this.list) return;

        this.list.recomputeRowHeights();
        this.list.forceUpdate();
    }
    private onListMounted = (list: List) => {
        this.list = list;
    }
    private getExpandedItemCount(item: ITreeNode): number {
        let count = 1;
        const { shouldRenderNodeHandler } = this.props;
        if (item.expanded && item.children) {
            let filter = (i: ITreeNode) => true;
            if (shouldRenderNodeHandler)
                filter = shouldRenderNodeHandler;

            count += item
                .children
                .filter(filter)
                .map<number>(this.getExpandedItemCount.bind(this))
                .reduce((total, count) => total + count, 0);
        }
        return count;
    }
    private calcRowHeight = (args: RowArgs): number => {
        const { data, shouldRenderNodeHandler } = this.props;
        const item = data[args.index];
        if (shouldRenderNodeHandler && !shouldRenderNodeHandler(item)) {
            return 0;
        }
        return this.getExpandedItemCount(data[args.index]) * DEFAULT_ROW_HEIGHT;
    }
    private itemRenderer = (item: ITreeNode, keyPrefix: string): JSX.Element => {
        const { canExpand, beginExpand, renderEmptyToggler, renderToggler, renderNodeContent, shouldRenderNodeHandler } = this.props;
        const nodePath = keyPrefix.split(TREE_PATH_SEPARATOR);
        const done = (node: ITreeNode, listComponent?: List) => {
            node.expanded = !node.expanded;
            if (!listComponent) return;
            //HACK: Typings bug
            (listComponent).recomputeRowHeights();
            listComponent.forceUpdate();
        };
        const onTogglerClick = (event: any) => {
            event.stopPropagation();
            

            if (beginExpand) {
                beginExpand(item, nodePath).then(res => {
                    item.children = res;
                    done(item, this.list);
                }); //TODO: Failure case?
            } else {
                done(item, this.list);
            }
        }

        const liProps: any = {};
        let children = [] as JSX.Element[];

        let toggler;
        const label = renderNodeContent ? renderNodeContent(item, nodePath) : item.title;

        let bCanExpand = false;
        if (canExpand) {
            bCanExpand = canExpand(item, nodePath);
        } else {
            bCanExpand = !!(item.expanded && item.children);
        }

        if (item.expanded) {
            toggler = renderToggler ? renderToggler(item, nodePath, true) : '[-]';
        } else {
            if (bCanExpand) {
                toggler = renderToggler ? renderToggler(item, nodePath, false) : '[+]';
            }
        }
        if (item.expanded && item.children) {
            let tmpChildren = item.children;
            if (shouldRenderNodeHandler) {
                tmpChildren = item.children.filter((child, index) => {
                    return shouldRenderNodeHandler(child);
                });
            }
            children = tmpChildren.map((child, index) => this.itemRenderer(child, `${keyPrefix}${TREE_PATH_SEPARATOR}${index}`));
        }
        if (!toggler) {
            toggler = renderEmptyToggler ? renderEmptyToggler(item, nodePath) : '   ';
        }

        children.unshift(
            <div className="item-body" key="label" style={{ cursor: (item.children && item.children.length) ? 'pointer' : 'auto' }}>
                <span className="item-body-inner">
                    <span onClick={onTogglerClick} className="cc-component-treeview-node-toggler">{toggler}</span>
                    <span className="cc-component-treeview-node-content">{label}</span>
                </span>
            </div>
        );
        return <ul key={keyPrefix} className="item-container">
            <li {...liProps}>{children}</li>
        </ul>;
        //return React.DOM.ul({ key: keyPrefix, className: "item-container" }, React.DOM.li(liProps, children));
    }
    private rowRenderer = (args: RowRenderArgs): JSX.Element | undefined => {
        const item = this.props.data[args.index];
        const { shouldRenderNodeHandler } = this.props;
        if (shouldRenderNodeHandler && !shouldRenderNodeHandler(item)) {
            return undefined;
        }
        return <ul key={args.key} style={args.style} className={this.props.rowClassName}>
            {this.itemRenderer(item, `${args.index}`)}
        </ul>;
    }
    render(): JSX.Element {
        const { data } = this.props;
        return <AutoSizer>
            {(p: any) => {
                return <List height={p.height}
                    className={`cc-component-treeview ${this.props.className}`}
                    overscanRowCount={10}
                    ref={this.onListMounted}
                    rowHeight={this.calcRowHeight}
                    rowRenderer={this.rowRenderer}
                    rowCount={data.length}
                    width={p.width} />;
            }}
        </AutoSizer>
    }
}

export interface ITreeNode {
    title: string;
    key?: string;
    expanded: boolean;
    children?: ITreeNode[];
    tag?: any;
}