import * as React from 'react';
import * as PropTypes from "prop-types";
import { IElectracWebContext } from '@electrac/api';
import * as ElectracAPI from "@electrac/model";
import { InteractionModel } from '@electrac/model';
import { useContext } from 'react';

/**
 * The full set of interaction sensitivity levels
 */
export const ALL_SHARING_LEVELS = [
    { value: ElectracAPI.SensitiveLevel.Shared, label: "Shared" },
    { value: ElectracAPI.SensitiveLevel.Office, label: "Office Only" },
    { value: ElectracAPI.SensitiveLevel.Sensitive, label: "Sensitive - User Only" },
    { value: ElectracAPI.SensitiveLevel.Archived, label: "Archived Only" }
];

/**
 * Describes an interaction sensitivity level
 */
export interface SensitiveLevelDescriptor {
    id: ElectracAPI.SensitiveLevel;
    label: string;
    isEnabled: boolean;
}


export const InteractionContext = React.createContext<IInteractionPanelContext | undefined>(undefined);




export const useInteraction = () => {
    const state = useContext(InteractionContext);
    if (!state) throw new Error("Need interaction provider");
    return state;
}

/**
 * The component context that child interaction item components can access
 */
export interface IInteractionPanelContext {
    getSharingLevels(interaction: ElectracAPI.InteractionSaveModel, isCreating: boolean, isOwner: boolean): SensitiveLevelDescriptor[];
    allowTopicCreation: boolean;
    getDefaultSensitiveLevel: ElectracAPI.SensitiveLevel;
    linkData: {
        linkType: ElectracAPI.LinkType,
        linkId: number
    };
    showElectorsForMultiInteraction(multiID: number): void;
    deleteAttachment(interactionId: number, fileId: number): void;
    setEditing(isEditing: boolean): void;
    setCollapsed(interactionId: number, isCollapsed: boolean): void;
    isEditing: boolean;
    notifyAdded(interaction: Readonly<InteractionModel>): void;
    notifyUpdated(interaction: Readonly<InteractionModel>, oldInteractionId?: number): void;
    notifyRemoved(interactionId: number): void;
    webContext: IElectracWebContext;
}
