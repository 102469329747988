import * as React from "react";
import { CRITERIA_VIEW_CONTEXT_VALIDATION_MAP } from './context';
import { IAdvancedListBuilderCriteriaNodeProps, CriteriaNodeProvider } from './common';
import { NodeEditorOptionsPanel, DEFAULT_NODE_CONTAINER_STYLE } from './node-editor-options';
import { CriteriaNodeUtil } from "./criteria-node-util";

export abstract class AbstractNodeEditor<T extends IAdvancedListBuilderCriteriaNodeProps, TState = {}> extends React.Component<T, TState> {
    //context: ICriteriaViewContext; todo use createcontext
    static contextTypes = CRITERIA_VIEW_CONTEXT_VALIDATION_MAP;

    protected canPromote(): boolean {
        return true;
    }
    protected canDemote(): boolean {
        const parent = this.context.findNode(this.props.node.parentId);
        if (parent) {
            if (parent.rst) { //Parent node has child restrictions
                const grandParent = this.context.findNode(parent.parentId);
                if (grandParent && grandParent.rst != parent.rst) { //Grand-parent must also have the same restrictions
                    return false;
                }
            }
        }
        return true;
    }
    protected renderValueSelector(e: any) {
        return e;
    }
    protected valueSelector(e: any) {
        return e;
    }
    protected onMouseEnter = (e: any) => {
        //this.setState({ isMouseOver: true });
    }
    protected onMouseLeave = (e: any) => {
        //this.setState({ isMouseOver: false });
    }
    protected onValueChange = (e: any) => {
        this.setNodeValue(this.valueSelector(e));
    }
    protected onRemoveMe = (e: any) => {
        if (this.props.node.parentId) {
            this.context.removeChildNode(this.props.node.parentId, this.props.node.clientId);
        }
    }
    protected baseRemoveMe(e: any) {
        this.onRemoveMe(e);
    }
    protected onDemoteMe = (e: any) => {
        this.context.demoteChildNode(this.props.node.clientId);
    }
    protected onPromoteToGroup = (e: any) => {
        this.context.promoteNodeToGroup(this.props.node.clientId);
    }
    protected onModifierChanged = (value: any) => {
        this.context.updateNode(this.props.node.clientId, { m: value });
    }
    protected setNodeValue(value: any) {
        this.context.updateNode(this.props.node.clientId, { a: value });
    }
}

//Inheritance may be frowned upon in React, but I can't find a better way to cut out rendering boilerplate
export abstract class AbstractNodeEditorWithStandardLayout<T extends IAdvancedListBuilderCriteriaNodeProps, TState = {}> extends AbstractNodeEditor<T, TState> {
    constructor(props: T) {
        super(props);
        
    }
    protected getStyle() {
        return {};
    }
    protected renderLabel(key: string, desc: CriteriaNodeProvider | undefined): JSX.Element | JSX.Element[] | undefined {
        if (desc) {
            return <strong key={key}>{`${desc.category} - ${desc.label}`}</strong>;
        }
        return undefined;
    }
    protected abstract renderValueEditor(key: string, value: any): JSX.Element | JSX.Element[];
    protected renderModifier(key: string): JSX.Element | JSX.Element[] | undefined {
        return undefined;
    }
    render(): JSX.Element {
        const { node, layoutRenderer, optionsLayoutRenderer } = this.props;
        const style = this.getStyle();
        // const isChecked = (node.a == "true");
        const desc = CriteriaNodeUtil.getNodeDescriptor(node.q) || CriteriaNodeUtil.getGroupNodeDescriptor(node.rst);
        const classNames = `k-block `;

        const label = this.renderLabel("node-editor-label", desc);
        const modifier = this.renderModifier("node-editor-modifier");
        const valueEditor = this.renderValueEditor("node-editor-value", this.renderValueSelector(node.a));
        const nodeOpts = <NodeEditorOptionsPanel key="node-editor-options" node={node} canDemote={this.canDemote()} canPromote={this.canPromote()} onRemove={this.onRemoveMe} onDemote={this.onDemoteMe} onPromoteToGroup={this.onPromoteToGroup} optionsLayoutRenderer={optionsLayoutRenderer} />;

        return <div className={classNames} style={{ ...DEFAULT_NODE_CONTAINER_STYLE, ...style }} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
            {(() => {
                if (layoutRenderer) {
                    return layoutRenderer(this.context, label, modifier, valueEditor, nodeOpts);
                } else {
                    return [
                        label,
                        modifier,
                        valueEditor,
                        nodeOpts
                    ];
                }
            })()}
        </div>;
    }
}

export abstract class AbstractNodeGroupEditor<T extends IAdvancedListBuilderCriteriaNodeProps> extends AbstractNodeEditor<T> {
//    context: ICriteriaViewContext;
    static contextTypes = CRITERIA_VIEW_CONTEXT_VALIDATION_MAP;
    constructor(props: T) {
        super(props);
    }
    protected canDemote(): boolean {
        return false;
    }
}