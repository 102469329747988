import * as React from "react";
import { isPositionError } from "@ml/common/src/type-guards";
import { AlertStyleKind, AlertPanel, AlertPanelTheme } from "./alert-panel";

export interface IErrorDisplayProps {
    displayStyle?: AlertStyleKind;
    error: any;
}

export interface IErrorDisplayState {
    id_head?: string;
    id_body?: string;
    error?: string;
    displayStyle?: AlertStyleKind;
    expanded?: boolean;
}

export class ErrorDisplay extends React.Component<IErrorDisplayProps, IErrorDisplayState> {
    constructor(props: any) {
        super(props);
        var now = (new Date()).getTime();
        this.state = {
            id_head: "error_head_" + now,
            id_body: "error_body_" + now,
            error: this.stringifyError(props.error),
            expanded: false,
            displayStyle: (this.props.displayStyle != null) ? this.props.displayStyle : AlertStyleKind.Kendo
        }
    }
    private onExpandDetails = (e: any) => {
        e.preventDefault();
        this.setState({ expanded: !this.state.expanded });
        return false;
    }
    private stringifyError(e: any): string {
        if (e instanceof Error) {
            return JSON.stringify({ message: e.message, name: e.name, stack: e.stack }, null, 4);
        } else if (isPositionError(e)) {
            return JSON.stringify({ message: e.message, name: "PositionError" }, null, 4);
        } else if (typeof (e) == "object") {
            return JSON.stringify(e, null, 4);
        }
        return e + "";
    }
    render(): JSX.Element {
        var body;
        if (this.state.displayStyle == AlertStyleKind.Bootstrap) {
            body = (
                <div className="alert alert-danger">
                    <h4><i className="fa fa-warning"></i> An error occurred in this component</h4>
                    <div className="panel panel-danger">
                        <div className="panel-heading" role="tab" id={this.state.id_head}>
                            <h4 className="panel-title">
                                <a onClick={this.onExpandDetails} className="collapsed" role="button" data-toggle="collapse" href={`#${this.state.id_body}`} aria-expanded="false" aria-controls={this.state.id_body}>
                                    Error Details (click to expand)
                                </a>
                            </h4>
                        </div>
                        {this.state.expanded === true && <div id={this.state.id_body} className="panel-body" aria-labelledby={this.state.id_head}>
                                    <pre className="pre-scrollable">
                                        {this.state.error}
                                    </pre>
                                </div>
                            }
                    </div>
                </div>
            );
        } else { //Kendo
            body = <AlertPanel theme={AlertPanelTheme.Error} kind={this.state.displayStyle}>
                <h3>An error occurred in this component</h3>
                <pre>{this.state.error}</pre>
            </AlertPanel>;
        }
        return body;
    }
}