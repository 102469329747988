import * as React from "react";

import { Icons } from "@electrac/plugin";
import {  ISurveyQuestionReadableCommonProps } from './Common';

export interface ISurveyInstructionProps extends ISurveyQuestionReadableCommonProps {

    /**
     * The instructions
     */
    text?: string;

}

export const SurveyInstruction = (props: ISurveyInstructionProps) => <div className={props.theme && props.theme.itemLabelClass}><strong>{Icons.TICK} </strong> {props.text}</div>;
