import * as React from 'react';
import * as ElectracAPI from "@electrac/model";
//import { Multiselect } from 'react-widgets';
import * as Multiselect from 'react-widgets/lib/Multiselect';

interface IOfficeMultiSelectProps {
    data: ElectracAPI.IdNamePair[];
    value: ElectracAPI.IdNamePair[];
    onChange: (e: ElectracAPI.IdNamePair[]) => void;
}

export const ShareOfficeMultiSelect = (props: IOfficeMultiSelectProps) => {
    return <Multiselect
        value={props.value}
        data={props.data}
        textField="Name"
        onChange={props.onChange} />;
};