import * as React from "react";

import { NameValuePair } from '@electrac/model';


export interface IWalkReportSelectionProps {
    className?: string;
    onChange: (change: string) => void;
}


export interface IWalkReportSelectionState {
    
    reportID?: string;
    reports?: NameValuePair[];
}

export type WalkReportSelectionFunctionProps = IWalkReportSelectionState & IWalkReportSelectionProps


/**
 * Display a standard dropdown for available CC surveys. Populate props by calling the appropriate API
 * $.getJSON("/api/surveys").then(r => {
 *           this.setState({ availableSurveys: r });
 *       });
 * @param props 
 */
export function WalkReportSelectionFunction(props: WalkReportSelectionFunctionProps) {

    const { reports, reportID} = props;

    if (!reports) {
        return <p className="form-control-static">Loading available reports ...</p>
    }

    return <select className={props.className} value={reportID || ""} onChange={(e) => props.onChange(e.target.value)}>
        <option>(Select Report)</option>
        {reports.map(r => (<option key={r.Value} value={r.Value}>{r.Text}</option>))}
    </select>;
}


// export class WalkReportSelection extends React.Component<IWalkReportSelectionProps, IWalkReportSelectionState> {

//     constructor(props: IWalkReportSelectionProps) {
//         super(props);
//         this.state = {
            
//         }
//     }

//     componentDidMount() {
//         $.getJSON("/api/surveys").then(r => {
//             this.setState({ availableReports: r });
//         });
//     }

//     render() {       
//         return <WalkReportSelectionFunction {...this.props} {...this.state} />
//     }
// }

