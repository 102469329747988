import { IDragSourceProps, IDropTargetProps } from "@electrac/components";
import { AlertPanel, AlertPanelTheme } from "@ml/components";
import * as React from "react";
import { DragSource, DropTarget } from "react-dnd";
import * as ReactDOM from "react-dom";
import { ISurveyChoiceItem } from "./choice";
import { SurveyFieldDescription } from "./Common";
import { ISurveyRankedChoiceProps } from "./RankedChoice";

const CHOICE_BASE_ITEM_STYLE = {
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white'
};
const CHOICE_ITEM_STYLE = {
    ...CHOICE_BASE_ITEM_STYLE,
    border: '1px dashed gray'
};
const SELECTED_CHOICE_ITEM_STYLE = {
    ...CHOICE_BASE_ITEM_STYLE,
    border: '1px solid black',
    backgroundColor: "#E2F0D9"
}

const ItemTypes = {
    RANKED_CHOICE_MIN: "ranked-choice-min"
};

const DRAGGABLE_ITEM_STYLE = {
    border: '1px dashed gray',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    backgroundColor: 'white',
    cursor: 'move'
};

const cardSource = {
    beginDrag(props: IAnsweredChoiceProps) {
        return {
            id: props.id,
            index: props.index
        };
    }
};

const cardTarget = {
    hover(props: IAnsweredChoiceProps, monitor, component: AnsweredChoice) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = (ReactDOM.findDOMNode(component) as Element).getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
        }

        // Time to actually perform the action
        props.onDragMove(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    }
};

interface IRankedChoiceContainerProps {
    debugMode?: boolean;
    answeredChoiceIDs: number[];
    choices: ISurveyChoiceItem[];
    minChoicesToRank: number;
    defaultOptionText?: string;
    onChange: (answer: string) => void;
}

interface IAnsweredChoiceProps {
    id: number;
    index: number;
    onDragMove: (dragIndex, hoverIndex) => void;
    choice: ISurveyChoiceItem;
    onRemove: () => void;
}

@DropTarget(ItemTypes.RANKED_CHOICE_MIN, cardTarget, connect => ({
    connectDropTarget: connect.dropTarget()
}))
@DragSource(ItemTypes.RANKED_CHOICE_MIN, cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
}))
class AnsweredChoice extends React.Component<IAnsweredChoiceProps & IDragSourceProps & IDropTargetProps> {
    constructor(props) {
        super(props);
    }
    render() {
        const { index, choice, isDragging, connectDragSource, connectDropTarget } = this.props;
        const opacity = isDragging ? 0 : 1;
        const itemStyle: React.CSSProperties = { ...DRAGGABLE_ITEM_STYLE };
        itemStyle.opacity = opacity;
        if (!connectDragSource || !connectDropTarget) {
            console.log("Drag and drop is not possible, rendering nothing");
            return null;
        }
        return connectDragSource(connectDropTarget(
            <div style={{ ...SELECTED_CHOICE_ITEM_STYLE, display: "flex", alignItems: "center" }}>
                <span style={{ flex: 1 }}>#{index + 1} - {choice.label}</span>
                <button title="Remove this choice" onClick={() => this.props.onRemove()}>🗑️</button>
            </div>
        ));
    }
}

const UnansweredChoice: React.FC<{ defaultOptionText?: string, onChange: (chid: string) => void, remainingChoices: { name: string, value: string }[] }> = (props) => {
    return <div style={CHOICE_ITEM_STYLE}>
        <select style={{ margin: 0, border: "none", width: "100%", fontStyle: "italic" }} onChange={e => props.onChange(e.target.value)}>
            <option>{props.defaultOptionText || "(Select a choice)"}</option>
            {props.remainingChoices.map(ch => <option key={ch.value} value={ch.value}>{ch.name}</option>)}
        </select>
    </div>
}

const RankedChoiceContainer: React.FC<IRankedChoiceContainerProps> = ({ defaultOptionText, answeredChoiceIDs, choices, onChange, debugMode, minChoicesToRank }) => {
    const remainingChoices = choices.filter(c => !answeredChoiceIDs.includes(c.value)).map(c => ({ name: c.label, value: c.value }));
    const els = [] as React.ReactNodeArray;
    const answeredEls = [] as React.ReactNodeArray;
    let index = 0;

    const onReorderChoice = (dragIndex: number, hoverIndex: number) => {
        const selection = [...answeredChoiceIDs];
        const removed = selection.splice(dragIndex, 1)[0];
        selection.splice(hoverIndex, 0, removed);
        const answer = JSON.stringify(selection);
        console.log("New answer", JSON.parse(answer));
        onChange(answer);
    };

    const onRemoveChoice = (chid) => {
        const selection = [...answeredChoiceIDs].filter(c => c != chid);
        const answer = JSON.stringify(selection);
        console.log("New answer", JSON.parse(answer));
        onChange(answer);
    };

    const onUnansweredChoiceMade = (chid) => {
        const selection = [...answeredChoiceIDs];
        console.log("Existing selection", selection);
        const ac = choices.find(c => c.value == chid);
        if (ac) {
            // Slot in the newly answered choice at end of this position.
            selection.push(ac.value);
            console.log("Current selection", selection);

            const answer = JSON.stringify(selection);
            console.log("New answer", JSON.parse(answer));
            onChange(answer);
        }
    };

    const bEmpty = answeredChoiceIDs.length == 0;
    const bHasMultiple = answeredChoiceIDs.length > 1;
    const bHasRemainder = answeredChoiceIDs.length < minChoicesToRank;
    const bAllChoicesMade = answeredChoiceIDs.length == minChoicesToRank;

    if (bEmpty) {
        els.push(<p key="min-choice-empty" style={{ color: "red" }}>Choose ({minChoicesToRank}) option(s) below to make your selection</p>);
    } else if (bHasRemainder) {
        if (bHasMultiple) {
            els.push(<p key="min-choice-warning" style={{ color: "red" }}>Select ({minChoicesToRank - answeredChoiceIDs.length}) more option(s) to complete your selection. You may drag-and-drop your current selected choices to re-order them</p>);
        } else {
            els.push(<p key="min-choice-warning" style={{ color: "red" }}>Select ({minChoicesToRank - answeredChoiceIDs.length}) more option(s) to complete your selection</p>);
        }
    } else if (bAllChoicesMade) {
        els.push(<p key="min-choice-met" style={{ color: "green" }}>Complete! You have selected all options</p>);
    }

    // Process answered first
    for (const cid of answeredChoiceIDs) {
        const ch = choices.find(c => c.value == cid);
        if (ch) {
            answeredEls.push(<AnsweredChoice id={ch.value}
                key={`answered-choice-${cid}`}
                index={index}
                choice={ch}
                onDragMove={onReorderChoice}
                onRemove={() => onRemoveChoice(ch.value)} />);
            index++;
        }
    }

    if (answeredEls.length > 0) {
        els.push(<div key="orderable-container" className="orderable-container">
            {answeredEls}
        </div>);
    }

    if (bHasRemainder) {
        for (let i = 0; i < (choices.length - answeredChoiceIDs.length); i++) {
            els.push(<UnansweredChoice defaultOptionText={defaultOptionText} onChange={chid => onUnansweredChoiceMade(chid)} key={`unanswered-choice-${i}`} remainingChoices={remainingChoices} />);
        }
    } else {
        for (let i = 0; i < (choices.length - answeredChoiceIDs.length); i++) {
            els.push(<div key={`empty-${i}`} style={{ ...DRAGGABLE_ITEM_STYLE, fontStyle: "italic" }}>{defaultOptionText || <>&nbsp;</>}</div>);
        }
    }
    return <>
        {els}
    </>
}

export interface ISurveyRankTopChoicesProps extends ISurveyRankedChoiceProps {
    minChoicesToRank?: number;
    defaultOptionText?: string;
}

export const SurveyRankTopChoices: React.FC<ISurveyRankTopChoicesProps> = ({ minChoicesToRank, defaultOptionText, choices, answer, theme, description, label, onChange, isReadOnly, debugMode }) => {
    let choicesToRender = choices;
    let answeredChoiceIDs = [] as number[];
    if (answer) {
        const ordering = JSON.parse(answer) as number[];
        if (ordering.length) {
            choicesToRender = [];
            for (const chid of ordering) {
                answeredChoiceIDs.push(chid);
                const ch = choices.find(c => c.value == chid);
                if (ch) {
                    choicesToRender.push(ch);
                }
            }
        }
    }

    return <div className={`${theme && theme.getFieldContainerClass()} survey-ranked-choice`}>
        <div className={theme && theme.getQuestionLabelClass()}>
            <label className={theme && theme.itemLabelClass}>{label}</label>
            <SurveyFieldDescription description={description} />
        </div>
        <div className={theme && theme.getQuestionBodyClass()}>
            {(() => {
                if (isReadOnly) {
                    return <ol className="ranked-choice-answer-list">
                        {choicesToRender.map((v, i) => <li key={i}>{v.label}</li>)}
                    </ol>;
                } else {
                    if (typeof (minChoicesToRank) == 'undefined') {
                        return <AlertPanel theme={AlertPanelTheme.Error}>ERROR: Min choices to rank setting was not specified for this question</AlertPanel>
                    }
                    if (minChoicesToRank > choices.length) {
                        return <AlertPanel theme={AlertPanelTheme.Error}>ERROR: Min choices to rank setting ({minChoicesToRank}) is greater than the number of choices available ({choices.length})</AlertPanel>
                    }
                    // NOTE: defaultOptionText is meant to represent "Option Text" in the original design mockup, however it is unclear what this string is actually
                    // meant to represent in practice, so it is left un-assigned for now.
                    return <RankedChoiceContainer defaultOptionText={defaultOptionText} minChoicesToRank={minChoicesToRank} answeredChoiceIDs={answeredChoiceIDs} choices={choices} onChange={onChange} debugMode={debugMode} />
                }
            })()}
        </div>
    </div>;
}