import * as React from "react";
import { ISurveyQuestionCommonProps } from "./Common";
import { SurveyChoice, ISurveyChoiceItem } from "./choice";
import { SurveyContainerStyle, ISurveyContainerContext, QuestionEventHandler } from "@electrac/plugin";
import { IMvcSelectItem } from "@electrac/model";
import { arraysChanged, logInfo } from "@ml/common";
import { SurveyFieldDescription } from "./Common";
import { RemoteChoiceAnswerSet } from "../RemoteChoiceAnswerSet";

export function choiceBindingsChanged(a: any, b: any): boolean {
    if (a == null && b != null) {
        //logWarn("a == null && b != null");
        return true;
    }
    if (a != null && b == null) {
        //logWarn("a != null && b == null");
        return true;
    }
    if (a != null && b != null)
    {
        var keysA = Object.keys(a).sort();
        var keysB = Object.keys(b).sort();
        if (arraysChanged(keysA, keysB)) {
            //logWarn("arraysChanged(keysA, keysB)");
            return true;
        }

        var valsA = keysA.map(k => a[k]);
        var valsB = keysB.map(k => b[k]);
        //logWarn("Comparing...");
        //logWarn("a");
        //logWarn(valsA);
        //logWarn("b");
        //logWarn(valsB);
        if (arraysChanged(valsA, valsB, (oa, ob) => oa.questionId == ob.questionId)) {
            //logWarn("arraysChanged(valsA, valsB, comparer)");
            return true;
        }
        //logWarn("both a and b appear to be the same");
    }
    //logWarn("both a and b are null/undefined");
    return false;
}



export interface ISurveyRemoteChoiceProps extends ISurveyQuestionCommonProps {
    context: ISurveyContainerContext;
    dataSourceId: number;
    choiceParameterBindings: any;
    noChoicesMessage: string;
    autoSelectIfOneItem: boolean;
    onChange?: (value?: any) => void;
    allowMultipleChoice: boolean;
    answer?: string;
    allowOtherChoice: boolean;
}

export interface ISurveyRemoteChoiceState {
    data: ISurveyChoiceItem[] | null;
    error?: string;
}

export class SurveyRemoteChoice extends React.Component<ISurveyRemoteChoiceProps, Partial<ISurveyRemoteChoiceState>> {
    private fnInternalChange: (selectedValues: any[], otherAnswer: string) => void;
    private fnQuestionHandler: QuestionEventHandler;
    constructor(props) {
        super(props);
        this.fnInternalChange = this.onInternalChange.bind(this);
        this.fnQuestionHandler = this.handleQuestionEvent.bind(this);
        this.state = {
            data: null
        };
    }
    private onInternalChange(selectedValues: any[], otherAnswer: string): void {
        const value = RemoteChoiceAnswerSet.encode(selectedValues, otherAnswer);
        if (this.props.onChange)
            this.props.onChange(value);
    }
    private updateItems(props: ISurveyRemoteChoiceProps, latestAnswers?: any) {
        const { context, dataSourceId, choiceParameterBindings, autoSelectIfOneItem } = props;
        let answer = props.answer;
        if (context && dataSourceId) {
            this.setState({ data: null, error: undefined });
            let latest;
            if (latestAnswers && latestAnswers.answer) {
                //This was called from a change trigger (whose event contains the latest value)
                //Include this answer as the "latest" parameter value
                const matchingParam = Object.keys(choiceParameterBindings)
                    .map(k => choiceParameterBindings[k])
                    .filter(binding => binding.questionId == latestAnswers.questionId)
                    .map(binding => binding.name)[0];
                if (matchingParam) {
                    latest = {};
                    latest[matchingParam] = latestAnswers.answer;
                }
            }
            const currentAnswerSet = new RemoteChoiceAnswerSet(answer);
            logInfo(`Attempting to fetch choices for ${props.label}`);
            logInfo(latestAnswers);
            logInfo(answer);
            context.getChoicesRemotely(dataSourceId, choiceParameterBindings, latest).then(res => {
                if (res instanceof Error) {
                    this.setState({ data: null, error: res.message });
                } else {
                    const items = (res || []).map((r: IMvcSelectItem) => {
                        return {
                            value: r.Value,
                            label: r.Text,
                            checked: false
                        };
                    });
                    const changedAnswers = currentAnswerSet.updateItems(items);
                    if (items.length == 1 && autoSelectIfOneItem === true) {
                        if (!items[0].checked) {
                            items[0].checked = true;
                            //Flow the auto-selected answer back
                            if (this.props.onChange)
                                this.props.onChange(items[0].value);
                        }
                    } else {
                        if (changedAnswers.length > 0) {
                            const newVal = currentAnswerSet.encodeThis();
                            logInfo(`Removing [${changedAnswers.join(",")}] from current answer. New answer is: ${newVal}`);
                            if (this.props.onChange)
                                this.props.onChange(newVal);
                        }
                    }
                    this.setState({ data: items });
                }
            }).catch(err => {
                if (err instanceof Error) {
                    this.setState({ data: null, error: err.message });
                } else {
                    this.setState({ data: null, error: err });
                }
            });
        }
    }
    private getBindingForQuestion(questionId: number, props: ISurveyRemoteChoiceProps) {
        if (props.choiceParameterBindings) {
            for (const pid in props.choiceParameterBindings) {
                const binding = props.choiceParameterBindings[pid];
                const qid = binding.questionId;
                if (qid == questionId) {
                    return binding;
                }
            }
        }
        return null;
    }
    private handleQuestionEvent(args) {
        const binding = this.getBindingForQuestion(args.questionId, this.props);
        if (binding) {
            this.updateItems(this.props, args);
        }
    }
    private teardownQuestionListeners(props: ISurveyRemoteChoiceProps): void {
        if (props.choiceParameterBindings) {
            for (const pid in props.choiceParameterBindings) {
                const qid = props.choiceParameterBindings[pid].questionId;
                if (this.props.context.unregisterQuestionListener(qid, this.fnQuestionHandler)) {
                    logInfo(`Un-registered listener ${this.props.label} to ${qid}`);
                }
            }
        }
    }
    private setupQuestionListeners(props: ISurveyRemoteChoiceProps): void {
        if (props.choiceParameterBindings) {
            for (const pid in props.choiceParameterBindings) {
                const qid = props.choiceParameterBindings[pid].questionId;
                if (this.props.context.registerQuestionListener(qid, this.fnQuestionHandler)) {
                    logInfo(`Registered listener (${this.props.label}) to ${qid}`);
                }
            }
        }
    }
    componentDidMount() {
        this.setupQuestionListeners(this.props);
        this.updateItems(this.props);
    }
    componentWillUnmount() {
        this.teardownQuestionListeners(this.props);
    }
    componentWillReceiveProps(nextProps: ISurveyRemoteChoiceProps) {
        if (this.props.dataSourceId != nextProps.dataSourceId || choiceBindingsChanged(this.props.choiceParameterBindings, nextProps.choiceParameterBindings)) {
            this.teardownQuestionListeners(this.props);
            this.setupQuestionListeners(nextProps);
            this.updateItems(nextProps);
        }
    }
    render(): JSX.Element {
        const { context, label, description, theme } = this.props;
        if (!context.isOnline()) {
            return <div className={`${theme && theme.getFieldContainerClass()} survey-choice`}>
                <div className={theme && theme.getQuestionLabelClass()}>
                    <label className={theme && theme.itemLabelClass}>{label}</label>
                    <SurveyFieldDescription description={description} />
                </div>
                <div className={theme && theme.getQuestionBodyClass()}>
                    <div className={theme && theme.getContainerClasses(SurveyContainerStyle.Info)}>
                        <strong>This question requires online connectivity to get the available choices</strong>
                    </div>
                </div>
            </div>;
        } else {
            const { error, data } = this.state;
            if (error) {
                return <div className={`${theme && theme.getFieldContainerClass()} survey-choice`}>
                    <div className={theme && theme.getQuestionLabelClass()}>
                        <label className={theme && theme.itemLabelClass}>{label}</label>
                        <SurveyFieldDescription description={description} />
                    </div>
                    <div className={theme && theme.getQuestionBodyClass()}>
                        <div className={theme && theme.getContainerClasses(SurveyContainerStyle.Error)}>
                            <strong>{error}</strong>
                        </div>
                    </div>
                </div>;
            } else if (data) {
                const { noChoicesMessage } = this.props;
                if (data.length == 0 && noChoicesMessage != null && noChoicesMessage != "") {
                    return <div className={`${theme && theme.getFieldContainerClass()} survey-choice`}>
                        <div className={theme && theme.getQuestionLabelClass()}>
                            <label className={theme && theme.itemLabelClass}>{label}</label>
                            <SurveyFieldDescription description={description} />
                        </div>
                        <div className={theme && theme.getQuestionBodyClass()}>
                            <div className={theme && theme.getContainerClasses(SurveyContainerStyle.Info)}>
                                <strong>{noChoicesMessage}</strong>
                            </div>
                        </div>
                    </div>;
                } else {
                    const merged: any = { ...this.props, ...{ data: data } };
                    let answers: string[] = [];
                    let otherAnswer;
                    if (this.props.answer) {
                        const set = new RemoteChoiceAnswerSet(this.props.answer);
                        answers = set.getAnswers();
                        otherAnswer = set.getOtherAnswer();
                    }
                    merged.onChange = this.fnInternalChange;
                    merged.answer = answers;
                    merged.otherAnswer = otherAnswer;
                    return <SurveyChoice {...merged} />;
                }
            } else {
                return <div className={`${theme && theme.getFieldContainerClass()} survey-choice`}>
                    <div className={theme && theme.getQuestionLabelClass()}>
                        <label className={theme && theme.itemLabelClass}>{label}</label>
                        <SurveyFieldDescription description={description} />
                    </div>
                    <div className={theme && theme.getQuestionBodyClass()}>
                        <div className={theme && theme.getContainerClasses(SurveyContainerStyle.Info)}>
                            <strong>Loading available choices ...</strong>
                        </div>
                    </div>
                </div>;
            }
        }
    }
}