import * as React from 'react';
import { useState } from 'react';
import { InteractionAttachments } from '@electrac/interaction';
import { ElectracAPI } from '@electrac/common';
import { InteractionFileAttachment, LinkType, InteractionModel } from '@electrac/model';
import { IInteractionPanelContext } from '@electrac/interaction/src/Context';
import { IElectracWebContext } from '@electrac/api';
import { async } from 'q';


const FIELDSET_STYLE = {
    padding: 8
};


export interface InteractionAttachmentProps {
    Files?: InteractionFileAttachment[];  
    webContext: IElectracWebContext,
    LinkId:number,
    LinkType:LinkType,
    Title:string;
    onSave: (enableValidation:boolean) => void,
    onSetPendingAttachments: (e: ElectracAPI.InteractionFileAttachment[])=>void
}


export function InteractionAttachmentsUpload(props:InteractionAttachmentProps) {

    const [pendingAttachments, setpendingAttachments] = useState<ElectracAPI.InteractionFileAttachment[]>([]);
    
    const {onSetPendingAttachments, onSave}=props;

    const onAttachLetter = (e:any) => {
        e.preventDefault();
       
        if (!props.webContext || !props.webContext.attachLetter) {
            alert('Unable to attach a letter at this time');
            return;
        }
        
       props.webContext.attachLetter(props.LinkId, props.LinkType as number, props.Title, async (file:ElectracAPI.InteractionFileAttachment) => {
            onSetPendingAttachments([file]);
            onSave(false);
        });
    }

    const onPendingAttachmentsChanged= (pen: ElectracAPI.InteractionFileAttachment[])=> {
        const attachments = [...pendingAttachments,...pen];
        setpendingAttachments(attachments);
        onSetPendingAttachments(attachments);
    }

    return (<div>
              <fieldset style={FIELDSET_STYLE}>
                        <legend>File Attachments</legend>
                        <div style={{ marginTop: 38 }}>
                             <button type="button" className="k-button" disabled={false} onClick={(e)=>onAttachLetter(e)}>Add Letter</button>
                            <InteractionAttachments attachments={props.Files} pendingAttachments={pendingAttachments} onChange={(e)=>onPendingAttachmentsChanged(e)} />
                        </div>
                    </fieldset>
                    </div>
            )
}