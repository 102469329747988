import * as React from "react";
import { autoCompleteUsers } from '@electrac/api';
import { AutoComplete } from '@electrac/components';
import { IdNamePair } from '@electrac/model';

//TODO: @electrac/components

export interface ISingleUsernameSelectorProps {
    value?: IdNamePair;
    onChange: (user: IdNamePair) => void;
}

export class SingleUsernameSelector extends React.Component<ISingleUsernameSelectorProps, any> {
    
    private onSelect = (e: IdNamePair) => {
        this.props.onChange(e);
    }
    private onFetchSuggestions = (term: string) => {
        return autoCompleteUsers(true, term);
    };
    render(): JSX.Element {
        return <AutoComplete value={this.props.value} onChange={this.onSelect} fetchSuggestions={this.onFetchSuggestions} />;
    }
}