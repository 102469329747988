import * as React from "react";
import { useState, useEffect, ReactNode } from "react";

import { ISurveyQuestionCommonProps, SurveyFieldDescription, } from './Common';
import { useSurveyPeople, useSurveyEvent } from '../container';
import { ISurveyContainerContext } from "../context";
import { ISurveyContainerTheme } from '@electrac/plugin';


export interface ISurveyEventConfirmationProps extends ISurveyQuestionCommonProps {
    context: ISurveyContainerContext
    onChange: (value: any) => void;
}


export const SurveyQuestionDisplay = ({ theme, description, label, children }: { children: ReactNode, 
    theme: ISurveyContainerTheme;
    description?: string;
    label?: string; }) => {
    return <div>
        <div className={theme.getQuestionLabelClass()}>
            <label className={theme.itemLabelClass}>{label}</label>
            <SurveyFieldDescription description={description} />
        </div>
        <div className={theme.getQuestionBodyClass()}>
            {children}
        </div></div>
}


export const SurveyEventConfirmation = (props: ISurveyEventConfirmationProps) => {
    const { theme, context, onChange } = props;
    const people = useSurveyPeople();
    const eventInfo = useSurveyEvent();
    if (!people) {
        return <>Event confirmation has no people</>;
    }

    const [state, setState] = useState<{}>();

    const selected = context.getSelectedElectors();

    // if the selected person changes (or goes from unselected to selected)
    // we want to clear the existing state.
    useEffect(() => setState({}), [selected[0] || -1]); 



    if (!selected || !selected.length) {
        return <div style={{ color: "red" }}>Please select a person to confirm their shifts</div>;
    }

    const person = people.filter(a => selected.indexOf(a.ElectorID) >= 0)[0];


    function updateSelection(shiftid: number, value: string) {
        if (!eventInfo) {
            alert('Event information is not available. Unable to make selection');
            return;
        }
        const { eventId, repeatId } = eventInfo;

        const updated = { ...state, [shiftid]: { Id: shiftid, Confirm: value } };
        setState(updated)

        const u = {
            EventID: eventId, RepeatID: repeatId, Round: 1,
            People: [{
                ElectorID: person.ElectorID,
                Shifts: Object.values(updated)
            }]
        };

        onChange(JSON.stringify(u));
    }


    return <div className={theme && theme.itemLabelClass}>
            <div>{person.Shifts && person.Shifts.map(a => (<div>
                {a.Start}&nbsp;
                <label>
                    <input type="radio" value="Y" name={a.StartUtc} onChange={(e) => updateSelection(a.Id, e.target.value)} />
                    Yes
            </label>
                <label>
                    <input type="radio" value="N" name={a.StartUtc} onChange={(e) => updateSelection(a.Id, e.target.value)} />
                    No
            </label>
            </div>))}</div>
        </div>;


};


// <div>
// <label data-bind="text: 'Shift: ' + Start"></label>
// <label>
//     <input type="radio" value="Y" class="@required" data-bind="attr: {name: 'Shift' + $index()}, checked: Confirm"/>
//     Yes</label>
// <label>
//     <input type="radio" value="N" data-bind="attr: { name: 'Shift' + $index() }, checked: Confirm"/>
//     No</label>
// </div>        
// </div>
// <div data-bind="visible: !Current().Shifts" style="color: red">Please select the person you talked to confirm the shift.</div>