import { InteractionSource, InteractionFileAttachment } from '@electrac/model';

//index.ts
//
// This file defines the API surface of the @ml/common module. All modules that are the public API should be re-exported from here.

export * from './client-api';
export * from './apicontext';
export * from './contracts';
// export * from './iimmutableobject';


export interface IElectracWebContext {
    showNotification: (title:string, msg:string) => void;
    
    attachLetter: (id: number, source: InteractionSource, topic: string, callback: (file: InteractionFileAttachment) => void) => void;
    getCurrentOfficeID: () => number;

    flagDirtyState: (key: string, msg: string) => void;
    clearDirtyState: (key:string) => void;
    confirmDiscardDirtyState: (msg: string) => boolean;
}



