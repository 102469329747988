import * as React from "react";
import * as ReactDOM from "react-dom";
import { getLocaleConfiguration } from "../locale";
import InputElement from "react-input-mask";
import { IFocusableField } from "../container";
import { ThemableInput } from "./ThemableInput";
import { ISurveyQuestionCommonProps, SurveyFieldDescription } from "./Common";
import uuid = require('node-uuid');

export interface ISurveyMobileProps extends ISurveyQuestionCommonProps {
    onChange: (value: string) => void;
    answer?: string;
    showMask?: boolean;
}

const EMPTY_STR = "";

export class SurveyMobile extends React.Component<ISurveyMobileProps, any> implements IFocusableField {
    private containerEl: HTMLDivElement | null = null;
    constructor(props) {
        super(props);
        this.state = {
            mobile: props.answer
        };
    }
    private onContainerMounted = (component: HTMLDivElement) => {
        this.containerEl = component;
    }
    private onChange = (e) => {
        this.props.onChange(e.target.value);
    }
    private onFieldChange = (e) => {
        this.setState({ mobile: e.target.value });
    }
    componentWillReceiveProps(props) {
        this.setState({ mobile: props.answer });
    }
    focus(): void {
        if (this.containerEl) {
            const node: any = ReactDOM.findDOMNode(this.containerEl);
            node.scrollIntoView();
        }
        //TODO: Flash animate the container to bring to user's attention
    }
    render(): JSX.Element {
        const { label, description, theme, isReadOnly } = this.props;
        const { mobile } = this.state;
        const masks = getLocaleConfiguration();
        let field: JSX.Element|null = null;
        if (isReadOnly === true) {
            field = <ThemableInput id={uuid.v4()} theme={theme} label={label} isReadOnly={true} value={mobile} />;
        } else {
            field = <div>
                <div className={theme && theme.getQuestionLabelClass() }>
                    <label className={theme && theme.itemLabelClass}>{label}</label>
                    <SurveyFieldDescription description={description} />
                </div>
                <div className={theme && theme.getQuestionBodyClass() }>
                    <InputElement className={`${theme && theme.getTextboxClass()} mobile-input`}
                        alwaysShowMask={!!this.props.showMask} maskChar={null} mask={masks.mobileInputMask} formatChars={masks.mobileFormatChars} value={mobile || EMPTY_STR} onChange={this.onFieldChange} onBlur={this.onChange} />
                </div>
            </div>;
        }
        return <div ref={this.onContainerMounted} className={theme && theme.getFieldContainerClass()}>
            {field}
        </div>;
    }
} 