/**
 * ClientAPI.ts
 *
 * This module contains the the Campaign Central client API
 *
 * Based on client type, this will either use jQuery AJAX or fetch(). For fetch(), a JWT token is required. For jQuery AJAX
 * it will implicitly use your authentication cookies (if set)
 */

import * as ElectracAPI from "@electrac/model";
import * as APIContracts from './contracts';
import { callApi, callApiFormData, ApiContext } from './apicontext';

import {
    SaveCallListOptions,
    SaveCallListResult
    , SaveWalkListResult
    , SaveWalkListOptions

    , SaveListOptionsBase

    , SaveListResultBase,
    InteractionModel
    
} from '@electrac/model'

// ------------- Interactions -------------- //

export interface SuggestItem {
    id: number;
    name: string;
}

export interface SaveInteractionResult {
    Id: number;
}

export function getTopics(token: ApiContext): Promise<ElectracAPI.IdNamePair[]> {
    return callApi<ElectracAPI.IdNamePair[]>(token, `/api/interaction/topics`, "GET");
}

export function getTags(token: ApiContext, text: string): Promise<ElectracAPI.IdNamePair[]> {
    return callApi<SuggestItem[]>(token, `/api/complete/TagRead?text=${text}`, "GET").then<ElectracAPI.IdNamePair[]>(res => {
        return res.map(r => { return { Id: r.id, Name: r.name }; });
    });
}

export function updateInteraction(token: ApiContext, inter: ElectracAPI.InteractionSaveModel): Promise<InteractionModel> {
    return callApi<InteractionModel>(token, `/api/interaction/${inter.Id}`, "PUT", inter);
}

export function createInteraction(token: ApiContext, inter: ElectracAPI.InteractionSaveModel): Promise<InteractionModel> {
    return callApi<InteractionModel>(token, `/api/interaction`, "POST", inter);
}

export function deleteInteraction(token: ApiContext, id: number, options?: any): Promise<SaveInteractionResult> {
    return callApi<SaveInteractionResult>(token, `/api/interaction/${id}`, "DELETE", options);
}

export function markInteractionDone(token: ApiContext, id: number, isDone: boolean): Promise<ElectracAPI.MarkInteractionDoneResult> {
    return callApi<ElectracAPI.MarkInteractionDoneResult>(token, `/api/interaction/${id}/done`, "POST", { IsDone: isDone });
}

export function attachmentUrl(fileId: number, editUrl: string): string {
    if (editUrl) return editUrl;

    // default fallback if editUrl not provided
    return `/Files/${fileId}?type=Attachment`;
}

export function uploadInteractionAttachments(token: ApiContext, files: File[]): Promise<ElectracAPI.InteractionFileAttachment[]> {
    return callApiFormData<ElectracAPI.InteractionFileAttachment[]>(true, `/Interaction/AttachmentUpload`, "POST", files);
}

export function deleteAttachment(token: ApiContext, interactionId: number, fileId: number): Promise<ElectracAPI.DeleteInteractionAttachmentResult> {
    return callApi<ElectracAPI.DeleteInteractionAttachmentResult>(token, `/api/interaction/${interactionId}/attachments/${fileId}`, "DELETE");
}

export function getOfficesForElector(token: ApiContext, electorID: number): Promise<ElectracAPI.IdNamePair[]> {
    return callApi<ElectracAPI.IdNamePair[]>(token, `/api/complete/GetOfficesForElector?electorID=${electorID}`, "GET");
}

export function getInteractionDetails(token: ApiContext, interactionId: number): Promise<ElectracAPI.InteractionDetailsModel> {
    return callApi<ElectracAPI.InteractionDetailsModel>(token, `/api/interaction/${interactionId}/details`, "GET");
}

// ------------- Events -------------- //

export function fetchEventShifts(token: ApiContext, id: number | string): Promise<ElectracAPI.ShiftDisplayModel[]> {
    return callApi<ElectracAPI.ShiftDisplayModel[]>(token, `/api/events/${id}/shifts`, "GET");
}


export function fetchEvents(token: ApiContext): Promise<ElectracAPI.UpcomingEventModel[]> {
    return callApi<ElectracAPI.UpcomingEventModel[]>(token, `/api/events/upcoming`, "GET");
}

export function fetchEventDetails(token: ApiContext, id: number | string): Promise<ElectracAPI.EventDetailsModelMini> {
    return callApi<ElectracAPI.EventDetailsModelMini>(token, `/api/events/${id}`, "GET");
}

export interface IFetchEventAttendanceOptions {
    eventId: number;
    repeatId: number;
}

export function fetchEventAttendance(token: ApiContext, options: IFetchEventAttendanceOptions): Promise<ElectracAPI.CloseShiftsGridModel[]> {
    return callApi<ElectracAPI.CloseShiftsGridModel[]>(token, `/api/events/${options.eventId}/currentattendance?repeatId=${options.repeatId}`, "GET");
}

export interface IMarkedAttendance {
    id: number;
    attended: boolean;
}

export interface IMarkAttendanceOptions {
    eventId: number;
    attendants: IMarkedAttendance[];
}

export function markAttendeesAtEvent(token: ApiContext, options: IMarkAttendanceOptions): Promise<any> {
    const args = {
        attendants: options.attendants
    };
    return callApi<any>(token, `/api/events/${options.eventId}/markattendance`, "POST", args);
}


// ------------- Event Volunteers -------------- //


export enum EventPurpose {
	PhoneBank,
	DoorKnock
}
export interface VolunteerEventModel {
    Id: number;
    Type: string;
    Name: string;
    Description: string;
    Location: string;
    Start: string;
    IsSetup: boolean;
    Purpose: EventPurpose;
}


export function  fetchEventVolunteer (token: ApiContext, api: string): Promise<VolunteerEventModel[]> {
    return callApi<VolunteerEventModel[]>(token, api, "GET")
};

export function  fetchAny(token: ApiContext, api: string): Promise<any> {
    return callApi<any>(token, api, "GET")
};

export function postRequestAny(token: ApiContext, api: string, request: any): Promise<any> {
    return callApi<any>(token, `/Survey/GetAlternateNumbers`, "POST", request);
}

export function postAny(token: ApiContext, api: string, ): Promise<any> {
    return callApi<any>(token, `/Survey/GetAlternateNumbers`, "POST");
}




// ------------- Surveys -------------- //

// ----------- Surveys: Call Screen --------- //

export function getCall(token: ApiContext, listId: number, listType?: string): Promise<ElectracAPI.PhoneCallDisplay> {
    return callApi<ElectracAPI.PhoneCallDisplay>(token, `/Survey/GetCallScreenPhone/?listId=${listId}&load=${listType}`, "GET");
}
export function getCallPeople(token: ApiContext, callId: number, eventId?: number, repeatId?: number): Promise<ElectracAPI.ElectorSurveyDisplay[]> {
    return callApi<ElectracAPI.ElectorSurveyDisplay[]>(token, `/Survey/GetCallScreenPeople/${callId}?eventId=${eventId}&repeatId=${repeatId}`, "GET");
}
export function getCallPeopleByPhone(token: ApiContext, listId: number, phone: string, eventId?: number, repeatId?: number): Promise<ElectracAPI.ElectorSurveyDisplay[]> {
    return callApi<ElectracAPI.ElectorSurveyDisplay[]>(token, `/Survey/GetCallScreenPeopleByPhone/?listId=${listId}&phone=${phone}&eventId=${eventId}&repeatId=${repeatId}`, "GET");
}

export function getAlternateNumbers(token: ApiContext, request: ElectracAPI.AlternateNumberRequest): Promise<ElectracAPI.AlternateNumberResponse[]> {
    return callApi<ElectracAPI.AlternateNumberResponse[]>(token, `/Survey/GetAlternateNumbers`, "POST", request);
}

export function uploadCall(token: ApiContext, data: any): Promise<boolean> {
    return callApi<boolean>(token, `/Survey/RunAjax`, "POST", data);
}

export function getVoterIDInfo(token: ApiContext, entityID: number): Promise<any> {
    return callApi<any>(token, `/api/people/EntityRecentVoterID/${entityID}`, "GET");
}

// ------------- People -------------- //

export function searchForPeople(context: ApiContext, search: any): Promise<any> {
    return callApi(context, '/api/People/Search', 'POST', search);
}

export function fetchPersonDetails(context: ApiContext, id: number): Promise<ElectracAPI.PersonDetailsModel> {
    return callApi(context, '/api/People/Details/' + id, 'GET');
}

export interface IFetchInteractionOptions {
    id: number;
    page: number;
    type?: ElectracAPI.InteractionSource;
}

export function fetchInteractions(context: ApiContext, options: IFetchInteractionOptions): Promise<ElectracAPI.InteractionModel[]> {
    return callApi(context, `/api/Interaction/ByType/${options.id}?page=${options.page}&type=${options.type || ElectracAPI.InteractionSource.Person}`, 'GET');
}

export interface IFetchInteractionsNearbyOptions {
    longitude: number;
    latitude: number;
}

export function fetchInteractionsNearby(context: ApiContext, options: IFetchInteractionsNearbyOptions): Promise<ElectracAPI.InteractionModel[]> {
    return callApi(context, `/api/interaction/nearby?longitude=${options.longitude}&latitude=${options.latitude}`, 'GET');
}

// ------------- Support for AddActivity and AddElectorate question types ------------------------- //

export function fetchElectoratesAutoComplete(context: ApiContext, searchTerm: string): Promise<ElectracAPI.IdNamePair[]> {
    return callApi(context, `/api/complete/GetElectorates?text=${searchTerm}`, "GET");
}

export function fetchActivitiesAutoComplete(context: ApiContext, searchTerm: string): Promise<ElectracAPI.IMvcSelectItem[]> {
    return callApi(context, `/api/complete/GetEventTypes?text=${searchTerm}`, "GET");
}
export function fetchIssuesAutoComplete(context: ApiContext, searchTerm: string): Promise<ElectracAPI.IMvcSelectItem[]> {
    return callApi(context, `/api/complete/issues?text=${searchTerm}`, "GET");
}

export function fetchSurveyChoices(context: ApiContext, dataSourceId: number, parameters: any): Promise<ElectracAPI.IMvcSelectItem[]> {
    let url = `/api/survey/choices/${dataSourceId}`;
    const keys = Object.keys(parameters || {});
    if (keys.length > 0) {
        url += "?";
        url += keys.map(k => { return { key: k, value: parameters[k] }; }).map(kvp => `${kvp.key}=${encodeURIComponent(kvp.value)}`).join("&");
    }
    return callApi(context, url, "GET");
}

// ------------- PIN -------------- //

export function generatePIN(token: ApiContext): Promise<string> {
    return callApi(token, `/api/walk/GeneratePIN`, "GET");
}

export function applyPIN(token: ApiContext, listId: string, pin: string): Promise<number> {
    return callApi(token, `/api/walk/applyPIN`, "POST", { ListId: listId, PIN: pin });
}

// -------------- Lists -------------- //

export interface IListQueryOptions {
    /**
     * Unused
     */
    noPIN?: boolean;
}

export function getRemoteLists(token: ApiContext, options?: IListQueryOptions): Promise<APIContracts.IListInfo[]> {
    return callApi(token, '/api/Walk/Lists', 'GET');

}

// --------------- Autocompletion ----------------- //
export function autoCompleteUsers(token: ApiContext, name: string): Promise<ElectracAPI.IdNamePair[]> {
    return new Promise<ElectracAPI.IdNamePair[]>((resolve, reject) => {
        callApi<ElectracAPI.UserAutoCompleteResult[]>(token, `/api/Complete/Users?term=${name}`, 'GET').then(res => {
            resolve(res.map(ac => {
                return {
                    Id: ac.UserID,
                    Name: `${ac.Lastname}, ${ac.Othernames} (${ac.Username})`
                };
            }));
        }).catch(reject);
    });
}

// ------------------ List Building --------------------- //
export function saveCallList(token: ApiContext, options: SaveCallListOptions): Promise<SaveCallListResult> {
    return callApi<SaveCallListResult>(token, `/api/list/SaveCallList`, "POST", options);
}

export function saveWalkList(token: ApiContext, options: SaveWalkListOptions): Promise<SaveWalkListResult> {
    return callApi<SaveWalkListResult>(token, `/api/list/SaveWalkList`, "POST", options);
}

export function saveList(token: ApiContext, options: SaveListOptionsBase): Promise<SaveListResultBase> {
    return callApi<SaveListResultBase>(token, `/api/list/SaveList`, "POST", options);
}

export function bulkAddInteractions(token: ApiContext, options: ElectracAPI.BulkAddInteractionOptions): Promise<ElectracAPI.BulkAddInteractionResult> {
    return callApi<ElectracAPI.BulkAddInteractionResult>(token, `/api/list/BulkAddInteraction`, "POST", options);
}