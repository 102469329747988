import * as React from 'react';
import ReactTooltip = require('react-tooltip');
import wrap = require("wordwrap");

//TODO: @electrac/components

export type TooltipStyle = "dark" | "success" | "warning" | "error" | "info" | "light";
export type TooltipEffect = "float" | "solid";

export interface IAutoEllipsisProps {
    text: string;
    length: number;
    tooltipStyle?: TooltipStyle;
    tooltipEffect?: TooltipEffect;
    containerStyle?: any;
    containerClassName?: string;
    tooltip?: boolean;
}

export class AutoEllipsis extends React.Component<IAutoEllipsisProps, any> {
    ttid: any;
    constructor(props: IAutoEllipsisProps) {
        super(props);
        this.ttid = `tooltip-${(new Date()).getTime()}`;
    }
    render(): JSX.Element {
        const { text, length, containerStyle, containerClassName, tooltipStyle, tooltipEffect, tooltip } = this.props;
        if (!text) {
            return <div style={containerStyle} className={containerClassName}></div>;
        }

        const showEllipsis = (text.length > length);
        const dispText = (text.length > length) ? `${text.substring(0, length)}...` : text;

        return <div style={containerStyle} className={containerClassName}>
            {(() => {
                if (showEllipsis) {
                    const ttText = wrap(20, 60)(text).split("\n").map((v, i) => <span key={`${this.ttid}-para-${i}`}>{v}<br /></span>);
                    return <span>
                        <span data-for={this.ttid} data-tip>{dispText}</span>

                        {}

                        {tooltip ? <ReactTooltip id={this.ttid} place="bottom" type={tooltipStyle || "info"} effect={tooltipEffect || "float"}>
                            {ttText}
                        </ReactTooltip> : null}


                    </span>;
                } else {
                    return <span>{dispText}</span>;
                }
            })()}
        </div>
    }
}