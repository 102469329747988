import * as React from "react";

export interface ICountdownProps {
    seconds: number;
    onFinished: () => void;
}

export class Countdown extends React.Component<ICountdownProps, any> {
    private timeoutID: any;
    constructor(props: ICountdownProps) {
        super(props);
        this.timeoutID = null;
        this.state = {
            seconds: props.seconds
        };
    }
    private tick() {
        this.timeoutID = setTimeout(() => {
            this.timeoutID = null;
            const seconds = this.state.seconds;
            const remaining = seconds - 1;
            if (remaining >= 0) {
                this.setState({ seconds: remaining });
                if (remaining == 0) {
                    if (this.props.onFinished)
                        this.props.onFinished();
                } else {
                    this.tick();
                }
            }
        }, 1000);
    }
    componentDidMount() {
        this.tick();
    }
    componentWillUnmount() {
        if (this.timeoutID) {
            clearTimeout(this.timeoutID);
        }
    }
    render(): JSX.Element {
        return <span>{this.state.seconds}</span>;
    }
}