import * as React from "react";

import { AvailableSurveyList } from '@electrac/model';


export interface ISurveySelectionProps {
    className?: string;
    onChange?: (change: string) => void;
}


export interface ISurveySelectionState {
    
    surveyID?: string;
    availableSurveys?: AvailableSurveyList;
}

export type SurveySelectionFunctionProps = ISurveySelectionState & ISurveySelectionProps


/**
 * Display a standard dropdown for available CC surveys. Populate props by calling the appropriate API
 * $.getJSON("/api/surveys").then(r => {
 *           this.setState({ availableSurveys: r });
 *       });
 * @param props 
 */
export function SurveySelectionFunction(props: SurveySelectionFunctionProps) {

    const { availableSurveys, surveyID, onChange} = props;

    if (!availableSurveys) {
        return <p className="form-control-static">Loading available surveys ...</p>
    }

    return <select className={props.className} value={surveyID || ""} onChange={(e) => onChange && onChange(e.target.value)}>
        <option>(Select Survey)</option>
        {availableSurveys.CurrentOffice && 
        <optgroup label={"Current Office"}>
            {availableSurveys.CurrentOffice.map(s => (<option key={s.SurveyID} value={s.SurveyID}>{s.Title}</option>))}
        </optgroup>}
        {availableSurveys.Branch &&
        <optgroup label={"Branch"}>
            {availableSurveys.Branch.map(s => (<option key={s.SurveyID} value={s.SurveyID}>{s.Title}</option>))}
        </optgroup>}
        {availableSurveys.Global &&
        <optgroup label={"Global"}>
            {availableSurveys.Global.map(s => (<option key={s.SurveyID} value={s.SurveyID}>{s.Title}</option>))}
        </optgroup>}
    </select>;
}


export class SurveySelection extends React.Component<ISurveySelectionProps, ISurveySelectionState> {

    constructor(props: ISurveySelectionProps) {
        super(props);
        this.state = {
            
        }
    }

    componentDidMount() {
        $.getJSON("/api/surveys").then(r => {
            this.setState({ availableSurveys: r });
        });
    }

    onChange = (e: string) => {
        const { onChange } = this.props;
        onChange && onChange(e);

        this.setState({ surveyID: e})
    }
    render() {       

        const { onChange, ...props } = this.props;

        return <SurveySelectionFunction {...props} {...this.state} onChange={this.onChange} />
    }
}



//export function SurveySelection(props: ISurveySelectionProps) {
//    const [availableSurveys, setAvailableSurveys] = React.useState<any[]>([]);
//    const [surveyId, setSurveyId] = React.useState(0);

//    React.useEffect(() => {
//        $.getJSON("/api/surveys").then(r => {
//            setAvailableSurveys(r);
//        });
//    }, [])

//    const onChangeFunc = (e: string) => {
//        const { onChange } = props;
//        onChange && onChange(e);

//        setSurveyId(parseInt(e, 10));
//    }

//    const { onChange, ...eprops } = props;

//    return <SurveySelectionFunction {...eprops} surveyId={surveyId} availableSurveys={availableSurveys} onChange={onChangeFunc} />
//}
