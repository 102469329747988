
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from 'react-redux'
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
//import promiseMiddleware from 'redux-promise';
import { Provider as HooksProvider } from "react-redux-hooks";
import { StatelessComponent, ComponentClass } from 'react';



import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';


const rootReducer: any = combineReducers({
    form: formReducer  // <---- This is a special reducer used by redux-form. Mounted at 'form'.
});

export default rootReducer;




export function configureStore(initialState: {}, root = rootReducer) {
    const store = (compose as any)(
        _getMiddleware(),
        ..._getEnhancers()
    )(createStore)(root, initialState);
    return store;
}

function _getMiddleware() {
    let middleware = [thunk];
    return applyMiddleware(...middleware);
}

declare global {
    interface Window {
        devToolsExtension: () => any;
    }
}
declare global {
    interface Window {
        devToolsExtension: () => any;
    }
}

function _getEnhancers() {
    let enhancers: any[] = [];
    if (window.devToolsExtension) {
        enhancers = [...enhancers, window.devToolsExtension()];
    }
    return enhancers;
}

export function renderReduxComponent(domEl: Element, component: string | StatelessComponent | ComponentClass, props: any): any {

    const store = configureStore({});
    const comp = React.createElement(component, props);

    ReactDOM.render(
        <Provider store={store}>
            <HooksProvider store={store}>
                {comp}
            </HooksProvider>
        </Provider>,
        domEl);

    return store;
}



