import * as React from "react";
import { Multiselect } from 'react-widgets';

export interface IRwMultiselectProps {
    placeholder?: any;
    tagComponent?: any;

    minLength?: number;
    filter?: any;
    valueField?: string;
    textField?: string;
    onSearch?: any;
    onSelect?: any;
    disabled?: boolean;

    //onSearchWait?: number; //<= used in conjunction with onSearch debounce
    //label?: string | JSX.Element;
    rootClassName?: string;

    meta?: any;
    input?: any;
}

//tagcomponent usage: (item) => { return <span>item.Name</span> }

export class RwMultiselect extends React.Component<IRwMultiselectProps, any> {
    constructor(props: IRwMultiselectProps) {
        super(props);
    }

    render(): JSX.Element {
        const { touched, error } = this.props.meta;
        const { onSearch, onSelect, rootClassName, input, placeholder, tagComponent, disabled } = this.props;

        // default classname to ensure we have some vertical space
        const rootClass = rootClassName || "field-container";
       
        return (<div className={rootClass}>
            <Multiselect
                {...this.props}
                onSelect={onSelect}
                //onSearch={(onSearchWait ? debounce(onSearch, onSearchWait) : onSearch)}
                onChange={(val) => { if (val) { input.onChange(val) } }}
                value={(input.value ? input.value : null)}
                valueField={(this.props.valueField ? this.props.valueField : "Id")}
                textField={(this.props.textField ? this.props.textField : "Name")}
                placeholder={(touched && error ? <span style={{ color: "red" }}>  {error}</span> : placeholder)}
                disabled={disabled}
            />
        </div>);
    }
}
