import * as React from 'react';
import { updateInteraction, deleteInteraction, markInteractionDone } from '@electrac/api';
import { ApiContext } from '@electrac/api';
import { isString } from '@ml/common';
import { showDialog } from "@ml/kendo-wrappers";
import { InteractionDetailsDialog } from './Details';

import { IInteractionPanelContext } from './Context';
import { getInteractionDisplayType } from './InteractionPanel';
import { InteractionModel } from '@electrac/model';

export type InteractionClassNameFunc = (interaction: Readonly<InteractionModel>) => string;
declare var electrac;

export type InteractionActionCallback = (result: Readonly<InteractionModel> | Error, extraData?: any) => void;

export type InteractionActionConfirmFunc = (interaction: Readonly<InteractionModel>, context: IInteractionPanelContext, callback: (confirmed: boolean, args?: any) => void) => void;

export interface IInteractionAction {
    confirmationPrompt?: string | InteractionActionConfirmFunc;
    type: string;
    description: string;
    actionClassNames?: string | InteractionClassNameFunc;
    isApplicable(interaction: Readonly<InteractionModel>): boolean;
    invoke(interaction: Readonly<InteractionModel>, context: IInteractionPanelContext, args: any, callback: InteractionActionCallback): void;
    onSuccess(interaction: Readonly<InteractionModel>, context: IInteractionPanelContext, extraData?: any): void;
}

export function getActionClassName(action: IInteractionAction, interaction: Readonly<InteractionModel>): string {
    const cls = action.actionClassNames;
    let result;
    if (isString(cls)) {
        result = cls;
    } else if (cls) {
        result = cls(interaction);
    }
    return `${result} interaction-action-${action.type}`
}

export function getContext(): ApiContext { return true; }

export class InteractionActionRegistry {
    
    private static defaultActions = {};

    public static addAction(action: IInteractionAction): void {
        InteractionActionRegistry.defaultActions[action.type] = action;
    }

    public static getAction(type: string): IInteractionAction {
        return InteractionActionRegistry.defaultActions[type];
    }

    public static getAllActions(): IInteractionAction[] {
        return Object.keys(InteractionActionRegistry.defaultActions)
            .map(k => InteractionActionRegistry.defaultActions[k]);
    }
}

export const EditInteractionAction: IInteractionAction = {
    type: "edit",
    description: "Edit this interaction",
    actionClassNames: "action-edit",
    isApplicable: () => {
        return true;
    },
    invoke: (interaction: Readonly<InteractionModel>, context: IInteractionPanelContext) => {
        const link = context.linkData;

        showDialog(<InteractionDetailsDialog
            disableTopicCreation={!context.allowTopicCreation}
            requestAllowedSharingLevels={context.getSharingLevels}
            interaction={interaction}
            linkType={link.linkType}
            linkId={link.linkId}
            title= "Edit Interaction"
            webContext={context.webContext}
            onSave={async (interaction) => {
            
                try {
                    const updateResult = await updateInteraction(getContext(), interaction);        
                    // Notify interaction panel
                    context.notifyUpdated(updateResult, interaction.Id);
                    return updateResult.Id;
                
                } catch (e) {
                    alert(`Error occurred while saving interaction: ${e}`);
                    throw e;
                }
            
            }} />);
    },
    onSuccess: (interaction: Readonly<InteractionModel>, context: IInteractionPanelContext, extraData: any) => {
        if (interaction.Id)
            context.notifyUpdated(interaction, extraData.oldId);
    }
};

export const DeleteInteractionAction: IInteractionAction = {
    type: "interaction-delete",
    description: "Delete this interaction",
    actionClassNames: "action-delete",
    confirmationPrompt: (interaction, context, callback) => {
        if (getInteractionDisplayType(interaction) === "MultiInteraction") {
            const linkData = context.linkData;
            const $dialog = electrac.Dialog('MultiDeleteDialog', 'Do you want to remove all multi-interactions or just the one for this person?',
            {
                title: 'Remove multi-interactions',
                buttons: {
                    Cancel: () => {
                        $dialog.dialog('close');
                        callback(false);
                    },
                    All: () => {
                        $dialog.dialog('close');
                        callback(true, { All: true, Source: linkData.linkType, SourceId: linkData.linkId });
                    },
                    One: () => {
                        $dialog.dialog('close');
                        callback(true, { All: false, Source: linkData.linkType, SourceId: linkData.linkId });
                    }
                },
                width: 350,
                open: function () {
                    $(this).parent().find('.ui-dialog-buttonset button').eq(0).css('margin-right', '25px');
                }
            });
        } else {
            callback(confirm("Are you sure you want to delete this interaction?"));
        }
    },
    isApplicable: () => {
        return true;
    },
    invoke: (interaction: Readonly<InteractionModel>, context: IInteractionPanelContext, args: any, callback: InteractionActionCallback) => {
        if (!interaction || !interaction.Id) return;

        deleteInteraction(getContext(), interaction.Id, args)
            .then(() => callback(interaction))
            .catch(callback);
    },
    onSuccess: (interaction: Readonly<InteractionModel>, context: IInteractionPanelContext) => {
        if (interaction.Id)
            context.notifyRemoved(interaction.Id);
    }
};

export const MarkInteractionAsDoneAction: IInteractionAction = {
    type: "markdone",
    description: "Mark this interaction as done",
    actionClassNames: (interaction: Readonly<InteractionModel>) => {
        return interaction.IsDone === true ? "action-done" : "action-markdone";
    },
    isApplicable: (interaction: Readonly<InteractionModel>) => {
        return interaction.Due != null;
    },
    invoke: (interaction: Readonly<InteractionModel>, context: IInteractionPanelContext, args: any, callback: InteractionActionCallback) => {
        markInteractionDone(getContext(), interaction.Id || 0, !interaction.IsDone).then(res => {
            callback({ ...interaction, IsDone: res.IsDone });
        }).catch(callback);
    },
    onSuccess: (interaction: Readonly<InteractionModel>, context: IInteractionPanelContext) => {
        context.notifyUpdated(interaction);
    }
};

export const ViewSurveyResponseAction: IInteractionAction = {
    type: "interaction-surveyresponse",
    description: "Show the survey response for this interaction",
    actionClassNames: "action-surveyresponse",
    isApplicable: (interaction: Readonly<InteractionModel>) => {
        return interaction.SurveyResponseID != null;
    },
    invoke: (interaction: Readonly<InteractionModel>) => {
        const url = `/Survey/SurveyPartial/${interaction.SurveyResponseID}`
        const $dialog = electrac.AjaxDialog(`SurveyResponse_${interaction.Id}`, url, {
            title: "Survey Response",
            width: 500,
            buttons: {
                'Close': function () {
                    $dialog.dialog('close');
                }
            }
        });
    },
    onSuccess: () => {
        //Do nothing as this doesn't affect the original interaction list in any way
    }
};