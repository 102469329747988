import * as React from 'react';
import * as ElectracAPI from "@electrac/model";
import * as Dropzone from "react-dropzone";
import { uploadInteractionAttachments } from '@electrac/api';

export interface IInteractionAttachmentProps {
    attachments?: ElectracAPI.InteractionFileAttachment[];
    pendingAttachments: ElectracAPI.InteractionFileAttachment[];
    onChange: (pendingAttachments: ElectracAPI.InteractionFileAttachment[]) => void;
}

const DROPZONE_HEIGHT = 100;
const DROPZONE_WIDTH = 220;
const ATTACHMENTS_WIDTH = 190;


const InteractionDrop = ({onChange, pendingAttachments} : {onChange: (pendingAttachments: ElectracAPI.InteractionFileAttachment[]) => void, pendingAttachments?: ElectracAPI.InteractionFileAttachment[] }) => {
    const [ isUploading, setIsUploading] = React.useState(false);

    const onFileDrop = (file) => {
        setIsUploading(true);

        uploadInteractionAttachments(true, file).then(res => {
            const pending = pendingAttachments || [];
            for (const f of res) {
                pending.push(f);
            }
            setIsUploading(false);

            onChange(pending);
        }).catch(err => {
            alert("An error occurred while uploading the files");
            setIsUploading(false);
        });
    }


    if (isUploading) {
        return <div>
            <h3>Uploading attachment ...</h3>
        </div>;
    }
        
        
    return (<Dropzone style={{ width: "100%", height: DROPZONE_HEIGHT, borderWidth: 2, borderStyle: "dashed", borderColor: "black" }} onDrop={onFileDrop}>
            <p>To upload documents</p>
            <ul style={{ marginLeft: 25 }}>
                <li>Drag and drop any file into this area</li>
                <li>Click this area and when prompted, choose a file</li>
            </ul>
        </Dropzone>);
}

export const InteractionAttachments = ({ onChange, attachments, pendingAttachments }: IInteractionAttachmentProps) => {
        
    if (!(attachments && attachments.length > 0 || pendingAttachments.length > 0)) {
        return <InteractionDrop onChange={onChange}/>
    }
    
    return <div>
        <div style={{ float: "left", width: ATTACHMENTS_WIDTH }}>
            {attachments && attachments.length > 0 &&<div>
                        <strong>Existing Attachments ({attachments.length}) </strong>
                        <ul>
                            {attachments.map(f => <li key={`attachment-${f.StorageFileID}`}>{f.FileName}</li>) }
                        </ul>
                    </div>}
            {pendingAttachments.length > 0 &&
                    <div>
                        <strong>Pending Attachments ({pendingAttachments.length}) </strong>
                        <ul>
                            {pendingAttachments.map(f => <li key={`pending-attachment-${f.StorageFileID}`}>{f.FileName}</li>) }
                        </ul>
                    </div>}
        </div>
        <div style={{ float: "right", width: DROPZONE_WIDTH }}>
            <InteractionDrop onChange={onChange} pendingAttachments={pendingAttachments} />
        </div>
        <div className="clearfix" />
    </div>;

}