import * as React from "react";

//TODO: @electrac/components

export enum ContentSectionDisplayStyle {
    Fieldset,
    Panel
    //CollapsiblePanel
}

export enum ContentSectionPanelStyle {
    Kendo,
    BootstrapDefault,
    BootstrapPrimary,
    BootstrapSuccess,
    BootstrapWarning,
    BootstrapDanger
}

export interface IContentSectionProps extends React.Props<any> {
    /**
     * The title of the section
     */
    title: string;
    /**
     * The type of section to render
     */
    displayStyle: ContentSectionDisplayStyle;
    /**
     * If rendering a panel, the style of panel to render
     */
    panelStyle?: ContentSectionPanelStyle;
    /**
     * If specified, the name of the icon to render alongside the title
     */
    iconClass?: string;
    /**
     * The kind of icon.
     *
     *   fa - font awesome
     *   icon - our custom fontello icon set
     */
    iconKind?: "fa" | "icon" | string;
}

/**
 * The Section component provides a display container abstraction over a block of content 
 */
export class ContentSection extends React.Component<IContentSectionProps, any> {
    constructor(props: IContentSectionProps) {
        super(props);
        this.state = {};
    }
    render(): JSX.Element {
        var title;
        if (this.props.iconClass != null) {
            title = (<span><i className={`fa ${this.props.iconKind || "fa"}-${this.props.iconClass}`}></i> {this.props.title}</span>);
        } else {
            title = this.props.title;
        }

        if (this.props.displayStyle == ContentSectionDisplayStyle.Fieldset) {
            return (
                <fieldset>
                    <legend>{title}</legend>
                    {this.props.children}
                </fieldset>
            );
        } else {
            if (this.props.panelStyle == ContentSectionPanelStyle.Kendo) {
                return <div className="k-block">
                    <div className="k-header">{title}</div>
                    {this.props.children}
                </div>;
            } else {
                let panelStyle = "default";
                switch (this.props.panelStyle) {
                    case ContentSectionPanelStyle.BootstrapPrimary:
                        panelStyle = "primary";
                        break;
                    case ContentSectionPanelStyle.BootstrapSuccess:
                        panelStyle = "success";
                        break;
                    case ContentSectionPanelStyle.BootstrapWarning:
                        panelStyle = "warning";
                        break;
                    case ContentSectionPanelStyle.BootstrapDanger:
                        panelStyle = "danger";
                        break;
                }
                return <div className={`panel panel-${panelStyle}`}>
                    <div className="panel-heading">
                        <h3 className="panel-title">{title}</h3>
                    </div>
                    <div className="panel-body">
                        {this.props.children}
                    </div>
                </div>;
            }
        }
    }
}