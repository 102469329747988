import * as React from "react";
import * as ElectracAPI from "@electrac/model";
import { AutoSizer } from "react-virtualized";
import { Feature } from './map-feature-selection';

export const STANDARD_BASE_LAYERS = [
    {
        name: "osm",
        title: "OpenStreetMap",
        type: "Tile",
        source: {
            type: "OSM"
        }
    }
];

export enum StandardBoundaryLayers {
    User = "userboundaries",
    UserMine = "userboundaries_mine",
    UserOther = "userboundaries_other",
    Selected = "selectedboundaries",
    OfficeBoundsPreRedist = "offices_pre_redist",
    OfficeBoundsCurrent = "offices_current"
}

export const STANDARD_OVERLAY_LAYERS =  [
    {
        name: StandardBoundaryLayers.OfficeBoundsPreRedist,
        title: "Office Electorate Boundaries (before redistribution)",
        type: "Vector",
        source: {
            type: "Vector",
        },
        style: {
            polygon: {
                borderColor: "rgba(204, 117, 0, 1)",
                borderWidth: 2.0,
                fillColor: "rgba(204, 117, 0, 0.1)"
            }
        }
    },
    {
        name: StandardBoundaryLayers.OfficeBoundsCurrent,
        title: "Office Electorate Boundaries (current)",
        type: "Vector",
        source: {
            type: "Vector",
        },
        style: {
            polygon: {
                borderColor: "rgba(234, 34, 23, 1)",
                borderWidth: 2.0,
                fillColor: "rgba(123, 45, 234, 0.1)"
            }
        }
    },
    
    {
        name: StandardBoundaryLayers.User,
        title: "User Boundaries",
        type: "Vector",
        source: {
            type: "Vector",
        },
        style: {
            polygon: {
                borderColor: "rgba(134, 34, 23, 1)",
                borderWidth: 2.0,
                fillColor: "rgba(23, 45, 234, 0.1)"
            }
        },
        options: {
            selectable: true
        }
    },
    {
        name: StandardBoundaryLayers.Selected,
        title: "Selected Boundaries",
        type: "Vector",
        source: {
            type: "Vector",
        },
        style: {
            polygon: {
                borderColor: "rgba(134, 34, 23, 1)",
                borderWidth: 2.0,
                fillColor: "rgba(23, 45, 234, 0.1)"
            }
        },
        options: {
            selectable: true
        }
    }
];

export type Coordinate = [number, number];
export type Extent = [number, number, number, number];

export interface IMapWidgetDigitizerOptions {
    message?: string;
    callback: (features: any) => void;
}

export type MapLayer = any;

export type PointGeometry = any;

export interface IMapWidgetInterface {
    addOverlay(): any;
    getSelectedFeatureCollection(): any;
    addFeaturesToLayer(layer: MapLayer, features: Feature[], zoom?: boolean): void;
    zoomToLayerExtent(layer: MapLayer): void;
    createFeature(properties: any, geom: any): Feature;
    createPoint(coords: [number, number]): PointGeometry;
    LLtoWebMercator(ll: [number, number]): [number, number];
    getProjection(): any;
    getZoomLevel(): number;
    getExtents(): [number, number, number, number];
    expandViewToIncludeExtent(extentLL: Extent): void;
    getLayerByName(name: string): MapLayer | undefined;
    clearLayerSource(layer: MapLayer): void;
    zoomToExtentLL(extent: Extent): void;
    zoomToExtent(extent: Extent): void;
    zoomToLayerExtent(layer: MapLayer, bEncompassCurrentExtent: boolean): void;
    zoomToCenterLonLat(x: number, y: number, zoom: number): void;
    zoomToCenter(x: number, y: number, zoom: number): void;
    isLoaded(): boolean;
    isDrawing(): boolean;
    digitizePoint(options: IMapWidgetDigitizerOptions): void;
    digitizeLine(options: IMapWidgetDigitizerOptions): void;
    digitizePolygon(options: IMapWidgetDigitizerOptions): void;
    digitizeCircle(options: IMapWidgetDigitizerOptions): void;
    getDrawnObjects(mode: number): any[];
    updateSize(): void;
    clearSelection(): void;
    geoJsonToWKT(feature: any): string;
    geoJsonToFeatures(geojson: any, options: any): any;
    registerMapEvent(type: string, listener: Function, opt_this?: any): void;
    unregisterMapEvent(type: string, listener: Function, opt_this?: any): void;
    getSize(): [number, number];
    tryInferSourceProjection(geojson: any): string;
}

//NOTE: This component is a quick-n-dirty wrapper around the jquery-based elector map widget
//
//This is not the replacement React-based OL map viewer you are looking for. Adjust your expectations accordingly :)

/**
 * Defines the zoom action to perform after electors have been loaded/updated
 */
export enum ElectorAutoZoomAction {
    /**
     * Zoom to the first elector
     */
    First,
    /**
     * Zoom to the bounding box of all electors
     */
    All,
    /**
     * Do not zoom
     */
    None
}

export interface IMapViewerProps {
    electors: ElectracAPI.ElectorSurveyDisplay[];
    autoZoomAction: ElectorAutoZoomAction;
    autoZoomDelay?: number;
    baselayers?: any[];
    overlays?: any[];
    showInlineLayerSwitcher: boolean;
    selectColor?: string;
    onBeforeFeatureSelected?: (feature: Feature, layer: MapLayer) => boolean;
    onMapResized?: (newSize: [number, number]) => void;
    onNewDimensions?: (width: number, height: number) => [number, number];
}

export class MapViewer extends React.Component<IMapViewerProps, any> {
    private map?: IMapWidgetInterface;
    constructor(props: IMapViewerProps) {
        super(props);
    }
    updateSize() {
        if (this.map) {
            this.map.updateSize();
        }
    }
    getMapWidgetInterface(): IMapWidgetInterface | undefined {
        
        return this.map;
    }
    componentDidMount() {

    }
    componentWillReceiveProps(nextProps: IMapViewerProps) {
        //console.log(nextProps);
        if (nextProps.electors != this.props.electors) {
            this.updateMapElectors(nextProps.electors);
        }
    }
    public updateMapElectors(electors: ElectracAPI.ElectorSurveyDisplay[]) {
        if (electors && this.map) {
            const layer = this.map.getLayerByName("elector");
            if (layer) {
                if (electors.length) {
                    let extent: Extent | undefined;
                    const features = electors.filter(e => {
                        //There are no electors on null island
                        return (e.Longitude != null && e.Longitude != 0 && e.Latitude != null && e.Latitude != 0);
                    }).map(e => {
                        //STUPID: Array.filter doesn't work as a type guard, you'll notice we have already checked
                        //e.Longitude and e.Latitude are not null above
                        // map checked above
                        const coords = this.map!.LLtoWebMercator([e.Longitude!, e.Latitude!]);
                        const pt = this.map!.createPoint(coords);
                        const feat = this.map!.createFeature(e, pt);

                        if (!extent) {
                            const coord = pt.getCoordinates();
                            extent = [
                                coord[0],
                                coord[1],
                                coord[0],
                                coord[1]
                            ];
                        } else {
                            const coord = pt.getCoordinates();
                            extent[0] = Math.min(extent[0], coord[0]);
                            extent[1] = Math.min(extent[1], coord[1]);
                            extent[2] = Math.max(extent[2], coord[0]);
                            extent[3] = Math.max(extent[3], coord[1]);
                        }

                        return feat;
                    });
                    this.map.addFeaturesToLayer(layer, features, true);
                    const zoomer = () => {
                        switch (this.props.autoZoomAction) {
                            case ElectorAutoZoomAction.First:
                                {
                                    const [lon, lat] = features[0].getGeometry().getFirstCoordinate();
                                    this.map!.zoomToCenter(lon, lat, 18);
                                }
                                break;
                            case ElectorAutoZoomAction.All:
                                {
                                    if (extent) {
                                        this.map!.zoomToExtent(extent);
                                    }
                                }
                                break;
                        }
                    };
                    if (this.props.autoZoomDelay! > 0) {
                        window.setTimeout(zoomer, this.props.autoZoomDelay);
                    } else {
                        zoomer();
                    }
                } else {
                    this.map.clearLayerSource(layer);
                }
            }
            this.map.updateSize();
        }
    }
    private onResized = () => {
        if (this.map) {
            this.map.updateSize();
            const { onMapResized } = this.props;
            if (onMapResized) {
                onMapResized(this.map.getSize());
            }
        }
    }
    private setNode = (node: HTMLElement | null) => {
        if (!node) {
            return;
        }
        const eNode = jQuery(node);
        eNode.ElectracMapWidget({
            name: "electorMap",
            onBeforeFeatureSelected: this.props.onBeforeFeatureSelected,
            hideLayerSwitcher: !this.props.showInlineLayerSwitcher,
            selectColor: this.props.selectColor,
            layers: {
                baselayers: this.props.baselayers ? [...this.props.baselayers] : [],
                overlays: this.props.overlays ? [...this.props.overlays] : []
            }
        });
        eNode.WaitForMapWidget().done((map) => {
            this.map = map;
            this.updateMapElectors(this.props.electors);
        });

    }
    render(): JSX.Element {
        if ((!this.props.baselayers || this.props.baselayers.length == 0) &&
            (!this.props.overlays || this.props.overlays.length == 0)) {
            return <div className="alert alert-danger">
                <h3>Invalid map configuration</h3>
                <p>No base layers or overlay layers have been set up</p>
            </div>;
        }
        return <AutoSizer onResize={this.onResized}>
            {({ width, height }) => {
                let dim = [width, height];
                const { onNewDimensions } = this.props;
                if (onNewDimensions) {
                    dim = onNewDimensions(width, height);
                }
                return <div style={{ border: 1, width: dim[0], height: dim[1] }}>
                    <div ref={this.setNode} style={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}>
                        {this.props.children}
                    </div>
                </div>
            }}
        </AutoSizer>;
    }
}