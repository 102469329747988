import * as React from "react";
import { SurveyContainerStyle, ISurveyContainerContext } from "@electrac/plugin";
import { ISurveyQuestionCommonProps, SurveyFieldDescription } from "./Common";
import * as Multiselect from 'react-widgets/lib/Multiselect';
import debounce = require("lodash.debounce");

export interface ISurveyAddIssueProps extends ISurveyQuestionCommonProps {
    context: ISurveyContainerContext;
    onChange: (args: any) => void;
    answer: any;
    flyoutUp?: boolean;
}

export class SurveyAddIssue extends React.Component<ISurveyAddIssueProps, any> {
    private fnIssueSearch: (searchTerm: string) => void;
    private fnIssueSearchDebounced: (searchTerm: string) => void;
    private fnChange: (e) => void;
    constructor(props) {
        super(props);
        this.state = {
            isSearching: false,
            availableActivities: []
        };
        this.fnChange = this.onChange.bind(this);
        this.fnIssueSearch = this.onIssueSearch.bind(this);
        this.fnIssueSearchDebounced = debounce((arg) => this.onIssueSearchDebounced(arg), 500);
    }
    private onChange(e) {
        this.props.onChange(e.map(item => item.Id).join(","));
    }
    private onIssueSearch(searchTerm: string) {
        this.fnIssueSearchDebounced(searchTerm);
    }
    private onIssueSearchDebounced(searchTerm: string) {
        this.setState({ isSearching: true });
        this.props.context.getIssues(searchTerm).then(res => {
            this.setState({
                isSearching: false,
                availableIssues: res
            });
        }).catch(err => {
            this.setState({
                isSearching: false,
                availableIssues: []
            });
        });
    }
    componentDidMount() {
        this.onIssueSearchDebounced("");
    }
    render(): JSX.Element {
        const { label, description, theme, context } = this.props;
        if (!context.isOnline()) {
            return <div className={`${theme && theme.getFieldContainerClass()}`}>
                <label className={theme && theme.itemLabelClass}>{label}</label>
                <div className={`${theme && theme.getContainerClasses(SurveyContainerStyle.Error)}`}>
                    <strong>WARNING: This question requires online access to display and you are determined to be either offline or have limited connectivity</strong>
                </div>
            </div>;
        }
        // const value = (answer != null && answer.length > 0) ? answer.split(",") : null;
        
        return <div className={`${theme && theme.getFieldContainerClass()} survey-add-Issue`}>
            <div className={theme && theme.getQuestionLabelClass()}>
                <label className={theme && theme.itemLabelClass}>{label}</label>
                <SurveyFieldDescription description={description} />
            </div>
            
            <div className={theme && theme.getQuestionBodyClass()}>
                <Multiselect filter="contains"
                data={this.state.availableIssues}
                busy={this.state.isSearching}
                onSearch={this.fnIssueSearch}
                onChange={this.fnChange}
                valueField="Id"
                textField="Name"
                dropUp={(this.props.flyoutUp === true)}
                />
            </div>
        </div>;
    }
}