/**
 * TypeGuards.ts
 *
 * This module provides re-usable TypeScript type guards 
 */

/**
 * Checks if the given value is a string
 */
export function isString(str: any): str is string {
    return typeof (str) === 'string';
}

/**
 * Checks if the given string is not a null or empty
 */
export function isNullOrEmpty(str: string): boolean {
    return str == null || str == "";
}

/**
 * Checks if the given value is an Error
 * @param err
 */
export function isError(err: any): err is Error {
    return err.message !== undefined && err instanceof Error; // err.stack !== undefined;
}

/**
 * Asserts a never type. Call in a default case of a switch statement or in the else case of a series of if/else statements
 * when the test involves a union type. This is a compile-time assertion that you have tested for all possible cases.
 *
 * In any call to this function the type of the parameter you pass in as indicated by the TypeScript tooling should be
 * of type (never). If not, the tooling would show an error on this function call which indicates that you have not fully
 * tested for all possible cases of that parameter.
 * @param impossible
 */
export function assertNever(impossible: never): void {
    throw new Error("Should never have gotten here");
}

export function isPositionError(arg: any): arg is PositionError {
    return arg
        && typeof (arg.PERMISSION_DENIED) != 'undefined'
        && typeof (arg.POSITION_UNAVAILABLE) != 'undefined'
        && typeof (arg.TIMEOUT) != 'undefined';
}