import * as React from 'react';

//TODO: @electrac/components

//export type LoadSpinnerStyle = "info" | "danger" | "primary" | "success" | "warning";
//export type LoadIconClass = "arrows-cw";
//export type LoadIconAnimation = "spin";

export interface ISpinnerIconProps {
    iconClass?: string;
    iconAnimation?: string;
}

export const SpinnerIcon = (props: ISpinnerIconProps) => {
    const iconClass = props.iconClass || "arrows-cw";
    const iconAnimation = props.iconAnimation || "spin";
    return <i className={`animate-${iconAnimation} icon-${iconClass} `} />;
}

export interface IBsLoadSpinnerProps extends ISpinnerIconProps {
    message?: string;
    bold?: boolean;
    spinnerStyle?: string;
    style?: React.CSSProperties;
}

export const BsLoadSpinner = (props: IBsLoadSpinnerProps) => {
    const bold = !!props.bold;
    const message = props.message || "Loading ...";
    const iconClass = props.iconClass || "arrows-cw";
    const iconAnimation = props.iconAnimation || "spin";
    const spinnerStyle = props.spinnerStyle || "info";
    const style = props.style;
    if (bold) {
        return <div className={`alert alert-${spinnerStyle}`} style={style}><strong><SpinnerIcon iconClass={iconClass} iconAnimation={iconAnimation} /> {message}</strong></div>;
    } else {
        return <div className={`alert alert-${spinnerStyle}`} style={style}><SpinnerIcon iconClass={iconClass} iconAnimation={iconAnimation} /> {message}</div>;
    }
};