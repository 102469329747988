import * as React from 'react';
import FormGroup = require("react-bootstrap/lib/FormGroup");
import ControlLabel = require("react-bootstrap/lib/ControlLabel");
import { Icons } from '@electrac/plugin';
import { RespondentField } from "./contracts";
import { getLocaleConfiguration } from "./locale";
import InputElement from "react-input-mask";
import {
    ISurveyContainerTheme,
} from "@electrac/plugin";

export interface IManualSurveyRespondentDetailsProps {
    /**
     * Custom title, defaults to "Respondent Details" if not specified
     */
    title?: string;
    /**
     * Raised when a respondent field has been changed
     */
    onChange: (field: RespondentField, value: string) => void;
    /**
     * If specified, denotes the fields that are required
     */
    requiredFields?: RespondentField[];

    theme: ISurveyContainerTheme;

    disableLabels?: boolean;

}

export class ManualSurveyRespondentDetails extends React.Component<IManualSurveyRespondentDetailsProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            fields: {}
        };
    }
    reset() {
        this.setState({ fields: {} });
    }
    onFieldChange(field: RespondentField, e) {
        this.trackFieldChange(field, e.target.value);
    }
    trackFieldChange(field: RespondentField, value: string) {
        const { fields } = this.state;
        fields[field] = value;
        this.setState({ fields: fields });
    }
    isRequired(field: RespondentField): boolean {
        if (this.props.requiredFields != null) {
            return this.props.requiredFields.indexOf(field) >= 0;
        }
        return false;
    }
    hasValue(field: RespondentField): boolean {
        const { fields } = this.state;
        return typeof (fields[field]) != 'undefined' && fields[field] != "";
    }
    validate(): string[] {
        const errors: string[] = [];
        if (this.props.requiredFields != null && this.props.requiredFields.length > 0) {
            for (const field of this.props.requiredFields) {
                if (!this.hasValue(field)) {
                    errors.push(`${field} is required`);
                }
            }
        }
        return errors;
    }
    render(): JSX.Element {
        const { onChange, theme } = this.props;
        const { fields } = this.state;
        const masks = getLocaleConfiguration();


        const renderLabel = (requiredField: RespondentField, name: string) => {
            const {disableLabels} = this.props;
            return (disableLabels ? <ControlLabel>{this.isRequired(requiredField) ? "* " : "" }{name}</ControlLabel> : <noscript /> );
        }

        const renderPlaceholder = (requiredField: RespondentField, name: string) => {
            return this.isRequired(requiredField) ? `${name} *` : name;
        }

        const onMobileChanged = (e) => {
            onChange("Mobile", e.target.value);
        };
        return <fieldset>
            <legend>{Icons.USER}<h4> {this.props.title || "Respondent Details"} </h4></legend>

            <FormGroup controlId="firstName">
                {renderLabel("FirstName", "First Name")}
                <input className={`${theme.getTextboxClass()}`} type="text" placeholder={renderPlaceholder("FirstName", "First Name")}
                    value={fields.FirstName || ""} onChange={this.onFieldChange.bind(this, "FirstName") } onBlur={ (e) => {
                        onChange("FirstName", e.target.value);
                    } } />
            </FormGroup>

            <FormGroup controlId="lastName">
                {renderLabel("LastName", "Last Name") }        
                <input className={`${theme.getTextboxClass()}`} type="text" placeholder={renderPlaceholder("LastName", "Last Name") }
                    value={fields.LastName || ""} onChange={this.onFieldChange.bind(this, "LastName") } onBlur={ (e) => {
                        onChange("LastName", e.target.value);
                    } } />
            </FormGroup>

            <FormGroup controlId="address">
                {renderLabel("Address", "Address") }
                <input className={`${theme.getTextboxClass()}`} type="text" placeholder={renderPlaceholder("Address", "Address") }
                    value={fields.Address || ""} onChange={this.onFieldChange.bind(this, "Address") } onBlur={ (e) => {
                        onChange("Address", e.target.value);
                    } } />
            </FormGroup>

            <FormGroup controlId="postcode">
                {renderLabel("Postcode", "Postcode") }
                <input className={`${theme.getTextboxClass()}`} type="text" placeholder={renderPlaceholder("Postcode", "Postcode") }
                    value={fields.Postcode || ""} onChange={this.onFieldChange.bind(this, "Postcode") } onBlur={ (e) => {
                        onChange("Postcode", e.target.value);
                    } } />
            </FormGroup>

 
            <FormGroup controlId="email">
                {renderLabel("Email", "Email") }
                <input className={`${theme.getTextboxClass()}`} type="text" placeholder={renderPlaceholder("Email", "Email") }
                    value={fields.Email || ""} onChange={this.onFieldChange.bind(this, "Email") } onBlur={ (e) => {
                        onChange("Email", e.target.value);
                    }} />
            </FormGroup>

            <FormGroup controlId="respondentMobile">
                {renderLabel("Mobile", "Phone") }
                <input className={`${theme.getTextboxClass()} mobile-input`} placeholder={renderPlaceholder("Mobile", "Phone") }
                    value={fields.Mobile || ""} onChange={this.onFieldChange.bind(this, "Mobile") } onBlur={onMobileChanged} />
            </FormGroup>
        </fieldset>;
    }
}