import * as React from "react";
import { IElectorImmutable, ElectorEntryItemDisplayFormatter } from "./contracts";
import { Icons, getGenderIcon } from '@electrac/plugin';
import uniq = require("lodash.uniq");
import moment = require("moment");

export interface IElectorSelectionProps {
    isReadOnly?: boolean;
    activeElectionDate?: string;
    voted?: number[];
    currentElectors: number[];
    electors: IElectorImmutable[];
    /**
     * A formatter function to display the entry of each elector. Only applies when mode is Walk
     */
    electorEntryFormatter?: ElectorEntryItemDisplayFormatter;
    onChange: (ids: number[], voted: number[]) => void;
}

export class ElectorSelection extends React.Component<IElectorSelectionProps, any> {
    constructor(props: IElectorSelectionProps) {
        super(props);
    }
    private onElectorVotedChanged = (e: any) => {
        const { currentElectors, voted } = this.props;
        const currentIds = currentElectors;
        let currentVoted = voted || [];
        const id = e.target.value;
        if (e.target.checked) {
            currentVoted.push(id);
        } else {
            currentVoted = currentVoted.filter(v => v != id);
        }
        currentVoted = uniq(currentVoted);
        this.props.onChange(currentIds, currentVoted);
    }
    private onElectorSelectionChanged = (e: any) => {
        const { currentElectors, voted } = this.props;
        let currentIds = currentElectors;
        const currentVoted = voted || [];
        const id = e.target.value;
        if (e.target.checked) {
            currentIds.push(id);
        } else {
            currentIds = currentIds.filter(v => v != id);
        }
        currentIds = uniq(currentIds);
        this.props.onChange(currentIds, currentVoted);
    }
    render(): JSX.Element {
        const { electors, voted, currentElectors, electorEntryFormatter, activeElectionDate } = this.props;
        //WTF: read-only doesn't work on checkbox/radio input fields. Is this by design? I have to currently map it to disabled instead.
        const isReadOnly = (this.props.isReadOnly === true);
        const currentVoted = voted || [];
        const electorItems = electors.map(e => {
            if (!e) return null;

            const g = e.gender;
            const defaultText = `${e.firstName} ${e.lastName}`;
            const elabel = electorEntryFormatter != null ? electorEntryFormatter(e) : defaultText;
            return {
                label: <span className="elector-selection-entry-label-text">{getGenderIcon(g)} {elabel}</span>,
                checked: currentElectors.filter(ce => ce == e.electorID).length == 1,
                value: `${e.electorID}`,
                voted: currentVoted.filter(v => v == e.electorID).length == 1
            };
        });
        return <div className="well">
            <label>
                <span>{Icons.USERS} Who did you speak to?</span>
            </label>
            {electorItems.map((item, index, arr) => {
                if (!item) return null;
                return <div key={index} className="elector-selection-entry">
                    <div className="checkbox" style={{ display: "inline-block" }}>
                        <label className="elector-selection-entry-label">
                            <input type="checkbox" disabled={isReadOnly} value={item.value} checked={item.checked} onChange={this.onElectorSelectionChanged} /> {item.label}
                        </label>
                    </div>
                    {!!activeElectionDate && moment.utc().isBefore(moment.utc(activeElectionDate))
                        && <div className="checkbox" style={{ display: "inline-block", marginLeft: 4 }}>
                            <label>
                                <input type="checkbox" disabled={isReadOnly} value={item.value} checked={item.voted} onChange={this.onElectorVotedChanged} /> Voted
                                    </label>
                            {item.voted && <span className="label label-info">Person will be marked as no contact until {moment.utc(activeElectionDate).format("Do MMM YYYY")}</span>}

                        </div>
                    }
                </div>;
            })}
        </div>;
    }
}