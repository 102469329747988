import * as React from 'react';
import Modal = require("react-bootstrap/lib/Modal");
import ModalHeader = require("react-bootstrap/lib/ModalHeader");
import ModalBody = require("react-bootstrap/lib/ModalBody");
import ModalFooter = require("react-bootstrap/lib/ModalFooter");
import ModalTitle = require("react-bootstrap/lib/ModalTitle");
import Button = require("react-bootstrap/lib/Button");
//import { Icons } from './Icons';

//TODO: @electrac/components

export interface IModalDialogAction {
    label: string;
    icon?: JSX.Element;
    disabled?: boolean;
    onClick: () => void;
}

export interface IModalDialogProps {
    show: boolean;
    title: any;
    onClose: () => void;
    showCloseButton?: boolean;
    maxBodyHeight?: any;
    children?: any;
    actions: IModalDialogAction[];
}

export const ModalDialog = (props: IModalDialogProps) => {
    const actions = props.actions || [];
    const bodyStyle: React.CSSProperties | undefined = props.maxBodyHeight != null ? { maxHeight: props.maxBodyHeight, overflowY: "auto" } : undefined;
    return <Modal show={props.show} onHide={props.onClose}>
        <ModalHeader closeButton={props.showCloseButton || true}>
            <ModalTitle>{props.title}</ModalTitle>
        </ModalHeader>
        <ModalBody style={bodyStyle}>
            {props.children}
        </ModalBody>
        <ModalFooter>
            {actions.map(act => {
                return <Button key={act.label} disabled={act.disabled} onClick={act.onClick}>{act.icon} {act.label}</Button>;
            })}
            {props.showCloseButton === true && <Button bsStyle="danger" onClick={props.onClose}>{/*Icons.CLOSE*/} Close</Button>}
        </ModalFooter>
    </Modal>;
};