import * as React from "react";
import { ISurveyContainerTheme } from "@electrac/plugin";

export interface ISurveyFieldDescriptionProps {
    description?: string;
}


export interface ISurveyQuestionCommonProps extends ISurveyQuestionReadableCommonProps {
  
    isReadOnly: boolean;   
}

export interface ISurveyQuestionReadableCommonProps extends ISurveyFieldDescriptionProps {
    qid?: number;
    debugMode?: boolean;
    theme?: ISurveyContainerTheme;
    label?: string;
}

export const SurveyFieldDescription = (props: ISurveyFieldDescriptionProps) => {
    if (props.description) {
        return <p><i>{props.description}</i></p>;
    }
    return null;
};