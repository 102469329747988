import * as React from "react";
import { NO_BREAK_SPACE } from "@ml/common";
import { CommonStyleColor } from "@ml/components";
import ReactTooltip = require('react-tooltip');

export interface IRtTooltipProps {
    id: string;
    backgroundColor?: string;
    color?: string;
    type?: string;
    tooltipText?: string | JSX.Element;
    tooltipSymbol?: string | JSX.Element;
    className?: string;
}


//field-container is defined in stles floatLabel less to be in line with float label
export const RtTooltip = (props: IRtTooltipProps) => {
    const { tooltipSymbol, tooltipText, backgroundColor, color, type, id } = props;

    const style: React.CSSProperties = {
        backgroundColor: (backgroundColor ? backgroundColor : CommonStyleColor.Bootstrap.Primary),
        color: (color ? color : "white")
    }

    return (
        <span>
            <span
                className={props.className ? props.className : "badge"}
                style={style}
                data-tip={true}
                data-for={id}>{(tooltipSymbol ? tooltipSymbol : "?")}</span>
            {NO_BREAK_SPACE}
            <ReactTooltip id={id} type={(type ? type as any : "dark")} effect="float">{tooltipText}</ReactTooltip>
        </span>
    )
}


