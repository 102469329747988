import * as React from "react";
import { TextHighlighter } from "@ml/components";

//TODO: @electrac/components

export interface ICheckboxListItem {
    value: any;
    checked: boolean;
    label: string;
}

export interface ICheckboxListProps {
    enableNameFiltering: boolean;
    data: ICheckboxListItem[];
    onChange?: (selectedItems: ICheckboxListItem[]) => void;
    maxHeight?: number;
}

export interface ICheckboxListState {
    filter: string;
    initialData: ICheckboxListItem[];
    filteredData: ICheckboxListItem[];
}

export class CheckboxList extends React.Component<ICheckboxListProps, ICheckboxListState> {
    constructor(props: ICheckboxListProps) {
        super(props);
        this.state = {
            filter: "",
            initialData: props.data,
            filteredData: props.data
        };
    }

    // uncheck all items in the list
    reset() {
     
        this.setState({
            filteredData: this.state.initialData.map(item => ({ ...item, checked:false }))  
        });
    }

    checkAll() {

        this.setState({
            filteredData: this.state.initialData.map(item => ({ ...item, checked:true }))  
        });
    }
      
    handleItemChangeCheckAll = (e: any) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        if(value){
            this.checkAll()
        }
        else
        {
            this.reset()
        }
    }

    private handleItemChange = (e: any) => {

        const newData = this.state.filteredData.map(function (item) {
            if (item.value == e.target.value) {
                return {...item, checked: e.target.checked }
            }
            return item;
        });

        this.setState({
            filteredData: newData
        });

        if (this.props.onChange) {
            const selectedItems = newData.filter(a=>a && a.checked);
            this.props.onChange(selectedItems);
        }
    }
    private onTextFilter = (e: any) => {
        let filterText = (e.target.value || "").toLowerCase();
        if (filterText.length < 3) {
            this.setState({
                filter: "",
                filteredData: this.state.initialData
            });
        } else {
            let newData = [] as ICheckboxListItem[];

            this.props.data.forEach(function (item) {
                if (item.label.toLowerCase().indexOf(filterText) >= 0) {
                    newData.push(item);
                }
            });

            this.setState({
                filter: filterText,
                filteredData:  newData
                
            });
        }
    }
    render(): JSX.Element {
        let options = this.state.filteredData.map((item, index) => {
            return (
                <div key={'chk-' + index} className="checkbox">
                    <label>
                        <input
                            type="checkbox"
                            value={item.value}
                            onChange={this.handleItemChange}
                            checked={item.checked ? true : false} /> <TextHighlighter highlight={this.state.filter} text={item.label} />
                    </label>
                </div>
            );
        });

        let filter: React.ReactNode = null;
        let tbHeight = 30;
        if (this.props.enableNameFiltering) {
            filter = (<input type="text" placeholder="Filter items by name" onChange={this.onTextFilter} />);
            tbHeight = 60; //Taking a guess here
        }

        let containerStyle: React.CSSProperties | undefined;
        if (this.props.maxHeight) {
            containerStyle = {
                maxHeight: (this.props.maxHeight - tbHeight),
                overflow: "auto"
            };
        }

        return (
            <div className="checkbox-list-root">
                <div>
                    {filter}
                </div>
                <div style={containerStyle}>
                    {options}
                </div>
                <label><input type="checkbox"  name="checkbox" value="value"  onChange={this.handleItemChangeCheckAll} /> Check All</label>
            </div>
        );
    }
}