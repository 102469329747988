import { KendoWidgetComponent, KendoWidgetName, IKendoWidgetPropsBase } from "./kendo-widget-component";

//TODO: @electrac/components

export type IKendoSliderOptions = kendo.ui.SliderOptions;

export interface IKendoSliderEvents {
    onChange?: (e: kendo.ui.SliderChangeEvent) => void;
    onSlide?: (e: kendo.ui.SliderSlideEvent) => void;
}

export interface IKendoSliderProps extends IKendoWidgetPropsBase<IKendoSliderOptions>, IKendoSliderEvents {

}

export interface IKendoSliderState {
    
}

@KendoWidgetName("kendoSlider")
export class KendoSlider extends KendoWidgetComponent<kendo.ui.Slider, IKendoSliderOptions, IKendoSliderProps, IKendoSliderState> {
    constructor(props: IKendoSliderProps) {
        super(props);
    }
    private onChange = (e: any) => {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }
    private onSlide = (e: any) => {
        if (this.props.onSlide) {
            this.props.onSlide(e);
        }
    }
    protected augmentWidgetOptions(baseOptions: IKendoSliderOptions): any { //Override
        return {
            ...baseOptions,
            ...{
                change: this.onChange,
                slide: this.onSlide
            }
        }
    }
    componentWillReceiveProps(nextProps: IKendoSliderProps) {
        if (this.props.options.value != nextProps.options.value) {
            this.$widget && this.$widget.value(nextProps.options.value as any);
        }
    }
}