import * as React from "react";

//TODO: @electrac/components

export interface ILoadSpinnerProps {
    message: string;
    key?: string;
    ref?: string;
}

const SpinIcon = (props: any) => <i className='fa fa-spin fa-refresh'></i>;
export class LoadSpinner extends React.Component<ILoadSpinnerProps, any> {
    constructor(props: ILoadSpinnerProps) {
        super(props);
        this.state = {};
    }
    render(): JSX.Element {
        var body;
        body = (<div className='alert alert-info'><SpinIcon />&nbsp; {this.props.message}</div>);
        return body;
    }
}