/// String.ts - String utility functions

export function strIsNullOrEmpty(str: string | null | undefined): str is null | undefined {
    return str == null || str.length == 0;
}

export function trim(str: string): string {
    if (!String.prototype.trim) {
        return str.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
    } else {
        return str.trim();
    }
}

export function trimToUpper(str: string): string {
    return trim(str).toUpperCase();
}

export function trimToLower(str: string): string {
    return trim(str).toLowerCase();
}

export const STR_EMPTY = "";