import * as React from "react";
import { IKendoWidgetPropsBase, IKendoDataSourceableOptions, KendoWidgetName, KendoWidgetComponent } from './kendo-widget-component';

//TODO: @electrac/components

interface IKendoTabStripProps extends IKendoWidgetPropsBase<IKendoTabStripOptions> {
    /**
     * The name of the tab to make initially selected
     */
    selectedTab?: string;
}

interface IKendoTabStripOptions extends IKendoDataSourceableOptions {
    animation?: Object | boolean;
    contentUrls?: string[];
    collapsible?: boolean;
}

interface IKendoTabStripState {

}

/**
 * The kendo tab strip component
 *
 * IMPORTANT: This component does not currently support direct dynamic modifications of child tabs once rendered. In order to support
 * tabs whose content may change, you must encapsulate the dynamic content within its own child component
 */
@KendoWidgetName("kendoTabStrip")
export class KendoTabStrip extends KendoWidgetComponent<kendo.ui.TabStrip, IKendoTabStripOptions, IKendoTabStripProps, IKendoTabStripState> {
    constructor(props: IKendoTabStripProps) {
        super(props);
    }
    shouldComponentUpdate(nextProps: IKendoTabStripProps, nextState: IKendoTabStripState): boolean {
        //On changing of selected tabs, we don't want a full re-render as that will screw up the kendo tabstrip
        if (nextProps.selectedTab != this.props.selectedTab) {
            this.getWidget().select(jQuery(`#${nextProps.selectedTab}`));
            return false;
        }
        return true;
    }
    render(): JSX.Element {
        let tabs: KendoTab[] = [];
        React.Children.forEach(this.props.children, ch => {
            let comp: any = ch;
            if (comp.type.name === "KendoTab")
                tabs.push(comp);
        });

        if (tabs.length != React.Children.count(this.props.children)) {
            console.warn("[KendoTabStrip] Irregular (tab / child component) count. This component only accets KendoTab components as children. Non-KendoTab components will be omitted");
        }

        return (
            <div className="kendo-tabstrip-content-root">
                <ul>{tabs.map(t => <li key={t.props.id} id={t.props.id} className={this.props.selectedTab == t.props.id ? "k-state-active" : undefined}>{t.props.title}</li>)}</ul>
                {tabs}
            </div>);
    }
}

export interface IKendoTabProps extends React.Props<any> {
    id: string;
    title: string;
}

export class KendoTab extends React.Component<IKendoTabProps, any> {
    constructor(props: IKendoTabProps) {
        super(props);
    }
    render(): JSX.Element {
        return (<div className="react-kendo-tab-content-root">{this.props.children}</div>);
    }
}