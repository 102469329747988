import * as React from 'react';
import { useContext, ReactNode, useRef, useImperativeHandle } from 'react';
import * as ElectracAPI from '@electrac/model';

import {
	SurveyStatus,
	SurveyQuestionType,
	ElectorEntryItemDisplayFormatter,
	IElectorImmutable,
	ISurveyImmutable,
	IAddressImmutable,
	ISurveyDraftImmutable,
	IManualSurveyResponseImmutable,
	ISurveyQuestionImmutable,
	ISurveyAnswerImmutable
} from './contracts';
import { EvaluateDisplayCondition, ConditionWriter } from './eval/ConditionEvaluator';
import { getLocale } from './locale';
import Panel = require('react-bootstrap/lib/Panel');
import { SurveyChoice, ISurveyChoiceItem } from './fields/choice';
import { SurveyRemoteChoice, ISurveyRemoteChoiceProps } from './fields/RemoteChoice';
import { SurveyText } from './fields/text';
import { SurveyInstruction } from './fields/instruction';
import { SurveyRemarks } from './fields/remarks';
import { SurveyYesNo } from './fields/yesno';
import { SurveyVoteIntention } from './fields/voteintention';
import { SurveyDate } from './fields/date';
import { SurveyMobile } from './fields/mobile';
import { SurveyAddToEvent } from './fields/AddToEvent';
import { SurveyPetition } from './fields/Petition';
import { SurveyAddActivity } from './fields/AddActivity';
import { SurveyEventConfirmation, SurveyQuestionDisplay } from './fields/event-confirmation';
import { SurveyAddElectorate } from './fields/AddElectorate';
import { SurveyPersuasion } from './fields/Persuasion';
import { SurveyRankedChoice } from './fields/RankedChoice';
import { SurveyTodoFollowUp } from './fields/TodoFollowUp';
import { Icons } from '@electrac/plugin';

import {
	fetchEvents,
	fetchEventShifts,
	fetchElectoratesAutoComplete,
	fetchActivitiesAutoComplete,
	fetchIssuesAutoComplete,
	ApiContext,
	callApi
} from '@electrac/api';

import { ISurveyContainerContext, QuestionEventHandler } from '@electrac/plugin';
import { QuestionEventHandlerSet } from './QuestionEventHandlerSet';
import isEmail = require('validator/lib/isEmail');
import isMobilePhone = require('validator/lib/isMobilePhone');
import { logWarn, strIsNullOrEmpty } from '@ml/common';
import { BsInputSize } from '@electrac/components';
import { ElectorSelection } from './ElectorSelection';
import { BootstrapSurveyTheme, UNSUPPORTED_QUESTION_KIND, DEBUG_QUESTION_TYPE, MISSING_VOTE_MODEL } from './SurveyContainerTheme';
import { buildDndChildContext, DND_CHILD_CONTEXT_VALIDATION_MAP, IDndContext } from '@electrac/components';
import { RemoteChoiceFetcher } from './RemoteChoiceFetcher';
import { SurveyButtonStyle, SurveyContainerStyle, ISurveyContainerTheme, getPlugin } from '@electrac/plugin';
import { SurveyAddIssue } from './fields/AddIssues';
import { ElectorSurveyDisplay } from '@electrac/model';
import { SurveyRankTopChoices } from './fields/RankTopChoices';
const ReactModal = require('react-modal');

interface ISurveyPeopleContext {
	people?: ElectorSurveyDisplay[];
}
interface ISurveyEventContext {
	eventId?: number;
	repeatId?: number;
}

const SurveyPeopleContext = React.createContext<ISurveyPeopleContext | undefined>(undefined);
const SurveyEventContext = React.createContext<ISurveyEventContext | undefined>(undefined);

export const FocusableField = React.forwardRef(function ({ children, className }: { children: ReactNode; className?: string }, ref) {
	const divRef = useRef<HTMLDivElement>(null);
	useImperativeHandle(ref, () => ({
		focus: () => {
			if (divRef) {
				const node = divRef.current;
				if (node) {
					node.scrollIntoView();
				}
			}
		}
	}));

	return (
		<div ref={divRef} className={className}>
			{children}
		</div>
	);
});

export const SurveyPeopleProvider = ({ children, people }: { children: ReactNode; people?: ElectorSurveyDisplay[] }) => (
	<SurveyPeopleContext.Provider value={{ people }}>{children}</SurveyPeopleContext.Provider>
);

export const SurveyEventProvider = ({ children, eventId, repeatId }: { children: ReactNode; eventId?: number; repeatId?: number }) => (
	<SurveyEventContext.Provider value={{ eventId, repeatId }}>{children}</SurveyEventContext.Provider>
);

export function useSurveyPeople() {
	const context = useContext(SurveyPeopleContext);
	if (!context) return undefined;

	return context.people;
}

export function useSurveyEvent() {
	const context = useContext(SurveyEventContext);

	return context;
}

export interface ISurveyContainerItemProps extends React.Props<any> {
	itemClass?: string;
}

export const SurveyContainerItem = React.forwardRef((props: ISurveyContainerItemProps, ref) => {
	return (
		<FocusableField ref={ref} className={props.itemClass}>
			{props.children}
		</FocusableField>
	);
});

export const SurveyContainerQuestionsRoot = (props: ISurveyContainerItemProps) => {
	return <div className={props.itemClass}>{props.children}</div>;
};

export enum SurveyContainerMode {
	/**
	 * This survey is for a household address visit
	 */
	Walk,
	/**
	 * This survey is for a previously completed household address visit. All fields are read-only in this mode
	 */
	CompletedWalk,
	/**
	 * This survey is for manual entry.
	 */
	Manual
}

export interface ISurveyContainerProps {
	showSurveyErrorsAsModal?: boolean;

	/**
	 * The mode this survey container should operate in
	 */
	mode: SurveyContainerMode;
	/**
	 * Enable/Disable debug mode
	 */
	debugMode?: boolean;
	/**
	 * The date format to use for any date fields
	 */
	dateFormat?: string;
	/**
	 * Electors for this survey. Required when mode is not manual
	 */
	electors?: IElectorImmutable[];
	/**
	 * The ids of electors who voted
	 */
	voted?: number[];
	/**
	 * The survey definition
	 */
	survey: ISurveyImmutable;
	/**
	 * The household address where this survey is conduced. Required when mode is not manual
	 */
	address?: IAddressImmutable;
	/**
	 * Raised when the choice of selected electors has changed. Required when mode is Walk
	 */
	onSelectedElectorsChanged?: (ids: number[], voted: number[], eventQuestionIdsToClear: number[]) => void;
	/**
	 * Raised when a question in the survey has been answered. Required when mode is not CompletedWalk
	 */
	onQuestionSet?: (questionId: number, answer: any) => void;
	/**
	 * Raised when a choice-based question in the survey has been answered. Required when mode is not CompletedWalk
	 */
	onChoiceQuestionSet?: (questionId: number, choices: any[], otherAnswer: any) => void;
	/**
	 * Raised when the survey status has changed. Required when mode is Walk
	 */
	onStatusSet?: (status: string) => void;
	/**
	 * Raised when the classifications of the current surveyed address have changed
	 */
	onAddressClassificationsChanged?: (items: any[]) => void;
	/**
	 * Raised when the survey follow-up notes has changed. Only raised when the survey status is set to follow-up
	 */
	onFollowUpSet?: (notes: string) => void;
	/**
	 * A survey draft to initialise the container with. Only applicable when mode is not WalkComplete
	 */
	draft?: ISurveyDraftImmutable | IManualSurveyResponseImmutable;
	/**
	 * Raised when the survey is completed. Required when mode is not CompletedWalk
	 */
	onComplete?: (callback: (success: boolean) => void) => void;
	/**
	 * If true, the whole survey form is read-only. Use this for displaying already completed surveys
	 */
	isReadOnly?: boolean;
	/**
	 * The vote intention model. You must set this if the survey contains vote intention questions
	 */
	voteModel?: ElectracAPI.VoteModel;
	/**
	 * A formatter function to display the entry of each elector. Only applies when mode is Walk
	 */
	electorEntryFormatter?: ElectorEntryItemDisplayFormatter;
	theme?: ISurveyContainerTheme;
	/**
	 * Uniform size to use for applicable input fields
	 */
	size?: BsInputSize;
	/**
	 * If true, hide the submit button. Used when a custom submission process is required
	 */
	submitLabel?: ReactNode;

	/**
	 * If true, hide the submit button. Used when a custom submission process is required
	 */
	hideSubmit?: boolean;
	/**
	 * The token that certain question types require to access extra services
	 */
	token: ApiContext;
	/**
	 * A custom selector function to pick out selected electors. Use this for cases where there is an external elector
	 * selection mechanism
	 */
	customElectorSelector?: () => number[];
	/**
	 * Indicates whether we're online. Required for certain question types to work (which require contacting web services)
	 */
	isOnline: boolean;
	/**
	 * The persuasion model to set for persuasion answers
	 */
	persuasionModel?: ElectracAPI.StandardVoteOutput;
	/**
	 * If true, enables options in vote intention question types to enter a custom vote intention
	 */
	enableCustomVoteIntention?: boolean;

	/**
	 * Enable validateSync to always validate irrespective of current status
	 */
	validateAlways?: boolean;
}

function strReplaceAll(target: string, search: string, replacement: string) {
	return target.replace(new RegExp(search, 'g'), replacement);
}

function applyMergeTagValues(text: string | undefined, mergeTagValues: Record<string, string>): string | undefined {
	if (strIsNullOrEmpty(text))
		return undefined;

	for (const k in mergeTagValues) {
		const v = mergeTagValues[k];
		text = strReplaceAll(text, `{${k}}`, v);
	}
	return text;
}

export interface ISurveyContainerState {
	validationErrors?: IFieldValidationError[];
	isSubmitting?: boolean;
	openErrorsModal?: boolean;
	isReady: boolean;
	mergeTagValues?: Record<string, string>;
}

export interface IFocusableField {
	focus(): void;
}

export type ChoiceConverter = (q: ISurveyQuestionImmutable, answer?: ISurveyAnswerImmutable) => ISurveyChoiceItem[];

export interface IFieldValidationError {
	questionId: number;
	message: string;
}

export const DEFAULT_REQUIRED_MARKER = '(*)';

export const SURVEY_DEFAULT_DATE_FORMAT = 'dddd, MMMM Do YYYY';

function getFirstChoiceText(q: ISurveyQuestionImmutable) {
	const choices = q.Choices;
	if (choices && choices.length > 0) {
		return choices[0].Choice || '';
	}
	return '';
}

function choicesToItems(q: ISurveyQuestionImmutable, answer?: ISurveyAnswerImmutable): ISurveyChoiceItem[] {
	let items = (q.Choices || []).sort((a, b) => {
		const aord = a.Order;
		const bord = b.Order;

		if (!bord) return 1;
		if (!aord || aord < bord) return -1;
		if (!bord || aord > bord) return 1;
		return 0;
	});

	let initialValues: any[] | null = null;
	if (answer != null) {
		let choices = answer.Choices;
		if (choices != null) {
			initialValues = choices.map((v, i) => {
				if (!v) return null;
				return v.ID;
			});
		}
	}
	let vals = initialValues || [];
	let finalItems = items.map(itm => {
		return { label: itm!.Choice, value: itm!.SurveyChoiceID, checked: vals.indexOf(itm!.SurveyChoiceID) >= 0 };
	});

	return finalItems;
}

function getAnswerIDs(q: ISurveyQuestionImmutable, a: ISurveyAnswerImmutable): string | number[] | undefined {
	if (a != null) {
		let choices = a.Choices;
		if (choices != null) {
			return choices.map((v, i) => v!.ID);
		} else {
			return [];
		}
	}
	return '';
}

function isRemoteChoiceProps(props: any): props is ISurveyRemoteChoiceProps {
	return props.dataSourceId && props.context;
}

export interface IRenderChoiceProps {
	inner: any;
	questionSetCallback: (value: any) => void;
	choiceSetCallback: (value: any, otherAnswer: any) => void;
}

// because we have ref
export class RenderChoice extends React.Component<IRenderChoiceProps, any> {
	constructor(props: IRenderChoiceProps) {
		super(props);
	}
	render() {
		const { inner, questionSetCallback, choiceSetCallback } = this.props;

		if (isRemoteChoiceProps(inner)) {
			return <SurveyRemoteChoice {...inner} onChange={questionSetCallback} />;
		} else {
			return <SurveyChoice {...inner} onChange={choiceSetCallback} />;
		}
	}
}

// function RenderChoice(props: IRenderChoiceProps) {
//     const { inner, questionSetCallback, choiceSetCallback } = props;

//     if (isRemoteChoiceProps(inner)) {
//         return <SurveyRemoteChoice {...inner} onChange={questionSetCallback} />;
//     } else {
//         return <SurveyChoice {...inner} onChange={choiceSetCallback} />;
//     }
// }

export interface IRenderQuestionProps {
	q: ISurveyQuestionImmutable;
	/**
	 * Render the question in a disabled state
	 */
	disabled: boolean;
	//questionKey: number;
	surveyTheme: ISurveyContainerTheme;
	voteModel?: ElectracAPI.VoteModel;
	currentAnswers: { [key: number]: ISurveyAnswerImmutable };
	onQuestionSet: (questionId: number, e: any) => void;
	onChoiceQuestionSet: (questionId: number, choice: any[], otherAnswer: any) => void;
	context: ISurveyContainerContext;
	requiredMarker?: string; // = DEFAULT_REQUIRED_MARKER,
	isReadOnly?: boolean; // = false,
	debugMode?: boolean; // = false,
	format?: string; // = SURVEY_DEFAULT_DATE_FORMAT
	mergeTagValues: Record<string, string>;
}

export const RenderQuestion = React.forwardRef((props: IRenderQuestionProps, qRef) => {
	const {
		q,
		disabled,
		currentAnswers,
		onQuestionSet,
		onChoiceQuestionSet,
		surveyTheme,
		voteModel,
		isReadOnly,
		debugMode,
		context,
		mergeTagValues
	} = props;
	let { format, requiredMarker } = props;
	const disableMe = disabled;

	if (!format) format = SURVEY_DEFAULT_DATE_FORMAT;
	if (!requiredMarker) requiredMarker = DEFAULT_REQUIRED_MARKER;

	const qkind = q.AnswerTypeID;
	let label = q.Question;
	if (q.Required) {
		label += ' ' + requiredMarker;
		label = label!.trim();
	}
	if (debugMode === true) {
		label = `[${q.SurveyQuestionID} - ${q.AnswerTypeID}] ${label}`;
	}
	const desc = q.Description;
	//Not sure if hack or by design here, but numerical keys (our question ids) come back out as strings
	//and get() is sensitive to this but then again, this could be intended because it probably doesn't want
	//to confuse numerical keys with array indices
	const qKey: any = q.SurveyQuestionID + '';

	const answer = currentAnswers[qKey];
	let answerVal: string | undefined;

	if (answer != null) {
		answerVal = answer.Answer;
	}
	const qid = q.SurveyQuestionID;
	const questionSetCallback = (value: any) => {
		onQuestionSet(qid, value);
	};
	const choiceSetCallback = (choice: any, otherAnswer: any) => {
		onChoiceQuestionSet(qid, choice, otherAnswer);
	};
	switch (qkind) {
		case SurveyQuestionType.Instruction:
			{
				const itext = applyMergeTagValues(label, mergeTagValues);
				return (
					<SurveyContainerItem itemClass={surveyTheme.getItemClassForQuestion(qkind, surveyTheme.itemClass)}>
						<SurveyInstruction text={itext} theme={surveyTheme} qid={qid} />
					</SurveyContainerItem>
				);
			}
		case SurveyQuestionType.Remarks:
			{
				const itext = applyMergeTagValues(label, mergeTagValues);
				return (
					<SurveyContainerItem itemClass={surveyTheme.getItemClassForQuestion(qkind, surveyTheme.itemClass)}>
						<SurveyRemarks text={itext} theme={surveyTheme} qid={qid} />
					</SurveyContainerItem>
				);
			}
		case SurveyQuestionType.PartyID:
			if (voteModel == null || voteModel.Party == null) {
				return (
					<SurveyContainerItem itemClass={surveyTheme.getItemClassForQuestion(MISSING_VOTE_MODEL, surveyTheme.itemClass)}>
						<strong>
							{Icons.EXCLAMATION} Cannot render question ({label}).Missing party vote intention model
						</strong>
					</SurveyContainerItem>
				);
			} else {
				return (
					<SurveyContainerItem ref={qRef} itemClass={surveyTheme.getItemClassForQuestion(qkind, surveyTheme.itemClass)}>
						<SurveyVoteIntention
							qid={qid}
							debugMode={debugMode}
							label={label}
							theme={surveyTheme}
							parties={voteModel.Party}
							isReadOnly={isReadOnly || disableMe}
							onChange={questionSetCallback}
							context={context}
							description={desc}
							voteType="partyID"
							answer={answerVal}
						/>
					</SurveyContainerItem>
				);
			}
		case SurveyQuestionType.VoteIdentification:
			if (voteModel == null || voteModel.Standard == null) {
				return (
					<SurveyContainerItem
						ref={qRef}
						itemClass={surveyTheme.getItemClassForQuestion(MISSING_VOTE_MODEL, surveyTheme.itemClass)}
					>
						<strong>
							{Icons.EXCLAMATION} Cannot render question ({label}).Missing standard vote intention model
						</strong>
					</SurveyContainerItem>
				);
			} else {
				return (
					<SurveyContainerItem ref={qRef} itemClass={surveyTheme.getItemClassForQuestion(qkind, surveyTheme.itemClass)}>
						<SurveyVoteIntention
							qid={qid}
							debugMode={debugMode}
							label={label}
							theme={surveyTheme}
							parties={voteModel.Standard}
							isReadOnly={isReadOnly || disableMe}
							onChange={questionSetCallback}
							context={context}
							description={desc}
							voteType="intention"
							answer={answerVal}
						/>
					</SurveyContainerItem>
				);
			}
		case SurveyQuestionType.RecallVoteIdentification:
			if (voteModel == null || voteModel.Recall == null) {
				return (
					<SurveyContainerItem
						ref={qRef}
						itemClass={surveyTheme.getItemClassForQuestion(MISSING_VOTE_MODEL, surveyTheme.itemClass)}
					>
						<strong>
							{Icons.EXCLAMATION} Cannot render question ({label}). Missing recall vote intention model
						</strong>
					</SurveyContainerItem>
				);
			} else {
				return (
					<SurveyContainerItem ref={qRef} itemClass={surveyTheme.getItemClassForQuestion(qkind, surveyTheme.itemClass)}>
						<SurveyVoteIntention
							qid={qid}
							debugMode={debugMode}
							label={label}
							theme={surveyTheme}
							parties={voteModel.Recall}
							isReadOnly={isReadOnly || disableMe}
							onChange={questionSetCallback}
							context={context}
							description={desc}
							voteType="intention"
							answer={answerVal}
						/>
					</SurveyContainerItem>
				);
			}
		case SurveyQuestionType.YesNo:
			return (
				<SurveyContainerItem ref={qRef} itemClass={surveyTheme.getItemClassForQuestion(qkind, surveyTheme.itemClass)}>
					<SurveyYesNo
						qid={qid}
						label={label}
						theme={surveyTheme}
						isReadOnly={isReadOnly || disableMe}
						answer={answerVal}
						description={desc}
						onChange={questionSetCallback}
					/>
				</SurveyContainerItem>
			);
		case SurveyQuestionType.Date:
			return (
				<SurveyContainerItem ref={qRef} itemClass={surveyTheme.getItemClassForQuestion(qkind, surveyTheme.itemClass)}>
					<SurveyDate
						qid={qid}
						isReadOnly={isReadOnly || disableMe}
						description={desc}
						label={label}
						theme={surveyTheme}
						format={format}
						onChange={questionSetCallback}
						answer={answerVal}
					/>
				</SurveyContainerItem>
			);
		case SurveyQuestionType.MobilePhone:
			return (
				<SurveyContainerItem ref={qRef} itemClass={surveyTheme.getItemClassForQuestion(qkind, surveyTheme.itemClass)}>
					<SurveyMobile
						qid={qid}
						isReadOnly={isReadOnly || disableMe}
						label={label}
						theme={surveyTheme}
						onChange={questionSetCallback}
						description={desc}
						answer={answerVal}
					/>
				</SurveyContainerItem>
			);
		case SurveyQuestionType.EmailAddress:
			return (
				<SurveyContainerItem ref={qRef} itemClass={surveyTheme.getItemClassForQuestion(qkind, surveyTheme.itemClass)}>
					<SurveyText
						qid={qid}
						textarea={false}
						isEmail={true}
						isReadOnly={isReadOnly || disableMe}
						label={label}
						theme={surveyTheme}
						description={desc}
						onChange={questionSetCallback}
						answer={answerVal}
						maxLength={q.Settings?.MaxLength}
					/>
				</SurveyContainerItem>
			);
		case SurveyQuestionType.TextEntry:
			return (
				<SurveyContainerItem ref={qRef} itemClass={surveyTheme.getItemClassForQuestion(qkind, surveyTheme.itemClass)}>
					<SurveyText
						qid={qid}
						textarea={true}
						isReadOnly={isReadOnly || disableMe}
						label={label}
						theme={surveyTheme}
						description={desc}
						onChange={questionSetCallback}
						answer={answerVal}
						maxLength={q.Settings?.MaxLength}
					/>
				</SurveyContainerItem>
			);
		case SurveyQuestionType.SingleChoice:
			const singleChoiceProps: any = {
				ref: qRef,
				qid: qid,
				debugMode: debugMode,
				allowOtherChoice: false,
				otherAnswer: answerVal,
				isReadOnly: isReadOnly || disableMe,
				label: label,
				theme: surveyTheme,
				data: choicesToItems(q, answer),
				description: desc,
				answer: getAnswerIDs(q, answer),
				allowMultipleChoice: false,
				compact: q.Settings && q.Settings.DisplayCompact
			};
			singleChoiceProps.randomiseChoices = !context.isDesignMode() && q.Settings && q.Settings.RandomiseChoices == true;
			const dataSourceId = q.Settings && q.Settings.ChoiceDataSource;
			if (dataSourceId) {
				let bindings = q.Settings && q.Settings.ChoiceParameterBindings;
				if (bindings) {
					singleChoiceProps.choiceParameterBindings = bindings;
				} else {
					singleChoiceProps.choiceParameterBindings = {};
				}
				singleChoiceProps.dataSourceId = dataSourceId;
				singleChoiceProps.context = context;
				singleChoiceProps.autoSelectIfOneItem = q.Settings && q.Settings.AutoSelectIfOnlyOneChoice;
				singleChoiceProps.noChoicesMessage = q.Settings && q.Settings.NoChoicesMessage;
			}

			return (
				<SurveyContainerItem ref={qRef} itemClass={surveyTheme.getItemClassForQuestion(qkind, surveyTheme.itemClass)}>
					<RenderChoice
						inner={singleChoiceProps}
						questionSetCallback={questionSetCallback}
						choiceSetCallback={choiceSetCallback}
					/>
				</SurveyContainerItem>
			);
		case SurveyQuestionType.SingleChoiceWithOtherEntry:
			return (
				<SurveyContainerItem ref={qRef} itemClass={surveyTheme.getItemClassForQuestion(qkind, surveyTheme.itemClass)}>
					{(() => {
						const props: any = {
							ref: qRef,
							qid: qid,
							debugMode: debugMode,
							allowOtherChoice: true,
							otherAnswer: answerVal,
							isReadOnly: isReadOnly || disableMe,
							label: label,
							theme: surveyTheme,
							data: choicesToItems(q, answer),
							description: desc,
							onChange: questionSetCallback,
							answer: getAnswerIDs(q, answer),
							allowMultipleChoice: false,
							compact: q.Settings && q.Settings.DisplayCompact
						};
						props.randomiseChoices = !context.isDesignMode() && q.Settings && q.Settings.RandomiseChoices;
						const dataSourceId = q.Settings && q.Settings.ChoiceDataSource;
						if (dataSourceId) {
							let bindings = q.Settings && q.Settings.ChoiceParameterBindings;
							if (bindings) {
								props.choiceParameterBindings = bindings;
							} else {
								props.choiceParameterBindings = {};
							}
							props.dataSourceId = dataSourceId;
							props.context = context;
							props.autoSelectIfOneItem = q.Settings && q.Settings.AutoSelectIfOnlyOneChoice;
							props.noChoicesMessage = q.Settings && q.Settings.NoChoicesMessage;
						}
						return (
							<RenderChoice inner={props} questionSetCallback={questionSetCallback} choiceSetCallback={choiceSetCallback} />
						);
					})()}
				</SurveyContainerItem>
			);
		case SurveyQuestionType.MultipleChoice:
			return (
				<SurveyContainerItem ref={qRef} itemClass={surveyTheme.getItemClassForQuestion(qkind, surveyTheme.itemClass)}>
					{(() => {
						const props: any = {
							qid: qid,
							debugMode: debugMode,
							allowOtherChoice: false,
							otherAnswer: answerVal,
							isReadOnly: isReadOnly || disableMe,
							label: label,
							theme: surveyTheme,
							data: choicesToItems(q, answer),
							description: desc,
							onChange: questionSetCallback,
							answer: getAnswerIDs(q, answer),
							allowMultipleChoice: true,
							compact: q.Settings && q.Settings.DisplayCompact,
							flyoutUp: q.Settings && q.Settings.FlyoutUp
						};
						props.randomiseChoices = !context.isDesignMode() && q.Settings && q.Settings.RandomiseChoices;
						const dataSourceId = q.Settings && q.Settings.ChoiceDataSource;
						if (dataSourceId) {
							let bindings = q.Settings && q.Settings.ChoiceParameterBindings;
							if (bindings) {
								props.choiceParameterBindings = bindings;
							} else {
								props.choiceParameterBindings = {};
							}
							props.dataSourceId = dataSourceId;
							props.context = context;
							props.autoSelectIfOneItem = q.Settings && q.Settings.AutoSelectIfOnlyOneChoice;
							props.noChoicesMessage = q.Settings && q.Settings.NoChoicesMessage;
						}
						return (
							<RenderChoice inner={props} questionSetCallback={questionSetCallback} choiceSetCallback={choiceSetCallback} />
						);
					})()}
				</SurveyContainerItem>
			);
		case SurveyQuestionType.MultipleChoiceWithOtherEntry:
			return (
				<SurveyContainerItem ref={qRef} itemClass={surveyTheme.getItemClassForQuestion(qkind, surveyTheme.itemClass)}>
					{(() => {
						const props: any = {
							qid: qid,
							debugMode: debugMode,
							allowOtherChoice: true,
							otherAnswer: answerVal,
							isReadOnly: isReadOnly || disableMe,
							label: label,
							theme: surveyTheme,
							data: choicesToItems(q, answer),
							description: desc,
							onChange: questionSetCallback,
							answer: getAnswerIDs(q, answer),
							allowMultipleChoice: true,
							compact: q.Settings && q.Settings.DisplayCompact,
							flyoutUp: q.Settings && q.Settings.FlyoutUp
						};
						props.randomiseChoices = !context.isDesignMode() && q.Settings && q.Settings.RandomiseChoices;
						const dataSourceId = q.Settings && q.Settings.ChoiceDataSource;
						if (dataSourceId) {
							let bindings = q.Settings && q.Settings.ChoiceParameterBindings;
							if (bindings) {
								props.choiceParameterBindings = bindings;
							} else {
								props.choiceParameterBindings = {};
							}
							props.dataSourceId = dataSourceId;
							props.context = context;
							props.autoSelectIfOneItem = q.Settings && q.Settings.AutoSelectIfOnlyOneChoice;
							props.noChoicesMessage = q.Settings && q.Settings.NoChoicesMessage;
						}
						return (
							<RenderChoice inner={props} questionSetCallback={questionSetCallback} choiceSetCallback={choiceSetCallback} />
						);
					})()}
				</SurveyContainerItem>
			);
		case SurveyQuestionType.RankedChoice:
			return (
				<SurveyContainerItem ref={qRef} itemClass={surveyTheme.getItemClassForQuestion(qkind, surveyTheme.itemClass)}>
					<SurveyRankedChoice
						qid={qid}
						debugMode={debugMode}
						isReadOnly={isReadOnly || disableMe}
						label={label}
						theme={surveyTheme}
						description={desc}
						onChange={questionSetCallback}
						choices={choicesToItems(q, answer)}
						answer={answerVal}
					/>
				</SurveyContainerItem>
			);
		case SurveyQuestionType.RankTopChoices:
			return (
				<SurveyContainerItem ref={qRef} itemClass={surveyTheme.getItemClassForQuestion(qkind, surveyTheme.itemClass)}>
					<SurveyRankTopChoices
						qid={qid}
						debugMode={debugMode}
						isReadOnly={isReadOnly || disableMe}
						label={label}
						theme={surveyTheme}
						description={desc}
						onChange={questionSetCallback}
						choices={choicesToItems(q, answer)}
						answer={answerVal}
						minChoicesToRank={q.Settings?.MinChoicesToRank}
					/>
				</SurveyContainerItem>
			);
		case SurveyQuestionType.Occupation:
			return (
				<SurveyContainerItem ref={qRef} itemClass={surveyTheme.getItemClassForQuestion(qkind, surveyTheme.itemClass)}>
					<SurveyText
						qid={qid}
						textarea={false}
						isEmail={false}
						isReadOnly={isReadOnly || disableMe}
						label={label}
						theme={surveyTheme}
						description={desc}
						onChange={questionSetCallback}
						answer={answerVal}
						maxLength={q.Settings?.MaxLength}
					/>
				</SurveyContainerItem>
			);
		case SurveyQuestionType.TodoFollowUp:
			return (
				<SurveyContainerItem ref={qRef} itemClass={surveyTheme.getItemClassForQuestion(qkind, surveyTheme.itemClass)}>
					{(() => {
						const showDatePicker = q.Settings && q.Settings.ShowDatePicker;
						return (
							<SurveyTodoFollowUp
								qid={qid}
								isReadOnly={isReadOnly || disableMe}
								label={label}
								theme={surveyTheme}
								description={desc}
								onChange={questionSetCallback}
								answer={answerVal}
								showFollowUpDatePicker={showDatePicker}
								followUpDateFormat={format}
								maxLength={q.Settings?.MaxLength}
							/>
						);
					})()}
				</SurveyContainerItem>
			);
		case SurveyQuestionType.AddPersonToEvent:
			return (
				<SurveyContainerItem ref={qRef} itemClass={surveyTheme.getItemClassForQuestion(qkind, surveyTheme.itemClass)}>
					<SurveyAddToEvent
						qid={qid}
						isReadOnly={isReadOnly || disableMe}
						label={label}
						context={context}
						theme={surveyTheme}
						onChange={questionSetCallback}
						description={desc}
						answer={answerVal}
					/>
				</SurveyContainerItem>
			);
		case SurveyQuestionType.Petition:
			return (
				<SurveyContainerItem ref={qRef} itemClass={surveyTheme.getItemClassForQuestion(qkind, surveyTheme.itemClass)}>
					<SurveyPetition qid={qid} label={label} text={getFirstChoiceText(q)} theme={surveyTheme} />
				</SurveyContainerItem>
			);
		case SurveyQuestionType.Persuasion:
			return (
				<SurveyContainerItem ref={qRef} itemClass={surveyTheme.getItemClassForQuestion(qkind, surveyTheme.itemClass)}>
					<SurveyPersuasion
						qid={qid}
						isReadOnly={isReadOnly || disableMe}
						label={label}
						context={context}
						theme={surveyTheme}
						onChange={questionSetCallback}
						description={desc}
						answer={answerVal}
					/>
				</SurveyContainerItem>
			);
		case SurveyQuestionType.AddIssue:
			return (
				<SurveyContainerItem ref={qRef} itemClass={surveyTheme.getItemClassForQuestion(qkind, surveyTheme.itemClass)}>
					<SurveyAddIssue
						qid={qid}
						isReadOnly={isReadOnly || disableMe}
						label={label}
						context={context}
						theme={surveyTheme}
						onChange={questionSetCallback}
						description={desc}
						answer={answerVal}
						flyoutUp={q.Settings && q.Settings.FlyoutUp}
					/>
				</SurveyContainerItem>
			);
		case SurveyQuestionType.AddActivity:
			return (
				<SurveyContainerItem ref={qRef} itemClass={surveyTheme.getItemClassForQuestion(qkind, surveyTheme.itemClass)}>
					<SurveyAddActivity
						qid={qid}
						isReadOnly={isReadOnly || disableMe}
						label={label}
						context={context}
						theme={surveyTheme}
						onChange={questionSetCallback}
						description={desc}
						answer={answerVal}
						flyoutUp={q.Settings && q.Settings.FlyoutUp}
					/>
				</SurveyContainerItem>
			);
		case SurveyQuestionType.AddElectorate:
			return (
				<SurveyContainerItem ref={qRef} itemClass={surveyTheme.getItemClassForQuestion(qkind, surveyTheme.itemClass)}>
					<SurveyAddElectorate
						qid={qid}
						isReadOnly={isReadOnly || disableMe}
						label={label}
						context={context}
						theme={surveyTheme}
						onChange={questionSetCallback}
						description={desc}
						answer={answerVal}
						flyoutUp={q.Settings && q.Settings.FlyoutUp}
					/>
				</SurveyContainerItem>
			);
		case SurveyQuestionType.Plugin:
			return (
				<SurveyContainerItem itemClass={surveyTheme.getItemClassForQuestion(qkind, surveyTheme.itemClass)}>
					{(() => {
						let pluginId = q.Settings && q.Settings.PluginID;
						let pluginConf = q.Settings && q.Settings.PluginConfiguration;
						if (pluginId && pluginConf) {
							const plugin = getPlugin(pluginId);
							if (plugin) {
								const oConf = pluginConf.toJS();
								return plugin.factory({
									qid: qid,
									questionRef: qRef,
									isReadOnly: isReadOnly || disableMe,
									label: q.Question,
									context: context,
									theme: surveyTheme,
									onChange: questionSetCallback,
									answer: answerVal,
									pluginConfig: oConf
								});
							} else {
								return (
									<div className={surveyTheme.getContainerClasses(SurveyContainerStyle.Error)}>
										<strong>Plugin ({pluginId}) is not registered for this question type</strong>
									</div>
								);
							}
						} else {
							return (
								<div className={surveyTheme.getContainerClasses(SurveyContainerStyle.Error)}>
									<strong>Missing required plugin id and/or configuration for this question</strong>
								</div>
							);
						}
					})()}
				</SurveyContainerItem>
			);

		case SurveyQuestionType.EventConfirmation:
			return (
				<SurveyContainerItem ref={qRef} itemClass={surveyTheme.getItemClassForQuestion(qkind, surveyTheme.itemClass)}>
					<SurveyQuestionDisplay label={label} theme={surveyTheme} description={desc}>
						<SurveyEventConfirmation
							qid={qid}
							isReadOnly={isReadOnly || disableMe}
							context={context}
							label={label}
							theme={surveyTheme}
							description={desc}
							onChange={questionSetCallback}
						/>
					</SurveyQuestionDisplay>
				</SurveyContainerItem>
			);
		default:
			return (
				<SurveyContainerItem
					ref={qRef}
					itemClass={surveyTheme.getItemClassForQuestion(UNSUPPORTED_QUESTION_KIND, surveyTheme.itemClass)}
				>
					<strong>
						{Icons.EXCLAMATION} Unknown or unsupported question type ({qkind}) for question {label}
					</strong>
				</SurveyContainerItem>
			);
	}
});

function Survey_GetOrderedQuestions(survey: ISurveyImmutable): ISurveyQuestionImmutable[] {
	return survey.Questions.sort((a, b) => {
		const ao = a.Qorder;
		const bo = b.Qorder;
		if (!ao && !bo) return 0;
		if (!ao) return -1;
		if (!bo) return 1;

		if (ao < bo) {
			return -1;
		} else if (ao > bo) {
			return 1;
		} else {
			return 0;
		}
	}) as ISurveyQuestionImmutable[]; // Settings is not exposed in the type for ISurveyImmutable... todo fix this!
}

function isSurveyDraft(response: any): response is ISurveyDraftImmutable {
	return !!response.People || !!response.ListId;
}

/**
 * The SurveyContainer represents the root component of a survey form.
 */
export class SurveyContainer extends React.Component<ISurveyContainerProps, ISurveyContainerState> {
	private questionHandlers: QuestionEventHandlerSet;
	private choiceFetcher: RemoteChoiceFetcher;

	constructor(props: ISurveyContainerProps) {
		super(props);
		this.questionHandlers = new QuestionEventHandlerSet();
		this.choiceFetcher = new RemoteChoiceFetcher();
		this.state = {
			isReady: false,
			validationErrors: [],
			isSubmitting: false,
			openErrorsModal: true
		};
	}
	componentDidMount() {
		if (!!((window as any).supportsMergeTags)) {
			callApi<{ Name: string, Value: string }[]>(this.props.token, "/SurveyMergeFields/EffectiveValues", "GET").then(res => {
				const mt: Record<string, string> = {};
				for (const nvp of res) {
					mt[nvp.Name] = nvp.Value;
				}
				this.setState({
					mergeTagValues: mt,
					isReady: true
				});
			});
		} else {
			this.setState({
				mergeTagValues: {},
				isReady: true
			})
		}
	}
	private getSelectedElectors(): number[] {
		if (this.props.customElectorSelector) {
			return this.props.customElectorSelector();
		}
		if (this.props.draft && isSurveyDraft(this.props.draft)) {
			const selected = this.props.draft.People;
			if (selected) {
				return selected;
			}
		}
		return [] as number[];
	}
	private getEvents(): Promise<ElectracAPI.UpcomingEventModel[]> {
		return fetchEvents(this.props.token);
	}
	private getShiftsForEvent(id: React.ReactText): Promise<ElectracAPI.ShiftDisplayModel[]> {
		return fetchEventShifts(this.props.token, id);
	}
	private getElectorates(searchTerm: string): Promise<ElectracAPI.IdNamePair[]> {
		return fetchElectoratesAutoComplete(this.props.token, searchTerm);
	}
	private getActivities(searchTerm: string): Promise<ElectracAPI.IMvcSelectItem[]> {
		return fetchActivitiesAutoComplete(this.props.token, searchTerm);
	}
	private getIssues(searchTerm: string): Promise<ElectracAPI.IMvcSelectItem[]> {
		return fetchIssuesAutoComplete(this.props.token, searchTerm);
	}
	private getChoicesRemotely(
		dataSourceId: number,
		choiceParameterBindings: any,
		latestAnswers?: any
	): Promise<ElectracAPI.IMvcSelectItem[] | Error> {
		const { token, survey, draft } = this.props;
		return this.choiceFetcher.getChoices(token, survey, draft, dataSourceId, choiceParameterBindings, latestAnswers);
	}
	private registerQuestionListener(questionId: number, handler: QuestionEventHandler): boolean {
		return this.questionHandlers.register(questionId, handler);
	}
	private unregisterQuestionListener(questionId: number, handler: QuestionEventHandler): boolean {
		return this.questionHandlers.unregister(questionId, handler);
	}
	static childContextTypes = DND_CHILD_CONTEXT_VALIDATION_MAP;
	getChildContext(): IDndContext {
		return buildDndChildContext();
	}
	getContainerContext(): ISurveyContainerContext {
		return {
			getPersuasionModel: () => this.props.persuasionModel,
			getSelectedElectors: this.getSelectedElectors.bind(this),
			getEvents: this.getEvents.bind(this),
			getShiftsForEvent: this.getShiftsForEvent.bind(this),
			isOnline: () => this.props.isOnline,
			getIssues: this.getIssues.bind(this),
			getActivities: this.getActivities.bind(this),
			getElectorates: this.getElectorates.bind(this),
			getChoicesRemotely: this.getChoicesRemotely.bind(this),
			supportsCustomIntention: () => this.props.enableCustomVoteIntention === true,
			isDesignMode: () => false,
			registerQuestionListener: this.registerQuestionListener.bind(this),
			unregisterQuestionListener: this.unregisterQuestionListener.bind(this)
		};
	}
	private isMultipleChoice(q: Readonly<ElectracAPI.QuestionModel>): boolean {
		const qtype = q.AnswerTypeID;
		return (
			qtype == SurveyQuestionType.MultipleChoice ||
			qtype == SurveyQuestionType.MultipleChoiceWithOtherEntry ||
			qtype == SurveyQuestionType.SingleChoice ||
			qtype == SurveyQuestionType.SingleChoiceWithOtherEntry
		);
	}
	private hasProvidedAnswer(q: Readonly<ElectracAPI.QuestionModel>, answer: ISurveyAnswerImmutable): boolean {
		if (q.Required === true) {
			const isMulti = this.isMultipleChoice(q);
			if (answer == null) {
				return false;
			} else if (isMulti && answer.Answer == null && (answer.Choices || []).length == 0) {
				return false;
			} else if (!isMulti && answer.Answer == null) {
				return false;
			}
			return true;
		}
		return true;
	}
	public clearValidationState() {
		const newState = {
			...this.state,
			validationErrors: []
		};
		this.setState(newState);
	}
	public validateSync(): IFieldValidationError[] {
		const { survey, draft, mode, validateAlways } = this.props;
		if (!draft) return [{ questionId: -1, message: 'Please complete a survey' }];

		const errors: IFieldValidationError[] = [];
		const currentAnswers = draft.Answers || {};

		let isRequiredActive = !!validateAlways;
		if (!isRequiredActive && isSurveyDraft(draft)) {
			if (draft.Status == null && mode == SurveyContainerMode.Walk) {
				errors.push({ questionId: -1, message: 'Please set the survey status before completing' });
			}

			isRequiredActive = draft.Status == SurveyStatus.Home || mode == SurveyContainerMode.Manual;
		}

		survey.Questions.forEach((q, k) => {
			if (!q) return;
			const qKey: any = q.SurveyQuestionID + '';
			const answer = currentAnswers[qKey];
			const question = q.Question;

			//Invisible questions that are required don't require validation
			let canSkipRequired = false;

			if (q.Condition && q.Condition.Display) {
				const evaluator = EvaluateDisplayCondition(q.Condition && q.Condition.Display, currentAnswers);
				if (!evaluator.evaluate()) {
					//Not visible
					canSkipRequired = true;
				}
			}

			if (!canSkipRequired && isRequiredActive && q && !this.hasProvidedAnswer(q, answer)) {
				errors.push({ questionId: q.SurveyQuestionID, message: `Answer required: ${question}` });
			} else if (answer != null && answer.Answer != null && q) {
				const answerVal = answer.Answer;
				const qt = q.AnswerTypeID;
				// empty string should not be checked
				if (qt == SurveyQuestionType.EmailAddress && answerVal && answerVal.length && !isEmail(answerVal)) {
					errors.push({ questionId: q.SurveyQuestionID, message: `Not a valid email address: ${question}` });
				}
				if (qt == SurveyQuestionType.MobilePhone && answerVal && answerVal.length) {
					let bValidPhone = true;
					try {
						//Normalise before validating
						const normPhone = answerVal.replace(/ /g, '');
						if (normPhone != '') {
							const locale = getLocale() as 'en-AU' | 'en-NZ';
							bValidPhone = isMobilePhone(normPhone, locale);
						}
					} catch (e) {
						bValidPhone = false;
					}
					if (!bValidPhone && q) {
						errors.push({ questionId: q.SurveyQuestionID, message: `Not a valid mobile phone: ${question}` });
					}
				}
			}
		});

		const newState = {
			...this.state,
			validationErrors: errors
		};
		this.setState(newState);

		return errors;
	}
	private onFollowUpChanged = (notes: string) => {
		if (this.props.onFollowUpSet) {
			this.props.onFollowUpSet(notes);
		}
	};
	private onSelectedStatusChanged = (items: any[]) => {
		if (this.props.onStatusSet) {
			this.props.onStatusSet(items[0]);
		}
	};
	private onAddressClassificationsChanged = (items: any[]) => {
		if (this.props.onAddressClassificationsChanged) {
			this.props.onAddressClassificationsChanged(items);
		}
	};
	onSelectedElectorsChanged = (ids: number[], voted: number[]) => {
		if (this.props.onSelectedElectorsChanged) {
			//Invalidate any add to event choices
			const evtQuestionIds = this.getEventQuestionIds();
			this.props.onSelectedElectorsChanged(ids, voted, evtQuestionIds);
		}
	};
	public clearAnswers = (ids: number[]) => {
		if (ids.length == 0) {
			return;
		}
		const questionsToClear = this.props.survey.Questions.filter(q => ids.indexOf(q.SurveyQuestionID) >= 0);
		questionsToClear.forEach(q => {
			this.onQuestionSet(q!.SurveyQuestionID, null);
		});
	};
	public getEventQuestionIds(): number[] {
		const evtQuestions = this.props.survey.Questions.filter(q => q.AnswerTypeID == SurveyQuestionType.AddPersonToEvent).map(
			q => q.SurveyQuestionID
		);
		return evtQuestions;
	}
	private onQuestionSet = (questionId: number, e: any) => {
		if (this.props.onQuestionSet) {
			this.props.onQuestionSet(questionId, e);
		}
		this.questionHandlers.trigger(questionId, { answer: e });
	};
	private onChoiceQuestionSet(questionId: number, choices: any[], otherAnswer: any) {
		if (this.props.onChoiceQuestionSet) {
			this.props.onChoiceQuestionSet(questionId, choices, otherAnswer);
		}
		this.questionHandlers.trigger(questionId, { choices: choices, answer: otherAnswer });
	}
	private onCompleteSurvey = () => {
		if (this.validateSync()) {
			this.setState({ isSubmitting: true }, () => {
				if (this.props.onComplete) {
					this.props.onComplete((success: boolean) => {
						this.setState({ isSubmitting: false });
					});
				} else this.setState({ isSubmitting: false });
			});
		}
	};
	private onFocusField = (refId: number) => {
		const comp: any = this.questionRefs[refId];
		if (comp != null) {
			comp.focus();
		} else {
			logWarn(`Tried to scroll to component ref ${refId} that does not exist`);
		}
	};
	private getQuestionsToRender(): ISurveyQuestionImmutable[] {
		const { survey } = this.props;
		return Survey_GetOrderedQuestions(survey);
	}

	private questionRefs: { [q: number]: any } = {};

	private setQuestionRef = (questionId: number, ref: any) => {
		this.questionRefs[questionId] = ref;
	};
	private setFooterRef = (ref: any) => {
		this.questionRefs[-1] = ref;
	};

	public render = () => {
		const {
			electors,
			survey,
			address,
			draft,
			voteModel,
			mode,
			electorEntryFormatter,
			dateFormat,
			theme,
			debugMode,
			voted,
			hideSubmit,
			submitLabel
		} = this.props;
		const { validationErrors, openErrorsModal, isReady } = this.state;
		const format = dateFormat || SURVEY_DEFAULT_DATE_FORMAT;

		if (!isReady) {
			return <></>;
		}

		//Default to bootstrap
		const surveyTheme: ISurveyContainerTheme = theme || new BootstrapSurveyTheme();

		let validationSummary = (
			<div className={surveyTheme.getContainerClasses(SurveyContainerStyle.Error)}>
				<strong>
					{Icons.EXCLAMATION} Errors were encountered in the survey.Click/tap on each error to go to the affected field
				</strong>
				<ul>
					{validationErrors &&
						validationErrors.map(e => {
							return (
								<li key={`Validate_${e.questionId}`}>
									<span style={{ textDecoration: 'underline' }} onClick={() => this.onFocusField(e.questionId)}>
										{e.message}
									</span>
								</li>
							);
						})}
				</ul>
			</div>
		);
		let validationRender: JSX.Element | null = null;
		if (validationErrors && validationErrors.length > 0) {
			if (this.props.showSurveyErrorsAsModal) {
				validationRender = (
					<div>
						{validationSummary}
						<ReactModal
							isOpen={openErrorsModal}
							shouldCloseOnOverlayClick={true}
							contentLabel="The following fields are required"
							style={{
								content: {
									top: '50%',
									left: '50%',
									right: 'auto',
									bottom: 'auto',
									marginRight: '-50%',
									transform: 'translate(-50%, -50%)'
								}
							}}
						>
							{validationSummary}
							<br />
							<div style={{ textAlign: 'center' }}>
								{' '}
								<a
									className={surveyTheme.getButtonClass(SurveyButtonStyle.Info)}
									onClick={() => {
										this.setState({ openErrorsModal: false });
									}}
								>
									Ok
								</a>
							</div>
						</ReactModal>
					</div>
				);
			} else {
				validationRender = validationSummary;
			}
		}

		const isReadOnly = this.props.isReadOnly === true || this.state.isSubmitting === true;
		let currentElectors: number[] = [];
		let currentAnswers = {};
		let currentStatus: undefined | string;
		let currentAddrClassifications: number[] = [];
		let followUp: string | undefined = undefined;
		if (draft != null) {
			if (draft.Answers) {
				currentAnswers = draft.Answers;
			} else {
				console.log('Draft answers is undefined');
			}
			if (isSurveyDraft(draft)) {
				followUp = draft.FollowUp;
				if (mode != SurveyContainerMode.Manual) {
					currentElectors = draft.People;
					currentStatus = draft.Status;
					const classifications = draft.AddressClassifications;
					if (classifications) {
						currentAddrClassifications = [...classifications];
					}
				}
			}
		}
		const surveyStatusValues: ISurveyChoiceItem[] = [
			{ label: SurveyStatus.Home, value: SurveyStatus.Home, checked: currentStatus == SurveyStatus.Home },
			{ label: SurveyStatus.NotHome, value: SurveyStatus.NotHome, checked: currentStatus == SurveyStatus.NotHome },
			{ label: SurveyStatus.HomeFollowUp, value: SurveyStatus.HomeFollowUp, checked: currentStatus == SurveyStatus.HomeFollowUp },
			{ label: SurveyStatus.NotKnocked, value: SurveyStatus.NotKnocked, checked: currentStatus == SurveyStatus.NotKnocked },
			{ label: SurveyStatus.NotAtAddress, value: SurveyStatus.NotAtAddress, checked: currentStatus == SurveyStatus.NotAtAddress }
		];
		const heading =
			mode != SurveyContainerMode.Manual && address ? (
				<strong>
					{Icons.HOME} {`${address.street} ${address.suburb}`}
				</strong>
			) : null;
		// const electorsLabel = <span>{Icons.USERS} Who did you speak to?</span>;
		let submit: JSX.Element | null = null;
		if (!isReadOnly && !!hideSubmit == false) {
			const disabled = mode == SurveyContainerMode.Walk && currentStatus == null;
			submit = (
				<button
					type="button"
					className={`${surveyTheme.getButtonClass(SurveyButtonStyle.Primary)} survey-submit`}
					disabled={disabled}
					onClick={this.onCompleteSurvey}
				>
					{submitLabel ? submitLabel : <>{Icons.TICK} Complete Survey</>}
				</button>
			);
		}
		const sortedQuestions = this.getQuestionsToRender();
		let requiredMarker = '';
		let requiredMsg: JSX.Element | null = null;
		if (mode == SurveyContainerMode.Manual || (currentStatus == SurveyStatus.Home && mode != SurveyContainerMode.CompletedWalk)) {
			requiredMarker = DEFAULT_REQUIRED_MARKER;
			requiredMsg = (
				<div style={{ marginBottom: '15px' }}>
					<strong className="required-marker">
						{Icons.INFO} Required questions are denoted with {requiredMarker}
					</strong>
				</div>
			);
		}
		let selectedElectors: JSX.Element | null = null;
		const activeElectionDate = survey.ActiveElectionDate;
		//Selected electors not applicable in manual mode
		if (mode != SurveyContainerMode.Manual && electors) {
			selectedElectors = (
				<ElectorSelection
					electors={electors}
					activeElectionDate={activeElectionDate}
					voted={voted}
					isReadOnly={isReadOnly}
					currentElectors={currentElectors}
					electorEntryFormatter={electorEntryFormatter}
					onChange={this.onSelectedElectorsChanged}
				/>
			);
		}
		let surveyStatusHead: JSX.Element | null = null;
		let surveyStatusFoot: JSX.Element | null = null;
		let addrClassHead: JSX.Element | null = null;
		let addrClassFoot: JSX.Element | null = null;
		const LBL_CLASSIFY_ADDRESS = 'Classify this address as';
		const LBL_SURVEY_STATUS = 'Survey Status';
		const LBL_FOLLOW_UP_NOTES = 'Follow Up Notes';
		const FOLLOW_UP_PLACEHOLDER =
			'This option means you have explicitly committed to following up with the elector. It is important that campaigns follow up when they say they will. Please enter a clear description of how and why this call should be followed up.';
		//Survey status not applicable in manual mode
		if (mode != SurveyContainerMode.Manual) {
			surveyStatusHead = (
				<div className="well">
					<SurveyChoice
						theme={surveyTheme}
						isReadOnly={isReadOnly}
						allowOtherChoice={false}
						label={LBL_SURVEY_STATUS}
						answer={[currentStatus]}
						data={surveyStatusValues}
						onChange={this.onSelectedStatusChanged}
						allowMultipleChoice={false}
					/>
					{(() => {
						if (currentStatus == SurveyStatus.HomeFollowUp) {
							return (
								<SurveyText
									theme={surveyTheme}
									label={LBL_FOLLOW_UP_NOTES}
									textarea={true}
									maxLength={255}
									isReadOnly={isReadOnly}
									answer={followUp}
									onChange={this.onFollowUpChanged}
									description={FOLLOW_UP_PLACEHOLDER}
									descriptionAsPlaceholder={true}
								/>
							);
						} else {
							return null;
						}
					})()}
					{submit}
				</div>
			);
			const classifications = survey.AddressClassifications;
			let classVals: ISurveyChoiceItem[] = [];
			if (classifications && classifications.length > 0) {
				classVals = classifications.map(i => {
					return {
						value: i.Id,
						label: i.Name,
						checked: false
					};
				});
			}
			if (!isReadOnly && classifications && classifications.length > 0) {
				addrClassHead = (
					<div className="well">
						<SurveyChoice
							theme={surveyTheme}
							isReadOnly={isReadOnly}
							allowMultipleChoice={true}
							allowOtherChoice={false}
							label={LBL_CLASSIFY_ADDRESS}
							answer={currentAddrClassifications}
							data={classVals}
							onChange={this.onAddressClassificationsChanged}
						/>
					</div>
				);
			}

			//No need to duplicate this over when looking at a completed survey
			if (mode != SurveyContainerMode.CompletedWalk) {
				surveyStatusFoot = (
					<div className="well">
						<SurveyChoice
							ref={this.setFooterRef}
							theme={surveyTheme}
							isReadOnly={isReadOnly}
							allowOtherChoice={false}
							label={LBL_SURVEY_STATUS}
							answer={[currentStatus]}
							data={surveyStatusValues}
							onChange={this.onSelectedStatusChanged}
							allowMultipleChoice={false}
						/>
						{(() => {
							if (currentStatus == SurveyStatus.HomeFollowUp) {
								return (
									<SurveyText
										theme={surveyTheme}
										label={LBL_FOLLOW_UP_NOTES}
										textarea={true}
										maxLength={255}
										isReadOnly={isReadOnly}
										answer={followUp}
										onChange={this.onFollowUpChanged}
										description={FOLLOW_UP_PLACEHOLDER}
										descriptionAsPlaceholder={true}
									/>
								);
							} else {
								return null;
							}
						})()}
					</div>
				);
				if (!isReadOnly && classifications && classifications.length > 0) {
					addrClassFoot = (
						<div className="well">
							<SurveyChoice
								theme={surveyTheme}
								isReadOnly={isReadOnly}
								allowMultipleChoice={true}
								allowOtherChoice={false}
								label={LBL_CLASSIFY_ADDRESS}
								answer={currentAddrClassifications}
								data={classVals}
								onChange={this.onAddressClassificationsChanged}
							/>
						</div>
					);
				}
			}
		}
		const context = this.getContainerContext();
		const surveyTitle =
			mode != SurveyContainerMode.Manual ? (
				<div className={surveyTheme.getContainerClasses(SurveyContainerStyle.Info)}>
					<strong>
						{Icons.SURVEY} Using Survey: {survey.Title}
					</strong>
				</div>
			) : null;
		return (
			<Panel className={surveyTheme.getContainerClasses(SurveyContainerStyle.SurveyContainer)}>
				<Panel.Heading>{heading}</Panel.Heading>
				{this.props.debugMode === true && (
					<div className={surveyTheme.getContainerClasses(SurveyContainerStyle.Error)}>
						<strong>NOTICE: This survey form is in debug mode</strong>
					</div>
				)}

				{surveyTitle}
				{selectedElectors}
				{addrClassHead}
				{surveyStatusHead}
				{requiredMsg}
				<SurveyContainerQuestionsRoot itemClass={surveyTheme.containerClass}>
					{sortedQuestions.map(q => {
						if (!q) return null;
						let disableMe = false;
						const bDisableInsteadOfHide = q.Condition && q.Condition.DisableIfConditionNotMet;
						const dispCondition = q.Condition && q.Condition.Display;
						if (dispCondition) {
							const evaluator = EvaluateDisplayCondition(dispCondition, currentAnswers);
							if (!evaluator.evaluate()) {
								if (!bDisableInsteadOfHide) {
									const writer = new ConditionWriter();
									if (debugMode === true) {
										evaluator.dump(writer);
										return (
											<SurveyContainerItem
												itemClass={surveyTheme.getItemClassForQuestion(DEBUG_QUESTION_TYPE, surveyTheme.itemClass)}
												key={`debug-header-${q.SurveyQuestionID}`}
											>
												<strong>DEBUG: Question hidden as its display requirements not met</strong>
												<p>
													[{q.SurveyQuestionID} - {q.Question}]
												</p>
												<p>Condition evaluated was: </p>
												<pre>{writer.getString()}</pre>
											</SurveyContainerItem>
										);
									} else {
										//TODO: In debug mode, we should show the successful evaluation too
										return null;
									}
								} else {
									disableMe = true;
								}
							}
						}
						return (
							<RenderQuestion
								q={q}
								ref={(r: React.Ref<any>) => this.setQuestionRef(q.SurveyQuestionID, r)}
								key={q.SurveyQuestionID}
								disabled={disableMe}
								surveyTheme={surveyTheme}
								voteModel={voteModel}
								currentAnswers={currentAnswers}
								onQuestionSet={this.onQuestionSet.bind(this)}
								onChoiceQuestionSet={this.onChoiceQuestionSet.bind(this)}
								context={context}
								requiredMarker={requiredMarker}
								isReadOnly={isReadOnly}
								debugMode={debugMode}
								format={format}
								mergeTagValues={this.state.mergeTagValues ?? {}}
							/>
						);
					})}
				</SurveyContainerQuestionsRoot>
				{addrClassFoot}
				{surveyStatusFoot}
				{validationRender}
				{submit}
			</Panel>
		);
	};
}
