import * as React from 'react';


type JSXElementOrString = JSX.Element | string;

export interface IIFProps {
    children: JSXElementOrString | JSXElementOrString[]
    show: boolean,
    else?: JSX.Element[] | JSX.Element | string
}

/**
 * Show a single child if the children is a single element
 * @param props
 */
export const Iif = (props: IIFProps) => {
    if (props.show) return <>{props.children}</>;
    if (props.else) return <>{props.else}</>;
    return <noscript />;
}