import { useState, useEffect } from "react";
import * as React from "react";



// flag dictionary
const flags: { [key:string]: string[] } = {};


async function ensureFeatureFlags(category: string): Promise<string[]> {
    // call an API to obtain feature flags
    if (!flags[category]) {
        //callApi
        try {
            const data = await $.get('/api/features?category=' + category);
            flags[category] = typeof(data) == 'string' ? JSON.parse(data) : data;
        } catch {
        }
    }

    return flags[category] || [];
}

export function featureEnabled(category: string, name: string): boolean {
    const f = flags[category];
    return f && f.indexOf && f.indexOf(name) >= 0;
}

/** Feature flag component. We can either use suspense or re-render */
export function Feature({ name, children, category }: { name: string, children: JSX.Element | JSX.Element[], category: string } ) {
    const [ ready, setIsReady ] = useState(!!flags[category]);
    
    useEffect(() => {
        ensureFeatureFlags(category).then(() => {
            setIsReady(true);
        })
    }, []);



    if (!ready || !featureEnabled(category, name)) return null;
    
    return <>{children}</>;
}