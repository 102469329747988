import * as ElectracAPI from "@electrac/model";


const util = require("util");

export enum LoginMode {
    Regular,
    Volunteering,
    SingleUsePIN,
    VolunteeringPin
}

/**
 * Function signature of a React stateless component
 */
export type ReactStatelessComponentFunc<TProps> = (props: TProps) => JSX.Element;

/**
 * Function signature of a geolocation request
 */
export type GeolocationRequestFunc = (success: PositionCallback, failure?: PositionErrorCallback) => void;

export interface ErrorLinkDef {
    id: string;
    route: string;
    description: string;
}

// CCError re-defined as follows since TypeScript 2.1 broke custom errors
// https://gist.github.com/justmoon/15511f92e5216fa2624b#gistcomment-1928632

export interface CCError extends Error {
    readonly name: string;
    readonly message: string;
    readonly stack: string;
    errorLinks: ErrorLinkDef[];
    showStackInErrorComponent: boolean;
}

export interface CCErrorConstructor {
    new (message: string, includeStack?: boolean, linkDefs?: ErrorLinkDef[]): CCError;
    readonly prototype: CCError;
}

export const CCError: CCErrorConstructor = <any>class CCError {
    public errorLinks: ErrorLinkDef[];
    public showStackInErrorComponent: boolean;
    public constructor(message: string, includeStack: boolean = false, linkDefs?: ErrorLinkDef[]) {
        Object.defineProperty(this, 'name', {
            get: () => (this.constructor as any).name,
        });
        Object.defineProperty(this, 'message', {
            get: () => message,
        });
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor);
        }
        this.showStackInErrorComponent = includeStack;
        this.errorLinks = [];
        if (linkDefs) {
            for (const ld of linkDefs) {
                this.errorLinks.push(ld);
            }
        }
    }
    
};
util.inherits(CCError, Error);

// contracts.ts
//
// Defines common data contracts and models used throughout the application
//

/**
 * Defines an office
 */
export interface IOffice {
    /**
     * Office ID
     */
    id: number;
    /**
     * The name of the office
     */
    name: string;
}
/**
 * Extended information for a user that is a volunteer
 */
export interface IVolunteerInfo {
    pin: number;
}
/**
 * Extended information for a user that is a regular user
 */
export interface IRegularUserInfo {
    FirstName: string;
    LastName: string;
}
/**
 * Information about a user
 */
export interface IUserInfo {
    id: number;
    username: string;
    volunteer?: IVolunteerInfo;
    details?: IRegularUserInfo;
}

export interface IReduxActionPayloadImmediate<T> {
    type: string;
    payload?: T;
}

export interface IReduxActionPayloadPromised<T> {
    types: string[];
    payload: {
        promise: Promise<T>;
    };
}

/**
 * Alias for a function that initiates a dispatch of a redux action
 */
export type ReduxActionDispatcher = Function;
/**
 * An object whose properties are accessible by string keys
 */
export type StringDictionary = { [key: string]: string };
/**
 * React router parameters
 */
export type ReactRouterParams = StringDictionary|any;


/**
 * Alias for an elector id
 */
export type ElectorIdRef = number;

/**
 * A real world geographic coordinate
 */
export interface IGeographicCoordinate {
    lng: number;
    lat: number;
}

/**
 * A house address
 */
export interface IAddress {
    id: number;
    street: string;
    suburb: string;
    electors: ElectorIdRef[];
    status: string;
    geom?: IGeographicCoordinate;
}


/**
 * A many to many mapping table of address to list
 */
export interface IListRelated {
    id: number;
    listId: number;
    
    /** computed string of id and listId that is unique enforcable */
    combined: string;
}



/**
 * A description of a walk list
 */
export type IListInfo = ElectracAPI.WalkListInfo;

export type IListInfoImmutable = Readonly<IListInfo>;

/**
 * An object wrapper around an array of IListInfo instances
 */
export interface IListSelection {
    lists: IListInfo[];
}
export type IListSelectionImmutable = Readonly<IListSelection>;



/**
 * A walk list
 */
export interface IWalkList extends IListInfo {
    houses: IAddress[];
    lastUpdated: string;
}

export type IWalkListImmutable = Readonly<IWalkList>;

export interface IWalkListWithBoundaries {
    list: IWalkList;
    boundaries: GeoJSON.FeatureCollection<GeoJSON.Polygon>;
}

export type IWalkListWithBoundariesImmutable = Readonly<IWalkList>;

export interface IBoundaryList {
    listId: number;
    boundaries: GeoJSON.FeatureCollection<any>;
}

/**
 * An elector
 */

// export interface IPerson {
//     electorID: number;
//     firstName: string;
//     lastName: string;
//     otherNames: string;
//     dob: string;
//     gender: string;
//     partyLeaning?: any;
// }

//NOTE: 1:1 copy from .net. Unfortunately we cannot hook this up to TsGenerator to generate a "string constant" class.
//The alternative of string literal types, while satsifying the semantic aspect makes for unreadable code as no symbolic
//meaning is attached to the string constant values when using string literal types.

export interface IRemoteListDownload {
    tasks: IRemoteDownloadTask[];
    finished?: boolean;
}

export type DownloadStatus = "Started" | "In Progress" | "Completed" | "Error"; 

export interface IRemoteDownloadTask {
    taskId: string;
    listId: any;
    listName: string;
    status: DownloadStatus;
    error?: Error;
    message?: string;
}

export type IRemoteListDownloadImmutable = Readonly<IRemoteListDownload>;

export type IRemoteDownloadTaskImmutable = Readonly<IRemoteDownloadTask>;



export type InteractionModelProperties = "Id" | "Topic" | "Details" | "Todo" | "Due" | "Date" | "OfficeID" | "Tags" | "Level" | "Owner" | "Author" | "OfficeName" | "InteractionType" | "AttributionType" | "IsDone" | "SurveyResponseID" | "IsVisible" | "IsEditable" | "Link" | "Reference" | "Files" | "ShareOffices" | "Longitude" | "Latitude" | "DistanceM" | "SubjectID" | "SubjectType" | "Priority";

export type BaseInteractionType = "Interaction" | "MultiInteraction" | "AddressInteraction" | "PositionInteraction" | "GroupInteraction" | "Other";
export type IInteractionImmutable = Readonly<ElectracAPI.InteractionModel>; 

export type IInteractionFileAttachmentImmutable = Readonly<ElectracAPI.InteractionFileAttachment>;

export type EventDetailsProperties = "Id" | "RecurrenceId" | "Title" | "Description" | "Location" | "IsAllDay" | "Start" | "End" | "Longitude" | "Latitude";

export type IAddressStatus = ElectracAPI.ListAddressStatus;

export interface IAddressStatusLocal extends IAddressStatus {
    combined: string;
}

export function makeNewInteraction(type: ElectracAPI.LinkType, linkId: number, defaultLevel?: ElectracAPI.SensitiveLevel): ElectracAPI.InteractionSaveModel {
    const inter = {
        IsDone: false,
        Level: ElectracAPI.SensitiveLevel.Office,
        LinkType: type,
        LinkId: linkId,
        Tags: [],
        Files: [],
        FilesToAttach: [],
        Priority: 0
    };
    if (defaultLevel != null) {
        inter.Level = defaultLevel;
    }
    return inter;
}

