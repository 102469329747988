import * as React from "react";
import * as ReactDOM from "react-dom";
import { InputFieldType } from '@electrac/components';
import { IFocusableField } from "../container";
import uuid = require('node-uuid');
import { ThemableInput } from "./ThemableInput";
import { ISurveyQuestionCommonProps } from "./Common";

export interface ISurveyTextProps extends ISurveyQuestionCommonProps {
    textarea?: boolean;
    onChange: (value: any) => void;
    answer?: string;
    maxLength?: number;
    isEmail?: boolean;
    descriptionAsPlaceholder?: boolean;
}

export interface ISurveyTextState {
    charsRemaining: number;
    value: string;
}

const EMPTY_STR = "";

export class SurveyText extends React.Component<ISurveyTextProps, ISurveyTextState> implements IFocusableField {
    private containerEl: HTMLDivElement | null = null;
    constructor(props) {
        super(props);
        this.state = {
            charsRemaining: props.maxLength ? (props.maxLength - (props.answer || EMPTY_STR).length) : -1,
            value: props.answer || EMPTY_STR
        };
        
    }
    private onContainerMounted = (component: HTMLDivElement) => {
        this.containerEl = component;
    }
    componentWillReceiveProps(nextProps: ISurveyTextProps) {
        this.setState({
            charsRemaining: nextProps.maxLength ? (nextProps.maxLength - (nextProps.answer || EMPTY_STR).length) : -1,
            value: nextProps.answer || EMPTY_STR
        });
    }
    focus(): void {
        if (this.containerEl) {
            const node: any = ReactDOM.findDOMNode(this.containerEl);
            node.scrollIntoView();
        }
        //TODO: Flash animate the container to bring to user's attention
    }
    private onFieldChange = (value) => {
        if (this.props.maxLength != null) {
            this.setState({ charsRemaining: (this.props.maxLength - value.length), value: value });
        } else {
            this.setState({ charsRemaining: -1, value: value });
        }
    }
    render(): JSX.Element {
        const {
            isReadOnly,
            textarea,
            label,
            description,
            maxLength,
            isEmail,
            onChange,
            theme,
            descriptionAsPlaceholder
        } = this.props;
        const { value, charsRemaining } = this.state;
        let inputLabel = label;
        if (!isReadOnly && charsRemaining >= 0) {
            inputLabel = `${label} (${charsRemaining} characters left)`;
        }
        
        let inputType: InputFieldType = "text";
        if (isEmail === true) {
            inputType = "email";
        } else if (!!textarea) {
            inputType = "textarea";
        }
        

        return <div ref={this.onContainerMounted} className={theme && theme.getFieldContainerClass() }>
            <ThemableInput
                id={uuid.v4() }
                theme={theme}
                isReadOnly={isReadOnly}
                extraInputProps={{
                    maxLength: maxLength
                }}
                type={inputType}
                label={inputLabel}
                placeholder={description}
                descriptionAsPlaceholder={descriptionAsPlaceholder}
                onChange={this.onFieldChange}
                onBlur={onChange}
                value={value} />
        </div>;
    }
}