import * as React from "react";
import { SurveyChoice, ISurveyChoiceItem } from "./choice";
import { NameValuePair } from "@electrac/model";
import { ISurveyContainerContext } from "@electrac/plugin";
import { ISurveyQuestionCommonProps } from "./Common";

declare var electrac;

export interface IVoteIntentionProps extends ISurveyQuestionCommonProps {
    onChange: (selectedParty: string | null) => void;
    parties: NameValuePair[];
    answer?: string;
    context: ISurveyContainerContext;
    voteType: "partyID" | "intention";
}

export interface IVoteIntentionState {
    otherIntention?: { name: string, data: string };
}

export class SurveyVoteIntention extends React.Component<IVoteIntentionProps, IVoteIntentionState> {
    fnOtherClick: (e) => void;
    fnChoiceChanged: (selectedValues, otherAnswer) => void;
    constructor(props) {
        super(props);
        this.fnChoiceChanged = this.onChoiceChanged.bind(this);
        this.fnOtherClick = this.onOtherClick.bind(this);
        this.state = {
            
        };
    }
    componentDidMount() {
        try {
            const { answer } = this.props;
            const matches = this.findMatchingVoterIDItem(answer);

            
            if (matches.length == 0) {
                const name = JSON.parse(answer || '{}').Name;
                const other = { name: name, data: answer || "" };
                this.setState({ otherIntention: other });
            }
        } catch (e) {

        }
    }
    private findMatchingVoterIDItem(obj) {
        const find = JSON.parse(obj);
        return this.props.parties.filter(p => {
            if (!p.Value) return false;
            
            const orig = JSON.parse(p.Value);
            return orig.Model != null
                && orig.Model.length == 1
                && orig.Model.length == find.Model.length
                && orig.Model[0].PartyID == find.Model[0].PartyID
                && orig.Model[0].CertaintyID == find.Model[0].CertaintyID
                && orig.Model[0].Category == find.Model[0].Category;
        });
    }
    private setVoterIDItem(obj, name) {
        const matches = this.findMatchingVoterIDItem(obj);
        let other;
        if (matches.length == 0) {
            other = { name: name, data: obj };
        } else if (matches.length == 1) {
            obj = matches[0].Value;
        }
        this.setState({ otherIntention: other }, () => {
            this.props.onChange(obj);
        });
    }
    private onOtherClick(e) {
        // Copypasta from Electrac.Common.js

        //  alert($(this).data('voteType'));
        //const eid = $(this).closest('.el-elector-top').find('input[name=ElectorID]').val();
        // const that = $(this);
        const voteType = this.props.voteType;
        // const parent = that.parent();
        let url = "/Survey/VoterIDAdvanced";
        if (voteType != "intention") {
            url += "?partyID=true";
        }
        // const hasOtherIntention = (this.state.otherIntention != null);
        
        
        const $dialog = electrac.AjaxDialog('VoteAdvanced', url, {
            title: 'Other/Advanced Options',
            modal: true,
            width: 580,
            //height: 300,
            open: () => {
                // HACK / TODO this should be part of the components themselves :-(
                ($('.rateit') as any).rateit();
                ($('#rateitStrength') as any).rateit('value', 4);
                $("#rateitStrength").bind('over', function (event, value) {
                    $(this).attr('title', $('#strengths option[value=' + value + ']').text());
                });
                //if (hasOtherIntention) {
                //    this.selectVoterIDItem(parent);
                //}
            },
            buttons: {
                Cancel: () => {
                    $dialog.dialog('close');
                },
                OK: () => {
                    const intention = !$("input[name$='advanced.intentions']:checked").val() ? null :
                        JSON.parse($("input[name$='advanced.intentions']:checked").val() as any);

                    if (!intention) {
                        $("#error").text("You must select the intention.");
                        $("#error").show();
                        return;
                    }
                    const strength: any = $("#rateitStrength");

                    const certainty = (strength).rateit('value') > 0 ? { "CertaintyID": strength.rateit('value') } : null;
                    const category = !$("input[name$='advanced.category']:checked").val() ? null : JSON.parse($("input[name$='advanced.category']:checked").val() as any);
                    //if (category == null && status == null) category = { "Category": 1 };
                    //const status = !$("input[name$='advanced.status']:checked").val() ? null : $.evalJSON($("input[name$='advanced.status']:checked").val());//(voteType != "intention" && (categorytmp.Category == 1 || categorytmp.Category == 2)) ? { "Category": 4 } : categorytmp;


                    const model = $.extend(intention, certainty, category);
                    //const tmp = $("input[name$='advanced.category']:checked").parent().text().trim();
                    //const name = status == null ? $("input[name$='advanced.parties']:checked").parent().text().trim()
                    //    + " " + $("#strengths option:selected").text().split(',')[0].toLowerCase() + " (" + (tmp == "First Preference" ? "FP" : (tmp == "Second Preference (TPP)" ? "TPP" : tmp)) + ")" :
                    //    tmp;
                    const name = $("input[name$='advanced.intentions']:checked").parent().text().trim();
                    const obj = JSON.stringify({
                        "Name": (name),
                        "Model": [model]
                    });

                    this.setVoterIDItem(obj, name);

                    /*
                    if (hasOtherIntention) {
                        this.findPredefinedVoterIDItem(parent, obj, model, name);
                    } else {
                        // elector record
                        $.post('/Elector/Vote/', { id: $('#ElectorID').val(), output: obj, electionID: $('#Elections').val() },
                            function (data) {
                                //electrac.ElectorInteractionsRefresh();
                                that.closest('#VoteIntentions').html(data);
                            });

                    }
                    */

                    $dialog.dialog('close');
                }
            },
            zIndex: 9999
        });
    }
    private onChoiceChanged(selectedValues: any[], otherAnswer: string) {
        if (selectedValues.length == 1) {
            this.props.onChange(selectedValues[0]);
        } else {
            this.props.onChange(null);
        }
    }
    render(): JSX.Element {
        const { label, description, isReadOnly, answer, parties, debugMode, theme } = this.props;
        const { otherIntention } = this.state;
        const data: ISurveyChoiceItem[] = parties.map(p => {
            return {
                label: p.Text,
                value: p.Value,
                checked: (p.Value == answer)
            };
        });
        if (otherIntention) {
            data.push({
                label: otherIntention.name,
                value: otherIntention.data,
                checked: (otherIntention.data == answer)
            });
        }
        const selectedAnswer: string[] = [];
        if (answer != null) {
            selectedAnswer.push(answer);
        }

        const buttonClass = theme ? theme.getButtonClass() : undefined;

        return <div>
            <SurveyChoice
                allowOtherChoice={false}
                allowMultipleChoice={false}
                debugMode={debugMode}
                theme={theme}
                label={label}
                description={description}
                data={data}
                isReadOnly={isReadOnly}
                answer={selectedAnswer}
                onChange={this.fnChoiceChanged}>
                {!isReadOnly && this.props.context.supportsCustomIntention() && <button type="button" className={buttonClass} onClick={this.fnOtherClick}>Other/Advanced</button>}
            </SurveyChoice>
        </div>;
    }
}