import * as React from "react";
import { IFieldProps } from "./index";
//import { DropdownList } from 'react-widgets';
import * as DropdownList from 'react-widgets/lib/DropdownList';
import debounce = require('lodash.debounce');
import { RtTooltip } from "@ml/components";

// We could re-use RwDropdownList it didn't bleed in label rendering concerns
export const RwDropdownFieldSimple = (props: any) => {
    const { input, meta, data, valueField, textField, placeholder, filter, disabled } = props;
    const changeHandler = (e: any) => {
        input.onChange(e[valueField]);
    };
    
    return <div>
        <DropdownList {...input}
            disabled={disabled}
            data={data}
            valueField={valueField}
            textField={textField}
            placeholder={placeholder}
            onChange={changeHandler} 
            filter={filter}
            />
        {(() => {
            if (meta.error) {
                return <div className="text-danger">{meta.error}</div>;
            }
        })()}
    </div>;
}

// example usage with redux form to create an autocomplete below. Use redux form's change action creator via redux's dispatch to change state values.
//<Field
//    name={Dat.ExpenseItem}
//    component={RwDropdownList}
//    data={ExpenseItemOptions}
//    placeholder="Select Expense Item..."
//    filter='contains'
//    minLength={3}
//    onSearch = {(searchTerm: string) => {
//        handleExpenseItemSearch(searchTerm, SearchExpenseItemsUrl, dispatch);
//    } }
//    busy={submitting || somethingLoading}
//    onSearchWait={400}
//    disabled={submitting || somethingLoading}
//    />


export interface IRwDropdownListValue {
    Id?: any;
    Name?: any;
}

export interface IRwDropdownListProps extends IFieldProps {
    /** The data field is objects, so we normalise to use the value
     * field value on the onChange event
    */
    useSelectedValueField?: boolean;
    placeholder?: any;
    minLength?: number;
    filter?: any;
    valueField?: any;
    textField?: any;
    onSearch?: any;
    onSelect?: any;
    onSearchWait?: number; //<= used in conjunction with onSearch debounce
    label?: string | JSX.Element;
    rootClassName?: string;
    tooltipText?: string | JSX.Element;
    tooltipType?: string;
    disabled?: boolean;
    id?: string; // field ID, primarily used for the tooltip
}


export class RwDropdownList extends React.Component<IRwDropdownListProps, any> {
    constructor(props: IRwDropdownListProps) {
        super(props);
    }

    private onSearch = (this.props.onSearchWait ? debounce(this.props.onSearch, this.props.onSearchWait) : this.props.onSearch);

    private changeHandler = (e: any) => {
        this.props.input.onChange(e[this.getValueField()]);
    };

    // private changeHandler = (e: any) => {
    //     if (!this.props.passValueOnly)
    //     this.props.input.onChange(e[this.props.valueField || 'Id']);
    // };

    private getValueField() {
        const { valueField} = this.props;
        if (valueField) return valueField;

        return 'Id';
    }
    private getTextField() {
        const { textField} = this.props;
        if (textField) return textField;

        return 'Name';
    }

    render(): JSX.Element {
        let touched = false, error = null;
        const { meta, disabled} = this.props;

        if (meta) {
            touched = meta.touched;
            error = meta.error;
        }
        const { onSelect, label, tooltipText, rootClassName, tooltipType, input } = this.props;
        const tipID =  this.props.id || this.props.input.name || "rw-dropdownlist-tt";

        const tooltip = (<RtTooltip id={tipID} tooltipText={tooltipText} type={tooltipType} />)

        const renderPlaceholder = (touched && error ? <span style={{ color: "red" }}> {error}</span> : this.props.placeholder);
        const extra = { 
            onChange: this.props.useSelectedValueField ? this.changeHandler : input.onChange 
        };

        return ( <div className={(rootClassName ? rootClassName : "field-container")}>
        <div className={(label || tooltipText ? "input-group" : "")}>
            {(tooltipText ? <span className="input-group-addon">{tooltip} </span> : null)}
            {(label ? <span className="input-group-addon">{label} </span> : null)}

            <DropdownList
                {...extra}
                {...this.props}
                disabled={disabled}
                onSelect={onSelect}
                value={input.value}
                placeholder={renderPlaceholder}
                valueField={this.getValueField()}
                textField={this.getTextField()}
                onSearch={this.onSearch}
            />
        </div>
    </div>);
    }
}
