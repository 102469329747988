import * as uuid from "node-uuid";
import { IAdvancedListBuilderNode, CriteriaNodeProvider } from './common';
import { AdvancedListBuilderLookupDataStore } from './lookup';
import { NodeProviderRegistry } from './provider-registry';

export class CriteriaNodeUtil {
    public static isCollectionNode(node: IAdvancedListBuilderNode): boolean {
        return node.op == null;
    }
    public static computeKey(node: IAdvancedListBuilderNode, prefix: string = ""): string {
        return `${prefix != "" ? (prefix + "_") : ""}${node.clientId}`; //_${node.op || ""}_${node.q || ""}_${node.m || ""}_${node.a || ""}`;
    }
    public static generateId(): string {
        return uuid.v4(); //`${(new Date()).getTime()}`;
    }
    public static indexOfChild(parentNode: IAdvancedListBuilderNode, childId: string): number {
        if (parentNode.items) {
            for (let i = 0; i < parentNode.items.length; i++) {
                if (parentNode.items[i].clientId == childId) {
                    return i;
                }
            }
        }
        return -1;
    }
    public static moveChildNode(parentNode: IAdvancedListBuilderNode, old_index: number, new_index: number): boolean {
        const items = parentNode.items;
        if (items) {
            if (new_index >= items.length) {
                let k = new_index - items.length;
                while ((k--) + 1) {
                    (items as any[]).push(undefined);
                }
            }
            items.splice(new_index, 0, items.splice(old_index, 1)[0]);
            return true;
        }
        return false;
    }
    public static createNode(parentId: string): IAdvancedListBuilderNode {
        return {
            q: undefined,
            a: undefined,
            clientId: CriteriaNodeUtil.generateId(),
            parentId: parentId
        };
    }
    public static hasCriteriaNodes(map: any, ofType?: (node: IAdvancedListBuilderNode) => boolean) {
        let bHasCriteria = false;
        const keys = Object.keys(map);
        for (const k of keys) {
            if (map[k].items) {
                continue;
            }
            if (ofType == null || ofType(map[k])) {
                bHasCriteria = true;
            }
            break;
        }
        return bHasCriteria;
    }
    public static populateNodeMap(map: any, rootNode: IAdvancedListBuilderNode): void {
        CriteriaNodeUtil.populateNodeMapInternal(map, rootNode);
    }

    private static populateNodeMapInternal(map: any, node: IAdvancedListBuilderNode): void {
        map[node.clientId] = node;
        if (node.items) {
            for (const child of node.items) {
                CriteriaNodeUtil.populateNodeMapInternal(map, child);
            }
        }
    }
    public static hydrateClientIds(rootNode: IAdvancedListBuilderNode): IAdvancedListBuilderNode {
        CriteriaNodeUtil.hydrateChildClientIds(rootNode);
        return rootNode;
    }
    private static hydrateChildClientIds(node: IAdvancedListBuilderNode, parentId: string | undefined = undefined): void {
        node.clientId = CriteriaNodeUtil.generateId();
        node.parentId = parentId;
        if (node.items) {
            for (const child of node.items) {
                CriteriaNodeUtil.hydrateChildClientIds(child, node.clientId);
            }
        }
    }
    public static getGroupNodeDescriptor(type: string | undefined): CriteriaNodeProvider | undefined {
        if (type) {
            const provider = NodeProviderRegistry.getProviders().filter(p => p.isGroupEditor === true && p.type == type)[0];
            if (provider) {
                return provider;
            }
        }
        return undefined;
    }
    public static getNodeDescriptor(type: string | undefined): CriteriaNodeProvider | undefined {
        if (type) {
            const providers = NodeProviderRegistry.getProviders();
            for (const ntd of providers) {
                if (ntd.type == type) {
                    return ntd;
                }
            }
        }
        return undefined;
    }
    public static isValidLookupQuestion(type: string): boolean {
        const store = AdvancedListBuilderLookupDataStore.getStore(type);
        if (store) {
            return true; //store.isValid();
        } else {
            return false;
        }
    }
    public static getDefaultLookupValue(type: string): string | undefined {
        const store = AdvancedListBuilderLookupDataStore.getStore(type);
        if (store) {
            return store.getDefaultValue();
        }
        return undefined;
    }
    private static resetValidationState(node: IAdvancedListBuilderNode): void {
        node.validationError = undefined;
        node.validationWarning = undefined;
        if (node.items) {
            node.items.forEach(child => {
                child.validationError = undefined;
                child.validationWarning = undefined;
            });
        }
    }
    private static isDirectEquality(node: IAdvancedListBuilderNode): boolean {
        return !node.m || node.m == "is";
    }
    private static validateCriteriaGroup(node: IAdvancedListBuilderNode): void {
        CriteriaNodeUtil.resetValidationState(node);
        if (node.op == "All") {
            const bucket: any = {};
            if (node.items) {
                for (const child of node.items) {
                    if (child.op && child.items) {
                        continue;
                    }
                    CriteriaNodeUtil.resetValidationState(child);
                    if (CriteriaNodeUtil.isDirectEquality(child)) { //Is direct equality
                        if (child.q) {
                            if (!bucket[child.q]) {
                                bucket[child.q] = 1;
                            } else {
                                bucket[child.q] = bucket[child.q] + 1;
                            }
                        }
                    }
                }
            }
            const msgs: string[] = [];
            for (const q in bucket) {
                if (bucket.hasOwnProperty(q) && bucket[q] > 1) {
                    const desc = CriteriaNodeUtil.getNodeDescriptor(q as string);
                    if (desc) {
                        msgs.push(`More than one sub-criteria of type (${desc.category} - ${desc.label}) was specified at this level. Such items are highlighted below. Since you have specified "${node.op}" at this level and the sub-criteria in question are direct equality operators, this is a condition that will never be satisfied. To fix this, either change the grouping type to something other than "${node.op}" -OR- change the operator type of one or more of the affected sub-critieria (if applicable) -OR- remove/relocate one or more of the affected sub-criteria`);
                    }
                    if (node.items) {
                    //Flag applicable children
                    node.items
                        .filter(n => CriteriaNodeUtil.isDirectEquality(n) && n.q == q)
                        .forEach(n => {
                            n.validationError = undefined;
                            n.validationWarning = `This sub-criteria conflicts with another sibling sub-critieria`;
                        });
                    }
                }
            }
            node.validationError = msgs.join("\n");
        }
    }
    public static validateThisNodeAndParent(node: IAdvancedListBuilderNode, parent: IAdvancedListBuilderNode): void {
        //The current node was passed in for 
        if (node && node.items) {
            CriteriaNodeUtil.validateCriteriaGroup(node);
        }

        //Under an "All" group, having more than one child node of the same type and the same equality operator is meaningless
        //For example: (LastName eq Smith) AND (LastName eq Bloggs) is a condition that will never be satisfied
        if (parent && parent.items) {
            CriteriaNodeUtil.validateCriteriaGroup(parent);
        }
    }
}