/**
 * AlertPanel.tsx
 *
 * Provides a common alert panel container abstraction with support for kendo and bootstrap stylings
 */

import * as React from "react";

export enum AlertStyleKind {
    Kendo,
    Bootstrap
}

export enum AlertPanelTheme {
    Info,
    Success,
    Error,
    Plain
}

export interface IAlertPanelProps {
    kind?: AlertStyleKind;
    theme: AlertPanelTheme;
    children?: any;
}

export const AlertPanel = (props: IAlertPanelProps) => {
    const kind = props.kind != null ? props.kind : AlertStyleKind.Kendo;
    const theme = props.theme;
    let style: any = null;
    const classNames: string[] = [];
    if (kind === AlertStyleKind.Kendo) {
        style = {
            padding: "15px",
            marginBottom: "20px",
            borderRadius: "5px"
        };
        classNames.push("k-block");
        switch (theme) {
            case AlertPanelTheme.Info:
                classNames.push("k-info-colored");
                break;
            case AlertPanelTheme.Error:
                classNames.push("k-error-colored");
                break;
            case AlertPanelTheme.Success:
                classNames.push("k-success-colored");
                break;
        }
    } else {
        if (theme == AlertPanelTheme.Plain) {
            classNames.push("well");
        } else {
            classNames.push("alert");
        }
        switch (theme) {
            case AlertPanelTheme.Info:
                classNames.push("alert-info");
                break;
            case AlertPanelTheme.Error:
                classNames.push("alert-danger");
                break;
            case AlertPanelTheme.Success:
                classNames.push("alert-success");
                break;
        }
    }
    return <div className={classNames.join(" ")} style={style}>
        {props.children}
    </div>;
}