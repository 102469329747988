import * as React from "react";
import { ErrorInfo } from 'react';
import { ErrorDisplay } from "./error-display";
import { AlertPanel, AlertPanelTheme, AlertStyleKind } from "./alert-panel";

export interface ErrorBoundaryProps {
    displayStyle?: AlertStyleKind;
    logError?: (error: Error, errorInfo: ErrorInfo) => void;
}

export interface ErrorBoundaryState {
    hasError: boolean;
    error?: Error;
    errorInfo?: ErrorInfo;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false, error: undefined, errorInfo: undefined };
    }
    componentDidCatch(error: Error, info: ErrorInfo) {
        const { logError } = this.props;
        // Display fallback UI
        this.setState({ hasError: true, error: error, errorInfo: info });
        if (logError) {      // You can also log the error to an error reporting service
            logError(error, info);
        }
    }
    render() {
        if (this.state.hasError) {
            const ds = this.props.displayStyle || AlertStyleKind.Kendo;
            // You can render any custom fallback UI
            return <div>
                <h1>Something went wrong :-(</h1>
                <ErrorDisplay error={this.state.error} displayStyle={ds} />
                {this.state.errorInfo && <AlertPanel theme={AlertPanelTheme.Error} kind={ds}>
                            <h3>More details:</h3>
                            <pre>{this.state.errorInfo.componentStack}</pre>
                        </AlertPanel>}
            </div>;
        }
        return this.props.children;
    }
}