import * as React from "react";
import * as ReactDOM from "react-dom";
import * as Multiselect from 'react-widgets/lib/Multiselect';

import { IFocusableField } from "../container";
import uuid = require("node-uuid");

import { ThemableInput } from "./ThemableInput";
import { ISurveyQuestionCommonProps, SurveyFieldDescription } from "./Common";

export interface ISurveyChoice {
    value: any;
    label: any;
}

export interface ISurveyChoiceItem extends ISurveyChoice {
    checked: boolean;
}

export interface ISurveyChoiceProps extends ISurveyQuestionCommonProps {
    data: ISurveyChoice[];
    onChange?: (selectedValues: any[], otherAnswer?: string) => void;
    allowMultipleChoice: boolean;
    answer?: any[];
    allowOtherChoice: boolean;
    otherAnswer?: string;
    compact?: boolean;
    flyoutUp?: boolean;
    randomiseChoices?: boolean;
}

export interface ISurveyChoiceState {
    items: ISurveyChoiceItem[];
    otherAnswer?: string;
}

const EMPTY_STR = "";

function updateFromNewAnswer(items: ISurveyChoiceItem[], answer?: any[]) {
    const ni = [ ...items ];
    for (const i of ni) {
        i.checked = false;
    }
    if (answer) {
        ni.forEach(i => {
            i.checked = (answer.filter(a => a === i.value).length == 1)
        });
    }
    return ni;
}

function areChoicesDifferent(items: ISurveyChoiceItem[], nextData: ISurveyChoice[]) {
    if (items.length == nextData.length) {
        for (const d of nextData) {
            if (!(items.filter(i => i.label == d.label && i.value == d.value).length == 1)) {
                return true;
            }
        }
        return false;
    }
    return true;
}

function choicesToSelection(data: ISurveyChoice[], answer?: any[], randomise: boolean = false): ISurveyChoiceItem[] {
    const items = data.map(d => {
        return {
            label: d.label,
            value: d.value,
            checked: false
        };
    });
    if (answer) {
        items.forEach(i => {
            i.checked = (answer.filter(a => a === i.value).length == 1)
        });
    } else {
        items.forEach(i => {
            i.checked = false;
        });
    }
    if (randomise) {
        //TODO: utils
        for (let i = items.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1));
            [items[i], items[j]] = [items[j], items[i]];
        }
    }
    return items;
}

export class SurveyChoice extends React.Component<ISurveyChoiceProps, ISurveyChoiceState> implements IFocusableField {
    private containerEl: HTMLDivElement | null = null;
    constructor(props: ISurveyChoiceProps) {
        super(props);
        this.state = {
            items: choicesToSelection(props.data, props.answer, props.randomiseChoices === true),
            otherAnswer: props.otherAnswer || EMPTY_STR
        };
    }
    private onContainerMounted = (component: HTMLDivElement) => {
        this.containerEl = component;
    }
    focus(): void {
        if (this.containerEl) {
            const node: any = ReactDOM.findDOMNode(this.containerEl);
            node.scrollIntoView();
        }
        //TODO: Flash animate the container to bring to user's attention
    }
    componentWillReceiveProps(nextProps: ISurveyChoiceProps) {
        let items;
        if (areChoicesDifferent(this.state.items, nextProps.data)) {
            items = choicesToSelection(nextProps.data, nextProps.answer, nextProps.randomiseChoices === true);
        } else { //This is to preserve whatever initial random sorting that was applied
            items = updateFromNewAnswer(this.state.items, nextProps.answer);
        }
        this.setState({ items: items, otherAnswer: nextProps.otherAnswer });
    }
    // uncheck all items in the list
  
    private onHandleMultiselectChange = (e) => {
        const currentItems = [...this.state.items];
        const selected: any[] = (e || []).map(v => v.value);
        const items: ISurveyChoiceItem[] = [];
        for (const it of currentItems) {
            const newItem = { ...it };
            if (selected.filter(s => s == it.value).length == 1) {
                newItem.checked = true;
            } else {
                newItem.checked = false;
            }
            items.push(newItem);
        }
        this.setState({
            items 
        });

        if (this.props.onChange) {
            this.props.onChange(selected, this.state.otherAnswer);
        }
    }
    private handleItemChange = (e) => {
        const items: ISurveyChoiceItem[] = [];
        const checkedItems: ISurveyChoiceItem[] = [];

        if (this.props.allowMultipleChoice) {
            this.state.items.forEach((item) => {
                if (item.value == e.target.value) {
                    item.checked = e.target.checked;
                }
                if (item.checked) {
                    checkedItems.push(item);
                }
                items.push(item);
            });
        } else {
            this.state.items.forEach((item) => {
                if (item.value == e.target.value) {
                    item.checked = this.props.compact ? true : e.target.checked;
                } else {
                    item.checked = false;
                }
                if (item.checked) {
                    checkedItems.push(item);
                }
                items.push(item);
            });
        }

        this.setState({items});

        if (this.props.onChange) {
            this.props.onChange(checkedItems.map(i => i.value), this.state.otherAnswer);
        }
    }
    private onOtherChoiceChanged = (newValue) => {
        const value = newValue || "";
        this.setState({ items: this.state.items, otherAnswer: value });
        if (this.props.onChange) {
            const checkedItems = this.state.items.filter(i => i.checked);
            this.props.onChange(checkedItems.map(i => i.value), value);
        }
    }
    private onOtherAnswerUpdated = (value) => {
        this.setState({ items: this.state.items, otherAnswer: value });
    }
    render(): JSX.Element {
        const { label, description, theme, compact, allowMultipleChoice } = this.props;
        //WTF: read-only doesn't work on checkbox/radio input fields. Is this by design? I have to currently map it to disabled instead.
        const isReadOnly = (this.props.isReadOnly === true);
        const type = this.props.allowMultipleChoice ? "checkbox" : "radio";
        let options;
        if (compact === true) {
            if (allowMultipleChoice === false) {
                const selectedValue = this.state.items.filter(i => i.checked).map(i => i.value)[0];
                options = <select className={theme && theme.getSelectClass()} onChange={this.handleItemChange} value={selectedValue}>
                    <option>(Select a choice)</option>
                    {this.state.items.map((item, index) => <option key={index} value={item.value}>{this.props.debugMode === true ? `${item.label} [${item.value}]` : item.label}</option>)}
                </select>;
            } else {
                const selectedValues = this.state.items.filter(i => i.checked).map(i => i.value);
                const msProps = {
                    data: this.state.items,
                    value: selectedValues,
                    textField: "label",
                    valueField: "value",
                    onChange: this.onHandleMultiselectChange,
                    dropUp: (this.props.flyoutUp === true)
                };
                options = <Multiselect {...msProps} />;
            }
        } else {
            options = this.state.items.map((item, index) => {
                return (
                    <div key={'chk-' + index} className={type}>
                        <label>
                            <input
                                disabled={isReadOnly}
                                type={type}
                                value={item.value}
                                onChange={this.handleItemChange}
                                checked={item.checked ? true : false} /> {item.label} {this.props.debugMode === true ? `[${item.value}]` : ""}
                        </label>
                    </div>
                );
            });
        }
       
        return (
            <div ref={this.onContainerMounted} className={`${theme && theme.getFieldContainerClass()} survey-choice`}>
                <div className={theme && theme.getQuestionLabelClass()}>
                    <label className={theme && theme.itemLabelClass}>{label}</label>
                    <SurveyFieldDescription description={description} />
                </div>
                <div className={theme && theme.getQuestionBodyClass()}>
                    {options}
                    {this.props.allowOtherChoice &&  <div className="survey-choice-other">
                <ThemableInput
                    id={uuid.v4()}
                    theme={theme}
                    label="Other:"
                    type={(compact ? "text" : "textarea")}
                    isReadOnly={isReadOnly}
                    onBlur={this.onOtherChoiceChanged}
                    onChange={this.onOtherAnswerUpdated}
                    value={this.state.otherAnswer || EMPTY_STR}
                    descriptionAsPlaceholder={true}
                    noLayout={true}
                    placeholder="Specify other choice" />
            </div>}
                    {this.props.children}
                </div>
            </div>
        );
    }
}