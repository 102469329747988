// FormValidation.tsx
//
// This module contains a set of re-usable field-level validation functions for redux-form <Field> elements
//
// See: http://redux-form.com/6.8.0/examples/fieldLevelValidation/

export const field_required = (value: any) => value ? undefined : 'Required'

export const field_maxLength = (max: number) => (value: string) =>
    value && value.length > max ? `Must be ${max} characters or less` : undefined

export const field_number = (value: any) => value && isNaN(Number(value)) ? 'Must be a number' : undefined

export const field_minValue = (min: number) => (value: number) =>
    value && value < min ? `Must be at least ${min}` : undefined

export const field_email = (value: string) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
        'Invalid email address' : undefined

export const field_strongPassword = (value: string) =>
    value && !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/.test(value) ?
        'Password must contain at least: one digit, one lower case, and 8 characters' : undefined

