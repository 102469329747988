import * as React from "react";
import * as ReactDOM from "react-dom";


export interface IFrameProps {
    frameProps: { body: string }
}
export const IFrameWrapper = (Component: React.ReactType<any>) => {
    return class IFrame extends React.Component<IFrameProps, any> {
        public el?: Element;
    
    
        public updateIFrameContents = () => {
            if (!this.el) return;
            ReactDOM.render((
                <Component {...this.props}/>
            ), this.el);
        }
        
        public render() {
            return (
                <iframe />
            );
        }
    
        componentDidMount() {
            var contentDocument = (ReactDOM.findDOMNode(this) as HTMLIFrameElement).contentDocument;
    
            if (contentDocument == null) {
                console.log("iframe content document wasn't found");
                return;
            }
    
            var frameBody = contentDocument.body,
                el = document.createElement('div');
                frameBody.appendChild(el);
            this.el = el;
            this.updateIFrameContents();
        }
    
        componentDidUpdate() {
            this.updateIFrameContents();
        }
    }

}


const createBodyHtml = (bodyHtml: string) => {
    return { __html: bodyHtml };   
}

const IFrameHtmlInner = (props: IFrameProps) => <div dangerouslySetInnerHTML={createBodyHtml(props.frameProps.body)}></div>;

export const IFrameHtml = IFrameWrapper(IFrameHtmlInner);