import { KendoWidgetComponent, KendoWidgetName, IKendoWidgetPropsBase } from "./kendo-widget-component";

//TODO: @electrac/components

export type IKendoGridOptions = kendo.ui.GridOptions;

export interface IKendoGridEvents {
    
}

export interface IKendoGridProps extends IKendoWidgetPropsBase<IKendoGridOptions>, IKendoGridEvents {
    onRowsSelected?: (rows: any[]) => void;

    /** work around the fact we will have external async loading of data and want to ensure the grid displays this explicitly */
    loading?: boolean;
}

export interface IKendoGridState {
    
}

const propName = (name: keyof IKendoGridProps) => name;

@KendoWidgetName("kendoGrid")
export class KendoGrid extends KendoWidgetComponent<kendo.ui.Grid, IKendoGridOptions, IKendoGridProps, IKendoGridState> {
    constructor(props: IKendoGridProps) {
        super(props);
    }
    private onChange = (e: any) => {
        const { onRowsSelected } = this.props;
        if (!this.$widget) return; // shouldn't happen

        if (onRowsSelected) {
            const kr: any = this.$widget.select();
            const rows: any[] = [];
            for (let i = 0; i < kr.length; i++) {
                const item = this.$widget.dataItem(kr[i]);
                rows.push(item.toJSON());
            }
            onRowsSelected(rows);
        }
    }
    protected augmentWidgetOptions(baseOptions: IKendoGridOptions): any { //Override
        return {
            ...baseOptions,
            change: this.onChange
        }
    }

    
    

    protected shouldPropBeExcludedFromWrappingElement(name: string): boolean {
        return super.shouldPropBeExcludedFromWrappingElement(name) 
        || name == propName("loading")
        || name == propName("onRowsSelected");
    }
    componentWillReceiveProps(nextProps: IKendoGridProps) {
        //TODO: Obviously doesn't cover the full set of kendo grid options. Add them as you go when required.
        if (this.props.options.dataSource != nextProps.options.dataSource) {
            let ds = nextProps.options.dataSource;
            if (!(ds instanceof kendo.data.DataSource)) {
                ds = new kendo.data.DataSource({
                    data: nextProps.options.dataSource
                });
            }
            this.$widget && this.$widget.setDataSource(ds);
        }
        if (this.props.options.height != nextProps.options.height) {
            this.$widget && this.$widget.setOptions({ height: nextProps.options.height });
        }
        if (this.props.loading !== nextProps.loading) {
            kendo.ui.progress(this.$elem as JQuery<HTMLElement>, nextProps.loading || false);
        }
    }
}