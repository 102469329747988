import * as React from "react";

export interface ITextHighlighterProps {
    highlight: string | RegExp;
    text: string;
    className?: string;
}

export interface ITextHighlighterState {
    className: string;
}

export class TextHighlighter extends React.Component<ITextHighlighterProps, ITextHighlighterState> {
    constructor(props: ITextHighlighterProps) {
        super(props);
        this.state = {
            className: props.className || "highlight"
        };
    }
    render(): JSX.Element {
        if (!this.props.highlight) {
            return <span>{this.props.text}</span>;
        } else {
            // http://hackhat.com/p/255/how-to-highlight-words-with-react-js/
            const rgx = (this.props.highlight instanceof RegExp)
                ? this.props.highlight
                : new RegExp(this.props.highlight, "gi");
            const segments = this.props.text.split(rgx);
            const replacements = this.props.text.match(rgx);

            // Here we store each child
            const parts = [] as JSX.Element[];
            segments.forEach((segment, i) => {
                // Here we add the first segment
                parts.push(<span key={`segment-${new Date().getTime()}-${i}`} className="highlighter-segment">{segment}</span>);

                // If last segment then don't add the highlight part
                if (segments.length === i + 1) {
                    return;
                }

                if (replacements && replacements[i]) {
                    // Here we add the highlighted part
                    parts.push(<span key={`highlight-${new Date().getTime()}-${i}`} className={this.state.className}>{replacements[i]}</span>);
                }
            });

            return (<span>{parts}</span>);
        }
    }
}