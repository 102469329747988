import * as React from "react";
import * as ReactDOM from "react-dom";
import * as moment from "moment";
import { IFocusableField } from "../container";
import { ISurveyQuestionCommonProps } from "./Common";
import { SurveyDate } from "./date";
import { SurveyText } from "./text";

export interface ISurveyTodoFollowUpProps extends ISurveyQuestionCommonProps {
    onChange: (value: any) => void;
    answer?: string;
    maxLength?: number;
    descriptionAsPlaceholder?: boolean;
    followUpDateFormat: string;
    showFollowUpDatePicker?: boolean;
}

export interface ISurveyTodoFollowUpState {
    notes: string;
    followUpDate: string | null;
}

function isValidUtcDateStr(str?: string|null): boolean {
    if (!str) return false;

    const dt = moment.utc(str);
    return dt.isValid();
}

function parseAnswer(answer?: string): [string, string|null] {
    if (!answer) {
        return ["", null];
    }
    var colIdx = answer.lastIndexOf("|") + 1;
    if (colIdx > 0) {
        var part = answer.substr(colIdx);
        if (isValidUtcDateStr(part)) {
            return [answer.substring(0, colIdx - 1), part];
        } else {
            return [answer, null];
        }
    } else {
        return [answer, null];
    }
}


export class SurveyTodoFollowUp extends React.Component<ISurveyTodoFollowUpProps, Partial<ISurveyTodoFollowUpState>> implements IFocusableField {
    private containerEl: HTMLDivElement | null = null;
    private minDate: string;
    constructor(props: ISurveyTodoFollowUpProps) {
        super(props);
        const parsed = parseAnswer(props.answer);
        this.state = {
            notes: parsed[0],
            followUpDate: parsed[1]
        };
        this.minDate = moment.utc().toISOString();
    }
    componentWillReceiveProps(nextProps: ISurveyTodoFollowUpProps) {
        const parsed = parseAnswer(nextProps.answer);
        this.setState({
            notes: parsed[0],
            followUpDate: parsed[1]
        });
    }
    private onFieldChange = (value) => {
        this.setState({ notes: value });
        if (this.props.onChange) {
            let a = value || "";
            if (isValidUtcDateStr(this.state.followUpDate)) {
                a += "|" + this.state.followUpDate;
            }
            this.props.onChange(a);
        }
    }
    private onDateChange = (value) => {
        const v = isValidUtcDateStr(value) ? value : null;
        this.setState({ followUpDate: v });
        if (this.props.onChange) {
            let a = this.state.notes || "";
            if (v) {
                a += "|" + v
            }
            this.props.onChange(a);
        }
    }
    private onContainerMounted = (component: HTMLDivElement) => {
        this.containerEl = component;
    }
    focus(): void {
        if (this.containerEl) {
            const node: any = ReactDOM.findDOMNode(this.containerEl);
            node.scrollIntoView();
        }
        //TODO: Flash animate the container to bring to user's attention
    }
    render(): JSX.Element {
        const {
            theme,
            qid,
            isReadOnly,
            label,
            description,
            maxLength,
            followUpDateFormat,
            showFollowUpDatePicker
        } = this.props;
        const {
            notes,
            followUpDate
        } = this.state;
        const containerClass = theme ? theme.getFieldContainerClass() : undefined;
        return <div ref={this.onContainerMounted} className={containerClass}>
            <SurveyText
                theme={theme}
                qid={qid}
                textarea={true}
                isEmail={false}
                isReadOnly={isReadOnly}
                label={label}
                description={description}
                answer={notes || ""}
                maxLength={maxLength}
                onChange={this.onFieldChange} />
            {showFollowUpDatePicker === true && <SurveyDate
                        theme={theme}
                        format={followUpDateFormat}
                        isReadOnly={isReadOnly}
                        label="Follow up due date"
                        description="Set the date this follow up should be completed by"
                        answer={followUpDate}
                        dropUp={true}
                        minDate={this.minDate}
                        onChange={this.onDateChange} />}
        </div>;
    }
}