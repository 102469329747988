import { ValidationMap } from "react";
import * as PropTypes from "prop-types";
import { DragDropManager } from 'dnd-core';
import HTML5Backend from 'react-dnd-html5-backend';
import TouchBackend from 'react-dnd-touch-backend';
import { logInfo } from "@ml/common";

//TODO: @electrac/components

//NOTE: Duplicated form Advanced List Builder component. Should get it to use this
export interface IDragSourceProps {
    connectDragSource?: (component: JSX.Element) => JSX.Element;
    isDragging?: boolean;
    name?: string;
}

//NOTE: Duplicated form Advanced List Builder component. Should get it to use this
export interface IDropTargetProps {
    connectDropTarget?: (component: JSX.Element) => JSX.Element;
    isOver?: boolean;
    canDrop?: boolean;
}

function supportsTouchEvents() {
    return ('ontouchstart' in window);
}

let defaultManager : DragDropManager | undefined;

/**
 * This is singleton used to initialize only once dnd in our app.
 * If you initialized dnd and then try to initialize another dnd
 * context the app will break.
 * Here is more info: https://github.com/gaearon/react-dnd/issues/186
 *
 * The solution is to call Dnd context from this singleton this way
 * all dnd contexts in the app are the same.
 */
export default function getDndContext(): DragDropManager {
    if (defaultManager) {
        return defaultManager;
    }

    let backend;
    if (supportsTouchEvents()) {
        backend = TouchBackend;
        logInfo("Using touch dnd backend");
    } else {
        backend = HTML5Backend;
        logInfo("Using html5 dnd backend");
    }

    defaultManager = new DragDropManager(backend);

    return defaultManager;
}

export interface IDndContext {
    dragDropManager: DragDropManager
};

export const DND_CHILD_CONTEXT_VALIDATION_MAP: ValidationMap<any> = {
    dragDropManager: PropTypes.object.isRequired
};

export function buildDndChildContext(): IDndContext {
    return {
        dragDropManager: getDndContext()
    };
}