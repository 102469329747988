import { CriteriaNodeProvider } from './common';


export class NodeProviderRegistry {
    static NODE_TYPES: CriteriaNodeProvider[] = [];
    public static getProviders(): CriteriaNodeProvider[] {
        return NodeProviderRegistry.NODE_TYPES;
    }
    public static getProvider(type: string): CriteriaNodeProvider {
        return NodeProviderRegistry.NODE_TYPES.filter(nt => nt.type == type)[0];
    }
    public static registerProvider(provider: CriteriaNodeProvider) {
        NodeProviderRegistry.NODE_TYPES.push(provider);
    }
    public static unregisterProvider(type: string): number {
        const before = NodeProviderRegistry.NODE_TYPES.length;
        NodeProviderRegistry.NODE_TYPES = NodeProviderRegistry.NODE_TYPES.filter(nt => nt.type != type);
        return before - NodeProviderRegistry.NODE_TYPES.length;
    }
    public static filterProviders(filter: (provider:CriteriaNodeProvider)=>boolean) {

        NodeProviderRegistry.NODE_TYPES = NodeProviderRegistry.NODE_TYPES.filter(filter)
    }
    public static unregisterProviderByCategory(category: string) {

        this.filterProviders(nt => nt.category != category);
    }

  
 
}

