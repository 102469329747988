import * as React from 'react';
import * as moment from 'moment';
import ReactTooltip = require('react-tooltip');
import { attachmentUrl, IElectracWebContext } from '@electrac/api';
import { IconCollapseDown, IconCollapseUp } from "@foxd/react-icons";

import { IInteractionAction, getActionClassName } from './Actions';
import { useInteraction } from './Context';

import { isNullOrEmpty, isError } from '@ml/common';
import { getInteractionDisplayType, isBulkInteraction } from './InteractionPanel';
import { InteractionModel, InteractionDisplayExtra } from '@electrac/model';
import { InteractionPart } from '.';

export const CLICK_NOOP = (e) => {
    e.preventDefault();
    return false;
};


export const arrowIconProps = {
	width: 14,
	height: 14,
	style: {
		stroke: '#999',
		color: '#ccc',
		display: 'flex',
	},
};


//NOTE: These are character lengths, not pixel sizes
export const TOPIC_AUTO_ELLIPSIS_MAX_LENGTH = 35;

export const InteractionTagDisplay = (props) => {
    const tags = props.tags;
    return <div className="el-todo">
        <strong>Tags: </strong>
        {tags.join(", ")}
    </div>;
};

/**
 * Properties of the ClassicInteractionDisplayItem component
 */
export interface IClassicInteractionDisplayItemProps {
    itemHeight?: number;
    interaction: Readonly<InteractionModel & { isCollapsed?: boolean }>;
    webContext: IElectracWebContext;
}


export const InteractionLink = (props: { link?: string }) => {
    const link = (props.link || "").trim();
    if (link.length > 0 && link.indexOf("http") == 0) {
        return <span><strong>Link:</strong><a target="_blank" href={link}> {link}</a></span>
    } else {
        return <span>{link}</span>;
    }
}

export const InteractionTodo = ({ due, reference, todo }: { todo?: string, due?: string, reference?: string }) => {
    const priority = 0;// interaction.Priority;
    if (!due && !reference) return null;

    return <div className="el-todo">
        {reference && <span><strong>Ref: </strong> {reference}<br /></span>}
        {<span>
        <span className="el-todo-icon"></span>
        <strong>To Do:</strong>
        {due != null && <span className="due">due: <span>{moment.utc(due).local().format("DD MMM YYYY")}</span></span>}
        {priority > 0 && <span className="due">priority: <span>{priority}</span></span>}
        {todo && !isNullOrEmpty(todo) &&
            <div className="desc">{todo}</div>
        }
        </span>}
    </div>;

}



/**
 * An interaction item display component styled to match the full classic Campaign Central interaction panel as close as possible
 * @param props Component properties
 */
export const DefaultClassicInteractionDisplayItem = (props: IClassicInteractionDisplayItemProps) => {
    const context = useInteraction();

    //const itemHeight = props.itemHeight;
    const { webContext, interaction } = props;

    const date = moment.utc(interaction.Date).local();
    const id = interaction.Id || 0;
    const itype = getInteractionDisplayType(interaction);
    const actions: JSX.Element[] = [];
    const availableActions = InteractionPart.getActions();


    //Should Render Differently    
    if (interaction.IsEditable === true) {
        if (availableActions.length > 0) {
            const actionHandler = (action: IInteractionAction) => {
                const onConfirm = (confirmed: boolean, args?: any) => {
                    if (confirmed === true) {
                        action.invoke(interaction, context, args, (res, extraData) => {
                            if (isError(res)) {
                                alert(`Error performing action: ${res.message}`);
                            } else {
                                action.onSuccess(res, context, extraData);
                            }
                        });
                    }
                };
                return (e) => {
                    e.preventDefault();
                    const confirmPrompt = action.confirmationPrompt;
                    if (confirmPrompt != null) {
                        if (typeof confirmPrompt === 'string') {
                            onConfirm(confirm(confirmPrompt));
                        } else {
                            confirmPrompt(interaction, context, onConfirm);
                        }
                    } else {
                        onConfirm(true);
                    }
                };
            };
            for (const act of availableActions) {
                if (!act.isApplicable(interaction)) {
                    continue;
                }
                actions.push(<a key={`${act.type}-${id}`} title={act.description} className={getActionClassName(act, interaction)} href="#" onClick={actionHandler(act)}></a>);
            }
        }
    } else {
        actions.push(<a className="el-lock" key={`locked-${id}`} href="#" onClick={CLICK_NOOP} title="You cannot modify this interaction"></a>);
    }
    //Finish Rendering Differently

    const isVisible = interaction.IsVisible;
    const isInvisible = !isVisible || !webContext || !webContext.getCurrentOfficeID || interaction.OfficeID != webContext.getCurrentOfficeID();
    const classNames = ["clearfix"];
    const isBulk = isBulkInteraction(interaction);
    const isCollapsed = !!interaction.isCollapsed;
    if (isBulk === true && isCollapsed) {
        classNames.push("el-bulkinteractionview");
    } else {
        classNames.push("interaction-list-item");
        if (isInvisible) {
            classNames.push("el-invisible");
        }
    }

    if (isCollapsed) {
        const expandHandler = (e) => {
            e.preventDefault();
            context.setCollapsed(id, false);
            return false;
        };

        return <div style={{ position: "relative" }} className={classNames.join(" ")}>
            <span className="el-date">{date.format("DD MMM YYYY").toUpperCase()} </span>
            <div className={`el-topic el-topic-${itype}`}>{interaction.Topic}</div>
            <button type="button" className="k-button el-interactionview-more" title="Show More Details" onClick={expandHandler}><IconCollapseDown {...arrowIconProps} /></button>
        </div>;
    } else {
        const ttinfoid = `info-${id}`;
        
        return <div style={{ position: "relative" }} className={classNames.join(" ")}>
            
            {date && date.isValid() 
                ? <div className="el-date">
                    <span className="m">{date.format("MMM")}</span>
                    <span className="d">{date.format("DD")}</span>
                    <span className="y">{date.format("YYYY")}</span>
                </div>
                : <div className="el-date"><span className="bad-date" title="Invalid or unknown date">N/A</span></div>
            }
            {isBulk && <button type="button" style={{ float: "right" }} className="k-button el-interactionview-hide" title="Collapse" onClick={(e) => {
                e.preventDefault();
                context.setCollapsed(id, true);
                return false;
            }}><IconCollapseUp {...arrowIconProps}  /></button>}

            <div className={`el-topic el-topic-${itype}`}>{interaction.Topic}</div>
            <div className={`el-details`}>{interaction.Details}</div>
            {isVisible && interaction.Files && !!interaction.Files.length && interaction.Files.map((v) => {
                const fileId = v!.StorageFileID;
                const editUrl = v!.EditUrl;
                const classes = editUrl ? "file-document attachment-link" : "attachment-link";
                return <div className="el-attachment" key={`attachment-${fileId}`}>
                    <a className={classes} href={attachmentUrl(fileId, editUrl!)} target="_blank">
                        <span className="attachment-icon"></span>
                        {v!.FileName}
                    </a>
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        if (window.confirm("Are you sure you want to remove this attachment?")) {
                            context.deleteAttachment(id, fileId);
                        }
                        return false;
                    }}>remove</a>
                </div>;
            })}

            <div className="small">
                {interaction.Owner && <span><strong>Owner: </strong> {interaction.Owner.Name} </span>}
                {interaction.Author && <span><strong>Author: </strong> {interaction.Author} </span>}
                {interaction.OfficeName && <span><strong>Office: </strong> {interaction.OfficeName} </span>}
            </div>
            <InteractionLink link={interaction.Link} />
            <InteractionTodo todo={interaction.Todo} due={interaction.Due} reference={interaction.Reference} />
            
            {!isVisible && <span><strong>{interaction.OfficeName}</strong> Private interaction</span>}

            {(interaction.Tags && !!interaction.Tags.length) && <InteractionTagDisplay tags={interaction.Tags} id={id} />}

            <div className="interaction-info">
                <span data-tip data-for={ttinfoid}>i</span>
                <ReactTooltip id={ttinfoid} place="right" type="dark" effect="float">
                    <span>ID: {id}<br /></span>
                    <span>Last Modified: {date.format("DD MMM YYYY h:mm:ss a")}<br /></span>
                    <span>Office: {interaction.OfficeName}<br /></span>
                </ReactTooltip>
            </div>
            {isVisible && <div className="el-interaction-info" title="This interaction is not specific to the individual being viewed">
                <InteractionType type={interaction.InteractionType} itype={itype} extra={interaction.ExtraData} />
            </div>}
            <div className="el-item-actions">
                {actions}
            </div>
        </div>;
    }
};



export const InteractionType = ({type, itype, extra} : {type?: string, itype: string, extra?: InteractionDisplayExtra }) => {
    const context = useInteraction();
    
    switch (type) {
        case "PositionInteraction":
            return <span>Position <strong>{extra && extra.Name}</strong> at <strong>{extra && extra.Name2}</strong></span>;
            
        case "GroupInteraction":
            return <span>Group <strong>{extra && extra.Name}</strong></span>;
            
        case "MultiInteraction":
            const multiID = parseInt(extra && extra.Name || "0", 10);
            const multiAction = (e) => {
                e.preventDefault();
                context.showElectorsForMultiInteraction(multiID);
                return false;
            };
            return <strong><a href="#" onClick={multiAction}>Attached to multiple electors</a></strong>;
            
        case "AddressInteraction":
            return <span>Address interaction <strong>{extra && extra.Name}</strong></span>;
        }

    if (itype == "InDirectInteraction") {
        return <strong style={{ color: "red" }}>Not attributed to an individual in the household</strong>;
    }
    
    return null;
}