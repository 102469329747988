import * as React from 'react';
import { CAPITAL_LETTER } from '@ml/common';
import * as moment from "moment";


export function getDateReferenceFromNow(start: moment.Moment): string {
    return start.from(moment.utc());
}

/**
 * Pass in generic object to output generic list of span. Useful to display a list of object in a ul, etc.
 */
export const objectToArrayOfSpan = (obj: {}, hideKey?: boolean, prepend?: string | JSX.Element): JSX.Element[] => {

    const arr: JSX.Element[] = [];

    if (!obj) return arr;

    for (var key in obj) {
        if (obj[key]) {
            arr.push(
                <span>
                    {(prepend ? prepend : " - ") }
                    {(hideKey ? <noscript/> : <strong>{key.replace(CAPITAL_LETTER, ' $1') } </strong>) }
                    { obj[key]}
                </span>
            );

        }
    }
    return arr;
}