import * as React from 'react';
import { List } from 'react-virtualized';

//TODO: @electrac/components

export interface IVirtualListRowsRenderedArgs {
    overscanStartIndex: number;
    overscanStopIndex: number;
    startIndex: number;
    stopIndex: number;
}

export type RowItemFunc<T> = (args: { index: number }) => T;
export type RowItemHeightFunc = (item: any) => number;

function isRowItemHeightFunc(obj: any): obj is RowItemHeightFunc {
    return typeof (obj) == 'function';
}

function listCount(list: any[]): number {
    return list.length;
    
}

function listItemAt(list: any[], index: number): any {
    return list[index];
}

export interface IVirtualListGroupProps {
    list: any[];
    heightPerRow?: number | RowItemHeightFunc;
    onRowsRendered?: (e: IVirtualListRowsRenderedArgs) => void;
    rowItemContentRenderer: (item: any, index: number, isScrolling: boolean) => JSX.Element;
    noItemsRenderer?: () => JSX.Element;
    width: any;
    height: any;
    scrollToItemIndex?: number;
    rootClassName?: string;
    itemClassName?: string | RowItemFunc<string>;
}

export interface IVirtualListGroupState {
    overscanRowCount: number;
    rowCount: number;
    scrollToIndex: number | undefined;
    useDynamicRowHeight: boolean;
    virtualScrollHeight: number;
    virtualScrollRowHeight?: number;
}

export class VirtualListGroup extends React.Component<IVirtualListGroupProps, IVirtualListGroupState> {
    constructor(props: IVirtualListGroupProps) {
        super(props);
        const initState = {
            overscanRowCount: 20,
            rowCount: listCount(props.list),
            scrollToIndex: undefined,
            useDynamicRowHeight: false,
            virtualScrollHeight: 300
        };
        const { heightPerRow } = props;
        let newHeight;
        if (isRowItemHeightFunc(heightPerRow)) {
            newHeight = 40;
        } else {
            newHeight = heightPerRow;
        }
        this.state = { ... initState, ... { virtualScrollRowHeight: newHeight } };
    }
    /**
     * Call this to force a re-render of the Virtual Scrolling container. As a performance optimization, the virtual
     * scrolling container only does shallow comparison. Underlying list changes will pass this shallow comparsion check
     */
    forceUpdateListGroup() {
        const scroll = this.refs['VirtualScroll'] as List;
        if (scroll) {
            //HACK: Typings bug
            (scroll as any).recomputeRowHeights(0);
            scroll.forceUpdate();
        }
    }
    componentWillReceiveProps(nextProps: IVirtualListGroupProps) {
        const { heightPerRow } = nextProps;
        let newHeight;
        if (isRowItemHeightFunc(heightPerRow)) {
            newHeight = this.state.virtualScrollRowHeight;
        } else {
            newHeight = heightPerRow;
        }
        this.setState({ ... this.state, ... { virtualScrollRowHeight: newHeight } });
    }
    private noRowsRenderer = () => {
        if (this.props.noItemsRenderer) {
            return this.props.noItemsRenderer();
        } else {
            return <div>
                <strong>No items</strong>
            </div>;
        }
    }
    private rowRenderer = (e: any) => {
        const { list, rowItemContentRenderer } = this.props;
        const datum = listItemAt(list, e.index);
        const itemStyle = { height: this.rowHeight(e), overflowY: "hidden", ...e.style };
        return <div key={e.index} style={itemStyle}>
            {rowItemContentRenderer(datum, e.index, e.isScrolling)}
        </div>;
    }
    private rowHeight = (e: any) => {
        const { heightPerRow } = this.props;
        if (isRowItemHeightFunc(heightPerRow)) {
            const { list } = this.props;
            const datum = listItemAt(list, e.index);
            return heightPerRow(datum);
        } else {
            return this.state.virtualScrollRowHeight;
        }
    }
    render(): JSX.Element {
        const { height, width, onRowsRendered, scrollToItemIndex } = this.props;
        const {
            overscanRowCount,
            rowCount
            
        } = this.state;
        return <List
            ref="VirtualScroll"
            height={height}
            className={this.props.rootClassName}
            scrollToIndex={scrollToItemIndex}
            overscanRowCount={overscanRowCount}
            noRowsRenderer={this.noRowsRenderer}
            onRowsRendered={onRowsRendered}
            rowCount={rowCount}
            rowHeight={this.rowHeight as any}
            rowRenderer={this.rowRenderer}
            width={width} />;
    }
}