import { DefaultInteractionAdd } from './DefaultInteractionAdd';
import { LinkType } from '@electrac/model';
import { DefaultClassicInteractionDisplayItem } from './Classic';
 
import { useInteraction } from './Context';
import { InteractionActionRegistry } from './Actions';


export * from "./NonVirtualizedInteractionPanel";
export * from "./InteractionPanel";
export * from "./ViewModel";
export * from "./topic-picker";
export * from "./tag-list";
export * from "./attachments";
export * from "./Actions";
export * from "./InteractionAttachmentsUpload";
export { useInteraction };



export interface IInterationOverides
{
    InteractionAdd?:(props:IInteractionAddProps)=>JSX.Element
} 

export const InteractionPart = {
    InteractionAdd: DefaultInteractionAdd,
    DisplayInteraction: DefaultClassicInteractionDisplayItem,
    getActions: InteractionActionRegistry.getAllActions
}

export interface IInteractionAddProps {
    canAddOrFilter?: boolean,
    onBeginAdd: () => void,
    LinkId:number,
    LinkType:LinkType
}








