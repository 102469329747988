import { KendoWidgetComponent, KendoWidgetName, IKendoWidgetPropsBase } from "./kendo-widget-component";

//TODO: @electrac/components

export type KendoEditorBuiltInTool = 'bold' |
    'italic' |
    'underline' |
    'strikethrough' |
    'subscript' |
    'superscript' |
    'fontName' |
    'fontSize' |
    'foreColor' |
    'backColor' |
    'justifyLeft' |
    'justifyCenter' |
    'justifyRight' |
    'justifyFull' |
    'insertUnorderedList' |
    'insertOrderedList' |
    'indent' |
    'outdent' |
    'createLink' |
    'unlink' |
    'insertImage' |
    'insertFile' |
    'createTable' |
    'addColumnLeft' |
    'addColumnRight' |
    'addRowAbove' |
    'addRowBelow' |
    'deleteRow' |
    'deleteColumn' |
    'formatting' |
    'cleanFormatting' |
    'insertHtml' |
    'viewHtml';

export interface IKendoEditorOptions { //The kendo d.ts was 99% correct, except the tools array can also be an array of strings (names of built-in commands)
    name?: string;
    domain?: string;
    encoded?: boolean;
    messages?: kendo.ui.EditorMessages;
    pdf?: kendo.ui.EditorPdf;
    resizable?: kendo.ui.EditorResizable;
    serialization?: kendo.ui.EditorSerialization;
    stylesheets?: any;
    tools?: (KendoEditorBuiltInTool | kendo.ui.EditorTool)[];
    imageBrowser?: kendo.ui.EditorImageBrowser;
    fileBrowser?: kendo.ui.EditorFileBrowser;
}

export interface IKendoEditorEvents {
    onChange?(e: kendo.ui.EditorEvent): void;
    onExecute?(e: kendo.ui.EditorExecuteEvent): void;
    onKeydown?(e: kendo.ui.EditorEvent): void;
    onKeyup?(e: kendo.ui.EditorEvent): void;
    onPaste?(e: kendo.ui.EditorPasteEvent): void;
    onPdfExport?(e: kendo.ui.EditorPdfExportEvent): void;
    onSelect?(e: kendo.ui.EditorEvent): void;
}

export interface IKendoEditorProps extends IKendoWidgetPropsBase<IKendoEditorOptions>, IKendoEditorEvents {
    content: string;
    onEditorMounted?: (widget: kendo.ui.Editor, element: JQuery<Element>) => void;
}

export interface IKendoEditorState {

}

@KendoWidgetName("kendoEditor")
export class KendoEditor extends KendoWidgetComponent<kendo.ui.Editor, IKendoEditorOptions, IKendoEditorProps, IKendoEditorState> {
    constructor(props: IKendoEditorProps) {
        super(props);
    }
    private onChange = (e: kendo.ui.EditorEvent) => {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }
    private onExecute = (e: kendo.ui.EditorExecuteEvent) => {
        if (this.props.onExecute) {
            this.props.onExecute(e);
        }
    }
    private onKeydown = (e: kendo.ui.EditorEvent) => {
        if (this.props.onKeydown) {
            this.props.onKeydown(e);
        }
    }
    private onKeyup = (e: kendo.ui.EditorEvent) => {
        if (this.props.onKeyup) {
            this.props.onKeyup(e);
        }
    }
    private onPaste = (e: kendo.ui.EditorPasteEvent) => {
        if (this.props.onPaste) {
            this.props.onPaste(e);
        }
    }
    private onPdfExport = (e: kendo.ui.EditorPdfExportEvent) => {
        if (this.props.onPdfExport) {
            this.props.onPdfExport(e);
        }
    }
    private onSelect = (e: kendo.ui.EditorEvent) => {
        if (this.props.onSelect) {
            this.props.onSelect(e);
        }
    }
    protected onKendoWidgetMounted() { //Override
        const { onEditorMounted } = this.props;
        if (!this.$widget) return; // should never happen
        this.$widget.value(this.props.content);
        
        if (onEditorMounted && this.$elem) {
            onEditorMounted(this.$widget, this.$elem);
        }
    }
    protected shouldPropBeExcludedFromWrappingElement(name: string): boolean { //Override
        return name == "onEditorMounted" || super.shouldPropBeExcludedFromWrappingElement(name);
    }
    getContent(): string {
        if (!this.$widget) throw new Error("this.$widget is not initalised, unable to getContent");
        return this.$widget.value();
    }
    protected augmentWidgetOptions(baseOptions: IKendoEditorOptions): any { //Override
        return {
            ...baseOptions,
            ...{
                change: this.onChange,
                execute: this.onExecute,
                keydown: this.onKeydown,
                keyup: this.onKeyup,
                paste: this.onPaste,
                pdfExport: this.onPdfExport,
                select: this.onSelect
            }
        }
    }
    componentWillReceiveProps(nextProps: IKendoEditorProps) {
        if (this.props.content != nextProps.content) {
            this.$widget && this.$widget.value(nextProps.content);
        }
    }
}