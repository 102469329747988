import { ItemsCallback, ITextValuePair } from './common';

import * as $ from 'jquery';
import { callApi } from '@electrac/api';
import { IdNamePair } from '@electrac/model';

/**
 * The base class of lookup data sources
 */
export abstract class LookupDataStore {
    public abstract getDefaultValue(): string | undefined;
    public abstract isValid(): boolean;
    public abstract getItems(callback: ItemsCallback, context?: string): void;
    public abstract resolveItemsFromValues(values: string[], callback: (resolvedItems: ITextValuePair[]) => void): void;
    public getLabelName(): string { return "Text"; }
    public getValueName(): string { return "Value"; }
}

/**
 * A lookup data store backed by a fixed array of ITextValuePair instances
 */
export class StaticLookupDataStore extends LookupDataStore {
    constructor(private items: ITextValuePair[], private firstItemAsInitialValue: boolean) {
        super();
    }
    public getDefaultValue(): string | undefined {
        if (this.isValid() && this.firstItemAsInitialValue) {
            return this.items[0].Value;
        }
        return undefined;
    }
    public resolveItemsFromValues(values: string[], callback: (resolvedItems: ITextValuePair[]) => void): void {
        const resolved = this.items.filter(i => values.indexOf(i.Value) >= 0);
        callback(resolved);
    }
    public isValid(): boolean {
        return this.items != null && this.items.length > 0;
    }
    public getItems(callback: ItemsCallback, context?: string): void {
        if (context != null) {
            callback(this.items.filter(it => it.Text.toLowerCase().indexOf(context.toLowerCase()) >= 0));
        }
        callback(this.items);
    }
}

/**
 * Options for the autocomplete lookup data store
 */
export interface IAutoCompleteLookupDataStoreOptions {
    /**
     * The remote URL
     */
    url: string;
    /**
     * The lookup type
     */
    type: string;
    nameProperty?: string;
    valueProperty?: string;
    param?: string;
    /**
     * A projection function that transforms the response data into our array of ITextValuePair instances
     */
    mapper?: (data: any) => ITextValuePair[];
}

/**
 * A lookup data store that retrieves its lookup values from a remote URL source
 */
export class AutoCompleteLookupDataStore extends LookupDataStore {
    private _options: IAutoCompleteLookupDataStoreOptions;
    constructor(options: IAutoCompleteLookupDataStoreOptions) {
        super();
        this._options = options;
    }
    public resolveItemsFromValues(values: string[], callback: ItemsCallback): void {
        console.log("resolving items", values);
        callApi<IdNamePair[]>(true, '/api/tag/resolve','POST', { Ids: values}).then((r) => {
            callback(r.map(a => ({ Text: a.Name!, Value: `${a.Id}` })));
        })
    }
    public isValid(): boolean {
        return true;
    }
    public getDefaultValue(): string | undefined {
        return undefined;
    }
    public getItems(callback: ItemsCallback, context?: string): void {
        if (!context || context == "") {
            callback([]);
        } else {
            const req: any = {};
            req[this._options.param || "term"] = context;
            $.getJSON(this._options.url, req, (data, status, xhr) => {
                if (this._options.mapper) {
                    callback(this._options.mapper(data));
                } else {
                    callback(data.map((i: any) => {
                        return { Text: i, Value: i };
                    }));
                }
            });
        }
    }
    public getLabelName(): string { return this._options.nameProperty || super.getLabelName(); }
    public getValueName(): string { return this._options.valueProperty || super.getValueName(); }
}

/**
 * Provides lookup values
 */
export class AdvancedListBuilderLookupDataStore {
    private static _stores: { [name: string]: LookupDataStore } = {};
    /**
     * Register a lookup data store for the given question type
     * @param name The question type
     * @param store The lookup data store
     */
    public static addStore(name: string, store: LookupDataStore) {
        AdvancedListBuilderLookupDataStore._stores[name] = store;
    }
    /**
     * Gets a lookup data store for the given question type
     * @param name The question type
     */
    public static getStore(name: string): LookupDataStore {
        return AdvancedListBuilderLookupDataStore._stores[name];
    }
}