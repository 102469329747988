import { QuestionEventHandler } from "@electrac/plugin";
import { logInfo } from "@ml/common";

export class QuestionEventHandlerSet {
    private handlers: { [id: number]: QuestionEventHandler[] };
    constructor() {
        this.handlers = {};
    }
    private indexOfHandler(questionId: number, handler: QuestionEventHandler): number {
        let index = -1;
        for (let i = 0; i < this.handlers[questionId].length; i++) {
            if (this.handlers[questionId][i] === handler) {
                index = i;
                break;
            }
        }
        return index;
    }
    public trigger(questionId: number, args: any): boolean {
        if (!this.handlers[questionId]) {
            return false;
        }
        let invoked = 0;
        const invokeArgs = { ...args, ...{ questionId: questionId } };
        for (const fn of this.handlers[questionId]) {
            fn(invokeArgs);
            invoked++;
        }
        if (invoked > 0) {
            logInfo(`Triggered handler for question (${questionId})`);
            logInfo(invokeArgs);
        }
        return (invoked > 0);
    }
    public register(questionId: number, handler: QuestionEventHandler): boolean {
        if (!questionId) {
            return false;
        }
        if (!this.handlers[questionId]) {
            this.handlers[questionId] = [];
        }
        const index = this.indexOfHandler(questionId, handler);
        if (index >= 0) {
            //logger.logWarn(`Already registered this handler for question id (${questionId})`);
            return false;
        }

        this.handlers[questionId].push(handler);
        //logger.logInfo(`Registered handler for question id (${questionId})`);
        return true;
    }
    public unregister(questionId: number, handler: QuestionEventHandler): boolean {
        if (!questionId) {
            return false;
        }
        if (!this.handlers[questionId]) {
            //logger.logWarn(`No pre-existing handlers registered for question id (${questionId})`);
            return false;
        }
        const index = this.indexOfHandler(questionId, handler);
        if (index >= 0) {
            this.handlers[questionId] = this.handlers[questionId].splice(index, 1);
            //logger.logInfo(`Un-registered question handler for question id (${questionId})`);
            return true;
        } else {
            //logger.logWarn(`No matching handler to un-register for question id (${questionId})`);
            return false;
        }
    }
}