import * as React from 'react';
import { useState, useEffect,useRef } from "react";
import RcCascader from 'rc-cascader';
import KeyCode from "rc-util/lib/KeyCode"
import "./styles.css"
import { FontelloFontIcon } from '@ml/components';

function arrayTreeFilter<T>(data: T[], 
  filterFn: { (o: T, level: number): boolean; (arg0: any, arg1: number): void; },
   options: { childrenKeyName: keyof T; }) {
  // options = options || {};
  const childrenKeyName = options.childrenKeyName;
  var children = data || [];
  var result: T[] = [];
  var level = 0;
  do {
      var foundItem = children.filter(function (item: any) {
          return filterFn(item, level);
      })[0];
      if (!foundItem) {
          break;
      }
      result.push(foundItem);
      children = (foundItem[childrenKeyName] || []) as T[];
      level += 1;
  } while (children.length > 0);
  return result;
}


export interface CascaderOptionType {
  value?: string;
  label?: React.ReactNode;
  disabled?: boolean;
  isLeaf?: boolean;
  loading?: boolean;
  children?: Array<CascaderOptionType>;
  [key: string]: any;
}

export interface FilledFieldNamesType {
  value: string;
  label: string;
  children: string;
}
export type FieldNamesType = Partial<FilledFieldNamesType>;

// todo check this for reals
function renderEmpty(name: string) {
  return <span>No items</span>;
}

type RenderEmptyHandler = (name:string) => React.ReactNode;

function classNames(input: { [cn: string]: boolean }) {
  let result = "";
  for (const c in input) {
    if (input[c]) {
      if (result) result += " ";
      result += c;
    }
  }
  return result;
}

export interface ShowSearchType {
  filter?: (inputValue: string, path: CascaderOptionType[], names: FilledFieldNamesType) => boolean;
  render?: (
    inputValue: string,
    path: CascaderOptionType[],
    prefixCls: string | undefined,
    names: FilledFieldNamesType,
  ) => React.ReactNode;
  sort?: (
    a: CascaderOptionType[],
    b: CascaderOptionType[],
    inputValue: string,
    names: FilledFieldNamesType,
  ) => number;
  matchInputWidth?: boolean;
  limit?: number | false;
}

export function Icon(props: any) {
  return <FontelloFontIcon name={props.type} />
}

export interface CascaderProps {
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  changeOnSelect?: boolean;
  options: CascaderOptionType[]
  value?: string[];
  placeholder?: string
  onChange?: (value: string[], selectedOptions?: CascaderOptionType[]) => void;
  showSearch?: boolean | ShowSearchType
  filedNames?: FieldNamesType;

  /** What to display if the search results in nothing */
  notFoundContent?: React.ReactNode;

  style?: React.CSSProperties;
  className?: string;

  displayRender?: (label: string[], selectedOptions?: CascaderOptionType[]) => React.ReactNode;
  allowClear?: boolean;
  disabled?: boolean;
  inputPrefixCls?: string;

  inputProps?: {
      style?: React.CSSProperties;
      autoComplete?: string;
      className?: string;
  },
  children?: React.ReactNode;
  prefixCls?: string;
  

  onPopupVisibleChange?: (popupVisible: boolean) => void;
}

function getFilledFieldNames(props: CascaderProps) {
  const fieldNames = props.filedNames || {};
  const names: FilledFieldNamesType = {
    children: fieldNames.children || 'children',
    label: fieldNames.label || 'label',
    value: fieldNames.value || 'value',
  };
  return names;
}

function flattenTree(
  options: CascaderOptionType[],
  props: CascaderProps,
  ancestor: CascaderOptionType[] = [],
) {
  const names: FilledFieldNamesType = getFilledFieldNames(props);
  let flattenOptions: CascaderOptionType[][] = [];
  const childrenName = names.children;
  options.forEach(option => {
    const path = ancestor.concat(option);
    if (props.changeOnSelect || !option[childrenName] || !option[childrenName].length) {
      flattenOptions.push(path);
    }
    if (option[childrenName]) {
      flattenOptions = flattenOptions.concat(flattenTree(option[childrenName], props, path));
    }
  });
  return flattenOptions;
}



/** The default method to perform filtering */
function defaultFilterOption(
  inputValue: string,
  path: CascaderOptionType[],
  names: FilledFieldNamesType,
) {
  const lowerInput = inputValue.toLocaleLowerCase();

  return path.some(option => {
    const label = option[names.label];
    if (!(typeof(label.indexOf) === 'function')) {
      return true; // hard to filter
    } else {
      return (label as string).toLocaleLowerCase().indexOf(lowerInput) > -1;
    }
  });
}

/** The default method to render options */
function defaultRenderFilteredOption(
  inputValue: string,
  path: CascaderOptionType[],
  prefixCls: string | undefined,
  names: FilledFieldNamesType,
) {
  const lowerInput = inputValue.toLocaleLowerCase();
  return path.map((option, index) => {
    const label = option[names.label];
    const node =
      (label as string).toLocaleLowerCase().indexOf(lowerInput) > -1
        ? highlightKeyword(label as string, lowerInput, prefixCls)
        : label;
    return index === 0 ? node : [' / ', node];
  });
}

function defaultSortFilteredOption(
  a: CascaderOptionType[],
  b: CascaderOptionType[],
  inputValue: string,
  names: FilledFieldNamesType,
) {
  function callback(elem: CascaderOptionType) {
    return (elem[names.label] as string).indexOf(inputValue) > -1;
  }

  return a.findIndex(callback) - b.findIndex(callback);
}

// We limit the filtered item count by default
const defaultLimit = 50;

function highlightKeyword(str: string, keyword: string, prefixCls: string | undefined) {
  // keyword comes in in lower case
  // we grab array indexes from the original str
  let index = 0;
  return str.toLocaleLowerCase().split(keyword).map((node: string, i: number) =>
    {

      if (i === 0) {
        
        const s = str.substring(index, index + node.length);
        index += node.length;
        return s;
      }

      const k = str.substring(index, index + keyword.length);
      index += keyword.length;
      const s = str.substring(index, index + node.length);

      index += node.length;
      return [
          <span className={`${prefixCls}-menu-item-keyword`} key="seperator">
            {k}
          </span>,
          s,
        ];
    },
  );
}


// function warningValueNotExist(list: CascaderOptionType[], fieldNames: FieldNamesType = {}) {
//   (list || []).forEach(item => {
//     const valueFieldName = fieldNames.value || 'value';
//     warning(valueFieldName in item, 'Cascader', 'Not found `value` in `options`.');
//     warningValueNotExist(item[fieldNames.children || 'children'], fieldNames);
//   });
// }


const defaultDisplayRender = (label: string[]) => label.join(' / ');


  function warning(check: boolean, compName: string, msg: string) {
    if (check) console.warn(compName, msg);
  }

export function Cascader(props: CascaderProps) {
  const { options, showSearch, onChange } = props;

  const [ flattenOptions, setFlattenOptions ] = useState(() => flattenTree(options, props));
  const [ inputValue, setInputValue ] = useState<string>("");
  const [ inputFocused, setInputFocused ] = useState(false);
  const [ value, setValueState ] = useState<any[]>([]);
  const [ popupVisible, setPopupVisible] = useState(false);
  const cachedOptions = useRef<any[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);


  useEffect(() => {
    if (showSearch) setFlattenOptions(flattenTree(options, props))
  }, [options, showSearch ]);
//     if (nextProps.showSearch && prevProps.options !== nextProps.options) {
//       newState.flattenOptions = flattenTree(nextProps.options, nextProps);
//     }








function generateFilteredOptions(prefixCls: string | undefined, renderEmpty: RenderEmptyHandler) {
  const { notFoundContent } = props;
  const names: FilledFieldNamesType = getFilledFieldNames(props);
  const {
    filter = defaultFilterOption,
    render = defaultRenderFilteredOption,
    sort = defaultSortFilteredOption,
    limit = defaultLimit,
  } = showSearch as ShowSearchType;
  

  // Limit the filter if needed
  let filtered: Array<CascaderOptionType[]>;
  if (limit > 0) {
    filtered = [];
    let matchCount = 0;

    // Perf optimization to filter items only below the limit
    flattenOptions.some(path => {
      const match = filter(inputValue, path, names);
      if (match) {
        filtered.push(path);
        matchCount += 1;
      }
      return matchCount >= limit;
    });
  } else {
    warning(
      typeof limit !== 'number',
      'Cascader',
      "'limit' of showSearch should be positive number or false.",
    );
    filtered = flattenOptions.filter(path => filter(inputValue, path, names));
  }

  filtered.sort((a, b) => sort(a, b, inputValue, names));

  if (filtered.length > 0) {
    return filtered.map((path: CascaderOptionType[]) => {
      return {
        __IS_FILTERED_OPTION: true,
        path,
        [names.label]: render(inputValue, path, prefixCls, names),
        [names.value]: path.map((o: CascaderOptionType) => o[names.value]),
        disabled: path.some((o: CascaderOptionType) => !!o.disabled),
      } as CascaderOptionType;
    });
  }
  return [
    {
      [names.label]: notFoundContent || renderEmpty('Cascader'),
      [names.value]: 'ANT_CASCADER_NOT_FOUND',
      disabled: true,
    },
  ];
}



  const setValue = (value: string[], selectedOptions: CascaderOptionType[] = []) => {
    if (!('value' in props)) {
      setValueState(value);
    }
    
    if (onChange) {
      onChange(value, selectedOptions);
    }
  };

  function getLabel() {
    const { options, displayRender = defaultDisplayRender as Function } = props;
    const names = getFilledFieldNames(props);
    
    const unwrappedValue = Array.isArray(value[0]) ? value[0] : value;
    const selectedOptions: CascaderOptionType[] = arrayTreeFilter(
      options,
      (o: CascaderOptionType, level: number) => o[names.value] === unwrappedValue[level],
      { childrenKeyName: names.children },
    );
    const label = selectedOptions.map(o => o[names.label]);
    return displayRender(label, selectedOptions);
  }


  const handleChange = (value: any, selectedOptions: CascaderOptionType[]) => {
    setInputValue('');
    if (selectedOptions[0].__IS_FILTERED_OPTION) {
      const unwrappedValue = value[0];
      const unwrappedSelectedOptions = selectedOptions[0].path;
      setValue(unwrappedValue, unwrappedSelectedOptions);
      return;
    }
    setValue(value, selectedOptions);
  };

  const handlePopupVisibleChange = (popupVisible: boolean) => {
    // controlled state
    if (!('popupVisible' in props)) {
      setPopupVisible(popupVisible);
      setInputFocused(popupVisible);
      if (!popupVisible) setInputValue('');
    }

    const { onPopupVisibleChange } = props;
    if (onPopupVisibleChange) {
      onPopupVisibleChange(popupVisible);
    }
  };

  const handleInputBlur = () => { setInputFocused(false); }

  const handleInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
    // Prevent `Trigger` behaviour.
    if (inputFocused || popupVisible) {
      e.stopPropagation();
      if (e.nativeEvent.stopImmediatePropagation) {
        e.nativeEvent.stopImmediatePropagation();
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // SPACE => https://github.com/ant-design/ant-design/issues/16871
    if (e.keyCode === KeyCode.BACKSPACE || e.keyCode === KeyCode.SPACE) {
      e.stopPropagation();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setInputValue(inputValue);
  };

  const clearSelection = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!inputValue) {
      setValue([]);
      handlePopupVisibleChange(false);
    } else {
      setInputValue('');
    }
  };



  // function focus() {
  //   inputRef.current && inputRef.current.focus();
  // }

  // function blur() {
  //   inputRef.current && inputRef.current.blur();
  // }

  // useImperativeHandle({
  //   focus, blur
  // });


//   return <RcCascader 
//     options={props.options}
//     value={props.value}
//     placeholder={props.placeholder}
//     onChange={props.onChange}
  
//   >
// <input type="text" />

//   </RcCascader>



    
    const {
      prefixCls = "cascader",
      // inputPrefixCls = "cascader-input",
      children,
      placeholder = "Type to search",
      
      disabled,
      style,
      allowClear,

      inputProps = {},
      
      // suffixIcon,
      notFoundContent
    } = props;

    


    const clearIcon =
      (allowClear && !disabled && value.length > 0) || inputValue ? (
        <Icon
          type="close-circle"
          theme="filled"
          className={`${prefixCls}-picker-clear`}
          onClick={clearSelection}
        />
      ) : null;
    const arrowCls = classNames({
      [`${prefixCls}-picker-arrow`]: true,
      [`${prefixCls}-picker-arrow-expand`]: popupVisible,
    });
    const pickerCls = classNames({
      [`${prefixCls}-picker`]: true,
      [`${prefixCls}-picker-with-value`]: !!inputValue,
      [`${prefixCls}-picker-disabled`]: !!disabled,
      // [`${prefixCls}-picker-${size}`]: !!size,
      [`${prefixCls}-picker-show-search`]: !!showSearch,
      [`${prefixCls}-picker-focused`]: inputFocused,
    });

    // Fix bug of https://github.com/facebook/react/pull/5004
    // and https://fb.me/react-unknown-prop
    // const inputProps = omit(otherProps, [
    //   'onChange',
    //   'options',
    //   'popupPlacement',
    //   'transitionName',
    //   'displayRender',
    //   'onPopupVisibleChange',
    //   'changeOnSelect',
    //   'expandTrigger',
    //   'popupVisible',
    //   'getPopupContainer',
    //   'loadData',
    //   'popupClassName',
    //   'filterOption',
    //   'renderFilteredOption',
    //   'sortFilteredOption',
    //   'notFoundContent',
    //   'fieldNames',
      
    // ]);

    
    const names: FilledFieldNamesType = getFilledFieldNames(props);

    let currentOptions;

    if (options && options.length > 0) {
      if (inputValue) {
        currentOptions = generateFilteredOptions(prefixCls, renderEmpty);
      } else {
        currentOptions = options;
      }
    } else {
      currentOptions = [
        {
          [names.label]: notFoundContent || renderEmpty('Cascader'),
          [names.value]: 'ANT_CASCADER_NOT_FOUND',
          disabled: true,
        },
      ];
    }
    // Dropdown menu should keep previous status until it is fully closed.
    if (!popupVisible) {
      currentOptions = cachedOptions.current;
    } else {
      cachedOptions.current = options;
    }

    const dropdownMenuColumnStyle: { width?: number; height?: string } = {};
    const isNotFound =
      (currentOptions || []).length === 1 && currentOptions && currentOptions[0][names.value] === 'ANT_CASCADER_NOT_FOUND';
    if (isNotFound) {
      dropdownMenuColumnStyle.height = 'auto'; // Height of one row.
    }
    // The default value of `matchInputWidth` is `true`
    const resultListMatchInputWidth = (showSearch as ShowSearchType).matchInputWidth !== false;
    if (resultListMatchInputWidth && (inputValue || isNotFound) && inputRef.current) {
      dropdownMenuColumnStyle.width = inputRef.current.offsetWidth;
    }

    // const inputIcon = (suffixIcon &&
    //   // (React.isValidElement<{ className?: string }>(suffixIcon) ? (
    //   //   React.cloneElement(suffixIcon, {
    //   //     className: classNames({
    //   //       [suffixIcon.props.className!]: suffixIcon.props.className,
    //   //       [`${prefixCls}-picker-arrow`]: true,
    //   //     }),
    //   //   })
    //   ) : (
    //     <span className={`${prefixCls}-picker-arrow`}>{suffixIcon}</span>
    //   ))) || <Icon type="down" className={arrowCls} />;
    const inputIcon = <Icon type="down" className={arrowCls} />;

    const input = children || (
      <span style={style} className={pickerCls}>
        <span className={`${prefixCls}-picker-label`}>{getLabel()}</span>
        <input type="text"
          {...inputProps}
          tabIndex={-1}
          ref={inputRef}
          // prefixCls={inputPrefixCls}
          placeholder={value && value.length > 0 ? undefined : placeholder}
          className={`${prefixCls}-input ${inputProps.className}`}
          value={inputValue}
          disabled={disabled}
          readOnly={!showSearch}
          autoComplete={inputProps.autoComplete || 'off'}
          onClick={showSearch ? handleInputClick : undefined}
          onBlur={showSearch ? handleInputBlur : undefined}
          onKeyDown={handleKeyDown}
          onChange={showSearch ? handleInputChange : undefined}
        />
        {clearIcon}
        {inputIcon}
      </span>
    );

    const expandIcon = <Icon type="right" />;

    const loadingIcon = (
      <span className={`${prefixCls}-menu-item-loading-icon`}>
        <Icon type="redo" spin />
      </span>
    );

    const getPopupContainer = props.getPopupContainer;
    const { inputIcon: ii, expandIcon: ei, loadingIcon: li, ...rest } = props as any;

    return (
      <RcCascader
        {...rest}
        prefixCls={prefixCls}
        getPopupContainer={getPopupContainer}
        options={currentOptions}
        value={value}
        popupVisible={popupVisible}
        onPopupVisibleChange={handlePopupVisibleChange}
        onChange={handleChange}
        dropdownMenuColumnStyle={dropdownMenuColumnStyle}
        expandIcon={expandIcon}
        loadingIcon={loadingIcon}
      >
        {input}
      </RcCascader>
    );



}
// import classNames from 'classnames';
// import KeyCode from 'rc-util/lib/KeyCode';
// import Input from '../input';
// import Icon from '../icon';


// import warning from '../_util/warning';





// export type CascaderExpandTrigger = 'click' | 'hover';


// export interface CascaderProps {
//   /** 可选项数据源 */
//   options: CascaderOptionType[];
//   /** 默认的选中项 */
//   defaultValue?: string[];
//   /** 指定选中项 */
//   value?: string[];
//   /** 选择完成后的回调 */
//   onChange?: (value: string[], selectedOptions?: CascaderOptionType[]) => void;
//   /** 选择后展示的渲染函数 */
//   displayRender?: (label: string[], selectedOptions?: CascaderOptionType[]) => React.ReactNode;
//   /** 自定义样式 */
//   style?: React.CSSProperties;
//   /** 自定义类名 */
//   className?: string;
//   /** 自定义浮层类名 */
//   popupClassName?: string;
//   /** 浮层预设位置：`bottomLeft` `bottomRight` `topLeft` `topRight` */
//   popupPlacement?: string;
//   /** 输入框占位文本 */
//   placeholder?: string;
//   /** 输入框大小，可选 `large` `default` `small` */
//   size?: string;
//   /** 禁用 */
//   disabled?: boolean;
//   /** 是否支持清除 */
//   allowClear?: boolean;
//   showSearch?: boolean | ShowSearchType;
//   notFoundContent?: React.ReactNode;
//   loadData?: (selectedOptions?: CascaderOptionType[]) => void;
//   /** 次级菜单的展开方式，可选 'click' 和 'hover' */
//   expandTrigger?: CascaderExpandTrigger;
//   /** 当此项为 true 时，点选每级菜单选项值都会发生变化 */
//   changeOnSelect?: boolean;
//   /** 浮层可见变化时回调 */
//   onPopupVisibleChange?: (popupVisible: boolean) => void;
//   prefixCls?: string;
//   inputPrefixCls?: string;
//   getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
//   popupVisible?: boolean;
//   /** use this after antd@3.7.0 */
//   fieldNames?: FieldNamesType;
//   /** typo props name before antd@3.7.0 */
//   filedNames?: FieldNamesType;
//   suffixIcon?: React.ReactNode;
// }

// export interface CascaderState {
//   inputFocused: boolean;
//   inputValue: string;
//   value: string[];
//   popupVisible: boolean | undefined;
//   flattenOptions: CascaderOptionType[][] | undefined;
//   prevProps: CascaderProps;
// }

// interface CascaderLocale {
//   placeholder?: string;
// }



// class Cascader extends React.Component<CascaderProps, CascaderState> {
//   static defaultProps = {
//     placeholder: 'Please select',
//     transitionName: 'slide-up',
//     popupPlacement: 'bottomLeft',
//     options: [],
//     disabled: false,
//     allowClear: true,
//   };

//   static getDerivedStateFromProps(nextProps: CascaderProps, { prevProps }: CascaderState) {
//     const newState: Partial<CascaderState> = {
//       prevProps: nextProps,
//     };

//     if ('value' in nextProps) {
//       newState.value = nextProps.value || [];
//     }
//     if ('popupVisible' in nextProps) {
//       newState.popupVisible = nextProps.popupVisible;
//     }
//     if (nextProps.showSearch && prevProps.options !== nextProps.options) {
//       newState.flattenOptions = flattenTree(nextProps.options, nextProps);
//     }

//     if (process.env.NODE_ENV !== 'production' && nextProps.options) {
//       warningValueNotExist(nextProps.options, getFieldNames(nextProps));
//     }

//     return newState;
//   }

//   cachedOptions: CascaderOptionType[] = [];

//   private input: Input;

//   constructor(props: CascaderProps) {
//     super(props);
//     this.state = {
//       value: props.value || props.defaultValue || [],
//       inputValue: '',
//       inputFocused: false,
//       popupVisible: props.popupVisible,
//       flattenOptions: props.showSearch ? flattenTree(props.options, props) : undefined,
//       prevProps: props,
//     };
//   }

//   renderCascader = (
//     { getPopupContainer: getContextPopupContainer, getPrefixCls, renderEmpty }: ConfigConsumerProps,
//     locale: CascaderLocale,
//   ) => {
//   };

//   render() {
//     return (
//       <ConfigConsumer>
//         {(configArgument: ConfigConsumerProps) => (
//           <LocaleReceiver>{locale => this.renderCascader(configArgument, locale)}</LocaleReceiver>
//         )}
//       </ConfigConsumer>
//     );
//   }
// }

// polyfill(Cascader);

// export default Cascader;
