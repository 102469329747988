import * as React from "react";
declare var __DEV__: boolean;

export interface IAssertProps {
    condition: boolean;
    message: string;
    children?: any;
}

export const Assert: React.StatelessComponent<IAssertProps> = ({ condition, message, children }) => {
    if (__DEV__) {
        if (condition === false) {
            return <div className="alert alert-danger dev-assertion-failure">Assertion failure: {message}</div>;
        }
    }
    return children;
}