import * as React from 'react';
import * as ElectracAPI from "@electrac/model";
//import { Multiselect } from 'react-widgets';
import * as Multiselect from 'react-widgets/lib/Multiselect';


import { getTags } from '@electrac/api';
import debounce = require('lodash.debounce');

//TODO: @electrac/components

export interface ITagListProps {
    value: ElectracAPI.IdNamePair[];
    onChange: (e: ElectracAPI.IdNamePair[]) => void;
    allowCreate?: boolean | 'onFilter';
}

/**
 * Used to denote new tags
 */
export const NEW_TAG_ID = -1;
/**
 * Used to denote existing tags (when initializing the component)
 */
export const EXISTING_TAG_ID = 0;

export class TagList extends React.Component<ITagListProps, any> {
    private fnTagSearchDebounced: (searchTerm: string) => void;
    constructor(props) {
        super(props);
        this.fnTagSearchDebounced = debounce((arg) => this.onTagSearchDebounced(arg), 500);
        this.state = {
            isSearchingTags: false,
            availableTags: []
        };
    }
    private onChange = (e) => {
        this.props.onChange(e);
    }
    private onTagSearchDebounced(searchTerm: string) {
        this.setState({ isSearchingTags: true });
        getTags(true, searchTerm).then(res => {
            this.setState({ isSearchingTags: false, availableTags: res });
        }).catch(err => {
            this.setState({
                isSearchingTags: false,
                availableTags: []
            });
        });
    }
    private onTagSearch = (searchTerm: string) => {
        this.fnTagSearchDebounced(searchTerm);
    }
    private onCreatingNewTag = (searchTerm: string) => {
        const tags = [...this.props.value, { Name: searchTerm, Id: NEW_TAG_ID }];
        this.props.onChange(tags);
    }
    render(): JSX.Element {
        return <Multiselect
            value={this.props.value}
            data={[...this.state.availableTags, ...this.props.value]}
            filter="contains"
            textField="Name"
            allowCreate={this.props.allowCreate}
            onChange={this.onChange}
            onCreate={this.onCreatingNewTag}
            onSearch={this.onTagSearch}
            busy={this.state.isSearchingTags} />;
    }
}