import { CCError } from './contracts';


/**
 * A client authentication handle for mobile apps
 */
export interface IApiContext {
    token: string;
    officeID?: number;
    pin?: number;
}

/**
 * Represents a "handle" to a client authentication token needed for calling any API in Campaign Central. This basically
 * determines whether to use fetch() + JWT token or jQuery AJAX for the underlying calls.
 */
export type ApiContext = IApiContext | boolean;

/**
 * Type guard to determine if the given parameter is an IApiContext
 * @param context
 */
function isApiContext(context: any): context is IApiContext {
    return context != null && context.token != null;
}

/**
 * The Campaign Central endpoint URL
 */
declare const __BASEURL__: string;

export function getFullUrl(relPart: string): string {
    let baseUrl = "";
    if (typeof(__BASEURL__) != 'undefined' && __BASEURL__ != null && __BASEURL__ != "") {
        baseUrl = __BASEURL__;
    }
    return baseUrl + relPart;
}

/**
 * A helper function to invoke a web service API
 */
export function callApi<T>(token: ApiContext, url: string, method: "GET" | "POST" | "PUT" | "DELETE", body?: any): Promise<T> {
    if (typeof(body) !== "string") {
        body = JSON.stringify(body);
    }

    // ensure we are using proper URL
    url = getFullUrl(url);

    if (isApiContext(token)) {

        // the X-Requested-With is a hack to make the login failure return a 401 rather than a redirect
        const headers: any = {
            'Accept': 'application/json, *.*',
            //'Cookie': 'OfficeID='
            'Authorization': 'Bearer ' + token.token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        };
        if (token.officeID) {
            headers["X-CC-OFFICE-ID"] = token.officeID;
        }
        if (token.pin) {
            headers["X-CC-PIN"] = token.pin;
        }
        return new Promise<T>((resolve, reject) => {
            fetch(url, {
                method: method,
                body: body,
                headers: headers
            }).then(r => {
                if (r.ok) {
                    r.json().then(resolve);
                } else {
                    r.json().then(err => {
                        reject(new CCError(err.ExceptionMessage || `Request to ${r.url} returned status of ${r.status}: ${r.statusText}`));
                    }).catch(err => {
                        reject(new CCError(`Request to ${r.url} returned status of ${r.status}: ${r.statusText}`));
                    });
                }
            }).catch(e => {
                reject(e);
            });
        });
    } else {

        return new Promise((resolve, reject) => {
            $.ajax({
                url: url,
                method: method,
                contentType: "application/json",
                dataType: "json",
                data: body
            }).then<any, any>(res => {
                resolve(res);
            }).fail(err => {
                try {
                    const resp = JSON.parse(err.responseText);
                    if (resp.ExceptionMessage) {
                        reject(new CCError(resp.ExceptionMessage));
                    } else if (resp.Message) {
                        reject(new CCError(resp.Message));
                    } else {
                        reject(new CCError(err.responseText));
                    }
                } catch (e) {
                    console.log('Error occurred parsing the AJAX request %o', e);
                    reject(new CCError(e.message));
                }
            });
        });
    }
}

export function callApiFormData<T>(token: ApiContext, url: string, method: "GET" | "POST", files: any[]): Promise<T> {
    if (files.length <= 0)
        throw new CCError("No files were given which is unexpected");
    const fd = new FormData();
    for (let i = 0; i < files.length; i++) {
        fd.append("file" + [i], files[i]);
    }

    if (isApiContext(token)) {
        throw new CCError("This operation is not supported");
    } else {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: url,
                type: method,
                processData: false,
                contentType: false,
                data: fd
            }).then(resolve).fail(reject);
        });
    }
}
