import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as ElectracAPI from "@electrac/model";
import {
    RespondentField,
    ISurveyAnswer,
    IManualSurveyDraft,
    IManualSurveyResponseImmutable,
    ISurveyImmutable
} from './contracts';
import {
    SurveyContainer,
    SurveyContainerMode,

    IFieldValidationError
} from './container';
import {
    SurveyContainerStyle,
    ISurveyContainerTheme,
    SurveyButtonStyle
} from "@electrac/plugin"
import {
    BootstrapSurveyTheme
} from "./SurveyContainerTheme";


import { ManualSurveyRespondentDetails } from './ManualSurveyRespondentDetails';
import { BsInputSize } from '@electrac/components';
import { ApiContext
     } from '@electrac/api';
import { ReactNode } from 'react';
var ReactModal = require('react-modal');


///Umbraco needs to be manually updated when build_survey js is changed. Bump the version up manually on the file name in Umbraco.
export interface IManualSurveyEntryProps {
    showRequiredFieldsAsModal?: boolean;
    showSurveyErrorsAsModal?: boolean;
    disableLabels?: boolean;
    enableCustomVoteIntention?: boolean;
    debugMode?: boolean;
    respondentDetailsRequired?: boolean;
    respondentTitle?: string;
    voteModel?: ElectracAPI.VoteModel;
    persuasionModel?: ElectracAPI.StandardVoteOutput;
    theme?: ISurveyContainerTheme;
    survey: ISurveyImmutable;
    onChange?: (response: IManualSurveyDraft) => void;
    onComplete?: (response: IManualSurveyDraft, callback: (success: boolean) => void) => void;
    /**
     * The date format to use for any date fields
     */
    dateFormat?: string;
    size?: BsInputSize;
    requiredFields?: RespondentField[];
    hideSubmit?: boolean;
    customElectorSelector?: () => number[];
    token?: ApiContext;
    validateAlways?: boolean;
    submitLabel?: ReactNode;
}

//NOTE: Bypassing redux for manual survey drafts for the sake of simplicity. We still
//use redux for establishing available surveys and current loaded survey.

export interface ISurveyEntryPageState {
    draft?: IManualSurveyResponseImmutable;
    respondentErrors?: string[];
    openRequiredFieldsModal?: boolean;
    //openSurveyErrorsModal?: boolean;

}

export class ManualSurveyEntry extends React.Component<IManualSurveyEntryProps, ISurveyEntryPageState> {
    
    private container: SurveyContainer | null;
    private details?: ManualSurveyRespondentDetails | null;
    constructor(props: IManualSurveyEntryProps) {
        super(props);
        this.state = {
            respondentErrors: [],
            openRequiredFieldsModal: true
        };
        this.container = null;
        this.details = null;
    }
    private prepareSurvey(props: IManualSurveyEntryProps) {
        const surveyID = props.survey.SurveyID;
        let currentDraft = this.state.draft;
        if (!currentDraft) {
            currentDraft = {
                SurveyId: surveyID,
                Answers: {}
            };
        }

        if (currentDraft.SurveyId != surveyID) {
            currentDraft = {
                ...currentDraft,
                Answers: {},
                SurveyId: surveyID
            }
        }

        this.setState({ ... this.state, ... { draft: currentDraft } });;
        if (this.props.onChange) {
            this.props.onChange(currentDraft);
        }
    }
   
    public selectedPersonChange(ids: number[]) {
        if (this.container)
            this.container.onSelectedElectorsChanged(ids, []);
    }
    public validateSync(): IFieldValidationError[] {
        if (this.container) {
            return this.container.validateSync();
        }
        return [];
    }
   
    componentDidMount() {
        this.prepareSurvey(this.props);
    }
    componentWillMount() {
        ReactModal.setAppElement('body');
    }
    componentWillReceiveProps(nextProps: IManualSurveyEntryProps) {
        if (nextProps.survey != null && (!this.props.survey || nextProps.survey.SurveyID != this.props.survey.SurveyID)) {
            this.prepareSurvey(nextProps);
        }
    }
    onComplete = (callback: (success: boolean) => void) => {
        if (!this.state.draft){
            this.setState({ respondentErrors: [ 'Missing draft'] });
            callback(false);
            return;
        }
        if (this.props.respondentDetailsRequired && this.details) {
            const errors = this.details.validate();
            this.setState({ respondentErrors: errors });
            if (errors.length > 0) {
                callback(false);
                return;
            }
        }
        if (this.props.onComplete)
            this.props.onComplete(this.state.draft, callback);
    }
    onRespondentChange = (field: RespondentField, value: string) => {
        let currentDraft = this.state.draft;
        if (currentDraft != null) {
            currentDraft = { 
                ...currentDraft,
                [field]: value
            };
            
            this.setState({ draft: currentDraft });

            if (this.props.onChange) {
                this.props.onChange(currentDraft);
            }
        }
    }
    onQuestionSet = (questionId: number, answer: string) => {
        let currentDraft = this.state.draft;
        if (currentDraft != null && currentDraft.SurveyId != null) {
            currentDraft = {
                ...currentDraft,
                Answers:  {
                    ...currentDraft.Answers,
                    [questionId]: {
                        Answer: answer,
                        QuestionID: questionId
                    }
                }
            }
            this.setState({ draft: currentDraft });
            if (this.props.onChange) {
                this.props.onChange(currentDraft);
            }
        }
    }
    onChoiceQuestionSet =(questionId: number, choices: number[], otherAnswer: string) => {
        let currentDraft = this.state.draft;
        if (currentDraft != null && currentDraft.SurveyId != null) {
            const answer: ISurveyAnswer = {
                Answer: otherAnswer,
                Choices: choices.map((v, i) => {
                    return {
                        ID: v,
                        Selected: true,
                        Order: i,
                        Amount: 0
                    };
                }),
                QuestionID: questionId
            };

            currentDraft = {
                ...currentDraft,
                Answers: {
                    ...currentDraft.Answers,
                    [questionId]: answer
                }
            }
            
            this.setState({ draft: currentDraft });
            if (this.props.onChange) {
                this.props.onChange(currentDraft);
            }
        }
    }
    invalidateEventAnswers() {
        if (this.container) {
            const eids = this.container.getEventQuestionIds();
            if (eids.length > 0) {
                this.container.clearAnswers(eids);
            }
        }
    }
    reset(): void {
        if (this.details != null) {
            this.details.reset();
        }
        const surveyID = this.props.survey.SurveyID;
        let currentDraft = this.state.draft;
        currentDraft = {
            SurveyId: surveyID,
            Answers: {}
        };
        this.setState({ draft: currentDraft });

        if (this.container) {
            this.container.clearValidationState();
        }
        if (this.props.onChange) {
            this.props.onChange(currentDraft);
        }
    }
    render(): JSX.Element {
        const { survey, respondentTitle, requiredFields, disableLabels, respondentDetailsRequired, showRequiredFieldsAsModal } = this.props;
        const { respondentErrors, openRequiredFieldsModal } = this.state;
        let theme: ISurveyContainerTheme;
        if (this.props.theme) {
            theme = this.props.theme;
        } else {
            theme = new BootstrapSurveyTheme();
        }
        return <div id="ManualSurveyContainer">
            {(() => {
                if (respondentDetailsRequired) {
                    return <div className={theme.getContainerClasses(SurveyContainerStyle.RespondentDetails) }>
                        <ManualSurveyRespondentDetails
                            ref={(component) => this.details = component}
                            requiredFields={requiredFields}
                            theme={theme}
                            disableLabels={disableLabels}
                            title={respondentTitle}
                            onChange={this.onRespondentChange} />
                        {(() => {
                            if (respondentErrors && respondentErrors.length > 0) {

                                const listOfErrors = (
                                    <div className={theme.getContainerClasses(SurveyContainerStyle.Error) }>
                                        <strong>The following fields are required</strong>
                                        <ul>
                                            {respondentErrors.map((err, index) => <li key={`respondent-error-${index}`}>{err}</li>) }
                                        </ul>
                                    </div>
                                );
                                if (showRequiredFieldsAsModal) {
                                    return (<div>
                                        {listOfErrors}
                                        <ReactModal
                                            isOpen={openRequiredFieldsModal}
                                            shouldCloseOnOverlayClick={true}
                                            contentLabel="The following fields are required"
                                            style= {
                                                {
                                                    content: {
                                                        top: '50%',
                                                        left: '50%',
                                                        right: 'auto',
                                                        bottom: 'auto',
                                                        marginRight: '-50%',
                                                        transform: 'translate(-50%, -50%)'
                                                    }
                                                }
                                            }
                                            >

                                            {listOfErrors}
                                            <br/>

                                            <div style={{ textAlign: "center" }}> <a className={theme.getButtonClass(SurveyButtonStyle.Info) }
                                                onClick={() => { this.setState({ openRequiredFieldsModal: false }); } }>Ok</a>
                                            </div>
                                        


                                        </ReactModal>

                                    </div>)

                                } else return listOfErrors;
                            } else return null;
                        })() }
                    </div>;
                } else return null;
            })() }
            <SurveyContainer
                showSurveyErrorsAsModal={this.props.showSurveyErrorsAsModal}
                isOnline={true}
                ref={(component) => this.container = component}
                mode={SurveyContainerMode.Manual}
                debugMode={this.props.debugMode}
                survey={survey}
                theme={theme}
                voteModel={this.props.voteModel}
                persuasionModel={this.props.persuasionModel}
                size={this.props.size}
                dateFormat={this.props.dateFormat}
                draft={this.state.draft}
                token={this.props.token || true}
                hideSubmit={this.props.hideSubmit}
                submitLabel={this.props.submitLabel}
                customElectorSelector={this.props.customElectorSelector}
                enableCustomVoteIntention={this.props.enableCustomVoteIntention}
                onComplete={this.onComplete}
                onQuestionSet={this.onQuestionSet}
                onChoiceQuestionSet={this.onChoiceQuestionSet}
                validateAlways={this.props.validateAlways} />
        </div>;
    }
}

export interface IManualSurveyOptions {
    debugMode?: boolean;
    respondentTitle?: string;
    showRequiredFieldsAsModal?: boolean;
    disableLabels?: boolean;
    respondentDetailsRequired?: boolean;
    enableCustomVoteIntention?: boolean;
    survey: ElectracAPI.SurveyModel;
    voteModel?: ElectracAPI.VoteModel;
    persuasionModel?: ElectracAPI.StandardVoteOutput;
    theme?: ISurveyContainerTheme;
    size?: BsInputSize;
    /**
     * The date format to use for any date fields
     */
    dateFormat?: string;
    onChange: (response: ElectracAPI.ManualSurveyResponse) => void;
    onSubmit: (response: IManualSurveyDraft, callback: (success: boolean) => void) => void;
    requiredFields?: RespondentField[];
    hideSubmit?: boolean;
    customElectorSelector?: () => number[];
    token?: ApiContext;
    respondentSubmitButtonText?: ReactNode;
}

export class ManualSurveyEntryViewModel {
    private node: Element | null = null;
    private options: IManualSurveyOptions;
    private component: ManualSurveyEntry | null = null;
    constructor(options: IManualSurveyOptions) {
        this.options = options;
    }
    unmount() {
        if (this.node) {
            ReactDOM.unmountComponentAtNode(this.node);
            this.node = null;
            this.component = null;
        }
    }
    validate(): IFieldValidationError[] {
        if (this.component) {
            return this.component.validateSync();
        }
        return [];
    }
    reset() {
        if (this.component) {
            this.component.reset();
        }
    }
    invalidateEventAnswers() {
        if (this.component) {
            this.component.invalidateEventAnswers();
        }
    }
    forceUpdate() {
        if (this.component) {
            this.component.forceUpdate();
        }
    }
    updateModel(model: ElectracAPI.SurveyModel, debugMode?: boolean) {
        this.options.survey = { ...model } as any;
        this.options.debugMode = debugMode;
        if (this.node)
            this.mount(this.node);
    }
    mount(node: Element) {
        this.node = node;
        const surveyImmutable = this.options.survey;
        ReactDOM.render(<ManualSurveyEntry
            ref={(r) => this.component = r}
            debugMode={this.options.debugMode}
            theme={this.options.theme}
            voteModel={this.options.voteModel}
            persuasionModel={this.options.persuasionModel}
            requiredFields={this.options.requiredFields}
            respondentTitle={this.options.respondentTitle}
            showRequiredFieldsAsModal={this.options.showRequiredFieldsAsModal}
            showSurveyErrorsAsModal={this.options.showRequiredFieldsAsModal}
            disableLabels={this.options.disableLabels}
            onComplete={this.options.onSubmit}
            onChange={this.options.onChange}
            dateFormat={this.options.dateFormat}
            size={this.options.size}
            hideSubmit={this.options.hideSubmit}
            submitLabel={this.options.respondentSubmitButtonText}
            token={this.options.token}
            enableCustomVoteIntention={this.options.enableCustomVoteIntention}
            respondentDetailsRequired={this.options.respondentDetailsRequired}
            customElectorSelector={this.options.customElectorSelector}
            survey={surveyImmutable} />, this.node);
    }
}