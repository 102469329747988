export { renderReduxComponent } from './default-redux-store';
export { RwDropdownList, RwDropdownFieldSimple } from "./rw-dropdown-list";


export { RwDatePicker, RwDateTimePicker } from "./rw-datetime-pickers";
export { RwMultiselect } from "./rw-multiselect";


//these are actually prop names of redux form but technically they dont have to be, hence it is in presentationals instead of containers->IReduxForm
export interface IFieldProps {
    meta?: IFieldMetaProps;
    input?: any;
}

//as of redux-form rc4 redux form natively provides input and meta props
export interface IFieldMetaProps {
    error?: string;
    touched: boolean;
}