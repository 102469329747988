import * as ElectracAPI from "@electrac/model";
import { callApi, ApiContext, IAddress, IGeographicCoordinate } from "@electrac/api";

import { QuestionModel, SurveyModel, SurveyResponseAnswerModel, ConditionModel } from '@electrac/model';
import { IQuestionDesignerSettings } from './design/Model';



export type IPerson = ElectracAPI.WalkListPerson;



export type IGeographicCoordinateImmutable = Readonly<IGeographicCoordinate>;



export type IPersonDetails = ElectracAPI.PersonDetailsModel;

export type IPersonDetailsImmutable = Readonly<IPersonDetails>;

export type IElectorImmutable = Readonly<IPerson>;
export type IAddressImmutable = Readonly<IAddress>;

export type ElectorEntryItemDisplayFormatter = (e: ElectracAPI.WalkListPerson) => string|JSX.Element;

/**
 * Valid question types
 */
export class SurveyQuestionType {
    public static get MerchandiseRequest(): string { return "A"; }
    public static get PartyID(): string { return "B"; }
    public static get VoteIdentification(): string { return "C"; }
    public static get Date(): string { return "D"; }
    public static get EmailAddress(): string { return "E"; }
    public static get RecallVoteIdentification(): string { return "F"; }
    public static get MobilePhone(): string { return "G"; }
    public static get Persuasion(): string { return "H"; }
    public static get Instruction(): string { return "I"; }
    public static get AddElectorate(): string { return "J"; }
    public static get AddIssue(): string { return "1"; }
    public static get AddActivity(): string { return "K"; }
    public static get TodoFollowUp(): string { return "L"; }
    public static get MultipleChoice(): string { return "M"; }
    public static get MultipleChoiceWithOtherEntry(): string { return "N"; }
    public static get SingleChoiceWithOtherEntry(): string { return "O"; }
    public static get Petition(): string { return "P"; }
    public static get Occupation(): string { return "Q"; }
    public static get Remarks(): string { return "R"; }
    public static get SingleChoice(): string { return "S"; }
    public static get TextEntry(): string { return "T"; }
    public static get RankedChoice(): string { return "U"; }
    public static get Plugin(): string { return "W"; }
    public static get AddPersonToEvent(): string { return "X"; }
    public static get YesNo(): string { return "Y"; }
    public static get EventConfirmation(): string { return "Z"; }
    public static get RankTopChoices(): string { return "#"; }
}

export type ISurvey = ElectracAPI.SurveyModel;

/**
 * Defines a survey
 */
/*
export interface ISurvey {
    SurveyID: any;
    Title: string;
    Description: string;
    Questions: ISurveyQuestion[];
}
*/

/**
 * Valid proprties of ISurvey
 */
// export type SurveyProperties = "SurveyID" | "Title" | "Description" | "Questions" | "ActiveElectionDate" | "AddressClassifications";

/**
 * An interface wrapper over an ISurvey made immutable 
 */
// export interface ISurveyImmutable extends IReadonly {   
//     get(key: SurveyProperties): any;
//     get(key: "SurveyID"): any;
//     get(key: "Title"): string;
//     get(key: "Description"): string;
//     get(key: "Questions"): Immutable.List<ISurveyQuestionImmutable>;
//     get(key: "AddressClassifications"): Immutable.List<IIdNamePairImmutable>;
//     get(key: "ActiveElectionDate"): string;
// }
export type ISurveyImmutable = Readonly<SurveyModel>;

export type ISurveyInfo = ElectracAPI.SurveySelect;

export type ISurveyInfoImmutable = Readonly<ISurveyInfo>;

export type IAvailableSurveyList = ElectracAPI.AvailableSurveyList;

export type IAvailableSurveyListImmutable = Readonly<{
    CurrentOffice?: Readonly<ElectracAPI.SurveySelect>[];
    /**
     *
     * System.Collections.Generic.IEnumerable<Electrac.Services.Contracts.Survey.SurveySelect> NotNullable Complex
     */
    Branch?: Readonly<ElectracAPI.SurveySelect>[];
    /**
     *
     * System.Collections.Generic.IEnumerable<Electrac.Services.Contracts.Survey.SurveySelect> NotNullable Complex
     */
    Global?: Readonly<ElectracAPI.SurveySelect>[];

}>;

export type ISurveyDraft = ElectracAPI.MobileSurveyDraft;

export type IManualSurveyDraft = ElectracAPI.ManualSurveyResponse;

//NOTE: names must match property names of IManualSurveyDraft
export type RespondentField = keyof(IManualSurveyDraft);// "LastName" | "FirstName" | "Address" | "Postcode" | "Email" | "Mobile";

/**
 * A draft of a filled survey
 */
// export interface ISurveyDraft {
//     Id: any;
//     SurveyId: any;
//     ListId: any;
//     AddressId: any; //TODO: rename addressId
//     /**
//      * The status of this survey. Use any value from SurveyStatus. Required for survey completion
//      */
//     Status: string;
//     People: number[];
//     /**
//      * Survey answers keyed on question id
//      */
//     Answers: { [key: number]: any|any[]; };
// }

// export type ManualSurveyResponseProperties = "SurveyId" | "Answers" ;

export interface IManualSurveyResponse {
    SurveyId: number;
    Answers: { [key: number]: ISurveyAnswerImmutable };
}

export type IManualSurveyResponseImmutable = Readonly<IManualSurveyResponse>;


// export type SurveyDraftProperties = "Id" | "SurveyId" | "ListId" | "AddressId" | "Status" | "People" | "Voted" | "Answers" | "StartDate" | "EndDate" | "Geom" | "FollowUp" | "AddressClassifications" | "UploadOn";

/**
 * An interface wrapper over an ISurveyDraft made immutable
 */

 export type ISurveyDraftImmutable = Readonly<ISurveyDraft>;
 
// export interface ISurveyDraftImmutable extends IReadonly {
//     get(key: SurveyDraftProperties): any;
//     get(key: "Id"): any;
//     get(key: "SurveyId"): any;
//     get(key: "ListId"): any;
//     get(key: "AddressId"): number;
//     get(key: "Status"): string;
//     get(key: "StartDate"): string;
//     get(key: "EndDate"): string;
//     get(key: "UploadOn"): string;
//     get(key: "Geom"): IGeographicCoordinateImmutable;
//     get(key: "People"): Immutable.List<number>;
//     get(key: "Voted"): Immutable.List<number>;
//     get(key: "AddressClassifications"): Immutable.List<number>;
//     get(key: "Answers"): Immutable.Map<number, ISurveyAnswerImmutable>;
//     get(key: "FollowUp"): string;
// }

// todo when tooling is updated
//type ImmutableSurveyDraft = {
//    get(key: k in keyof ElectracAPI.ISurveyDraft): ElectracAPI.ISurveyDraft[k]
//}

export type ISurveyResponseAnswerChoice = ElectracAPI.SurveyResponseChoiceModel;

export type ISurveyResponseAnswerChoiceImmutable = Readonly<ISurveyResponseAnswerChoice>;

export type ISurveyAnswer = ElectracAPI.SurveyResponseAnswerModel;

/*
export interface ISurveyAnswer {
    Choices?: ISurveyResponseAnswerChoice[];
    QuestionID: number;
    Answer?: string;
}
*/

// export type SurveyAnswerProperties = "QuestionID" | "Answer" | "Choices";

export type ISurveyAnswerImmutable = Readonly<SurveyResponseAnswerModel>;
/**
 * An interface wrapper over an ISurveyAnswerSingle made immutable
 */
// export interface ISurveyAnswerImmutable extends IReadonly {
//     get(key: SurveyAnswerProperties): any;
//     get(key: "QuestionID"): number;
//     get(key: "Answer"): string;
//     get(key: "Choices"): Immutable.List<ISurveyResponseAnswerChoiceImmutable>;
// }

/**
 * Defines a question in a survey
 */
export type ISurveyQuestion = ElectracAPI.QuestionModel;

/**
 * Defines a question in a survey
 */
/*
export interface ISurveyQuestion {
    SurveyQuestionID: any;
    AnswerTypeID: SurveyQuestionKind;
    Question: string;
    Description: string;
    Qorder?: number;
    MaxLength?: number;
    // For a single/multi choice question type, the list of available choices
    Choices?: ISurveyQuestionChoice[];
}
*/

/**
 * Valid properties of ISurveyQuestion
 */
// export type SurveyQuestionProperties = "SurveyQuestionID" | "AnswerTypeID" | "Question" | "Description" | "Qorder" | "Choices" | "MaxLength" | "Required" | "Condition";

/**
 * An interface wrapper over an ISurveyQuestion made immutable
 */
export type ISurveyQuestionImmutable = Readonly<QuestionModel & { 
    // Settings are client side only
    // I believe they are stored in JSON in the actual question
    Settings?: Partial<IQuestionDesignerSettings>;
    /*{
        DisplayCompact?: boolean
        RandomiseChoices?: boolean,
        AutoSelectIfOnlyOneChoice?: boolean,
        ChoiceDataSource?: string,
        ChoiceParameterBindings?: any,
        NoChoicesMessage?: string,
        FlyoutUp?: boolean,
        ShowDatePicker?: boolean,
        PluginID?: string,
        PluginConfiguration?: any
    }*/
 }>;

// extends IReadonly {
//     get(key: SurveyQuestionProperties): any;
//     get(key: "SurveyQuestionID"): number;
//     /**
//      * SurveyQuestionType
//      */
//     get(key: "AnswerTypeID"): string;
//     get(key: "Question"): string;
//     get(key: "Description"): string;
//     get(key: "Qorder"): number;
//     get(key: "MaxLength"): number;
//     /**
//      * For a single/multi choice question type, the list of available choices
//      */
//     get(key: "Choices"): Immutable.List<ISurveyQuestionChoiceImmutable>;
//     get(key: "Required"): boolean;

//     get(key: "Condition"): IConditionModelImmutable;
//     get(key: "Settings"): IReadonly;
// }

export type IConditionModelImmutable = Readonly<ConditionModel>;



export type IDisplayConditionModelImmutable = Readonly<ElectracAPI.DisplayConditionModel>;

// export interface IDisplayConditionModelImmutable extends IReadonly {
//     get(key: any): any;
//     get(key: "RootGroup"): IConditionGroupImmutable;
// }

// export interface IConditionGroup extends IConditionGroupChild {
//     Conditions: IConditionGroupChild[];
//     Operator: ElectracAPI.GroupOperator;
// }
export type IConditionGroupImmutable = Readonly<ElectracAPI.ConditionGroup>;

export type IConditionGroupChildImmutable = Readonly<ElectracAPI.ConditionGroupChild>;

export type IQuestionConditionImmutable = Readonly<ElectracAPI.QuestionCondition>;

// export interface IConditionGroupChildImmutable extends IReadonly {
//     get(key: any): any;
//     get(key: "Negate"): boolean;
//     get(key: "Kind"): ElectracAPI.ConditionGroupChildKind;
// }

// export interface IConditionGroupImmutable extends IConditionGroupChildImmutable {
//     get(key: any): any;
//     get(key: "Conditions"): Immutable.List<IConditionGroupChildImmutable>;
//     get(key: "Operator"): ElectracAPI.GroupOperator;
// }

// export interface IQuestionConditionImmutable extends IConditionGroupChildImmutable {
//     get(key: any): any;
//     get(key: "QuestionID"): number;
//     get(key: "Operator"): ElectracAPI.ConditionOperator;
//     get(key: "Value"): string;
// }

export type ISurveyQuestionChoice = ElectracAPI.ChoiceModel;

export type ISurveyQuestionChoiceImmutable = Readonly<ISurveyQuestionChoice>;

/**
 * Defines an aggregation of a household, its electors and the chosen survey
 */
export interface IHouseholdSurvey {
    house: IAddress;
    electors: IPerson[];
    survey: ISurvey;
}

export class SurveyStatus {
    /**
     * Respondent elector(s) were at home on the specified address
     */
    public static get Home() { return "Home"; }
    /**
     * Respondent elector(s) were not present at the specified address. This is just to indicate
     * that the person(s) referenced were not present (ie. They might be at work)
     */
    public static get NotHome() { return "Not Home"; }
    /**
     * Respondent elector(s) are not available to conduct the survey at this time and wish
     * to follow-up at a later time
     */
    public static get HomeFollowUp() { return "Home Follow-up"; }
    /**
     * The house at this address is inaccessible
     */
    public static get NotKnocked() { return "Not Knocked"; }
    /**
     * Indicates the people are not at the given address so will adjust their roll records as RTS (return to sender)
     */
    public static get NotAtAddress() { return "Not At Address"; }
}

export type IHouseholdSurveyImmutable = Readonly<IHouseholdSurvey>;

export interface ISurveyResponse {
    response?: ISurveyDraft;
    survey: IHouseholdSurvey;
}

export type ISurveyResponseImmutable = Readonly<ISurveyResponse>;


export interface SurveyAnswer {
    answer?: any;
    choices?: any[];
}

export type SurveyAnswerMap = { [questionID: number]: SurveyAnswer };





export function getSurveyDefinition(token: ApiContext, id: number): Promise<ISurvey> {
    //TODO: Cache locally and check for cached copy? (since we already have a loki collection for it)
    return callApi<ISurvey>(token, `/api/surveys/${id}`, 'GET');
}

export function getRemoteSurveys(token: ApiContext): Promise<IAvailableSurveyList> {
    return callApi<IAvailableSurveyList>(token, '/api/surveys', 'GET');
}

export function submitManualSurvey(token: ApiContext, survey: IManualSurveyDraft): Promise<any> {
    return callApi<any>(token, '/api/surveys/submitmanual', 'POST', survey);
}
