import * as React from "react";
import * as DropdownList from 'react-widgets/lib/DropdownList';
import { logError } from "@ml/common";
import * as Multiselect from 'react-widgets/lib/Multiselect';

export interface IAutoCompleteProps {
    value: any;
    initialData?: any[];
    multi?: boolean;
    fetchSuggestions: (searchTerm: string) => Promise<any[]>;
    onChange: (e: any) => void;
    /**
     * Resolves the value for the given item. If not specified, the item is assumed to be the value
     */
    getItemValue?: (item: any) => any;
    /**
     * A function that determines if the 2 items are equal. If not specified, the default equality comparison is used
     */
    compareItems?: (item: any, currentValue: any) => boolean;
    labelName?: string;
    valueName?: string;
    placeholder?: string;
}

export interface IAutoCompleteState {
    data: any[] | undefined,
    selectedItemData?: any[],
    isBusy: boolean
}

export class AutoComplete extends React.Component<IAutoCompleteProps, IAutoCompleteState> {
    constructor(props: IAutoCompleteProps) {
        super(props);
        this.state = {
            data: undefined,
            isBusy: false
        };
    }
    componentDidMount() {
        if (this.props.initialData) {
            this.setState({ data: this.props.initialData, selectedItemData: this.props.initialData });
        }
    }
    private onSelect = (e: any) => {
        // keep track of the added items
        this.setState({ selectedItemData: e });

        this.props.onChange(e);
    }
    private itemCompare(item: any, currentValue: any): boolean {
        if (this.props.compareItems) {
            return this.props.compareItems(item, currentValue);
        } else {
            return item == currentValue[this.props.valueName || 'Id'];
        }
    }
    private itemIsSelected(item: any, currentValues: any): boolean {
        if (Array.isArray(currentValues)) {
            return currentValues.filter(v => this.itemCompare(item, v)).length > 0;
        } else {
            return this.itemCompare(currentValues, item);
        }
    }
    private mergeSelection(items: any[], existingItems: any[]): any[] {
        const toAdd: any[] = [];
        for (const ei of existingItems) {
            if (items.filter(i => this.itemCompare(i, ei)).length == 0) {
                toAdd.push(ei);
            }
        }
        if (toAdd.length) {
            return [...items, ...toAdd];
        } else {
            return items;
        }
    }
    private onSearch = (searchTerm: string) => {
        const { fetchSuggestions } = this.props;
        console.log(`searching ${searchTerm}`)
        if (fetchSuggestions) {
            const data = this.state.data || this.props.initialData;
            const currentValues = this.props.value;

            // do nothing for empty search term
            if (currentValues && !currentValues.length && !searchTerm) return;

            const currentItems = data ? [ ...data.filter((i: any) => this.itemIsSelected(i, currentValues)) ] : [];
          
            this.setState({ isBusy: true });

            fetchSuggestions(searchTerm).then(res => {
                const newData = this.mergeSelection(res, currentItems);
                const newExtra = this.mergeSelection(newData, this.state.selectedItemData || []);
                this.setState({ isBusy: false, data: newExtra });
            }).catch(err => {
                this.setState({ isBusy: false });
                logError(err);
            });
        }
    }
    render(): JSX.Element {
        const data = this.state.data || this.props.initialData;
        if (this.props.multi == true) {
            return <Multiselect
                allowCreate={false}
                minLength={3}
                value={this.props.value}
                busy={this.state.isBusy}
                onSearch={this.onSearch}
                textField={this.props.labelName || "Name"}
                valueField={this.props.valueName || "Id"}
                onChange={this.onSelect}
                data={data}
                placeholder={this.props.placeholder}
                filter='contains' />;
        } else {
            return <DropdownList
                minLength={3}
                value={this.props.value}
                busy={this.state.isBusy}
                onSearch={this.onSearch}
                textField={this.props.labelName || "Name"}
                valueField={this.props.valueName || "Id"}
                onChange={this.onSelect}
                data={data}
                placeholder={this.props.placeholder}
                filter='contains' />;
        }
    }
}