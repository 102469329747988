import * as React from 'react';
import moment = require("moment");

import { Icons } from '@electrac/plugin';

import Button = require("react-bootstrap/lib/Button");
import OverlayTrigger = require("react-bootstrap/lib/OverlayTrigger");
import Popover = require("react-bootstrap/lib/Popover");

import { InteractionTag } from './InteractionPanel';
import { InteractionModel } from '@electrac/model';

export const DateDisplay = ({ date } : { date: moment.Moment}) => {
    if (date.isValid()) {
        //NOTE: May display dates "off-by-one" as this interaction display considers the time portion
        //where the partial view version does not.
    
        return <div style={{ position: "relative", top: "50%", transform: "translateY(-50%)" }}>
            <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "12pt", padding: 0, margin: 0 }}>{date.format("MMM")}</div>
            <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "24pt", padding: 0, margin: 0 }}>{date.format("DD")}</div>
            <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "12pt", padding: 0, margin: 0 }}>{date.format("YYYY")}</div>
        </div>;
    }


    return <div style={{ position: "relative", top: "50%", transform: "translateY(-50%)" }}>
        <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "24pt", padding: 0, margin: 0 }}>N/A</div>
    </div>

    
}

export const InteractionListItem = (props: { interaction: InteractionModel }) => {
    const { interaction } = props;
    
    const tags = interaction.Tags;
    
    return <div key={interaction.Id}>
        <div className="col-md-3 col-xs-2 alert alert-info">
            <DateDisplay date={moment(interaction.Date)} />
        </div>
        <div className="col-md-9 col-xs-10 well">
            <p><strong>{interaction.Topic}</strong></p>
            <p style={{ textOverflow: "ellipsis" }}>{interaction.Details}</p>
            {(() => {
                if (tags && tags.length > 0) {
                    if (tags.length == 1) {
                        return <p><InteractionTag value={tags[0]} /></p>;
                    } else {
                        const po = <Popover id={`tags-${interaction.Id}`} title="Tags">
                            {tags.map(t => {
                                return <p key={t}>{Icons.TAG} {t}</p>;
                            })}
                        </Popover>;
                        return <p>
                            <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={po}>
                                <Button bsStyle="info" bsSize="xsmall">{Icons.TAG} {tags.length} tags</Button>
                            </OverlayTrigger>
                        </p>;
                    }
                } else {
                    return null;
                }
            })()}
        </div>
    </div>;
};
