import * as React from 'react';
import { KendoSpinner } from '@ml/kendo-wrappers';
import { ErrorDisplay } from '@ml/components';
import { isError } from 'util';
import { CCError } from '@electrac/api';

//TODO: @electrac/components

export interface IPresentationContainerState<TModel>
{
    isLoading?: boolean;
    errorDetails?: any;
    model?: TModel;
}

const initialState = {
    isLoading: true,
    model: undefined,
    errorDetails: null
}

type PresentationActions<TModel> = 
    { type: 'START_LOADING' }
    | {type: 'LOAD_MORE' }
    | { type: 'ERROR', error: any }
    | { type: 'LOADED', value: TModel }

function presentationReducer<TModel>(state: IPresentationContainerState<TModel>, action: PresentationActions<TModel>): IPresentationContainerState<TModel>
{
    switch (action.type) {
        case "START_LOADING": return initialState;
        case "LOAD_MORE": return { ...state, isLoading: true }

        
        case "ERROR":
            const err = isError(action.error) ? action.error : new CCError(action.error)
        return {
            isLoading: false,
            model: undefined,
            errorDetails: err
            
        }
        case "LOADED": return {
                isLoading: false,
                model: action.value,
                errorDetails: null
            
        }
    }
}

export function usePresentation<TModel>(initialModel?: TModel) {
    return React.useReducer((ps, a) => presentationReducer<TModel>(ps,a), { ...initialState, model: initialModel });
}

export function transitionToInitial<TModel>(): IPresentationContainerState<TModel> {
    return initialState;
}

export function transitionToLoaded<T>(model: T): IPresentationContainerState<T> {
    return {
        isLoading: false,
        model: model,
        errorDetails: null
    };
}

export function transitionToError<T>(err: Error | any): IPresentationContainerState<T> {
    return {
        isLoading: false,
        model: undefined,
        errorDetails: err
    };
}


export function Presentation<TModel>({ children, state: { isLoading, errorDetails, model} }: { children: JSX.Element, state: IPresentationContainerState<TModel>}) {
    if (isLoading || !model) {
        return <KendoSpinner message="Loading..." />
    }
    if (errorDetails) {
        return <ErrorDisplay error={errorDetails} />;
    }

    return children;
}

export function PresentationContainer<T>(ComposedComponent: React.ComponentClass<T> | React.StatelessComponent<T>, loader: Promise<T>) {


    return class extends React.Component<{}, IPresentationContainerState<T>> {
        constructor(props: any) {
            super(props);
            this.state = transitionToInitial<T>();
        }

        

        async componentDidMount() {
            try {
                const resp = await loader;
                this.setState(transitionToLoaded<T>(resp));
            } catch (err) {
                console.log('Failed to transition to loaded state', err);
                this.setState(transitionToError<T>(err));
            }
        }

        render(): JSX.Element {
            const { isLoading, errorDetails, model } = this.state;

            if (isLoading || !this.state.model) {
                return <KendoSpinner message="Loading..." />;
            }
            if (errorDetails) {
                return <ErrorDisplay error={errorDetails} />;
            }

            let props: any = { ...this.props, ...{ model: model }};
            return <ComposedComponent {...props} />
        }
    }
}


