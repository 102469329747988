export function arraysChanged<T>(arr1: T[], arr2: T[], equalityComparer?: (obj1: T, obj2: T) => boolean): boolean {
    if (arr1 == null && arr2 != null)
        return true;

    if (arr1 != null && arr2 == null)
        return true;

    if (arr1 != null && arr2 != null) {
        if (arr1.length != arr2.length)
            return true;

        for (let i = 0; i < arr1.length; i++) {
            const a = arr1[i];
            const b = arr2[i];
            if (equalityComparer && !equalityComparer(a, b)) {
                return true;
            } else if (!equalityComparer && a != b) {
                return true;
            }
        }
    }
    return false;
}

/* intersperse: Return an array with the separator interspersed between
 * each element of the input array.
 *
 * > _([1,2,3]).intersperse(0)
 * [1,0,2,0,3]
 */
export function arrayIntersperse<T>(arr: T[], sep: T): T[] {
    if (arr.length === 0) {
        return [];
    }

    return arr.slice(1).reduce((xs, x, i) => {
        return xs.concat([sep, x]);
    }, [arr[0]]);
}