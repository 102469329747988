import { ITextValuePair, CriteriaNodeProvider, NodeQuestionType } from '../components/list-builder/common';
import { StaticLookupDataStore, AdvancedListBuilderLookupDataStore, IAutoCompleteLookupDataStoreOptions, AutoCompleteLookupDataStore } from '../components/list-builder/lookup';
import { NodeProviderRegistry } from '../components/list-builder/provider-registry';
import { NodeProviderScope } from '../components/list-builder/provider-scope';

/**
 * Defines an extension point for plugins to register additional criteria and lookup data stores.
 */
export interface IListBuilderRegistration {
    /**
     * Registers a static lookup data source for the given question type
     * @param name The question type
     * @param items The list of items comprising the static lookup data source
     * @param firstItemAsInitialValue If true, the first item on the list will be chosen as the initial selected value, otherwise anything consuming this data store should take this as a hint to present a placeholder/prompt to select an item
     */
    registerStaticLookup(name: string, items: ITextValuePair[], firstItemAsInitialValue: boolean): void;
    /**
     * Registers a remote lookup data source for the given question type
     * @param name The question type
     * @param option Remote lookup data store options
     */
    registerRemoteLookup(name: string, option: IAutoCompleteLookupDataStoreOptions): void;
    /**
     * Registers a criteria node provider. If the UI for this criteria node requires lookup data
     * make sure to register their respective static/remote lookup data source as well
     * @param provider The criteria node provider
     */
    addCriteriaNodeProvider(provider: CriteriaNodeProvider): void;
    /** 
     * Creates a node provider registration context restricted on the given question types and categories
     */
    createNodeProviderScope<TQuestionType extends string, TCategoryType extends string>(): NodeProviderScope<TQuestionType, TCategoryType>;
}

export abstract class ListBuilderViewModelBase implements IListBuilderRegistration {
    constructor() {

    }
    public registerStaticLookup(name: string, items: ITextValuePair[], firstItemAsInitialValue: boolean) {
        AdvancedListBuilderLookupDataStore.addStore(name, new StaticLookupDataStore(items, firstItemAsInitialValue));
    }
    public registerRemoteLookup(name: string, option: IAutoCompleteLookupDataStoreOptions) {
        AdvancedListBuilderLookupDataStore.addStore(name, new AutoCompleteLookupDataStore(option));
    }
    public addCriteriaNodeProvider(provider: CriteriaNodeProvider) {
        NodeProviderRegistry.registerProvider(provider);
    }
    public mount(node: Element) {
        //TODO: These registrations should be picked up from server-side
        const lkGender: NodeQuestionType = "gender";
        const lkIScope: NodeQuestionType = "iscope";
        const lkISearchType: NodeQuestionType = "isearchType";
        this.registerStaticLookup(lkGender, [
            { Text: "Male", Value: "M" },
            { Text: "Female", Value: "F" }
        ], true);
        this.registerStaticLookup(lkIScope, [
            { Text: "Current Office", Value: "true" },
            { Text: "Shared", Value: "false" }
        ], true);
        //TODO: Constant-ify
        this.registerStaticLookup(lkISearchType, [
            { Text: "People Only", Value: "PeopleOnly" },
            { Text: "People and Multi", Value: "PeopleAndMulti" },
            { Text: "People and Groups", Value: "PeopleAndGroups" },
            { Text: "Groups and Multi", Value: "GroupsAndMulti" }
        ], true);
        this.mountComponent(node);
    }
    protected abstract mountComponent(node: Element): void;

    public abstract createNodeProviderScope<TQuestionType extends string, TCategoryType extends string>(): NodeProviderScope<TQuestionType, TCategoryType>;
}