import { MapViewer, StandardBoundaryLayers } from './map-viewer';

export function loadCurrentOfficeBoundaries(map: MapViewer) {
    const w = map.getMapWidgetInterface();
    if (!w) throw new Error("Map widget interface not available");
    
    const layer_current = w.getLayerByName(StandardBoundaryLayers.OfficeBoundsCurrent);
    const layer_previous = w.getLayerByName(StandardBoundaryLayers.OfficeBoundsPreRedist);
    if (layer_current) {
        w.clearLayerSource(layer_current);
    }
    if (layer_previous) {
        w.clearLayerSource(layer_previous);
    }
    if (layer_current || layer_previous) {}
    $.getJSON("/api/map/GetOfficeBoundaries").done((resp) => {
        const features = w.geoJsonToFeatures(resp, { dataProjection: "EPSG:4326", featureProjection: "EPSG:3857" });
        if (features.length > 0) {
            const prev: any[] = [];
            const current: any[] = [];
            for (let i = 0; i < features.length; i++) {
                if (features[i].get("IsCurrent") == "true")
                    current.push(features[i]);
                else
                    prev.push(features[i]);
            }
            if (prev.length > 0 && current.length > 0) {
                w.addFeaturesToLayer(layer_current, current);
                w.addFeaturesToLayer(layer_previous, prev);
                w.zoomToLayerExtent(layer_current);
            } else {
                w.addFeaturesToLayer(layer_current, features);
                w.zoomToLayerExtent(layer_current);
            }
            map.updateSize();
        }
    });
}