import * as React from "react";
import * as ReactDOM from "react-dom";
import { showDialog } from '@ml/kendo-wrappers';
import { IElectracWebContext, createInteraction, updateInteraction } from '@electrac/api';
import { isError } from '@ml/common';
import * as ClientAPI from '@electrac/api';
import * as ElectracAPI from "@electrac/model";

import { isBulkInteraction, IInteractionListItem } from './InteractionPanel';
import { IPresentationContainerState, usePresentation, Presentation } from '@electrac/components';

import { SensitiveLevelDescriptor, ALL_SHARING_LEVELS, InteractionContext } from './Context';
import * as uuid from 'node-uuid';
import { CheckboxList, ICheckboxListItem } from '@electrac/components';
import { ErrorBoundary } from "@ml/components";
import { InteractionModel } from '@electrac/model';
import { InteractionDetailsDialog } from './Details';
import { getContext } from './Actions';
import { useState, useImperativeHandle } from 'react';
import { InteractionPart } from '.';
import { IClassicInteractionDisplayItemProps, arrowIconProps } from './Classic';
import { IconPrinter, IconMenu } from '@foxd/react-icons';

declare var electrac: any;

function isInteractionModel(i: any): i is InteractionModel {
    return !!i.Date;
}

const INTERACTION_DATE_COMPARATOR = (a: Readonly<IInteractionListItem>, b: Readonly<IInteractionListItem>) => {
    if (!isInteractionModel(a)) return 1;
    if (!isInteractionModel(b)) return -1;

    const adate = a.Date;
    const bdate = b.Date;
    if (!adate && !bdate)
        return 0;
    if (!adate)
        return 1;
    if (!bdate)
        return -1;

    return adate.localeCompare(bdate);
};

const INVERTED_INTERACTION_DATE_COMPARATOR = (a: Readonly<IInteractionListItem>, b: Readonly<IInteractionListItem>) => {
    const res = INTERACTION_DATE_COMPARATOR(a, b);
    if (res > 0) {
        return -1;
    } else if (res < 0) {
        return 1;
    } else {
        return 0;
    }
};

type InteractionFilterKind = "Interaction" | "MultiInteraction" | "AddressInteraction" | "PositionInteraction" | "GroupInteraction" | "BulkInteraction" | "OtherOffice";

const INTERACTION_TYPES: { value: InteractionFilterKind, label: string }[] = [
    { value: "MultiInteraction", label: "Multi Interactions" },
    { value: "AddressInteraction", label: "Address Interactions" },
    { value: "PositionInteraction", label: "Position Interactions" },
    { value: "GroupInteraction", label: "Group Interactions" },
    { value: "BulkInteraction", label: "Bulk Interactions" },
    { value: "Interaction", label: "Other Interactions" },
    { value: "OtherOffice", label: "Interactions from Other Offices" }
];

declare function getCurrentOfficeID();

function interactionMatchesFilter(interaction?: Readonly<InteractionModel>, filterItems?: ICheckboxListItem[]): boolean {

    if (!interaction) return false;

    if (!filterItems) filterItems = [];

    const activeFilters = filterItems.filter(v => v.checked === true);
    const otherOffices = activeFilters.filter(v => v.value === "OtherOffice");
    if (otherOffices.length === 0) {
        //Other office filter not active, instantly disqualify interactions not from current office
        if (getCurrentOfficeID && interaction.OfficeID != getCurrentOfficeID())
            return false;
    }

    for (const fl of activeFilters) {
        if (interactionMatchesFilterKind(interaction, fl.value))
            return true;
    }

    return false;
}


function listFilter(item?: Readonly<IInteractionListItem>, filteredTypes?: ICheckboxListItem[]): boolean {
    if (filteredTypes && filteredTypes.length > 0) {
        return interactionMatchesFilter(item, filteredTypes);
    }
    return true; //No filter, always pass
}

function interactionMatchesFilterKind(interaction?: Readonly<InteractionModel>, kind?: InteractionFilterKind): boolean {
    if (kind == "BulkInteraction") {
        return isBulkInteraction(interaction);
    } else if (!interaction) {
        return false;
    }

    //These values map directly to BaseInteractionType
    return interaction.InteractionType === kind;
}

/**
 * Properties of the top-level interaction panel component
 */
export interface IInteractionPresentationContainerProps {
    /**
     * The id of the subject
     */
    objectId: number;
    /**
     * The type of interactions to show. If not specified, this is assumed to be person (and the id given being a person id)
     */
    source: ElectracAPI.IdNamePair;
    /**
     * Controls whether to show the interaction filter button.
     */
    showFilter: boolean;
    /**
     * Allow interactions to be added
     */
    allowAddInteraction: boolean;
    /**
     * Enable/disable topic creation
     */
    allowTopicCreation: boolean;
    /**
     * The default level to assign for new interactions
     */
    defaultNewInteractionLevel: ElectracAPI.SensitiveLevel;
    /**
     * A custom item renderer for interactions. If not specified, default styling will be used
     */
    InteractionItemRenderer?: React.ComponentType<IClassicInteractionDisplayItemProps>;

    showNotification: (title: string, msg: string) => void;

    webContext: IElectracWebContext;
}

export interface IInteractionPresentationContainerState extends IPresentationContainerState<IInteractionPresentationContainerModel> {
    filterFlyoutOpen?: boolean;
    filteredTypes: ICheckboxListItem[];
    filteredListView?: Readonly<IInteractionListItem>[];
}

export interface IInteractionPresentationContainerModel {
    addingInteraction?: boolean;
    page?: number;
    total: number;
    hasMore: boolean;
    isEditing: boolean;
    interactions: IInteractionListItem[];
}


export interface IInteractionImperitive {
    reload: () => void;
}

export const InteractionPresentationContainer = React.forwardRef((props: IInteractionPresentationContainerProps, ref: React.Ref<IInteractionImperitive>) => {
    
    useImperativeHandle(ref, () =>
    ({
        reload: () => { fetchPage(1, true) }
    }));

    const [ flyoutId ] = useState(() => `interaction-flyout-${uuid.v4()}`);
    const [ flyoutOpen, setFlyoutOpen] = useState(false);
  
    const [ filterTypes, setFilterTypes ] = useState(INTERACTION_TYPES.map
        (
            v => { return { value: v.value, checked: (v.value != "GroupInteraction" || (v.value == "GroupInteraction" 
            && !(window.window["INTERACTION_DEFAULT_SELECTION"] && props.source.Name == 'Person') // this line is a hack for ACT
            )), label: v.label }; }
        ));

    const [ state, dispatch ] = usePresentation<IInteractionPresentationContainerModel>();
    const { isLoading, model } = state;

    // derived state based on filter selections
    const filteredList = model && model.interactions.filter(i => listFilter(i, filterTypes))
        .sort(INVERTED_INTERACTION_DATE_COMPARATOR);
    const canAddOrFilter = !isLoading && model && !model.isEditing;

    
    React.useEffect(() => {
        fetchPage(1, true);
    }, []);


    
    const fetchPage = (page: number, reset: boolean = false) => {
        const args: any = {
            id: props.objectId,
            type: props.source.Id
        };
        args.page = page;

        ClientAPI.fetchInteractions(true, args).then(res => {
           
            const currentList =  (reset ? [] : (model && model.interactions || []));

            const newList = [
                ...currentList,
                ...res.map<IInteractionListItem>(v => {
                    
                    return {
                        ...v,
                        isCollapsed: isBulkInteraction(v)
                    };
                }) 
            ];

            
            dispatch({ type: "LOADED", 
            value: {
                ...model,
                interactions: newList,
                total: newList.length,
                hasMore: res && !!res.length && newList.length>=10, //The last page having 0 items signifies no more results
                isEditing: false, 
                page: page
            }})
            if (!(res && !!res.length)) {
                props.webContext.showNotification("Load More Interactions", "No more interactions");
            }
        }).catch(err => {
            dispatch({type: 'ERROR', error: err});
        });
    }    

    const toggleFlyout = () => {
        setFlyoutOpen(!flyoutOpen);
    }

    const onFilterChanged = (selectedItems: ICheckboxListItem[]) => {
        if (!filterTypes || !model) return;

        const items = filterTypes
            .map(interactionType => {
                const match = selectedItems.filter(si => si.value === interactionType.value);
                return { value: interactionType.value, checked: (match.length === 1), label: interactionType.label };
            });
        setFilterTypes(items);
    }



    const onBeginAdd = () => {

        showDialog(<InteractionDetailsDialog
            disableTopicCreation={!props.allowTopicCreation}
            defaultSensitiveLevel={props.defaultNewInteractionLevel}
            requestAllowedSharingLevels={getSharingLevels}
            linkType={props.source.Id}
            linkId={props.objectId}
            title="Add Interaction"
            webContext={props.webContext}
            onSave={async (interaction) => {

                try {
                    if (interaction.Id == null) {
                        const saveResult = await createInteraction(getContext(), interaction);

                        // Notify interaction panel
                        notifyAdded(saveResult);

                        return saveResult.Id;
                    } else {
                        const updateResult = await updateInteraction(getContext(), interaction);

                        // Notify interaction panel
                        notifyUpdated(updateResult, interaction.Id);

                        return updateResult.Id;
                    }
                } catch (e) {
                    alert(`Error occurred while saving interaction: ${e}`);
                    throw e;
                }

            }} />);
    }

    const onShowReport = (e: React.MouseEvent) => {
        e.preventDefault();
        if (electrac && electrac.ShowReport) {
            electrac.ShowReport({ name: "Interactions", report: "Interactions", qstr: `source=${props.source.Name}&SourceId=${props.objectId}` });
        }
    }

    
    const onLoadMoreInteractions = () => {
        const newPage = (model && model.page || 0) + 1;
        fetchPage(newPage, false);
    }


    function updateModel(model?: IInteractionPresentationContainerModel) {
        dispatch({ type: "LOADED", value: model });
    }


    const getSharingLevels = (interaction: ElectracAPI.InteractionSaveModel, isCreating: boolean, isOwner: boolean) => {
        const share: SensitiveLevelDescriptor[] = [];

        const model = interaction.Level;
        ALL_SHARING_LEVELS.forEach(lvl => {
            const item: SensitiveLevelDescriptor = { id: lvl.value, label: lvl.label, isEnabled: false };

            const isVisible = model == ElectracAPI.SensitiveLevel.Archived ||
                lvl.value != ElectracAPI.SensitiveLevel.Archived;

            if (model == ElectracAPI.SensitiveLevel.Sensitive ||
                lvl.value != ElectracAPI.SensitiveLevel.Sensitive ||
                isCreating ||
                isOwner) {
                item.isEnabled = true;
            }

            if (isVisible)
                share.push(item);
        });

        return share;
    }
    
    const notifyAdded = (interaction: Readonly<InteractionModel>): void => {

        if (!model) return
        
        updateModel({ ...model, interactions: [{ ...interaction, isCollapsed: false }, ...model.interactions]});

        props.showNotification("Add interaction", "Interaction added");
    }
    const notifyUpdated = (interaction: Readonly<InteractionModel>, oldInteractionId?: number): void => {
        if (!model) return

        // replace the interaction in the list.
        // For the case where we are transitioning bulk -> individual the oldInteractionID would
        // be the one we update, so this solves it.
        

        updateModel({
            ...model,
            interactions: model.interactions.map((v) => {
            if (v.Id == oldInteractionId)
                return {...interaction, isCollapsed: false }; // ensure this doesn't match
            else
                return v;
        })});

        props.showNotification("Update interaction", "Interaction updated");
    }

    const notifyRemoved = (interactionId: number): void => {
        
        if (!model) return
        
        updateModel({
            ...model,
            interactions: model.interactions.filter((v) => v.Id !== interactionId)
        });

        props.showNotification("Delete interaction", "Interaction deleted");
    }

    const showElectorsForMultiInteraction = (multiID: number) => {
        electrac.ShowMultiList(multiID);
    }

    const setEditing = (isEditing: boolean): void => {
        if (!model) return
        updateModel({ ...model, isEditing: isEditing });
    }

    const setCollapsed = (interactionId: number, isCollapsed: boolean): void => {
        if (!model) return
        
        
        updateModel({...model, interactions: model.interactions.map(v => {
            if (v.Id == interactionId) {
                if (v.isCollapsed != isCollapsed) {
                    return { ...v, isCollapsed: isCollapsed };
                }
            }
            return v;
        })});
    }
    function isEditing(): boolean {
        return !model || model.isEditing;
    }

    const deleteAttachment=(interactionId: number, fileId: number) => {
        if (!model) return;
        
        ClientAPI.deleteAttachment(true, interactionId, fileId).then(res => {
            if (res.IsDeleted === true) {

                
                updateModel({...model, interactions: model.interactions.map((v) => {
                    if (v.Id == interactionId) {
                        const files = v.Files;
                        if (files) {
                            const attachments = files.filter(file => file.StorageFileID !== fileId);
                            return { ...v, Files: attachments };
                        }
                    }
                    return v;
                })});
                props.showNotification("Delete Attachment", "Attachment deleted");
            } else {
                alert("Failed to delete attachment");
            }
        }).catch(err => {
            if (isError(err))
                alert(`Failed to delete attachment: ${err.message}`);
            else
                alert(`Failed to delete attachment: ${err}`);
        });
    }


    const RenderInteraction = props.InteractionItemRenderer ?? InteractionPart.DisplayInteraction;

 

    return <ErrorBoundary>
        <InteractionContext.Provider value={{
            getSharingLevels: getSharingLevels,
            getDefaultSensitiveLevel: props.defaultNewInteractionLevel,
            allowTopicCreation: props.allowTopicCreation,
            linkData: {
                linkType: props.source.Id,
                linkId: props.objectId
            },
            showElectorsForMultiInteraction: showElectorsForMultiInteraction,
            setCollapsed: setCollapsed,
            setEditing: setEditing,
            isEditing: isEditing(),
            deleteAttachment: deleteAttachment,
            notifyAdded: notifyAdded,
            notifyUpdated: notifyUpdated,
            notifyRemoved: notifyRemoved,
            webContext: props.webContext,
            //hasMoreInteractions: model && model.hasMore
        }}>
            <Presentation state={state}>
                <div className="cc-interaction-panel-body">
                    <div key="interaction-toolbar-body" style={{ width: "100%", height: 37 }}>
                        <div className="action-toolbar">
                            {
                                props.allowAddInteraction && 
                                <InteractionPart.InteractionAdd LinkId={props.objectId} LinkType={props.source.Id} canAddOrFilter={canAddOrFilter} onBeginAdd={onBeginAdd} />
                            }
                            {props.showFilter && <div className="action-toolbar-item has-flyout" style={{ float: "right" }}>
                                <button data-flyout-id={flyoutId} type="button" onClick={toggleFlyout} className="k-button" title="Filter the current list of interactions" disabled={!canAddOrFilter}><IconMenu {...arrowIconProps}  /></button>
                            </div>
                            }
                            {model && model.interactions.length > 0 && <div className="action-toolbar-item" style={{ float: "right" }}>
                                <button type="button" title="Print interaction report" className="k-button" onClick={onShowReport}><IconPrinter {...arrowIconProps}/></button>
                            </div>}
                            {props.source.Name && <div className="action-toolbar-item" style={{ float: "right" }}>
                                <span style={{ paddingRight: 4 }}>Linked to: <strong>{props.source.Name}</strong></span>
                            </div>}
                        </div>
                    </div>
        
                    {flyoutOpen && <div key="open-flyout" style={{ zIndex: 51, background: "white", position: "absolute", width: "100%", padding: 10 }}>
                        <p><strong>Show the following</strong></p>
                        <CheckboxList enableNameFiltering={false} data={filterTypes} onChange={onFilterChanged} maxHeight={250} />
                        <button type="button" className="k-button k-primary" onClick={toggleFlyout}>Hide</button>
                    </div>}
    
    
                    <div key="interaction-list" style={{ position: "absolute", top: 38, bottom: 0, left: 0, right: 0, overflowY: "auto", overflowX: "hidden" }}>
    
                        {(!filteredList || !filteredList.length) && <div className="k-block k-info-colored" style={{ padding: "5px" }}>
                            <h3>No interactions found</h3>
                        </div>}
    
                        {filteredList && filteredList.map((v) => (
                            <div key={`interaction-item-${v.Id}`} className={`listitem`}>

                                <RenderInteraction interaction={v} webContext={props.webContext} />
                            </div>
                        ))}
                        {model && model.hasMore && <div style={{ textAlign: "center", marginTop: 10 }}>
                        <button type="button" className="k-button" onClick={onLoadMoreInteractions}>Load More Interactions</button>
                        </div>}
                    </div>
                </div>
            </Presentation>

            
        </InteractionContext.Provider>
    </ErrorBoundary>;

}); // forwardRef





/**
 * This is the entry point view model for the interaction panel component.
 *
 * To use this component, create an instance of this view model with the desired id/type and call mount on the
 * desired DOM element
 */

export class InteractionListViewModel {
    private objectId: number;
    private objectType: ElectracAPI.IdNamePair;
    private showFilter: boolean;
    private enableTopicCreation: boolean;
    private enableAddInteraction: boolean;
    private defaultNewInteractionLevel: ElectracAPI.SensitiveLevel;

    private _container: IInteractionImperitive | null = null;
    constructor(objectId: number,
        source: ElectracAPI.IdNamePair,
        defaultNewInteractionLevel: ElectracAPI.SensitiveLevel,
        showFilter: boolean,
        enableTopicCreation: boolean,
        enableAddInteraction: boolean) {

        this.objectId = objectId;
        this.objectType = source || { Id: ElectracAPI.InteractionSource.Person, Name: "Person" };
        this.showFilter = showFilter;
        this.defaultNewInteractionLevel = defaultNewInteractionLevel;
        this.enableTopicCreation = enableTopicCreation;
        this.enableAddInteraction = enableAddInteraction;
    }
    public refresh() {
        if (this._container)
            this._container.reload();
    }
    /**
     * Mounts the interaction panel component at the specified DOM element
     * @param node
     */
    public mount(node: Element, webContext: IElectracWebContext) {
        //A previous opening of the profile may have already mounted another instance at this node, so unmount first
        //before mounting the new one
        ReactDOM.render(<InteractionPresentationContainer
            ref={(component) => {
                this._container = component;
            }}
            objectId={this.objectId}
            source={this.objectType}
            showFilter={this.showFilter}
            showNotification={webContext.showNotification}
            defaultNewInteractionLevel={this.defaultNewInteractionLevel}
            allowAddInteraction={this.enableAddInteraction}
            allowTopicCreation={this.enableTopicCreation}
            webContext={webContext}
        />, node);
    }
}