import * as React from "react";
import { FontelloFontIcon } from './fontello-font-icon';
import { ErrorBoundary } from './error-boundary';



export interface IWizardStep {
    title?: string;
    component: JSX.Element,
    stepTitle?: boolean,
    hideNavigation?: boolean,
    initialState?: any,
    /** if specified, used for the key for the data in all state */
    name?: string,
    /** By default the step needs to call the setCanGoNext through some action on the step. This flag enables the step to bypass this and enable the next button always */
    enableNextUnconditionally?: boolean
}

export interface IWizardProps {
    /** Defaults to true */
    showStepTitle?: boolean;
    steps: IWizardStep[]
    
}

interface IWizardState {
    canGoNext: { [key:number]: boolean } // keep track of the can Go Next for each step
    step: number,
    allState: { [key: string]: any }
}

// export interface IWizardContext {
//     canGoNext: boolean;
//     setCanGoNext: (active: boolean) => void;
// }

// export const WizardContext = React.createContext<IWizardContext>({
//     canGoNext: true,

//     setCanGoNext: () => {}
// });

/** This interface will be tacked on to the component, kind of like context / HOC but enables us to do stuff */
export interface IWizardStepProps<TState = any> {
    setCanGoNext: (can: boolean) => void;
    goNext: () => void;
    goPrev: () => void;
    stepState: TState,
    allState: { [key: string]: any };
    step: number;
    setState: (data: TState) => void;
}

export class Wizard extends React.Component<IWizardProps, IWizardState> {

    constructor(props: IWizardProps) {
        super(props);

        this.state = {
            step: 1,
            canGoNext: { [0]: props.steps.length > 0 && props.steps[0].enableNextUnconditionally || false },
            allState: { }
        }
    }

    onPrev = () => {
        this.setState({
            step: this.state.step - 1
        })
    }
    onNext = () => {
        const { steps } = this.props;
        const { step } = this.state;

        // note the step index state is - 1 to the actual step index. this probably should be pivoted to be starting with zero, however
        // we have what it is and thus we access the current index to get the actual array indexed step
        const activeStep = steps[step];
        if (activeStep.enableNextUnconditionally === true) this.setCanGoNextForStep(step, true);

        this.setState({
            step: step + 1
        });
        // assume we can implicilty go next for the current step, which is index minus 1
        this.setCanGoNextForStep(step - 1, true);

        const stepName = this.stepName(step);
        if (activeStep.initialState && !this.state.allState[stepName]) {
            this.setStateFactory(stepName)(activeStep.initialState);
        }
    }
    setStep = (step: number) => {
        this.setState({ step });
    }
    private setCanGoNextForStep = (step: number, can:boolean) => {
        if (this.state.canGoNext[step] != can) {
            this.setState(prevState => ({ canGoNext: { ...prevState.canGoNext, [step]: can } }));
        }
    }
    setCanGoNext = (can: boolean) => {
        this.setCanGoNextForStep(this.state.step - 1, can);
    }

    stepName = (index: number) => {
        const { steps } = this.props;
        const activeStep = steps[index];
        return activeStep.name || `${index}`;
    }
    setStateFactory = (stepName: string) => (state: any) => this.setState(prevState => { 
        return {
            allState: { ...prevState.allState, [ stepName ]: state }
        }
    });

    render() {
        const { steps, showStepTitle } = this.props;
        const { step } = this.state;

        if (!steps) {
            return <div>Wizard steps not provided</div>
        }
        const stepIndex = step - 1;

        const activeStep = steps[stepIndex];
        const canGoNext = this.state.canGoNext[stepIndex] && step < steps.length;

        const stepName = this.stepName(stepIndex)

        const cloneExtensions = {
            setCanGoNext: this.setCanGoNext,
            goNext: this.onNext,
            goPrev: this.onPrev,
            allState: this.state.allState,
            stepState: this.state.allState[stepName],
            step: stepIndex,
            setState: this.setStateFactory(stepName)
        }

        const activeStepClone = React.cloneElement(activeStep.component, cloneExtensions);

        const hideNav = activeStep.hideNavigation === true;

        return <div>
            <div className="stepwizard">
                <div className="stepwizard-row setup-panel">
                    {steps.map((ws, index) => {
                        const stepIter = index + 1;
                        return <div key={stepIter} className="stepwizard-step">
                            <button type="button" onClick={() => this.setStep(stepIter)} className={`btn ${this.state.step == stepIter ? "btn-primary" : "btn-default"} btn-circle`} disabled={!this.state.canGoNext[index]}>{stepIter < step ? <FontelloFontIcon name='ok' /> : stepIter }</button>
                            <p>{ws.title}</p>
                        </div>;
                    })}
                </div>
            </div>
            <div role="form">
                <div className="row setup-content">
                    <div className="col-xs-12">
                        <div className="col-md-12">
                            <div>
                                {(activeStep.stepTitle !== false && showStepTitle === true) && <h3>{activeStep.title}</h3>}
                                <ErrorBoundary>
                                {activeStepClone}
                                </ErrorBoundary>
                                {!hideNav && canGoNext && <button className="btn btn-primary nextBtn pull-right" type="button" onClick={this.onNext}>Next</button>}
                                {!hideNav && step > 1 && <button className="btn btn-default prevBtn pull-left" type="button" onClick={this.onPrev}>Go Back</button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}
