import { strIsNullOrEmpty, STR_EMPTY, trimToLower, trimToUpper } from "@ml/common";
import * as ko from "knockout";


declare let $: JQueryStatic;
export type KnockoutObservable<T> = ko.Observable<T>;
export type KnockoutComputed<T> = ko.Computed<T>;

// inconsistent typings
export type KnockoutSubscription = any;//ko.Subscription; 

//How many recent searches to remember
const RECENT_SEARCH_LIMIT = 20;

const RECENT_SEARCHES_KEY = "CC_RecentPeopleSearches";

function getRecentSearches(): string[] {
    let searches: string[];
    if (!window.localStorage[RECENT_SEARCHES_KEY])
        searches = [];
    else
        searches = JSON.parse(window.localStorage[RECENT_SEARCHES_KEY]);
    return searches.filter(s => !strIsNullOrEmpty(s));
}

function addRecentSearch(query: string): string[] {
    let searches;
    if (!window.localStorage[RECENT_SEARCHES_KEY])
        searches = [];
    else
        searches = JSON.parse(window.localStorage[RECENT_SEARCHES_KEY]).filter((s: any) => !strIsNullOrEmpty(s));

    const idx = searches.indexOf(query);
    if (idx >= 0) { //Remove old entry to relocate
        searches.splice(idx, 1);
    }
    searches.push(query);

    while (searches.length > RECENT_SEARCH_LIMIT) {
        //Remove the oldest (first) one
        searches.shift();
    }

    window.localStorage[RECENT_SEARCHES_KEY] = JSON.stringify(searches);
    return searches;
}

class ParserWarning {
    private _message: string;
    constructor(msg: string) {
        this._message = msg;
    }
    public message(): string {
        return this._message;
    }
}

export class LookupQueryPart {
    private _facet: string;
    private _value: string;
    constructor(facet: string, value: string) {
        this._facet = facet;
        this._value = value;
    }
    public facet(): string { return this._facet; }
    public value(): string { return this._value; }
}

export enum InclusionSet {
    Electors = (1 << 0),
    NonElectors = (1 << 1),
    Volunteers = (1 << 2),
    ElectorsWithInteractionInLast12Months = (1 << 3),
    Maximum = ElectorsWithInteractionInLast12Months
}

const Facet_KnownAs = "ka";
const Facet_Occupation = "occ";
const Facet_DateOfBirth = "dob";
const Facet_Habitat = "hb";
const Facet_Phone = "ph";
const Facet_Mobile = "mob";
const Facet_Email = "email";
const Facet_Postcode = "pc";
const Facet_Locality = "loc";
const Facet_Street = "st";
const Facet_Postal1 = "p1";
const Facet_Postal2 = "p2";
const Facet_Postal3 = "p3";
const Facet_StreetNum = "no";

class LookupQueryFacets {
    public static normalize(facet: string): string | undefined {
        const fn = trimToLower(facet);
        switch (fn)
        {
            case Facet_KnownAs:
            case Facet_Occupation:
            case Facet_DateOfBirth:
            case Facet_Habitat:
            case Facet_Phone:
            case Facet_Mobile:
            case Facet_Email:
            case Facet_Postcode:
            case Facet_Locality:
            case Facet_Street:
            case Facet_Postal1:
            case Facet_Postal2:
            case Facet_Postal3:
            case Facet_StreetNum:
                return fn;
            default:
                return undefined;
        }
    }
}

export class PeopleLookupQuery {
    private _parts: LookupQueryPart[];
    private _warnings: ParserWarning[];
    private _baseQuery: string="";
    private _inclusionSet: InclusionSet | undefined;
    constructor() {
        this._parts = [];
        this._warnings = [];
    }
    public stringifyWarnings(delimiter: string = "\n"): string {
        const parts: string[] = [];
        for (const warn of this._warnings) {
            parts.push(warn.message());
        }
        return parts.join(delimiter);
    }
    public getParts(): LookupQueryPart[] { return this._parts; }
    public hasWarnings(): boolean { return this._warnings.length > 0; }
    public setBaseQuery(value: string) { this._baseQuery = value; }
    public getBaseQuery(): string { return this._baseQuery; }
    public getInclusionSets(): InclusionSet | undefined { return this._inclusionSet; }
    public applyInclusionSet(set: InclusionSet | number): PeopleLookupQuery {
        if (this._inclusionSet)
            this._inclusionSet = this._inclusionSet | set;
        else
            this._inclusionSet = set;
        return this;
    }
    protected normalizeFacet(facet: string): string | undefined {
        return undefined;
    }
    public addPart(facet: string, value: string): PeopleLookupQuery {
        if (trimToUpper(facet) == "I")
        {
            const val = this.tryParseInclusionSet(value);
            if (val != null)
            {
                this.applyInclusionSet(val);
            }
            else
            {
                this._warnings.push(new ParserWarning(`Encountered unknown inclusion set value: ${value}. Skipping this part`));
            }
        }
        else
        {
            const fn = LookupQueryFacets.normalize(facet) || this.normalizeFacet(facet);
            if (!strIsNullOrEmpty(fn))
            {
                this._parts.push(new LookupQueryPart(fn!, value));
            }
            else
            {
                this._warnings.push(new ParserWarning(`Encountered unknown facet modifier: ${fn}. Skipping this part`));
            }
        }
        return this;
    }
    public static parse<T extends PeopleLookupQuery>(text: string, query: T): T {
        const parser = new LookupQueryTextParser(text);

        let currentFacet: string | undefined;
        let currentFacetValue: string | undefined;
        let tt = parser.nextToken();
        while (tt != TokenType.End)
        {
            switch(tt)
            {
                case TokenType.BaseQuery:
                    query.setBaseQuery(parser.getCurrentToken());
                    break;
                case TokenType.FacetPair:
                    const tokens = parser.getCurrentToken().split(FACET_DELIMITER);
                    currentFacet = tokens[0];
                    currentFacetValue = tokens[1];
                    break;
            }

            if (!strIsNullOrEmpty(currentFacet) && !strIsNullOrEmpty(currentFacetValue))
            {
                query.addPart(currentFacet!, currentFacetValue!);

                currentFacet = undefined;
                currentFacetValue = undefined;
            }

            tt = parser.nextToken();
        }

        return query;
    }

    protected inclusionSetToString(set: InclusionSet | number): string | undefined {
        switch (set) {
            case InclusionSet.Electors:
                return "E";
            case InclusionSet.ElectorsWithInteractionInLast12Months:
                return "INT";
            case InclusionSet.NonElectors:
                return "NE";
            case InclusionSet.Volunteers:
                return "V";
        }
        return undefined;
    }

    protected tryParseInclusionSet(str: string): number | InclusionSet | undefined {
        const val = trimToUpper(str);
        switch (val) {
            case "E":
                return InclusionSet.Electors;
            case "INT":
                return InclusionSet.ElectorsWithInteractionInLast12Months;
            case "NE":
                return InclusionSet.NonElectors;
            case "V":
                return InclusionSet.Volunteers;
        }
        return undefined;
    }
    protected getInclusionSetFlagValues(): number[] {
        const values: number[] = [];
        for (const set in InclusionSet) {
            if (InclusionSet.hasOwnProperty(set)) {
                const flag = parseInt(set, 10);
                if (isNaN(flag)) continue;
                values.push(flag);
            }
        }
        return values;
    }


    private stringifyInclusionSets(): string {
        const list: string[] = [];
        const value = this._inclusionSet;
        const flags = this.getInclusionSetFlagValues();

        if (value) {
            for (const flag of flags) {       
                if ((value & flag) == flag) {
                    const name = this.inclusionSetToString(flag);
                    if (!strIsNullOrEmpty(name)) {
                        list.push(`I:${name}`);
                    }
                }
            }
        }
        return list.join(" ");
    }
    public toString(): string {
        let strParts: string[] = [];
        for (const part of this._parts) {
            strParts.push(`${part.facet()}:${part.value()}`)
        }
        return $.trim(`${this.getBaseQuery() || ""} ${this.stringifyInclusionSets()} ${strParts.join(" ")}`);
    }
}

enum TokenType {
    BaseQuery,
    FacetPair,
    End
}

const TOKEN_DELIMITER: string = ' ';
const FACET_DELIMITER: string = ':';
const NAME_LIKE_REGEX = /^([^\d@ '\*]*)(\s*,\s*(([\D'\-\*]+))?(\s+([\D\*]+))?)?$/i;

class LookupQueryTextParser {
    private _index: number = -1;
    private _parts: string[];
    private _currentToken: string="";
    constructor(text: string) {
        this._index = -1;
        this._parts = (text || "").split(TOKEN_DELIMITER);
    }

    private atEnd(): boolean { return this._index > this._parts.length - 1; }
    private hasFacetDelimiter(): boolean { return this._parts[this._index].indexOf(FACET_DELIMITER) >= 0; }
    private currentPart(): string { return this._parts[this._index]; }
    private rewind(): void { this._index--; }
    private advance(): void { this._index++; }

    public nextToken(): TokenType
    {
        let tt = TokenType.End;

        if (this._parts.length == 0 || this.atEnd())
            return tt;

        const bFirst = (this._index < 0);
        this.advance();

        if (this.atEnd())
            return tt;

        let sb: any = new String(this._parts[this._index]);

        if (this.hasFacetDelimiter()) //Found a facet delimiter
        {
            tt = TokenType.FacetPair;
                
            while (true)
            {
                this.advance();
                if (this.atEnd())
                {
                    break;
                }
                else if (this.hasFacetDelimiter())
                {
                    this.rewind();
                    break;
                }
                else
                {
                    sb += ` ${this.currentPart()}`;
                }
            }
        }
        else
        {
            while (true)
            {
                this.advance();
                if (this.atEnd()) //End of query string, treat as base query
                {
                    if (bFirst)
                        tt = TokenType.BaseQuery;
                    break;
                }
                    
                if (this.hasFacetDelimiter()) //Found a facet delimiter
                {
                    if (sb.length > 0)
                    {
                        //Ship off what we have so far
                        if (bFirst)
                            tt = TokenType.BaseQuery;

                        //Rewind as the above part is basically a "peek" of what's ahead
                        this.rewind();
                        break;
                    }
                }
                else
                {
                    if (this.atEnd()) //End of query string, treat as base query
                    {
                        if (bFirst)
                            tt = TokenType.BaseQuery;
                        break;
                    }
                    else
                    {
                        if (this.hasFacetDelimiter()) //Found a facet delimiter
                        {
                            this.rewind();
                            break;
                        }
                        else
                        {
                            sb += ` ${this.currentPart()}`;
                        }
                    }
                }
            }
        }

        this._currentToken = sb;

        return tt;
    }

    public getCurrentToken(): string { return this._currentToken + ""; }
}

export const COL_POSTCODE = "Postcode";
export const COL_SUBTOWN = "Subtown";
export const COL_STREET = "StreetName";

export type AutoCompleteFunc = (searchTerm: string, callback: Function) => any;

/**
 * View Model for people lookup search
 */
export class PeopleLookupViewModel {
    /**
     * If set, controls what inclusion sets are visible in the UI. If null, all
     * inclusion sets are allowed, otherwise only the ones matching this mask will
     * be allowed
     */
    AllowedInclusionSets: KnockoutObservable<number>;

    RecentSearches: ko.ObservableArray<string>;

    IncludeElectors: KnockoutObservable<boolean>;
    IncludeNonElectors: KnockoutObservable<boolean>;
    IncludeVolunteers: KnockoutObservable<boolean>;
    IncludeElectorsWithInteractionsInLast12Months: KnockoutObservable<boolean>;

    CanIncludeElectors: KnockoutObservable<boolean>;
    CanIncludeNonElectors: KnockoutObservable<boolean>;
    CanIncludeVolunteers: KnockoutObservable<boolean>;
    CanIncludeElectorsWithInteractionsInLast12Months: KnockoutObservable<boolean>;
        
    KnownAs: KnockoutObservable<string>;
    Occupation: KnockoutObservable<string>;
    DateOfBirth: KnockoutObservable<string>;
    Habitat: KnockoutObservable<string>;
    Phone: KnockoutObservable<string>;
    Mobile: KnockoutObservable<string>;
    Email: KnockoutObservable<string>;
    Postcode: KnockoutObservable<string>;
    Locality: KnockoutObservable<string>;
    Street: KnockoutObservable<string>;

    Postal1: KnockoutObservable<string>;
    Postal2: KnockoutObservable<string>;
    Postal3: KnockoutObservable<string>;

    StreetNum: KnockoutObservable<string>;

    QueryString: KnockoutObservable<string>;

    BaseQuery: KnockoutObservable<string>;
    CanAutoSuggest: KnockoutComputed<boolean>;

    PostCodeAutoCompleteSource: AutoCompleteFunc;
    LocalityAutoCompleteSource: AutoCompleteFunc;
    StreetAutoCompleteSource: AutoCompleteFunc;

    PostCodeAutoCompleteOptions: any;
    LocalityAutoCompleteOptions: any;
    StreetAutoCompleteOptions: any;

    IsAutoCompleting: KnockoutObservable<boolean>;

    protected subscriptions: KnockoutSubscription[];
    private isModelFrozen: boolean;
    private isQueryStringFrozen: boolean;

    public LocalStorageError: string | undefined;

    TabWidget: KnockoutObservable<any>;

    RestoreSearch: (item: string) => void;

    constructor() {
        this.AllowedInclusionSets = ko.observable();
        this.TabWidget = ko.observable();
        this.RestoreSearch = (item) => {
            this.QueryString(item);
            const tabs = this.TabWidget();
            if (tabs != null)
                tabs.select(0);
        }

        this.isQueryStringFrozen = false;
        this.isModelFrozen = false;
        this.subscriptions = [];

        try {
            this.LocalStorageError = undefined;
            this.RecentSearches = ko.observableArray(getRecentSearches().reverse());
        } catch (e) {
            this.LocalStorageError = "There was a problem reading recently saved searches. This is most likely because you are using Internet Explorer and the current security settings on your browser prevent us from saving recent searches. Elector searches will not be saved.";
            this.RecentSearches = ko.observableArray([] as string[]);
        }
        this.IncludeElectors = ko.observable<boolean>(false);
        this.IncludeVolunteers = ko.observable<boolean>(false);
        this.IncludeNonElectors = ko.observable<boolean>(false);
        this.IncludeElectorsWithInteractionsInLast12Months = ko.observable<boolean>(false);

        this.CanIncludeElectors = ko.observable<boolean>(true);
        this.CanIncludeVolunteers = ko.observable<boolean>(true);
        this.CanIncludeNonElectors = ko.observable<boolean>(true);
        this.CanIncludeElectorsWithInteractionsInLast12Months = ko.observable<boolean>(true);

        this.KnownAs = ko.observable();
        this.Occupation = ko.observable();
        this.DateOfBirth = ko.observable();
        this.Habitat = ko.observable();
        this.Phone = ko.observable();
        this.Mobile = ko.observable();
        this.Email = ko.observable();
        this.Postcode = ko.observable();
        this.Locality = ko.observable();
        this.Street = ko.observable();
        this.Postal1 = ko.observable();
        this.Postal2 = ko.observable();
        this.Postal3 = ko.observable();
        this.StreetNum = ko.observable();

        this.BaseQuery = ko.observable("");
        this.QueryString = ko.observable("");

        this.CanAutoSuggest = ko.pureComputed(() => {
            const q = this.BaseQuery(); 
            return !strIsNullOrEmpty(q) && q!.match(NAME_LIKE_REGEX) != null;
        });

        this.IsAutoCompleting = ko.observable<boolean>(false);
        this.subscriptions.push(this.AllowedInclusionSets.subscribe((newValue) => this.onAllowedInclusionSetsChanged(newValue!)));
        this.subscriptions.push(this.QueryString.subscribe((newValue) => this.onQueryStringChange()));

        this.subscriptions.push(this.IncludeElectors.subscribe((newValue) => this.onModelChange()));
        this.subscriptions.push(this.IncludeVolunteers.subscribe((newValue) => this.onModelChange()));
        this.subscriptions.push(this.IncludeNonElectors.subscribe((newValue) => this.onModelChange()));
        this.subscriptions.push(this.IncludeElectorsWithInteractionsInLast12Months.subscribe((newValue) => this.onModelChange()));

        this.subscriptions.push(this.BaseQuery.subscribe((newValue) => this.onModelChange()));
        this.subscriptions.push(this.KnownAs.subscribe((newValue) => this.onModelChange()));
        this.subscriptions.push(this.Occupation.subscribe((newValue) => this.onModelChange()));
        this.subscriptions.push(this.DateOfBirth.subscribe((newValue) => this.onModelChange()));
        this.subscriptions.push(this.Habitat.subscribe((newValue) => this.onModelChange()));
        this.subscriptions.push(this.Phone.subscribe((newValue) => this.onModelChange()));
        this.subscriptions.push(this.Mobile.subscribe((newValue) => this.onModelChange()));
        this.subscriptions.push(this.Email.subscribe((newValue) => this.onModelChange()));
            
        this.subscriptions.push(this.Postcode.subscribe((newValue) => this.onModelChange()));
        this.subscriptions.push(this.Locality.subscribe((newValue) => this.onModelChange()));
        this.subscriptions.push(this.Street.subscribe((newValue) => this.onModelChange()));

        this.subscriptions.push(this.Postal1.subscribe((newValue) => this.onModelChange()));
        this.subscriptions.push(this.Postal2.subscribe((newValue) => this.onModelChange()));
        this.subscriptions.push(this.Postal3.subscribe((newValue) => this.onModelChange()));

        this.subscriptions.push(this.StreetNum.subscribe((newValue) => this.onModelChange()));

        //this.subscriptions.push(this.IsAutoCompleting.subscribe((newValue) => console.log(`IsAutoCompleting: ${newValue}`)));

        this.PostCodeAutoCompleteOptions = {
            minLength: 1,
            delay: 200,
            open: () => this.IsAutoCompleting(true),
            close: () => this.IsAutoCompleting(false)
        };
        this.LocalityAutoCompleteOptions = {
            minLength: 1,
            delay: 200,
            open: () => this.IsAutoCompleting(true),
            close: () => this.IsAutoCompleting(false)
        };
        this.StreetAutoCompleteOptions = {
            minLength: 1,
            delay: 200,
            open: () => this.IsAutoCompleting(true),
            close: () => this.IsAutoCompleting(false)
        };

        this.PostCodeAutoCompleteSource = (term, callback) => {
            const opts = {
                column: "Postcode",
                value: term
            };
            $.getJSON("/Street/HelperFilterEx", opts).done(res => {
                callback(res.items);
            });
        };
        this.LocalityAutoCompleteSource = (term, callback) => {
            const opts = {
                column: "Subtown",
                value: term,
                Postcode: this.Postcode()
            };
            $.getJSON("/Street/HelperFilterEx", opts).done(res => {
                callback(res.items);
            });
        };
        this.StreetAutoCompleteSource = (term, callback) => {
            const opts = {
                column: "StreetName",
                value: term,
                Postcode: this.Postcode(),
                Subtown: this.Locality()
            };
            $.getJSON("/Street/HelperFilterEx", opts).done(res => {
                callback(res.items);
            });
        };
    }
    // private autoCompleteFor(field: "Postcode" | "Locality" | "Street", value: string): void {
    //     console.log(`Autocomplete for: ${field}, value: ${value}`);
    //     if (this.isModelFrozen === true) {
    //         return;
    //     }
    // }
    private freezeQueryString(): void {
        this.isQueryStringFrozen = true;
    }
    private unfreezeQueryString(): void {
        this.isQueryStringFrozen = false;
    }
    private freezeModel(): void {
        this.isModelFrozen = true;
    }
    private unfreezeModel(): void {
        this.isModelFrozen = false;
    }
    private onAllowedInclusionSetsChanged(newValue: number): void {
        if (newValue != null) {
            if ((newValue & InclusionSet.Electors) != InclusionSet.Electors) {
                if (this.IncludeElectors()) {
                    this.IncludeElectors(false);
                }
                this.CanIncludeElectors(false);
            } else {
                this.CanIncludeElectors(true);
            }
            if ((newValue & InclusionSet.Volunteers) != InclusionSet.Volunteers) {
                if (this.IncludeVolunteers()) {
                    this.IncludeVolunteers(false);
                }
                this.CanIncludeVolunteers(false);
            } else {
                this.CanIncludeVolunteers(true);
            }
            if ((newValue & InclusionSet.NonElectors) != InclusionSet.NonElectors) {
                if (this.IncludeNonElectors()) {
                    this.IncludeNonElectors(false);
                }
                this.CanIncludeNonElectors(false);
            } else {
                this.CanIncludeNonElectors(true);
            }
            if ((newValue & InclusionSet.ElectorsWithInteractionInLast12Months) != InclusionSet.ElectorsWithInteractionInLast12Months) {
                if (this.IncludeElectorsWithInteractionsInLast12Months()) {
                    this.IncludeElectorsWithInteractionsInLast12Months(false);
                }
                this.CanIncludeElectorsWithInteractionsInLast12Months(false);
            } else {
                this.CanIncludeElectorsWithInteractionsInLast12Months(true);
            }
        } else {
            this.CanIncludeElectors(true);
            this.CanIncludeVolunteers(true);
            this.CanIncludeNonElectors(true);
            this.CanIncludeElectorsWithInteractionsInLast12Months(true);
        }
        this.handleAllowedInclusionSetChanged(newValue);
    }
    protected handleAllowedInclusionSetChanged(newValue: number): void { }
    SaveActiveQuery() {
        try {
            const qs = this.QueryString();
            if (qs) {
                const searches = addRecentSearch(qs);
                this.RecentSearches(searches);
            }
        } catch (e) {
            console.warn(`Could not save active query. This is most likely due to security settings on your installation of IE`);
        }
    }

    applyInclusionSets(flags: number | undefined) {
        if (flags == null) {
            this.IncludeElectors(false);
            this.IncludeVolunteers(false);
            this.IncludeNonElectors(false);
            this.IncludeElectorsWithInteractionsInLast12Months(false);
        } else {
            this.IncludeElectors((flags & InclusionSet.Electors) == InclusionSet.Electors);
            this.IncludeVolunteers((flags & InclusionSet.Volunteers) == InclusionSet.Volunteers);
            this.IncludeNonElectors((flags & InclusionSet.NonElectors) == InclusionSet.NonElectors);
            this.IncludeElectorsWithInteractionsInLast12Months((flags & InclusionSet.ElectorsWithInteractionInLast12Months) == InclusionSet.ElectorsWithInteractionInLast12Months);
        }
    }

    protected parseQueryString(qstr: string): PeopleLookupQuery {
        return PeopleLookupQuery.parse(qstr, new PeopleLookupQuery());
    }

    onQueryStringChange() {
        if (this.isQueryStringFrozen === true) {
            return;
        }

        const qstr = this.QueryString();
        const query = this.parseQueryString(qstr || STR_EMPTY);
        if (query.hasWarnings()) {
            console.warn(`Found issues parsing the query string: ${qstr}\n${query.stringifyWarnings("\n - ")}`);
        }

        this.freezeModel();

        this.BaseQuery(query.getBaseQuery());

        const flags = query.getInclusionSets();
        this.applyInclusionSets(flags);


        const parts = query.getParts();
            
        this.KnownAs();
        this.Occupation();
        this.DateOfBirth();
        this.Habitat();
        this.Phone();
        this.Mobile();
        this.Email();
        this.Postcode();
        this.Locality();
        this.Street();
        this.Postal1();
        this.Postal2();
        this.Postal3();
        this.StreetNum();

        this.resetModelBeforeUpdateFromQueryString();

        for (const part of parts) {
            switch (part.facet()) {
                case Facet_KnownAs:
                    this.KnownAs(part.value());
                    break;
                case Facet_Occupation:
                    this.Occupation(part.value());
                    break;
                case Facet_DateOfBirth:
                    this.DateOfBirth(part.value());
                    break;
                case Facet_Habitat:
                    this.Habitat(part.value());
                    break;
                case Facet_Phone:
                    this.Phone(part.value());
                    break;
                case Facet_Mobile:
                    this.Mobile(part.value());
                    break;
                case Facet_Email:
                    this.Email(part.value());
                    break;
                case Facet_Postcode:
                    this.Postcode(part.value());
                    break;
                case Facet_Locality:
                    this.Locality(part.value());
                    break;
                case Facet_Street:
                    this.Street(part.value());
                    break;
                case Facet_Postal1:
                    this.Postal1(part.value());
                    break;
                case Facet_Postal2:
                    this.Postal2(part.value());
                    break;
                case Facet_Postal3:
                    this.Postal3(part.value());
                    break;
                case Facet_StreetNum:
                    this.StreetNum(part.value());
                    break;
                default:
                    this.updateModelFromPart(part.facet(), part.value());
                    break;
            }
        }

        this.unfreezeModel();
    }

    protected createLookupQuery(): PeopleLookupQuery {
        return new PeopleLookupQuery();
    }

    protected resetModelBeforeUpdateFromQueryString(): void {

    }

    protected updateModelFromPart(facet: string, value: string): void {

    }

    protected applyQueryPartsFromUpdatedModel(query: PeopleLookupQuery): void {

    }

    private tryApplyPart(query: PeopleLookupQuery, facet: string, value: string | undefined): void {
        if (!strIsNullOrEmpty(value)) {
            query.addPart(facet, value);
        }
    }
    onModelChange() {
        if (this.isModelFrozen === true) {
            return;
        }

        const query = this.createLookupQuery();
        const bq = this.BaseQuery();
        if (!strIsNullOrEmpty(bq)) {
            query.setBaseQuery(bq);
        }
        this.tryApplyPart(query, Facet_KnownAs, this.KnownAs());
        this.tryApplyPart(query, Facet_Occupation, this.Occupation());
        this.tryApplyPart(query, Facet_DateOfBirth, this.DateOfBirth());
        this.tryApplyPart(query, Facet_Habitat, this.Habitat());
        this.tryApplyPart(query, Facet_Phone, this.Phone());
        this.tryApplyPart(query, Facet_Mobile, this.Mobile());
        this.tryApplyPart(query, Facet_Email, this.Email());
        this.tryApplyPart(query, Facet_Postcode, this.Postcode());
        this.tryApplyPart(query, Facet_Locality, this.Locality());
        this.tryApplyPart(query, Facet_Street, this.Street());
        this.tryApplyPart(query, Facet_Postal1, this.Postal1());
        this.tryApplyPart(query, Facet_Postal2, this.Postal2());
        this.tryApplyPart(query, Facet_Postal3, this.Postal3());
        this.tryApplyPart(query, Facet_StreetNum, this.StreetNum());

        this.applyInclusionSetFlags(query);
        this.applyQueryPartsFromUpdatedModel(query);

        const queryString = query.toString();

        this.freezeQueryString();

        this.QueryString(queryString);

        this.unfreezeQueryString();
    }
    applyInclusionSetFlags(query: PeopleLookupQuery) {
        if (this.IncludeElectors())
            query.applyInclusionSet(InclusionSet.Electors);
        if (this.IncludeNonElectors())
            query.applyInclusionSet(InclusionSet.NonElectors);
        if (this.IncludeVolunteers())
            query.applyInclusionSet(InclusionSet.Volunteers);
        if (this.IncludeElectorsWithInteractionsInLast12Months())
            query.applyInclusionSet(InclusionSet.ElectorsWithInteractionInLast12Months);
    }
    dispose(): void {
        for (const sub of this.subscriptions) {
            sub.dispose();
        }
        this.subscriptions = [];
    }
}