import { ElectorEntryItemDisplayFormatter } from './contracts';
import { AUElectorEntryItemDisplayFormatter, NZElectorEntryItemDisplayFormatter } from './display-utils';


const localeConfigs: { [countryCode: string]: ILocaleConfiguration } = {
    "en-AU": {
        mobileInputMask:  "0499 999 999",
        mobileFormatChars: {
            "9": "[0-9]",
            "4": "[4]"
        },
        phoneInputMask: "(09) 9999 9999",
        countryCode: "AU",
        electorEntryFormatter: AUElectorEntryItemDisplayFormatter
    },
    "en-NZ": {
        mobileInputMask: "029999999??",
        mobileFormatChars: {
            "9": "[0-9]",
            "a": "[A-Za-z]",
            "?": "[0-9]?",
            "*": "[A-Za-z0-9]"
        },
        phoneInputMask: "0a 999 9999",
        phoneFormatChars: {
            "a": "[2-9]"
        },
        countryCode: "NZ",
        electorEntryFormatter: NZElectorEntryItemDisplayFormatter
    }
};

export interface ILocaleConfiguration {
    mobileInputMask: string;
    phoneInputMask: string;
    countryCode: string;
    mobileFormatChars?: { [char: string]: string };
    phoneFormatChars?: { [char: string]: string };
    electorEntryFormatter: ElectorEntryItemDisplayFormatter;
}

export function getLocaleConfiguration(): ILocaleConfiguration {
    return localeConfigs[getLocale()];
}

export function getRequestedLocaleConfiguration(locale: string): ILocaleConfiguration {
    return localeConfigs[locale];
}

export const DEFAULT_LOCALE = "en-AU";

let _locale = DEFAULT_LOCALE;

export function getLocale() { return _locale; }

export function setLocale(locale: string) { _locale = locale; }