import * as React from "react";

import { Icons } from "@electrac/plugin";
import { ISurveyInstructionProps } from './instruction';



export const SurveyPetition = (props: ISurveyInstructionProps) => {
    
    const { theme, label } = props;
    return <div className={theme && theme.getFieldContainerClass()}>
        <div className={theme && theme.getQuestionLabelClass()}>
            <label className={theme && theme.itemLabelClass}>{label}</label>
        </div>
        <div className={theme && theme.getQuestionBodyClass()}>
            <strong>{Icons.TAG} Petition content: </strong> {props.text}
        </div>
    </div>;
};