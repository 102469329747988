import * as React from 'react';
import { CCError, InteractionModelProperties, BaseInteractionType } from '@electrac/api';
import { Icons } from '@electrac/plugin';
import Alert = require("react-bootstrap/lib/Alert");
import Button = require("react-bootstrap/lib/Button");
import ControlLabel = require("react-bootstrap/lib/ControlLabel");
import FormGroup = require("react-bootstrap/lib/FormGroup");

import OverlayTrigger = require("react-bootstrap/lib/OverlayTrigger");
import Popover = require("react-bootstrap/lib/Popover");
import { LinkType, InteractionSaveModel, IdNamePair, AttributionType, InteractionModel } from "@electrac/model";
import { BsLoadSpinner } from '@electrac/components';
import { ModalDialog } from '@electrac/components';
import { InputField } from '@electrac/components';
//TODO: VirtualizedSelect is only being pulled here because it is needed by a component below that is only for the mobile app
//Just move that component over to the mobile app instead
import VirtualizedSelect from 'react-virtualized-select';
//import { DateTimePicker } from "react-widgets";


import * as DateTimePicker from 'react-widgets/lib/DateTimePicker';


import { RowItemHeightFunc, RowItemFunc, VirtualListGroup } from '@electrac/components';
import moment = require("moment");

const EMPTY_LIST = [];


/**
 * Indicates if the given bit mask contains the given bit
 * @param mask
 * @param bit
 */
export function hasBit(mask: number, bit: number) {
    return (mask & bit) == bit;
}

export type InteractionDisplayType = "Interaction" | "InDirectInteraction" | "GroupInteraction" | "MultiInteraction" | "PositionInteraction" | "AddressInteraction";

/**
 * Gets the display interaction type for the given interaction
 * @param interaction
 */
export function getInteractionDisplayType(interaction: Readonly<InteractionModel>): InteractionDisplayType {
    const type = interaction.InteractionType;
    if (type == "Interaction") {
        const at: AttributionType = interaction.AttributionType || 0;
        if (hasBit(at, AttributionType.Contact_With_Electors_Household_Or_Family) ||
            hasBit(at, AttributionType.Contact_With_Electors_Phone_Number) ||
            hasBit(at, AttributionType.Bulk)) {
            return "InDirectInteraction";
        }
    }

    switch (type) {
        case "PositionInteraction":
            return "PositionInteraction";
        case "GroupInteraction":
            return "GroupInteraction";
        case "MultiInteraction":
            return "MultiInteraction";
        case "AddressInteraction":
            return "AddressInteraction";
        default:
            return "Interaction";
    }
}




/**
 * Indicates if the given interaction is a bulk interaction
 * @param interaction
 */
export function isBulkInteraction(interaction?: Readonly<InteractionModel>): boolean {
    if (!interaction) return false;
    
    const tags = interaction.Tags || EMPTY_LIST;
    const bt: BaseInteractionType = interaction.InteractionType as BaseInteractionType;
    const it: InteractionDisplayType = getInteractionDisplayType(interaction);
    return (it == "InDirectInteraction" || bt == "MultiInteraction" || tags.indexOf("Not Answered") > -1);
}



//require("react-widgets/lib/css/react-widgets.css");

export type IInteractionListItem = (InteractionModel & { isCollapsed: boolean });


export interface IVirtualizedInteractionPanelProps {
    hasMoreInteractions: boolean;
    interactions: Readonly<IInteractionListItem>[];
    interactionItemRenderer?: (itemHeight: number, interaction: Readonly<InteractionModel>) => JSX.Element;
    headerRenderer?: (width: any) => JSX.Element;
    footerRenderer?: (width: any) => JSX.Element;
    noItemsRenderer?: () => JSX.Element;
    width: any;
    height: any;
    onAddInteraction: () => void;
    onLoadMoreInteractions: () => void;
    toolbarPlacement?: "top" | "bottom";
    rootClassName?: string;
    itemClassName?: string | RowItemFunc<string>;
    itemHeight?: RowItemHeightFunc;
    notify: (title: string, msg: string, opts: any) => void;
}

export const InteractionTag = (props) => {
    return <span className="label label-info">{Icons.TAG} {props.value}</span>;
};

export const DefaultInteractionListItem = (props: { itemHeight: number, interaction: Readonly<InteractionModel> }) => {
    const { interaction, itemHeight } = props;
    //NOTE: May display dates "off-by-one" as this interaction display considers the time portion
    //where the partial view version does not.
    const date = moment(interaction.Date);
    let tags = interaction.Tags;
    if (tags == null) {
        tags = [];
    }
    
    return <div key={interaction.Id}>
        <div className="col-md-3 col-xs-2 alert alert-info" style={{ height: `${itemHeight}px`}}>
            {(() => {
                if (date.isValid()) {
                    return <div style={{ position: "relative", top: "50%", transform: "translateY(-50%)" }}>
                        <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "12pt", padding: 0, margin: 0 }}>{date.format("MMM") }</div>
                        <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "24pt", padding: 0, margin: 0 }}>{date.format("DD") }</div>
                        <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "12pt", padding: 0, margin: 0 }}>{date.format("YYYY") }</div>
                    </div>;
                } else {
                    return <div style={{ position: "relative", top: "50%", transform: "translateY(-50%)" }}>
                        <div style={{ textAlign: "center", fontWeight: "bold", fontSize: "24pt", padding: 0, margin: 0 }}>N/A</div>
                    </div>
                }
            })()}
        </div>
        <div className="col-md-9 col-xs-10 well" style={{ height: `${itemHeight}px`}}>
            <p><strong>{interaction.Topic}</strong></p>
            <p style={{ maxHeight: `${itemHeight - 30}px`, textOverflow: "ellipsis" }}>{interaction.Details}</p>
            {(() => {
                if (tags.length > 0) {
                    if (tags.length == 1) {
                        return <p><InteractionTag value={tags[0]} /></p>;
                    } else {
                        const po = <Popover id={`tags-${interaction.Id}`} title="Tags">
                            {tags.map(t => {
                                return <p key={t}>{Icons.TAG} {t}</p>;
                            })}
                        </Popover>;
                        return <p>
                            <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={po}>
                                <Button bsStyle="info" bsSize="xsmall">{Icons.TAG} {tags.length} tags</Button>
                            </OverlayTrigger>
                        </p>;
                    }
                } else {
                    return null;
                }
            })()}
        </div>
    </div>;
};

// used by the mobile app

export class VirtualizedInteractionPanel extends React.Component<IVirtualizedInteractionPanelProps, any> {
    private fnNoItems: () => JSX.Element;
    private fnAddInteraction: (e) => void;
    private fnLoadMoreInteractions: (e) => void;
    constructor(props) {
        super(props);
        this.fnNoItems = this.onNoItems.bind(this);
        this.fnAddInteraction = this.onAddInteraction.bind(this);
        this.fnLoadMoreInteractions = this.onLoadMoreInteractions.bind(this);
    }
    private listRowItemRenderer=(itemHeight: number, interaction: Readonly<InteractionModel>): JSX.Element => {
        if (this.props.interactionItemRenderer) {
            return this.props.interactionItemRenderer(itemHeight, interaction);
        } else {
            return <DefaultInteractionListItem interaction={interaction} itemHeight={itemHeight} />;
        }
    }
    private onNoItems(): JSX.Element {
        if (this.props.noItemsRenderer) {
            return this.props.noItemsRenderer();
        } else {
            return <Alert bsStyle="info">
                <strong>{Icons.EXCLAMATION} No interactions found</strong>
            </Alert>;
        }
    }
    private onAddInteraction(e) {
        const { onAddInteraction } = this.props;
        onAddInteraction();
    }
    private onLoadMoreInteractions(e) {
        const { onLoadMoreInteractions } = this.props;
        onLoadMoreInteractions();
    }
    componentWillReceiveProps(nextProps: IVirtualizedInteractionPanelProps) {
        //We're transitioning to hasMoreInteractions = false
        if (this.props.hasMoreInteractions != nextProps.hasMoreInteractions && !nextProps.hasMoreInteractions) {
            this.props.notify("Load More Interactions", "No more interactions", {
                position: "top",
                effect: "stackslide"
            });
        }
    }
    /**
     * Call this to force a re-render of the Virtual Scrolling container. As a performance optimization, the virtual
     * scrolling container only does shallow comparison. Underlying list changes will pass this shallow comparsion check
     */
    forceUpdateList(): void {
        const list = this.refs['interaction-list-group'] as VirtualListGroup;
        if (list) {
            list.forceUpdateListGroup();
        }
    }
    render(): JSX.Element {
        const { width, height, interactions, hasMoreInteractions, itemHeight: hf, toolbarPlacement, headerRenderer, footerRenderer } = this.props;

        const itemHeight= 120;
        const tbHeight = 38; //Computed height of a btn-group-justified
        const list = <VirtualListGroup ref='interaction-list-group'
                                       list={interactions}
                                       width={width}
                                       height={height - tbHeight}
                                       heightPerRow={itemHeight}
                                       rootClassName={this.props.rootClassName}
                                       itemClassName={this.props.itemClassName}
                                       noItemsRenderer={this.fnNoItems}
                                       rowItemContentRenderer={( interaction: Readonly<InteractionModel>)=>this.listRowItemRenderer( itemHeight, interaction) } />;

        if (headerRenderer && footerRenderer) {
            return <div>
                {headerRenderer(width)}
                {list}
                {footerRenderer(width)}
            </div>;
        } else {
            const toolbar = <div style={{ height: tbHeight, width: width }}>
                <div className="btn-group btn-group-justified" role="group">
                    <div className="btn-group" role="group">
                        <button type="button" className="btn btn-primary interaction-action-add" onClick={this.fnAddInteraction}>{Icons.PLUS} Add interaction</button>
                    </div>
                    <div className="btn-group" role="group">
                        <button type="button" className="btn btn-primary" disabled={!hasMoreInteractions} onClick={this.fnLoadMoreInteractions}>{Icons.ELLIPSIS} Load more</button>
                    </div>
                </div>
            </div>;
            if (toolbarPlacement == "top") {
                return <div>
                    {toolbar}
                    {list}
                </div>;
            } else {
                return <div>
                    {list}
                    {toolbar}
                </div>;
            }
        }
    }
}

export interface INewInteractionDialogProps {
    officeID: number;
    electorID: number;
    show: boolean;
    onClose: () => void;
    onSave: (inter: InteractionSaveModel, callback: () => void, errBack: (err: CCError) => void) => void;
    onTopicsRequested: (callback: (topics: IdNamePair[]) => void, errBack: (err: CCError) => void) => void;
    //onTagsRequested: (text: string, callback: (tags: IdNamePair[]) => void) => void;
}

/**
 * New interaction dialog (for mobile app)
 */
export class NewInteractionDialog extends React.Component<INewInteractionDialogProps, any> {
    private fnSaveInteraction: () => void;
    constructor(props) {
        super(props);
        this.fnSaveInteraction = this.onSaveInteraction.bind(this);
        this.state = {
            availableTopics: null,
            availableTags: null,
            actions: [{
                icon: Icons.TICK,
                label: "Save",
                disabled: false,
                onClick: this.fnSaveInteraction
            }],
            saveError: null,
            interaction: {}
        };
    }
    componentDidMount() {
        const { onTopicsRequested } = this.props;
        onTopicsRequested(topics => {
            this.setState({
                availableTopics: topics.map(v => { return { label: v.Name, value: v.Id }; }),
                saveError: null
            });
        }, err => {
            this.setState({
                saveError: err.message
            });
        });
    }
    private buildInteractionFromState(): InteractionSaveModel {
        const { electorID, officeID } = this.props;
        const { interaction } = this.state;
        return {
            OfficeID: officeID,
            LinkId: electorID,
            Details: interaction.Details,
            Tags: interaction.Tags != null ? interaction.Tags.map((v, i) => v.value) : null,
            Due: interaction.Due != null ? moment(interaction.Due).utc().toISOString() : undefined,
            Todo: interaction.Todo,
            AssignedTopic: interaction.Topic,
            LinkType: LinkType.Elector,
            Level: interaction.Level,
            IsDone: interaction.IsDone,
            Priority: interaction.Priority,
            FilesToAttach: []
        };
    }
    private onSaveInteraction() {
        const actions = this.state.actions;
        actions[0].icon = Icons.REFRESH_SPIN;
        actions[0].label = "Saving ...";
        actions[0].disabled = true;
        this.setState({ actions: actions, saveError: null });

        const inter = this.buildInteractionFromState();
        this.props.onSave(inter, () => {
            const acts = this.state.actions;
            acts[0].icon = Icons.TICK;
            acts[0].label = "Save";
            acts[0].disabled = false;
            this.setState({ actions: acts });
        }, (err) => {
            const acts = this.state.actions;
            acts[0].icon = Icons.TICK;
            acts[0].label = "Save";
            acts[0].disabled = false;
            this.setState({ actions: acts, saveError: err.message });
        });
    }
    private trackFieldChange(field: InteractionModelProperties, value: any) {
        const { interaction } = this.state;
        if (field === "Topic") {
            if (value != null)
                interaction[field] = { Id: value.value, Name: value.label };
            else
                delete interaction[field];
        } else {
            interaction[field] = value;
        }
        this.setState({ interaction: interaction });
    }

    render(): JSX.Element {
        const { actions, interaction, saveError } = this.state;
        //NOTE: allowCreate doesn't work as advertised on VirtualizedSelect (https://github.com/JedWatson/react-select/issues/568#issuecomment-153785740), meaning this can only accept whatever existing tags are available
        const err = saveError != null ? <Alert bsStyle="danger">{Icons.EXCLAMATION} Error saving interaction: {saveError}</Alert> : null;
        return <ModalDialog show={this.props.show}
            title="New Interaction"
            actions={actions}
            maxBodyHeight={"320px"}
            showCloseButton={true}
            onClose={this.props.onClose}>
            <div>
                {err}
                <FormGroup controlId="topic">
                    <ControlLabel>Topic</ControlLabel>
                    {(() => {
                        if (this.state.availableTopics != null) {
                            const selectedTopic = interaction.Topic || {};
                            return <VirtualizedSelect multi={false} searchable={true} value={selectedTopic.Id} options={this.state.availableTopics} onChange={this.trackFieldChange.bind(this, "Topic") } />;
                        } else {
                            return <BsLoadSpinner message="Loading available topics..." />;
                        }
                    })() }
                </FormGroup>
                {/*
                <InputField id="topic" type="text" size="small" label="Topic" value={interaction.Topic || ""} onChange={this.trackFieldChange.bind(this, "Topic")} />
                */}
                <InputField id="details" type="textarea" size="small" label="Details" value={interaction.Details || ""} onChange={this.trackFieldChange.bind(this, "Details") } />
                {/*
                <FormGroup controlId="tags">
                    <ControlLabel>Tags</ControlLabel>
                    {(() => {
                        if (this.state.availableTags != null) {
                            return <VirtualizedSelect multi={true} searchable={true} value={interaction.Tags || []} options={this.state.availableTags} onChange={this.trackFieldChange.bind(this, "Tags")} />;
                        } else {
                            return <LoadSpinner message="Loading available tags..." />;
                        }
                    })()}
                </FormGroup>
                */}
                <InputField id="todo" type="textarea" size="small" label="Todo" value={interaction.Todo || ""} onChange={this.trackFieldChange.bind(this, "Todo") } />
                <FormGroup controlId="due">
                    <ControlLabel>Due Date</ControlLabel>
                    <div>
                        {(() => {
                            //HACK: Workaround dropUp not being in d.ts
                            const dtProps: any = {
                                format: "D MMM YYYY",
                                value: interaction.Due,
                                onChange: this.trackFieldChange.bind(this, "Due"),
                                time: false,
                                dropUp: true
                            };
                            return <DateTimePicker {...dtProps} />;
                        })()}
                    </div>
                </FormGroup>
            </div>
        </ModalDialog>;
    }
}