import * as React from "react";
import * as DropdownList from "react-widgets/lib/DropdownList";
import { GenericEventHandler, ITextValuePair } from './common';

export interface ILookupValueSelectorProps {
    value: string;
    placeholder: string;
    onChange: GenericEventHandler;
    items: ITextValuePair[];
    allowSearchFiltering?: boolean;
}

export const LookupValueSelector = (props: ILookupValueSelectorProps) => {
    const handler = (e: any) => {
        props.onChange(e.Value);
    }
    const filter = props.allowSearchFiltering === true ? "contains" : false;
    return <DropdownList placeholder={props.placeholder} onChange={handler} value={props.value || ""} data={props.items} textField="Text" valueField="Value" filter={filter} />;
}