/**
 * common.ts
 *
 * This is the entry point for the common script bundle.
 *
 * NOTE: We are not using webpack to produce this bundle
 */

//EXPOSING COMPONENTS THAT ARE COMMON TO CC PROPER AND MOBILE AND PLUGINS. BASE COMMON TO EVERYTHING.
//KEEP AS SMALL AS B
require("react-widgets/dist/css/react-widgets.css");

import "whatwg-fetch";

import * as ElectracAPI from "@electrac/model";
import * as Config from "@electrac/survey/src/locale";


import * as SurveyForm from "@electrac/survey/src/container";
import * as SurveyChoiceField from "@electrac/survey/src/fields/choice";
import * as SurveyDateField from "@electrac/survey/src/fields/date";
//import { SurveyInstruction } from "./components/survey/fields/Instruction";
import * as SurveyMobileField from "@electrac/survey/src/fields/mobile";
//import { SurveyRemarks } from "./components/survey/fields/Remarks";
import * as SurveyTextField from "@electrac/survey/src/fields/text";
import * as SurveyVoteIntentionField from "@electrac/survey/src/fields/voteintention";
import * as SurveyYesNoField from "@electrac/survey/src/fields/yesno";


import * as DisplayUtils from "./display-utils";
// import * as ReduxFormUtils from "./utils/ReduxFormUtils";
//import * as NormaliseDropdownObjToVal from "./normalisers/DropdownNormalisers";
import { BootstrapSurveyTheme, ClassicSurveyTheme } from '@electrac/survey/src/SurveyContainerTheme';
// import * as ReduxUtils from "./store/ConfigureStore";

import * as ManualSurvey from "@electrac/survey/src/ManualSurveyEntry";
import { Icons } from "@electrac/plugin";
import * as APIHelper from '@electrac/api/src/apicontext'
import * as ClientAPI from '@electrac/api/src/client-api';
import * as APIContracts from '@electrac/api/src/contracts'




//NOTE: Exports are done in this manner (flattened) to ensure that any exported interfaces will be preserved in
//the re-export below. Remember that the concept of interfaces is only specific to TypeScript

import { BsLoadSpinner } from '@electrac/components';

// preserve legacy shapes
const BootstrapLoadSpinner = { BsLoadSpinner };
const CommonIcons = { Icons };

export {
    CommonIcons,
    BootstrapLoadSpinner,
    SurveyForm,
    SurveyChoiceField,
    SurveyDateField,
    SurveyMobileField,
    SurveyTextField,
    SurveyVoteIntentionField,
    SurveyYesNoField,
    ManualSurvey,
    BootstrapSurveyTheme,
    ClassicSurveyTheme,
    Config,
    
    
    ClientAPI,
    ElectracAPI,
    APIContracts,
    DisplayUtils,
    APIHelper,
    // ReduxFormUtils,
    // ReduxUtils,
    // Notify,

    //Normalisers
    //NormaliseDropdownObjToVal
};

