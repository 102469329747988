import {
    SurveyQuestionType
} from "./contracts";

import { ISurveyContainerTheme, SurveyContainerStyle, SurveyButtonStyle } from "@electrac/plugin";

export const DEBUG_QUESTION_TYPE = "DEBUG_QUESTION_TYPE";
export const UNSUPPORTED_QUESTION_KIND = "UNSUPPORTED_QUESTION_KIND";
export const MISSING_VOTE_MODEL = "MISSING_VOTE_MODEL";

export class Umb_AlpPrincipleSurveyTheme implements ISurveyContainerTheme {
    containerClass: string;
    itemClass: string;
    itemLabelClass: string;
    constructor() {
        this.containerClass = "list-group";
        this.itemClass = "list-group-item";
        this.itemLabelClass = "question-label";
    }
    getQuestionLabelClass(): string {
        return "";
    }
    getQuestionBodyClass(): string {
        return "";
    }
    getItemLabelClassForQuestion(qkind: string, defaultClass: string): string {
        return defaultClass;
    }
    getItemClassForQuestion(qkind: string, defaultClass: string): string {
        const classNames = [defaultClass];

        switch (qkind) {
            case SurveyQuestionType.Instruction:
                classNames.push("list-group-item-warning");
                break;
            case SurveyQuestionType.Remarks:
                classNames.push("list-group-item-info");
                break;
            case MISSING_VOTE_MODEL:
            case DEBUG_QUESTION_TYPE:
            case UNSUPPORTED_QUESTION_KIND:
                classNames.push("list-group-item-danger");
                break;
        }

        return classNames.join(" ");
    }
    getContainerClasses(style: SurveyContainerStyle): string {
        const classNames = [""];
        switch (style) {
            case SurveyContainerStyle.Error:
                classNames.push("alert alert-danger");
                break;
            case SurveyContainerStyle.Info:
                classNames.push("alert alert-info");
                break;
            case SurveyContainerStyle.Success:
                classNames.push("alert alert-success");
                break;
            case SurveyContainerStyle.RespondentDetails:
                classNames.push("respondentDetailsBox");
                break; 

            case SurveyContainerStyle.SurveyContainer:
                classNames.push("surveyBox");
                break; 

                
        }
        return classNames.join(" ");
    }
    getButtonClass(desiredStyle?: SurveyButtonStyle): string {
        const classNames = [""];
        const defaultClass = "btn a-btn white-btn";

        switch (desiredStyle) {
            case SurveyButtonStyle.Primary:
                classNames.push(defaultClass);
                break;
            case SurveyButtonStyle.Info:
                classNames.push("btn a-btn red-btn");
                break;
            case SurveyButtonStyle.Danger:
                classNames.push(defaultClass);
                break;
            case SurveyButtonStyle.Info:
                classNames.push(defaultClass);
                break;
            case SurveyButtonStyle.Success:
                classNames.push(defaultClass);
                break;
            case SurveyButtonStyle.Warning:
                classNames.push(defaultClass);
                break;


                
        }
        return classNames.join(" ");
    }
    getFieldContainerClass(): string {
        return "";
    }
    getTextboxClass(): string {
        return "";
    }
    getSelectClass(): string {
        return "";
    }
}

export class ClassicSurveyTheme implements ISurveyContainerTheme {
    containerClass: string;
    itemClass: string;
    itemLabelClass: string;
    constructor() {
        this.containerClass = "classic-survey-container";
        this.itemClass = "el-question-box";
        this.itemLabelClass = "el-question-text el-question-text-emulated";
    }
    getQuestionLabelClass(): string {
        return "col-xxs-12 col-xs-12 col-sm-8 col-md-8 col-lg-6";
    }
    getQuestionBodyClass(): string {
        return "col-xxs-12 col-xs-12 col-sm-4 col-md-4 col-lg-6";
    }
    getItemLabelClassForQuestion(qkind: string, defaultClass: string): string {
        var classNames = [defaultClass];
        switch (qkind) {
            case DEBUG_QUESTION_TYPE:
            case UNSUPPORTED_QUESTION_KIND:
            case MISSING_VOTE_MODEL:
        }
        return classNames.join(" ");
    }
    getItemClassForQuestion(qkind: string, defaultClass: string): string {
        const classNames = [defaultClass];
        switch (qkind) {
            case SurveyQuestionType.Instruction:
                classNames.push("el-instructionI");
                break;
            case SurveyQuestionType.Remarks:
                classNames.push("el-instructionR");
                break;
        }
        return classNames.join(" ");
    }
    getContainerClasses(style: SurveyContainerStyle): string {
        const classNames = ["k-block k-block-pad-this"];
        switch (style) {
            case SurveyContainerStyle.Error:
                classNames.push("k-error-colored");
                break;
            case SurveyContainerStyle.Info:
                classNames.push("k-info-colored");
                break;
            case SurveyContainerStyle.Success:
                classNames.push("k-success-colored");
                break;
            case SurveyContainerStyle.RespondentDetails:
                classNames.push("well");
                break; 

            case SurveyContainerStyle.SurveyContainer:
                classNames.push("");
                break; 
        }
        return classNames.join(" ");
    }
    getButtonClass(desiredStyle?: SurveyButtonStyle): string {
        const classNames = ["k-button"];
        switch (desiredStyle) {
            case SurveyButtonStyle.Primary:
                classNames.push("k-primary");
        }
        return classNames.join(" ");
    }
    getFieldContainerClass(): string {
        return "clearfix";
    }
    getTextboxClass(): string {
        return "k-textbox";
    }
    getSelectClass(): string {
        return "";
    }
}

export class BootstrapSurveyTheme implements ISurveyContainerTheme {
    containerClass: string;
    itemClass: string;
    itemLabelClass: string;
    constructor() {
        this.containerClass = "list-group";
        this.itemClass = "list-group-item";
        this.itemLabelClass = "question-label";
    }
    getQuestionLabelClass(): string {
        return "";
    }
    getQuestionBodyClass(): string {
        return "";
    }
    getItemLabelClassForQuestion(qkind: string, defaultClass: string): string {
        return defaultClass;
    }
    getItemClassForQuestion(qkind: string, defaultClass: string): string {
        const classNames = [defaultClass];

        switch (qkind) {
            case SurveyQuestionType.Instruction:
                classNames.push("list-group-item-warning");
                break;
            case SurveyQuestionType.Remarks:
                classNames.push("list-group-item-info");
                break;
            case MISSING_VOTE_MODEL:
            case DEBUG_QUESTION_TYPE:
            case UNSUPPORTED_QUESTION_KIND:
                classNames.push("list-group-item-danger");
                break;
        }

        return classNames.join(" ");
    }
    getContainerClasses(style: SurveyContainerStyle): string {
        const classNames = ["alert"];
        switch (style) {
            case SurveyContainerStyle.Error:
                classNames.push("alert-danger");
                break;
            case SurveyContainerStyle.Info:
                classNames.push("alert-info");
                break;
            case SurveyContainerStyle.Success:
                classNames.push("alert-success");
                break;

            case SurveyContainerStyle.RespondentDetails:
                classNames.push("well");
                break;     

            case SurveyContainerStyle.SurveyContainer:
                classNames.push("");
                break; 
        }
        return classNames.join(" ");
    }
    getButtonClass(desiredStyle?: SurveyButtonStyle): string {
        const classNames = ["btn"];
        switch (desiredStyle) {
            case SurveyButtonStyle.Primary:
                classNames.push("btn-primary");
                break;
            case SurveyButtonStyle.Danger:
                classNames.push("btn-danger");
                break;
            case SurveyButtonStyle.Info:
                classNames.push("btn-info");
                break;
            case SurveyButtonStyle.Success:
                classNames.push("btn-success");
                break;
            case SurveyButtonStyle.Warning:
                classNames.push("btn-warning");
                break;
        }
        return classNames.join(" ");
    }
    getFieldContainerClass(): string {
        return "form-group";
    }
    getTextboxClass(): string {
        return "form-control";
    }
    getSelectClass(): string {
        return "form-control";
    }
}