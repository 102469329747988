import * as React from 'react';


///does not come with a spinner. define your own spinner style under the root classname ml-spinner
///this is to allow full control of your spinner style
/// http://tobiasahlin.com/spinkit/ is a good place for pure css spinners
export interface ISecretSpinShowProps {
    show: boolean;
    onShow?: Function; //potentially very expensive func. do not use unless really need to.

    spin?: boolean;
    spinnerRootClassName?: string;

    secret?: boolean; //actually renders empty fragment
    secretDisplay?: JSX.Element;

    hide?: boolean; //sets display none
}


export class SecretSpinShow extends React.Component<ISecretSpinShowProps, any> {

    constructor(props: ISecretSpinShowProps) {
        super(props);

    }

    componentWillReceiveProps(thisProps: ISecretSpinShowProps, nextProps: ISecretSpinShowProps) {

        if (thisProps.onShow && thisProps.show) {
            thisProps.onShow();
        }
    }

    render(): JSX.Element {

        const { show, spin, hide, children, spinnerRootClassName, secretDisplay, secret } = this.props;

        if (spin) {
            return <div className={(spinnerRootClassName ? spinnerRootClassName : "ml-spinner")}>
                <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                </div>
            </div>;
        }

        
        if (show) return <>{children}</>;
        
        if (secret && secretDisplay) return secretDisplay;

        // needed for elements like stripe card that we need to be preserved, but hidden
        if (hide) return <div style={{ display: "none" }}>{children}</div>;


        return <noscript />;


    }

}


