import * as React from "react";
import {arrayIntersperse} from "@ml/common";
import { WalkListPerson } from '@electrac/model';

const AULevelDescription = (props) => {
    const value = props.value;
    if (value != null /* isNaN(null) == false. WTF!? */ && !isNaN(value)) {
        if (value >= 0.0 && value <= 1.0) {
            if (value <= 0.33) {
                return <span className="label label-danger">Low</span>;
            } else if (value > 0.33 && value <= 0.66) {
                return <span className="label label-warning">Med</span>;
            } else if (value > 0.66 && value <= 0.95) {
                return <span className="label label-success">High</span>;
            } else {
                return <span className="label label-info">V.High</span>;
            }
        } else {
            return <span className="label label-default">Unknown</span>;
        }
    } else {
        return <span className="label label-default">Unknown</span>;
    }
};


export function NZElectorEntryItemDisplayFormatter(e: WalkListPerson): JSX.Element {
    const xdata = e.xdata["NZLC"]; //NZ Labour Connect
    const parts: JSX.Element[] = [];

    if (xdata) {
        if (xdata.AgeBand != null) {
            parts.push(<span key={`ageband-${e.electorID}`} className="label label-info">Band: {xdata.AgeBand}</span>);
        }
        if (xdata.RollSource != null) {
            parts.push(<span key={`rollsource-${e.electorID}`} className="label label-primary">Roll: {xdata.RollSource}</span>);
        }
        if (xdata.Occupation != null) {
            parts.push(<span key={`occupation-${e.electorID}`} className="label label-warning" >Occupation: {xdata.Occupation}</span>);
        }
    }
    const fullParts = arrayIntersperse(parts, <>, </>);
    if (fullParts.length > 0) {
        return <span>{e.firstName} {e.lastName} ({e.gender}, {fullParts.map(p => p)}) </span>;
    } else {
        return <span>{e.firstName} {e.lastName} ({e.gender}) </span>;
    }
}


export function AUElectorEntryItemDisplayFormatter(e: WalkListPerson): JSX.Element {
    const xdata = e.xdata["AU"]; // ALP
    const udata = e.xdata["UC"]; // Union Central

    const parts: JSX.Element[] = [];

    if (xdata) {

        if (xdata.TopIssueName != null) {
            parts.push(<span key={`issue-${e.electorID}`}>Top Issue: {xdata.TopIssueName }</span>);
        }
        if (xdata.Supporter != null) {
            parts.push(<span key={`support-${e.electorID}`}>Labor Supporter Score: <AULevelDescription value={parseFloat(xdata.Supporter) } /></span>);
        }
        if (xdata.Persuasion != null) {
            parts.push(<span key={`persuade-${e.electorID}`}>Persuasion Score: <AULevelDescription value={parseFloat(xdata.Persuasion) } /></span>);
        }


    }

    if (udata) {
        if (udata.Issues != null) {
            parts.push(<span key={`issue-${e.electorID}`}>Issues: {udata.Issues }</span>);
        }
        if (udata.Union != null) {
            parts.push(<span key={`union-${e.electorID}`}>Union: {udata.Union }</span>);
        }
    }

    const fullParts = arrayIntersperse(parts, <>, </>);
    if (fullParts.length > 0) {
        return <span>{e.firstName } {e.lastName } ({e.age }/{e.gender }, {fullParts.map(p => p) }) </span>;
    } else {
        return <span>{e.firstName } {e.lastName } ({e.age }/{e.gender }) </span>;
    }
}
